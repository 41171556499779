import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import collect from "collect.js";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { firestoreConnect } from "react-redux-firebase";
import GenericBackHeader from "../../../components/GenericBackHeader";

class BookSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: 0,
      type: 0,
    };
  }

  renderBookSummary() {
    if (this.props.balances) {
      let s = this.props.balances;

      // let c = collect(s).filter((i) => {
      //    return i.balance >= 0 && !i.email.includes("+") && i.email !== "anon"
      // })

      let c = collect(s)
        .filter((i) => {
          return i.balance >= 0;
        })
        .map((i) => {
          return {
            balance: i.balance || 0,
            email: i.email,
            losingTickets: i.losingTickets || [],
            pendingBetCount: i.pendingBetCount || 0,
            pendingDepositAmt: i.pendingDepositAmt || 0,
            pendingDepositCount: i.pendingDepositCount || 0,
            pendingWithdrawalAmt: i.pendingWithdrawalAmt || 0,
            pendingWithdrawalCount: i.pendingWithdrawalCount || 0,
            totalBets: i.totalBets || 0,
            totalBonusAmt: i.totalBonusAmt || 0,
            totalBonusCount: i.totalBonusCount || 0,
            totalSeizureAmt: -1*i.totalCorrectionAmt || 0,
            totalDepositAmount: i.totalDepositAmount || 0,
            totalDepositCount: i.totalDepositCount || 0,
            totalFreeBetWin: i.totalFreeBetWin || 0,
            totalLoss: i.totalLoss || 0,
            totalPendingRisk: i.totalPendingRisk || 0,
            totalPendingWin: i.totalPendingWin || 0,
            totalWager: i.totalWager || 0,
            totalWin: i.totalWin || 0,
            totalWithdrawalAmt: i.totalWithdrawalAmt || 0,
            totalWithdrawalCount: i.totalWithdrawalCount || 0,
            username: i.username || "",
            weeklyLosses: i.weeklyLosses || 0,
            weeklyWins: i.weeklyWins || 0,
            winningTickets: i.winningTickets || [],
          };
        });

      console.log("C: ", c);
      // console.log("mapped: ",mapped)

      return (
        <TableContainer>
          <Table aria-label="simple table">
            <TableBody>
              <TableRow key={"bet"}>
                <TableCell component="th" scope="row">
                  Total Player Win
                </TableCell>
                <TableCell align="right" component="th" scope="row">
                  {Math.round(c.sum("totalWin") - c.sum("totalLoss")) || 0}
                </TableCell>
              </TableRow>
              <TableRow key={"bet"}>
                <TableCell style={{ borderBottom: "none" }} component="th" scope="row">
                  Total Deposit Amt
                </TableCell>
                <TableCell style={{ borderBottom: "none" }} align="right" component="th" scope="row">
                  {Math.round(c.sum("totalDepositAmount")) || 0}
                </TableCell>
              </TableRow>
              <TableRow key={"bet"}>
                <TableCell component="th" scope="row">
                  Total Bonus Amt
                </TableCell>
                <TableCell  align="right" component="th" scope="row">
                  {Math.round(c.sum("totalBonusAmt")) || 0}
                </TableCell>
              </TableRow>
              {/*<TableRow key={"bet"} >*/}
              {/*  <TableCell style={{borderBottom:"none"}} component="th" scope="row">Total Player Free Bet Win</TableCell>*/}
              {/*  <TableCell style={{borderBottom:"none"}} align="right" component="th" scope="row">*/}
              {/*    {isNaN(Math.round(c.sum("totalFreeBetWin"))) ? 0 : Math.round(c.sum("totalFreeBetWin"))}*/}
              {/*  </TableCell>*/}
              {/*</TableRow>*/}
              <TableRow key={"bet"}>
                <TableCell style={{ borderBottom: "none" }} component="th" scope="row">
                  Total Withdrawal
                </TableCell>
                <TableCell style={{ borderBottom: "none" }} align="right" component="th" scope="row">
                  {Math.round(c.sum("totalWithdrawalAmt")) || 0}
                </TableCell>
              </TableRow>
              <TableRow key={"bet"}>
                <TableCell style={{ borderBottom: "none" }} component="th" scope="row">
                  Total Seizures
                </TableCell>
                <TableCell style={{ borderBottom: "none" }} align="right" component="th" scope="row">
                  {Math.round(c.sum("totalSeizureAmt")) || 0}
                </TableCell>
              </TableRow>
              <TableRow key={"bet"}>
                <TableCell style={{ borderBottom: "none" }} component="th" scope="row">
                  Total Pending Withdrawals
                </TableCell>
                <TableCell style={{ borderBottom: "none" }} align="right" component="th" scope="row">
                  {Math.round(c.sum("pendingWithdrawalAmt")) || 0}
                </TableCell>
              </TableRow>
              <TableRow key={"bet"}>
                <TableCell component="th" scope="row">
                  Total Pending Wager Amt
                </TableCell>
                <TableCell align="right" component="th" scope="row">
                  {isNaN(Math.round(c.sum("totalPendingRisk"))) ? 0 : Math.round(c.sum("totalPendingRisk"))}
                </TableCell>
              </TableRow>
              <TableRow key={"bet"}>
                <TableCell  sx={{padding: .5}} component="th" scope="row" />
                <TableCell  sx={{padding: .5}} component="th" scope="row" />
              </TableRow>
              <TableRow key={"bet"}>
                <TableCell style={{ borderBottom: "none" }} component="th" scope="row">
                  Total Player Balances
                </TableCell>
                <TableCell style={{ borderBottom: "none" }} align="right" component="th" scope="row">
                  {Math.round(c.sum("balance")) || 0}
                </TableCell>
              </TableRow>

            </TableBody>
          </Table>
        </TableContainer>
      );
    }
  }

  renderRefLink() {
    if (this.props.groupConfigs.groupName) {
      let cleanName = this.props.groupConfigs.groupName.replaceAll(" ", "%20");
      return "https://superfunbet.com/join/" + cleanName;
    }
  }

  renderRefLinkWithBonus() {
    if (this.props.groupConfigs.groupName) {
      let cleanName = this.props.groupConfigs.groupName.replaceAll(" ", "%20");
      return "https://superfunbet.com/join/" + cleanName + "/free/10";
    }
  }

  render() {
    console.log("!! BOOK FINANCIAL SUMMARY this.props: ", this.props);
    return (
      <>
        <GenericBackHeader title="Book Financial Summary" showBackButton />
        {this.renderBookSummary()}
      </>
    );
  }
}

BookSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  balances: PropTypes.object,
  profile: PropTypes.object,
  groupConfigs: PropTypes.object,
  uid: PropTypes.string,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: "inline-block",
    marginTop: "20px",
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  grid: {
    textAlign: "center",
    marginBottom: "20px",
  },
});

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    email: state.firebase.auth.email,
    profile: state.firebase.profile,
    balances: state.firestore.data?.bookSummaryBalances,
  };
};

export default compose(
  connect(mapStateToProps),
  withRouter,
  firestoreConnect((props) => {
    return [
      {
        collection: `groups/${props.profile.managerGid}/balances`,
        storeAs: "bookSummaryBalances",
      },
    ];
  })
)(withStyles(styles)(BookSummary));
