import React, { Component } from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import { withRouter } from "react-router-dom";
import Grid from "@mui/material/Grid";
import { AutorenewSharp } from "@mui/icons-material";
import CoinFlip2 from "./CoinflipComponent/CoinFlip2";

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(1),
    margin: 2,
    fontSize: ".85rem",
    textAlign: "center",
    cursor: "pointer",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    ...theme.palette.custom.coinflipBetItemText,
  },
});

class CoinFlipBetItem2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      coinFlipOpen: false,
    };
  }

  _onClose = () => {
    this.setState({ coinFlipOpen: false });
  };

  _coinflipGameOptions = () => {
    const gameOptions = [];
    for (const option of this.props.flipOptions) {
      gameOptions.push({ option: option.wheelName || option.displayName, betSide: option.betSide });
    }
    return gameOptions;
  };

  render() {
    const { classes } = this.props;
    console.log("coinflip bet item props: ", this.props);
    return (
      <>
        <Paper
          className={classes.paper}
          variant="coinflip"
          elevation={0}
          onClick={() => {
            // if (this._coinflipGameOptions(this.props.game, this.props.allowedLines).length > 0) {
            // if (
            //   false &&
            //   1
            //   // !this.props.game?.standard[this.props.betPeriod]["moneyline"] ||
            //   // !this.props.game?.standard[this.props.betPeriod]["spread"] ||
            //   // !this.props.game?.standard[this.props.betPeriod]["total"]
            // ) {
            //   alert("sorry this bet is unavailable now");
            // } else
            if (!this.props.disable) {
              // getFirebase().analytics().logEvent("CLICK_COINFLIP");
              this.setState({ coinFlipOpen: true });
            }
            // }
          }}
        >
          <Grid container direction="column" justifyContent="center" alignItems="center" width="100%">
            <Grid item xs={12}>
              {this.props.flipOptions[0].displayName}{" "}
              {this.props.betPeriod === "1h" && this.props.betType === "ml" ? "(1H)" : ""}
            </Grid>
            <Grid item xs={12}>
              <AutorenewSharp fontSize="small" />
            </Grid>
            <Grid item xs={12}>
              {this.props.flipOptions[1].displayName}{" "}
              {this.props.betPeriod === "1h" && this.props.betType === "ml" ? "(1H)" : ""}
            </Grid>
          </Grid>
        </Paper>
        <CoinFlip2
          open={this.state.coinFlipOpen}
          onClose={this._onClose}
          gameOptions={this._coinflipGameOptions()}
          inFlight={this.props.inFlight}
          coinflipJuice={this.props.coinflipJuice}
          betPeriod={this.props.betPeriod}
          betType={this.props.betType}
          bucket={this.props.bucket}
          collection={this.props.collection}
          docId={this.props.docId}
          game={this.props.game}
        />
      </>
    );
  }
}

CoinFlipBetItem2.defaultProps = {
  offOverride: "Off",
};

CoinFlipBetItem2.propTypes = {
  classes: PropTypes.object,
  inFlight: PropTypes.bool,
  coinflipJuice: PropTypes.number,
  flipOptions: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      wheelName: PropTypes.string,
      side: PropTypes.string.isRequired,
    })
  ),
  game: PropTypes.object.isRequired,
  betPeriod: PropTypes.string.isRequired,
  betType: PropTypes.string.isRequired,
  bucket: PropTypes.string.isRequired,
  collection: PropTypes.string.isRequired,
  docId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
  disable: PropTypes.bool,
  lineSource: PropTypes.string.isRequired,
};

export default connect()(withStyles(styles)(withRouter(CoinFlipBetItem2)));
