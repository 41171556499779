import React, { Component } from "react";
import PropTypes from "prop-types";
import MenuItem from "@mui/material/MenuItem";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { firestoreConnect } from "react-redux-firebase";
import { ListItemText, Skeleton } from "@mui/material";
import { joinGroup } from "../reducers/register-reducer";

class ChangeGroupMenuItem extends Component {
  handleClick = () => {
    this.props.dispatch(joinGroup({ gid: this.props.gid }));
    this.props.closeCallback();
  };

  render() {
    return (
      <MenuItem key={this.props.gid} onClick={this.handleClick}>
        <ListItemText inset>
          {this.props.groupName && this.props.groupName || (
            <Skeleton variant="text" width={120}/>
          )}
        </ListItemText>
      </MenuItem>
    );
  }
}

ChangeGroupMenuItem.propTypes = {
  gid: PropTypes.string.isRequired,
  groupName: PropTypes.string,
  dispatch: PropTypes.func,
  closeCallback: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    groupName: state.firestore.data["groupName/" + props.gid]?.groupName
  };
};

export default compose(
  connect(mapStateToProps),
  withRouter,
  firestoreConnect((props) => {
    return [
      {
        collection: "groups",
        doc: props.gid,
        storeAs: "groupName/" + props.gid,
      },
    ];
  })
)(ChangeGroupMenuItem);
