import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { firestoreConnect, getFirebase } from "react-redux-firebase";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  ListItem,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import QueryStatsIcon from "@mui/icons-material/QueryStats";
import CloseIcon from "@mui/icons-material/Close";
import JuiceMap from "../OLD/Admin/screens/JuiceMap";
import { v4 } from "uuid";
import { awardBonus } from "../../reducers/cashier-reducer";
import List from "@mui/material/List";
import { getTimestamp } from "../../reducers/sportsbook-reducer";
import ToggleButton from "@mui/material/ToggleButton";
import { ToggleButtonGroup } from "@mui/lab";
import BetList2 from "../../components/BetDisplay/BetList2";
import FormHelperText from "@mui/material/FormHelperText";

class PlayerTool extends React.Component {
  constructor(props) {
    super(props);
    this.initialState = {
      uiState: "menu",
      juices: [0, 0.49, 0.98, 1.46, 1.92, 2.38, 2.83, 3.27, 3.7, 4.13, 4.55, 4.95, 5.36, 5.75, 6.14, 6.75],
      cashHistoryType: "bets",
    };
    this.state = this.initialState;
  }

  // helper functions
  getAmericanfromJuicePcnt = (num) => {
    return Object.keys(JuiceMap).find((key) => JuiceMap[key] === num);
  };
  _changeJuiceUp = (e) => {
    let currentJuice;
    if (this.props.balance.customJuice >= 0) currentJuice = this.props.balance.customJuice;
    else currentJuice = this.props.groupJuice;
    const playerJuice = getFirebase()
      .firestore()
      .collection("groups")
      .doc(this.props.playerGid)
      .collection("balances")
      .doc(this.props.playerUid);
    playerJuice.update({ customJuice: this.state.juices[this.state.juices.indexOf(currentJuice) + 1] });
  };
  _changeJuiceDown = (e) => {
    let currentJuice;
    if (this.props.balance.customJuice >= 0) currentJuice = this.props.balance.customJuice;
    else currentJuice = this.props.groupJuice;
    const playerJuice = getFirebase()
      .firestore()
      .collection("groups")
      .doc(this.props.playerGid)
      .collection("balances")
      .doc(this.props.playerUid);
    playerJuice.update({ customJuice: this.state.juices[this.state.juices.indexOf(currentJuice) - 1] });
  };
  _changeMaxBetUp = () => {
    let currentMaxBet;
    if (this.props.balance.customMaxBet >= 0) currentMaxBet = this.props.balance.customMaxBet;
    else currentMaxBet = this.props.groupMaxBet;
    const playerMaxBet = getFirebase()
      .firestore()
      .collection("groups")
      .doc(this.props.playerGid)
      .collection("balances")
      .doc(this.props.playerUid);
    playerMaxBet.update({ customMaxBet: currentMaxBet + 25 });
  };
  _changeMaxBetDown = () => {
    let currentMaxBet;
    if (this.props.balance.customMaxBet >= 0) currentMaxBet = this.props.balance.customMaxBet;
    else currentMaxBet = this.props.groupMaxBet;
    const playerMaxBet = getFirebase()
      .firestore()
      .collection("groups")
      .doc(this.props.playerGid)
      .collection("balances")
      .doc(this.props.playerUid);
    playerMaxBet.update({ customMaxBet: currentMaxBet - 25 });
  };
  _getPickColor = (type, result, risk, win, team) => {
    if (type === "amount") {
      if (result === "pending") {
        return (
          <>
            <Typography variant="subtitle2">
              Risk {risk} to win {win} (pending){" "}
            </Typography>
            <Typography variant="subtitle2">{team}</Typography>
          </>
        );
      }
      if (result === "win") {
        return (
          <Typography variant="subtitle2" style={{ color: "green" }}>
            +{win} on {team}{" "}
          </Typography>
        );
      } else if (result === "loss") {
        return (
          <Typography variant="subtitle2" style={{ color: "red" }}>
            -{risk} on {team}{" "}
          </Typography>
        );
      } else if (result === "push") {
        return <Typography variant="subtitle2"> {team} </Typography>;
      }
    }
  };
  _formatTrans = (amount, type, state) => {
    if ((state === "approved" && type === "deposit") || type === "bonus") {
      return (
        <Typography variant="subtitle2" style={{ color: "green" }}>
          {" "}
          {amount} credit {type} {state}{" "}
        </Typography>
      );
    } else if ((state === "withdrawal" && type === "approved") || type === "correction") {
      return (
        <Typography variant="subtitle2" style={{ color: "red" }}>
          {" "}
          {amount} {type} {state}{" "}
        </Typography>
      );
    } else {
      return (
        <Typography variant="subtitle2">
          {" "}
          {amount} {type} {state}{" "}
        </Typography>
      );
    }
  };

  // probably want to move these to separate components eventually
  renderBalance = () => {
    return (
      <ListItemButton
        onClick={() => {
          this.setState({
            uiState: "balance",
          });
        }}
        sx={{ borderRadius: 6, margin: 1, border: 2, borderColor: "#bf0070" }}
      >
        <Grid container direction="row" justifyContent="space-around" alignItems="center">
          <Grid item>
            <RemoveCircleIcon />
          </Grid>

          <Grid item>
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                Balance
              </Grid>
              <Grid item xs={12}>
                {this.props.balance.balance.toFixed(0)}
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <AddCircleIcon />
          </Grid>
        </Grid>
      </ListItemButton>
    );
  };
  renderMaxBet = () => {
    return (
      <ListItemButton onClick={() => {}} sx={{ borderRadius: 6, margin: 1, border: 2, borderColor: "#bf0070" }}>
        <Grid container direction="row" justifyContent="space-around" alignItems="center">
          <Grid item>
            <RemoveCircleIcon
              onClick={() => {
                this._changeMaxBetDown();
              }}
            />
          </Grid>

          <Grid item>
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                Max Bet
              </Grid>
              <Grid item xs={12}>
                {this.props.balance?.customMaxBet >= 0 ? this.props.balance?.customMaxBet : this.props.groupMaxBet}
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <AddCircleIcon
              onClick={() => {
                this._changeMaxBetUp();
              }}
            />
          </Grid>
        </Grid>
      </ListItemButton>
    );
  };
  renderJuice = () => {
    return (
      <>
        <ListItemButton sx={{ borderRadius: 6, margin: 1, border: 2, borderColor: "#bf0070" }}>
          <Grid container direction="row" justifyContent="space-around" alignItems="center">
            <Grid item>
              {this.props.balance.customJuice !== 0 && <RemoveCircleIcon onClick={this._changeJuiceDown} />}
            </Grid>

            <Grid item>
              <Grid container direction="column" justifyContent="center" alignItems="center">
                <Grid item xs={12}>
                  Juice
                </Grid>
                <Grid item xs={12}>
                  {this.props.balance?.customJuice >= 0
                    ? this.getAmericanfromJuicePcnt(this.props.balance?.customJuice)
                    : this.getAmericanfromJuicePcnt(this.props.groupJuice)}
                </Grid>
              </Grid>
            </Grid>

            <Grid item>
              {this.props.balance.customJuice !== 6.75 && <AddCircleIcon onClick={this._changeJuiceUp} />}
            </Grid>
          </Grid>
        </ListItemButton>
      </>
    );
  };
  renderSummary = () => {
    return (
      <ListItemButton
        onClick={() => {
          this.setState({ uiState: "cashHistory" });
        }}
        sx={{ borderRadius: 2, margin: 1, borderBottom: 2, borderTop: 2, border: 2, borderColor: "#bf0070" }}
      >
        <Grid container direction="row" justifyContent="space-around" alignItems="center">
          <Grid item>
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                Player Loss
              </Grid>
              <Grid item xs={12}>
                {Math.round(this.props.balance.totalLoss - this.props.balance.totalWin) || 0}
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container direction="column" justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                <QueryStatsIcon fontSize={"large"} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ListItemButton>
    );
  };
  renderBalanceChange = () => {
    const email = this.props.balance.email;
    const bonus = {
      uid: this.props.playerUid,
      email: this.props.balance.email,
      gid: this.props.playerGid,
      type: this.state.changeBalanceAmt > 0 ? "bonus" : "correction",
      amount: this.state.changeBalanceAmt,
      timestamp: new Date().getTime(),
      trans_id: v4(),
      state: "credited",
    };
    return (
      <Dialog open={this.props.open}>
        <DialogTitle style={{ fontFamily: "Libre Franklin", borderBottom: 3 }}>
          <Grid container direction="row" justifyContent="space-around" alignItems="center">
            <Grid item xs={11}>
              Issue Credits
            </Grid>
            <Grid
              item
              xs={1}
              onClick={() => {
                this.setState(this.initialState);
              }}
            >
              <CloseIcon />
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Stack spacing={2}>
            <ListItemText
              primary={email.length < 30 ? email : email.slice(0, 27).concat("...")}
              secondary={"current balance: " + this.props.balance.balance}
            />

            <TextField
              type={"number"}
              label={"additional credits"}
              onChange={(e) => this.setState({ changeBalanceAmt: Number(e.target.value) })}
            />

            <FormHelperText id="my-helper-text">{`Enter a positive number to add credits, or a negative number to remove credits.`}</FormHelperText>

            <Button
              variant={"contained"}
              onClick={() => {
                this.props.dispatch(awardBonus(bonus));
                this.setState(this.initialState);
              }}
              // sx={{ borderRadius: 6, border: 2, borderColor: "#bf0070" }}
            >
              ADD CREDITS
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    );
  };
  renderOldCashHistoryList = () => {
    return (
      <>
        {!this.props.bets && !this.props.bets2 && this.state.cashHistoryType === "bets" && (
          <ListItem key={"nothing"}>
            <Typography>no bets placed yet</Typography>
          </ListItem>
        )}

        {!this.props.pastTrans && this.state.cashHistoryType === "transactions" && (
          <ListItem key={"nothing"}>
            <Typography>no transactions yet</Typography>
          </ListItem>
        )}

        {this.props.bets2 && this.state.cashHistoryType === "bets" && <BetList2 allowGrading={false} bets={this.props.bets2} />}
        {this.props.bets &&
          this.state.cashHistoryType === "bets" &&
          Object.keys(this.props.bets).map((i) => {
            const B = this.props.bets;
            return (
              <ListItem key={i}>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item xs={12}>
                    <Grid container direction="row" justifyContent="left" alignItems="center">
                      <Grid item>
                        {this._getPickColor(
                          "amount",
                          B[i].result,
                          Math.round(B[i].risk),
                          Math.round(B[i].win),
                          B[i].team
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item xs={12}>
                    <Grid container direction="row" justifyContent="left" alignItems="center">
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">{B[i].game.AwayTeam}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">{B[i].game.HomeTeam}</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <Typography variant="subtitle2">({getTimestamp(B[i].minGameStartTime)})</Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </ListItem>
            );
          })}

        {this.props.pastTrans &&
          this.state.cashHistoryType === "transactions" &&
          Object.keys(this.props.pastTrans)
            .sort((x, y) => (this.props.pastTrans[x]?.timestamp < this.props.pastTrans[y]?.timestamp ? 1 : -1))
            .map((i) => {
              const T = this.props.pastTrans;
              const _cleanUpTimestamp = () => {
                // this is to handle old ways of naming timestamps
                if (T[i].timestamp) {
                  return getTimestamp(T[i].timestamp);
                } else if (T[i].awardedTimestamp) {
                  return getTimestamp(T[i].awardedTimestamp);
                } else if (T[i].pendingTimestamp) {
                  return getTimestamp(T[i].pendingTimestamp);
                } else return "";
              };
              return (
                <ListItem key={i}>
                  <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                      {this._formatTrans(T[i].amount, T[i].type, T[i].state)}
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant={"subtitle2"}>{_cleanUpTimestamp()}</Typography>
                    </Grid>
                  </Grid>
                </ListItem>
              );
            })}
      </>
    );
  };
  renderCashHistory = () => {
    const handleToggle = (e) => {
      this.setState({
        cashHistoryType: e.target.value,
      });
    };
    return (
      <Dialog open={this.props.open}>
        <DialogTitle style={{ fontFamily: "Libre Franklin", borderBottom: 3 }}>
          <Grid container direction="row" justifyContent="space-around" alignItems="center">
            <Grid item xs={11}>
              {this.props.balance.email.length < 23
                ? this.props.balance.email
                : this.props.balance.email.slice(0, 20).concat("...")}
            </Grid>
            <Grid
              item
              xs={1}
              onClick={() => {
                this.setState(this.initialState);
              }}
            >
              <CloseIcon />
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <ToggleButtonGroup
            value={this.state.cashHistoryType}
            // exclusive
            onChange={handleToggle}
            aria-label="text alignment"
          >
            <ToggleButton sx={{ minWidth: 120 }} value="bets" aria-label="left aligned">
              Bets
            </ToggleButton>
            <ToggleButton value="transactions" aria-label="centered">
              Transactions
            </ToggleButton>
          </ToggleButtonGroup>

          <List>{this.renderOldCashHistoryList()}</List>
        </DialogContent>
      </Dialog>
    );
  };
  renderPlayerTool = () => {
    return (
      <>
        {(this.state.uiState === "menu" || this.state.uiState === "cashHistory") && this.renderSummary()}
        {this.state.uiState === "menu" && this.renderBalance()}
        {this.state.uiState === "menu" && this.renderMaxBet()}
        {this.state.uiState === "menu" && this.renderJuice()}

        {this.state.uiState === "balance" && this.renderBalanceChange()}
        {this.state.uiState === "cashHistory" && this.renderCashHistory()}
      </>
    );
  };

  render() {
    console.log("Player Tool this.props: ", this.props);
    console.log("Player Tool this.state: ", this.state);
    const email = this.props.balance.email;
    return (
      <Dialog open={this.props.open}>
        <DialogTitle style={{ fontFamily: "Libre Franklin", borderBottom: 3 }}>
          <Grid container direction="row" justifyContent="space-around" alignItems="center">
            <Grid item xs={11}>
              {email.length < 20 ? email : email.slice(0, 17).concat("...")}
            </Grid>

            <Grid item xs={1} onClick={this.props.closeCallback}>
              <CloseIcon />
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>{this.props.balance && this.renderPlayerTool()}</DialogContent>
      </Dialog>
    );
  }
}

PlayerTool.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  balance: PropTypes.object,
  open: PropTypes.bool,
  closeCallback: PropTypes.func,
  playerUid: PropTypes.string,
  playerGid: PropTypes.string,
  groupJuice: PropTypes.number,
  groupMaxBet: PropTypes.number,
  bets: PropTypes.object,
  bets2: PropTypes.object,
  pastTrans: PropTypes.object,
  profile: PropTypes.object,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  pastBetsList: {
    width: "100%",
  },
});

const mapStateToProps = (state) => {
  return {
    groupJuice: state.firestore.data?.playerTool_groupConfigs?.groupJuice,
    groupMaxBet: state.firestore.data?.playerTool_groupConfigs?.maxBet,
    bets: state.firestore.data?.playerTool_bets,
    bets2: state.firestore.data?.playerTool_bets2,
    pastTrans: state.firestore.data?.playerTool_pastTrans,
    profile: state.firebase.profile,
  };
};

export default compose(
  connect(mapStateToProps),
  withRouter,
  firestoreConnect((props) => {
    console.log("playeruid: ", props.playerUid);
    return [
      {
        collection: `groups`,
        doc: `${props.playerGid}`,
        storeAs: "playerTool_groupConfigs",
      },
      {
        collection: `groups/${props.playerGid}/bets`,
        where: [`uid`, "==", `${props.playerUid}`],
        orderBy: ["minGameStartTime", "desc"],
        limit: 25,
        storeAs: "playerTool_bets",
      },
      {
        collection: `groups/${props.playerGid}/bets2`,
        where: [`uid`, "==", `${props.playerUid}`],
        // orderBy: ['minGameStartTime', 'desc'],
        // limit: 25,
        storeAs: "playerTool_bets2",
      },
      {
        collection: `groups/${props.playerGid}/transactions`,
        where: [`uid`, "==", `${props.playerUid}`],
        limit: 25,
        storeAs: "playerTool_pastTrans",
      },
    ];
  })
)(withStyles(styles)(PlayerTool));
