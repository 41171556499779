import { getTimestamp } from "../reducers/sportsbook-reducer";
import moment from "moment";
import React from "react";

const renderGametime = (timeInMS, event_type) => {
  if (timeInMS) {
    return (
      <small
        style={{ fontSize: ".65rem", textAlign: "left", float: "left", color: "#888888" }}
      >
        {getTimestamp(timeInMS)} &nbsp; &nbsp;{" "}
        {(event_type === "live" && <font style={{ color: "#bf0070", fontWeight: 700 }}>LIVE</font>) || (
          <em>{(timeInMS > Date.now() ? "Starts " : "Started ") + moment(timeInMS).fromNow()}</em>
        )}
      </small>
    );
  }
};

export { renderGametime };
