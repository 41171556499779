// const materialColors = [
//   "#e5737366",
//   "#f0629266",
//   "#ba68c866",
//   "#9575cd66",
//   "#7986cb66",
//   "#64b5f666",
//   "#4fc3f766",
//   "#4dd0e166",
//   "#4db6ac66",
//   "#81c78466",
//   "#aed58166",
//   "#ff8a6566",
//   "#d4e15766",
//   "#ffd54f66",
//   "#ffb74d66",
//   "#a1887f66",
//   "#90a4ae66",
// ];
//
// const javaHashCode = (str) => {
//   if (str && typeof str === "string") {
//     return Array.from(str).reduce((s, c) => (Math.imul(31, s) + c.charCodeAt(0)) | 0, 0);
//   } else {
//     return 0;
//   }
// };
const decimalFromAmerican = (odds) => {
  let decimalOdds = 1;
  if (odds === 100 || odds === -100)
    decimalOdds += 1;
  else if (odds > 100)
    decimalOdds += Number((0.01 * odds).toFixed(2));
  else if (odds < 0)
    decimalOdds += Number((-100 / odds).toFixed(2));
  return decimalOdds;
}

const impliedFromAmerican = (odds) => {
  let impliedLine;
  if (Math.abs(odds) === 100)
    impliedLine = 0.5;
  else if (odds < 0)
    impliedLine = Math.abs(odds) / (100 - odds);
  else if (odds > 100)
    impliedLine = 100 / (odds + 100);
  return impliedLine
}

const americanFromDecimal = (odds) => {
  if (odds === 2)
    return 100;
  else if (odds > 2)
    return 100*(odds - 1)
  else if (odds < 2)
    return -100/(odds -1)
}

const impliedFromDecimal = (odds) => {
  return 100/odds;
}

const americanFromImplied = (odds) => {
  return Math.round(odds <= 0.5 ? 100 / odds - 100 : -100 / (1 - odds) + 100)
}
const decimalFromImplied = (odds) => {
  return 1/odds
}

const oddsConversions = (fromType, odds) => {
  console.log("convert: ",fromType, odds)
  let returnAmerican; let returnDecimal; let returnImplied;
  if (fromType === "American") {
    returnAmerican = odds;
    returnDecimal = decimalFromAmerican(odds);
    returnImplied = impliedFromAmerican(odds);
  } else if (fromType === "Decimal") {
    returnAmerican = americanFromDecimal(odds);
    returnDecimal = odds;
    returnImplied = impliedFromDecimal(odds);
  } else if (fromType === "ImpliedOdds") {
    returnAmerican = americanFromImplied(odds);
    returnDecimal = decimalFromImplied(odds);
    returnImplied = odds;
  } else {
    return ("unsupported")
  }
  return {
    american: returnAmerican,
    decimal: returnDecimal,
    implied: returnImplied,
  }
};

export { oddsConversions };
