import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { alpha, Box, Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import IMAGE_hero from "../../../images/Football_transparent.png";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { createAnonAccount } from "../../Auth/functions/createAccount";
import { Redirect, withRouter } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";

class BottomCTA extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      friendDialogOpen: false,
    };
  }


  renderCTA = () => {
    const { classes } = this.props;
    const description =
      "Email admin@superfunbet.com to schedule a private demo";
    return (
        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
        >
          <Box sx={{  border: 1.5, borderRadius: 2, borderColor: "#bf0070", padding: 5, margin: 3}}>
            <Typography sx={{ textAlign: "center", fontFamily: "Libre Franklin", fontSize: "1.75rem" }}>
              Got Questions?
            </Typography>
            <Typography sx={{textAlign: "left", fontFamily: "Libre Franklin", fontSize: "1rem", paddingTop: 2}}>
              {description}
            </Typography>
          </Box>

        </Stack>

    );
  };

  _handleClick = () => {
    if (this.state.bookName?.length >= 4 && this.state.bookName?.length <= 18)
      createAnonAccount(this.props.firstVisitURL, this.state.bookName);
    else alert("Your book name must be between 4 and 18 characters");
  };

  renderCTAButtons = () => {
    return (
      <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            sx={{
              width: "100%",
              boxShadow: 4,
              borderRadius: 4,
            }}
            onChange={(e) => this.setState({ bookName: e.target.value })}
            label={`book name`}
            InputLabelProps={{ shrink: true }}
            inputProps={{
              style: {
                textAlign: "center",
                backgroundColor: "#ffffff",
                height: "100%",
              },
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button
            onClick={this._handleClick}
            sx={{ boxShadow: 4, fontSize: "1.2rem", height: "100%", width: "100%", minHeight: "52px" }}
            align="center"
            variant={"contained"}
            color="secondary"
          >
            CREATE
          </Button>
        </Grid>
      </Grid>
    );
  };

  renderMiniCTA = () => {
    const { classes } = this.props;
    const description =
        "Super Fun Bet lets you be the bookie for your friends.  Create your own private sportsbook.  No fees, no risk," +
        " no download.  Just name your book!";
    return (

        <Stack
            direction="column"
            justifyContent="center"
            alignItems="center"
            spacing={2}
            id={"aboutus"}
        >
          <Box sx={{           background: "linear-gradient(#ffffff, #FFE86780)",
            border: 1.5, borderRadius: 2, borderColor: "#bf0070", padding: 3, margin: 3}}>
            <Grid
                container
                direction="column"
                justifyContent="space-evenly"
                alignItems="center"
                style={{ height: "100%" }}
            >
              <Grid item>
                <Grid item  sx={{ borderBottom: 1, borderColor: "secondary", paddingTop: 1, paddingBottom: 3, paddingRight: 0, paddingLeft: 0 }}>
                  <Typography className={classes.headerLogo} align={"left"} style={{ fontSize: "2rem" }}>
                    {"About Us"}
                  </Typography>
                  {/*<Typography sx={{ fontFamily: "Libre Franklin", fontSize: "1.75rem" }}>*/}
                  {/*  {"No risk, no download."}*/}
                  {/*</Typography>*/}
                </Grid>
                <Grid item>
                  <Typography
                      sx={{
                        borderBottom: 1,
                        textAlign: "justify",
                        padding: 2,
                        paddingLeft: 0,
                        paddingRight: 0,
                        fontFamily: "Libre Franklin",
                        fontSize: "1rem",
                        maxWidth: "450px",
                      }}
                  >
                    <>
                      {"We're a small group of sportsbettors who made this app to bet among ourselves.  " +
                          "We don't think sportsbetting needs to be shady or dangerous or taxed or promoted endlessly in annoying commercials.  " }
                      <br/>
                      <br/>
                      {  "SuperFunBet is free, with no plans to ever charge anything.  We don't require ANY personal information, " +
                          "though the app works  better with an email address to use as your login id."}
                      <br/>
                      <br/>
                      {
                        "We hope you'll enjoy the app as much as we do.  It's pretty sophisticated for being free, with sharp lines that make it fair for bookie and player alike.  If you've got" +
                          " any questions, email us at:"
                      }
                      <br/>
                      <br/>
                      {
                        "admin@superfunbet.com"
                      }
                      <br/>
                      <br/>
                      {
                        "Try running your own sportsbook.  All you need is a name!"
                      }

                    </>
                    {/*{"We think everyone should have access to safe, legal sportsbetting.  Create a book and give it a try.  No risk, no download, no commitment."}*/}
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: 5, paddingBottom: 4 }}>
                  {this.renderCTAButtons()}
                </Grid>
              </Grid>
            </Grid>
          </Box>

        </Stack>





    );
  };


  render() {
    console.log("CTA this.props: ", this.props);
    return this.renderMiniCTA()
  }
}

BottomCTA.propTypes = {
  classes: PropTypes.object.isRequired,
  firstVisitURL: PropTypes.string,
  anonAccountState: PropTypes.string,
  history: PropTypes.array,
  uid: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  dialogTitle: {
    color: theme.palette.primary.dark,
    paddingBottom: 0,
  },
  headerLogo: {
    fontFamily: "NeuveticaHeavyItalic",
    // fontStyle: 'italic',
    // color: "black",
    fontWeight: 700,
    fontSize: "2rem",
  },
  footerLogo: {
    fontFamily: "NeuveticaHeavyItalic",
    // fontStyle: 'italic',
    // color: "black",
    fontWeight: 700,
    fontSize: "1rem",
  },
  dialogSubtitle: {
    paddingTop: 10,
    color: theme.palette.primary.light,
  },
  paper: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  values: {
    borderColor: theme.palette.primary.light,
    color: theme.palette.primary.dark,
    borderWidth: 2,
    marginBottom: 15,
    alignItems: "left",
    padding: 0,
  },
});

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    firstVisitURL: state.newUserState.initialPage,
    anonAccountState: state.newUserState.anonAccountState,
  };
};

export default withRouter(connect(mapStateToProps)(withStyles(styles)(BottomCTA)));
