import { Box, Grid, List } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Bet2 from "./Bet2";
import Divider from "@mui/material/Divider";
import BetStandardized from "./BetStandardized";

class BetList2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPendingBets: false,
    };
  }

  getColor = (result) => {
    if (result === "win") return "#90ee90";
    else if (result === "loss") return "red";
    else return "white";
  };

  renderBets() {
    if (this.props.bets) {
      let b = this.props.bets;
      let filteredB = Object.values(b)
        .filter((bet) => bet)
        .filter((bet) => bet.result)
        .filter((bet) => bet.minGameStartTime);
      if(!this.props.doNotSort){
        filteredB = filteredB.sort((betX, betY) => (betX.format === "2205" ? 1 : -1))
          .sort((betX, betY) => (betX.minGameStartTime < betY.minGameStartTime ? 1 : -1))
      }
      if(this.props.oldestAtTop){
        filteredB = filteredB.sort((betX, betY) => (betX.format === "2205" ? 1 : -1))
          .sort((betX, betY) => (betX.minGameStartTime > betY.minGameStartTime ? 1 : -1))
      }

      return filteredB.map((bet) => {
          // eslint-disable-next-line no-prototype-builtins
          if (bet.format === "2205") {
            return (
              <Grid key={bet} container justifyContent="center">
                {/*<Box*/}
                {/*  sx={{*/}
                {/*    border: 1,*/}
                {/*    paddingTop: 2,*/}
                {/*    marginBottom: 2,*/}
                {/*    maxWidth: 400,*/}
                {/*    marginTop: 1,*/}
                {/*    boxShadow: "2px 2px 4px #000000",*/}
                {/*  }}*/}
                {/*>*/}
                  <BetStandardized
                    key={bet.user_bet_id}
                    bet={bet}
                    fromAdmin={this.props.fromAdmin}
                    fromSuperAdmin={this.props.fromSuperAdmin}
                    allowGrading={this.props.allowGrading}
                    isParlay={bet.isParlay}
                  />
                {/*</Box>*/}
              </Grid>
            );
          } else if (!this.props.fromSuperAdmin && bet.unconfirmedBet)
            return (
              <>
                <Bet2
                  key={bet.user_bet_id}
                  bet={bet}
                  allowGrading={this.props.allowGrading}
                  fromAdmin={this.props.fromAdmin}
                  fromSuperAdmin={this.props.fromSuperAdmin}
                  isHistory={this.props.isHistory}
                  isParlay={bet.isParlay}
                />
                <Divider />
              </>
            );
        });
    }
  }

  render() {
    const { classes } = this.props;
    console.log("Betlist 2 props: ", this.props);

    return <List className={classes.pastBetsList}>{this.props.bets && this.renderBets()}</List>;
  }
}

BetList2.defaultProps = {
  allowGrading: false,
  isHistory: false,
  doNotSort: false,
};

BetList2.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  bets: PropTypes.object,
  allowGrading: PropTypes.bool,
  isHistory: PropTypes.bool,
  fromAdmin: PropTypes.bool,
  fromSuperAdmin: PropTypes.bool,
  profile: PropTypes.object,
  doNotSort: PropTypes.bool,
  oldestAtTop: PropTypes.bool,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  pastBetsList: {
    width: "100%",
    // padding: 0,
    // margin: 0
  },
});

export default connect()(withStyles(styles)(BetList2));
