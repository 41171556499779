import React from "react";
import PickemAppBar from "../PickemAppBar.js";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import {
    Avatar,
    ListItemAvatar,
    ListItemButton,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText
} from "@mui/material";
import {AdminPanelSettings, ChevronRight, DateRange, LocalActivity} from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import { useHistory } from "react-router-dom";
import Divider from "@mui/material/Divider";
import { signOut } from "../../../reducers/user-reducer.js";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

const ProfileMenu = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.firebase.profile);

  return (
    <div>
      <PickemAppBar title="Profile" isHome={false} />
      <List sx={{ width: "100%" }}>

          <ListItemButton
              divider
              // onClick={() => history.push("/pickem/entries/")}
              onClick={() => alert("this isnt working yet")}
                  >
              <ListItemIcon>
                  <LocalActivity color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Tournament Entries" secondary="View Your Entries" />
              <ListItemSecondaryAction>
                  <ChevronRight />
              </ListItemSecondaryAction>
          </ListItemButton>

          <ListItemButton
              divider
              onClick={() => history.push("/pickem/profile/card-collection")}
          >
              <ListItemIcon>
                  <DateRange color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Cards" secondary="View and Buy Cards" />
              <ListItemSecondaryAction>
                  <ChevronRight />
              </ListItemSecondaryAction>
          </ListItemButton>
      {/*  {profile.isLoaded && profile.is_admin && (*/}
      {/*    <ListItem onClick={() => history.push("/pickem/admin/")}>*/}
      {/*      <ListItemAvatar>*/}
      {/*        <Avatar>*/}
      {/*          <AdminPanelSettings />*/}
      {/*        </Avatar>*/}
      {/*      </ListItemAvatar>*/}
      {/*      <ListItemText primary="Admin" secondary="Site administration" />*/}
      {/*    </ListItem>*/}
      {/*  )}*/}
      {/*</List>*/}
      {/*<Divider />*/}
      {/*<List sx={{ width: "100%", bgcolor: "background.paper" }}>*/}
      {/*  <ListItem onClick={() => dispatch(signOut(() => history.push("/pickem/")))}>*/}
      {/*    <ListItemAvatar>*/}
      {/*      <Avatar>*/}
      {/*        <LogoutIcon />*/}
      {/*      </Avatar>*/}
      {/*    </ListItemAvatar>*/}
      {/*    <ListItemText primary="Logout" secondary="Logout from account" />*/}
      {/*  </ListItem>*/}
      </List>
    </div>
  );
};

ProfileMenu.propTypes = {};

export default ProfileMenu;
