import React, { useEffect } from "react";
import { List, Typography } from "@mui/material";
import firebase from "../../../firebase";
import { TournamentListItem } from "./TournamentListItem";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import CheckForHandle from "../Common/CheckForHandle";

export function TournamentList() {
  const [tournaments, setTournaments] = React.useState([]);

  const fetchTournaments = async () => {
    const db = firebase.firestore();
    await db
      .collection("pickem")
      .doc("tournies")
      .collection("active")
      .where("devOnly", "in", [false, process.env.NODE_ENV === "development" ? true : false])
      .onSnapshot((querySnapshot) => {
        const newData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        setTournaments(newData);
      });
  };

  useEffect(() => {
    fetchTournaments();
  }, []);

  return (
    <div>
      <Box>
        <Grid xs={12} container direction="row" justifyContent="center" alignItems="center">
          <Typography
            sx={{
              textAlign: "center",
              padding: 1,
              minWidth: "250px",
              color: "#bf0070",
            }}
          >
            Upcoming Tournaments
          </Typography>
        </Grid>
        <List sx={{ width: "100%", padding: 1 }}>
          {tournaments.map((tournament) => {
            return <TournamentListItem key={tournament.id} tournament={tournament} />;
          })}
        </List>
        <CheckForHandle />
      </Box>
    </div>
  );
}
