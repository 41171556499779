import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import CasinoIcon from "@mui/icons-material/Casino";
import { Header } from "../../../components";
import Container from "@mui/material/Container";
import { oddsConversions } from "../../../utilities/oddsConversions";
import { Box, TextField } from "@mui/material";

class OddsCalculator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      odds: null,
    };
  }

  handleChange = (e, type) => {
    console.log("e: ", e);
    console.log("e.target.value: ", e.target.value, "type: ", type);
    if (type === "American" && (e.target.value === "-" || e.target.value === "+")) {
      this.setState({
        odds: {
          american: e.target.value,
          decimal: "",
          implied: "",
        },
      });
    } else if (
      type === "American" &&
      (e.target.value.length < 3 ||
        (e.target.value.length === 3 && (e.target.value[0] === "-" || e.target.value[0] === "+")))
    ) {
      this.setState({
        odds: {
          american: e.target.value,
          decimal: "",
          implied: "",
        },
      });
    } else if (type === "Decimal" && e.target.value == 1) {
      console.log("entering dec");
      this.setState({
        odds: {
          american: "",
          decimal: e.target.value,
          implied: "",
        },
      });
    } else if (type === "ImpliedOdds" && e.target.value === ".") {
      this.setState({
        odds: {
          american: "",
          decimal: "",
          implied: e.target.value,
        },
      });
    } else {
      this.setState({
        odds: oddsConversions(type, Number(e.target.value)),
      });
    }
  };

  renderInputs = () => {
    return (
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "25ch" },
        }}
        noValidate
        autoComplete="off"
      >
        <div>
          <TextField
            id="outlined-multiline-flexible"
            label="American"
            type={"number"}
            multiline
            onKeyDown={(k) => {
              if (k.key === "Backspace") {
                this.setState({
                  odds: null,
                });
              }
            }}
            maxRows={4}
            value={this.state.odds?.american || ""}
            onChange={(e) => this.handleChange(e, "American")}
          />
          <TextField
            id="outlined-multiline-flexible"
            label="Decimal"
            type={"number"}
            onKeyDown={(k) => {
              if (k.key === "Backspace") {
                this.setState({
                  odds: null,
                });
              }
            }}
            multiline
            maxRows={4}
            value={this.state.odds?.decimal || ""}
            onChange={(e) => this.handleChange(e, "Decimal")}
          />
          <TextField
            id="outlined-multiline-flexible"
            label="Implied Odds"
            multiline
            type={"number"}
            onKeyDown={(k) => {
              if (k.key === "Backspace") {
                this.setState({
                  odds: null,
                });
              }
            }}
            maxRows={4}
            value={this.state.odds?.implied || ""}
            onChange={(e) => this.handleChange(e, "ImpliedOdds")}
          />
        </div>
      </Box>
    );
  };

  render() {
    console.log("Odds Conversions this.props: ", this.props);
    console.log("Odds Conversions this.state: ", this.state);

    oddsConversions("american", "decimal", 3);
    const { classes } = this.props;
    return (
      <>
        <Header title="OddsCalculator" icon={CasinoIcon} />
        <Container>
          <h3>Odds Calculator</h3>
          {this.renderInputs()}
        </Container>
      </>
    );
  }
}

OddsCalculator.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  dialogTitle: {
    color: theme.palette.primary.dark,
    paddingBottom: 0,
  },
  dialogSubtitle: {
    paddingTop: 10,
    color: theme.palette.primary.light,
  },
  paper: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  values: {
    borderColor: theme.palette.primary.light,
    color: theme.palette.primary.dark,
    borderWidth: 2,
    marginBottom: 15,
    alignItems: "left",
    padding: 0,
  },
});

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(OddsCalculator));
