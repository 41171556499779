import { changeJuice, getTimestamp } from "../../../reducers/sportsbook-reducer";

const betLine = (betType, line, adjustJuice) => {
  if (betType === "MoneyLineHome") {
    return changeJuice(line.MoneyLineHome, adjustJuice);
  }
  if (betType === "MoneyLineAway") {
    return changeJuice(line.MoneyLineAway, adjustJuice);
  }
  if (betType === "PointSpreadHome") {
    return changeJuice(line.PointSpreadHomeLine, adjustJuice);
  }
  if (betType === "PointSpreadAway") {
    return changeJuice(line.PointSpreadAwayLine, adjustJuice);
  }
  if (betType === "Over") {
    return changeJuice(line.OverLine, adjustJuice);
  }
  if (betType === "Under") {
    return changeJuice(line.UnderLine, adjustJuice);
  }
};

const convertAmericanToDecimal = (americanOdds) => {
  if (isNaN(americanOdds)) {
    return null;
  }
  let decimalOdds = 1;
  if (americanOdds === 100 || americanOdds === -100) decimalOdds += 1;
  else if (americanOdds > 100) decimalOdds += Number((0.01 * americanOdds).toFixed(2));
  else if (americanOdds < 0) decimalOdds += Number((-100 / americanOdds).toFixed(2));
  return decimalOdds;
};

const calcParlayOdds = (parlayOddsArray) => {
  return parlayOddsArray.map((odd) => convertAmericanToDecimal(odd)).reduce((accum, current) => accum * current);
};

const calcParlayOdds2 = (bets, lines, juice) => {
  if (!bets) {
    return 0;
  }
  return Object.values(bets)
    .filter((bet) => bet)
    .map((bet) => convertAmericanToDecimal(betLine2(lines[bet.doc_id], bet, juice)))
    .reduce((accum, current) => accum * current);
};

const betPrimaryText = (betType, line) => {
  if (betType === "MoneyLineHome") {
    return line.HomeTeam + " Moneyline";
  }
  if (betType === "MoneyLineAway") {
    return line.AwayTeam + " Moneyline";
  }
  if (betType === "PointSpreadHome") {
    return line.HomeTeam + " " + line.PointSpreadHome;
  }
  if (betType === "PointSpreadAway") {
    return line.AwayTeam + " " + line.PointSpreadAway;
  }
  if (betType === "Over") {
    return "Over " + line.TotalNumber;
  }
  if (betType === "Under") {
    return "Under " + line.TotalNumber;
  }
  if (betType === "props") return "props";
};

const nameShortener = (name) => {
  return name.substring(name.lastIndexOf(" ") + 1, name.length);
};

const shortBetPrimaryText = (betType, line) => {
  if (betType === "MoneyLineHome") {
    return nameShortener(line.HomeTeam);
  }
  if (betType === "MoneyLineAway") {
    return nameShortener(line.AwayTeam);
  }
  if (betType === "PointSpreadHome") {
    return nameShortener(line.HomeTeam) + " " + line.PointSpreadHome;
  }
  if (betType === "PointSpreadAway") {
    return nameShortener(line.AwayTeam) + " " + line.PointSpreadAway;
  }
  if (betType === "Over") {
    return "Over " + line.TotalNumber;
  }
  if (betType === "Under") {
    return "Under " + line.TotalNumber;
  }
};

const betLine2 = (game, bet, juice) => {
  console.log(" *** game bet juice", game, bet, juice);
  try {
    if (!game) {
      return null;
    }
    if (bet.bet_type === "props") {
      return bet.oddsjamData.price;
    }
    if (!bet || !bet.bet_period) {
      return "Off";
    }
    if (game.format === "2205") {
      if (bet.bet_type === "ml") {
        if (!game.standard[bet.bet_period].moneyline) {
          return "Off";
        }
        return changeJuice(game.standard[bet.bet_period].moneyline[bet.bet_side], juice);
      }
      if (bet.bet_type === "spread") {
        if (!game.standard[bet.bet_period].spread) {
          return "Off";
        }
        return changeJuice(game.standard[bet.bet_period].spread[bet.bet_side], juice);
      }
      if (bet.bet_type === "ou") {
        if (!game.standard[bet.bet_period].total) {
          return "Off";
        }
        return changeJuice(game.standard[bet.bet_period].total[bet.bet_side], juice);
      }
      return "???";
    }

    if (bet.bet_type === "ml") {
      if (!game.periods[bet.bet_period].money_line) {
        return "Off";
      }
      return changeJuice(game.periods[bet.bet_period].money_line[bet.bet_side], juice);
    }
    if (bet.bet_type === "spread") {
      if (!game.periods[bet.bet_period].spreads) {
        return "Off";
      }
      const defaultSpread = game.periods[bet.bet_period].spreads.default;
      return changeJuice(game.periods[bet.bet_period].spreads[defaultSpread][bet.bet_side], juice);
    }
    if (bet.bet_type === "ou") {
      if (!game.periods[bet.bet_period].totals) {
        return "Off";
      }
      const defaultTotal = game.periods[bet.bet_period].totals.default;
      return changeJuice(game.periods[bet.bet_period].totals[defaultTotal][bet.bet_side], juice);
    }
  } catch (e) {
    return "Off";
  }
};

const _formatNumberFromSide = (num, side = "home") => {
  if (side === "away") {
    num *= -1;
  }
  return num;
};

const _niceFormatNumber = (num, side = "home") => {
  if (side === "away") {
    num *= -1;
  }
  if (num > 0) {
    return "+" + num;
  } else {
    return num;
  }
};

const adjustRef = (game, bet) => {
  if (!game) {
    return -10001;
  }
  if (game.format === "2205") {
    if (bet.bet_type === "ml") {
      return 0;
    }
    if (bet.bet_type === "spread") {
      const defaultSpread = game.standard[bet.bet_period].spread?.points;
      if (defaultSpread === null || defaultSpread === undefined) {
        return -10002;
      }
      return _formatNumberFromSide(defaultSpread, bet.bet_side);
    }
    if (bet.bet_type === "ou") {
      return game.standard[bet.bet_period].total?.points;
    }
  } else {
    return -10000;
  }
};

const _getOddsjamMarketName = (game, bet) => {
  if (bet.bet_period === "ft" && bet.bet_type === "spread" && game.sport !== "baseball")
    return "Point Spread";
  else if (bet.bet_period === "ft" && bet.bet_type === "spread" && game.sport === "baseball")
    return "Run Line";
  else if (bet.bet_period === "1h" && bet.bet_type === "spread")
    return "1st Half Point Spread";
  else if (bet.bet_period === "ft" && bet.bet_type === "ml")
    return "Moneyline";
  else if (bet.bet_period === "1h" && bet.bet_type === "ml")
    return "1st Half Moneyline";
  else if (bet.bet_period === "ft" && bet.bet_type === "ou" && game.sport === "baseball")
    return "Total Runs";
  else if (bet.bet_period === "ft" && bet.bet_type === "ou")
    return "Total Points";
  else if (bet.bet_period === "1h" && bet.bet_type === "ou")
    return "1st Half Total Points";
  else
    return null;
};

const _getOddsjamBetName = (game, bet) => {

  let side = game[bet.bet_side];
  let homeSpreadPoints = Number(game.standard[bet.bet_period].spread.points);
  let awaySpreadPoints = -1*Number(game.standard[bet.bet_period].spread.points);

  if (bet.bet_type === "props") {
    return bet.oddsjamData.bet_name;
  }

  if (bet.bet_type === "spread" && bet.bet_side === "home" && homeSpreadPoints > 0)
    return side + " +"  + homeSpreadPoints
  else if (bet.bet_type === "spread" && bet.bet_side === "home" && 0 >= homeSpreadPoints)
    return side + " "  + homeSpreadPoints
  else if (bet.bet_type === "spread" && bet.bet_side === "away" && awaySpreadPoints >= 0)
    return side + " +"  + awaySpreadPoints
  else if (bet.bet_type === "spread" && bet.bet_side === "away" && 0 >= awaySpreadPoints)
    return side + " "  + awaySpreadPoints

  if (bet.bet_side === "over")
    return "Over" + " " + game.standard[bet.bet_period].total.points;
  else if (bet.bet_side === "under")
    return "Under" + " " + game.standard[bet.bet_period].total.points;
  else if (bet.bet_type === "ml")
    return game[bet.bet_side];
  else
    return null;




};

const betPrimaryText2 = (game, bet) => {
  console.log("best slip primary text: ", bet);
  let text = "";
  try {
    if (bet.bet_type === "props") {
      return bet.oddsjamData.betslipDisplay;
    }
    if (!game) {
      text = "Off";
    }
    if (!bet || !bet.bet_period) {
      text = "Off";
    }
    if (game.format === "2205") {
      if (bet.bet_type === "ml") {
        if (bet.bet_side === "draw") {
          text = "Draw";
        } else {
          text = game[bet.bet_side] + " ML";
        }
      }
      if (bet.bet_type === "spread") {
        const defaultSpread = game?.standard[bet.bet_period].spread?.points;
        if (defaultSpread === null || defaultSpread === undefined) {
          text = "Off";
        }
        text = game[bet.bet_side] + " " + _niceFormatNumber(defaultSpread, bet.bet_side);
      }
      if (bet.bet_type === "ou") {
        const defaultTotal = game.standard[bet.bet_period].total?.points;
        if (defaultTotal === null || defaultTotal === undefined) {
          text = "Off";
        }
        if (bet.bet_side === "over") {
          text = "Over " + defaultTotal;
        }
        if (bet.bet_side === "under") {
          text = "Under " + defaultTotal;
        }
      }
    } else {
      if (bet.bet_type === "ml") {
        if (bet.bet_side === "draw") {
          text = "Draw";
        }
        text = game[bet.bet_side] + " ML";
      }
      if (bet.bet_type === "spread") {
        const defaultSpread = game.periods[bet.bet_period].spreads?.default;
        if (defaultSpread === null || defaultSpread === undefined) {
          text = "Off";
        }
        text = game[bet.bet_side] + " " + _niceFormatNumber(defaultSpread, bet.bet_side);
      }
      if (bet.bet_type === "ou") {
        const defaultTotal = game.periods[bet.bet_period].totals?.default;
        if (defaultTotal === null || defaultTotal === undefined) {
          text = "Off";
        }
        if (bet.bet_side === "over") {
          text = "Over " + defaultTotal;
        }
        if (bet.bet_side === "under") {
          text = "Under " + defaultTotal;
        }
      }
    }
  } catch (e) {
    return "Off";
  }
  if (bet.bet_period === "1h") text += " (1H)";
  return text;
};

const niceBetLine = (game, bet, juice, isCoinflip = false) => {
  // const newJuice = game.event_type === "live" ? 6.75 : juice;
  if (isCoinflip) {
    return changeJuice(100, juice);
  }
  const rawLine = betLine2(game, bet, game.event_type === "live" || game.is_live ? 6.75 : juice);
  if (!rawLine || rawLine === Infinity) {
    return "Off";
  } else {
    if (rawLine > 0) {
      return "+" + rawLine;
    }
    return rawLine;
  }
};

const emailData = (game, bet, juice, isCoinflip = false) => {
  return {
    bet: betPrimaryText2(game, bet),
    price: niceBetLine(game, bet, juice, isCoinflip),
    matchName: game.away + " at " + game.home,
    gameTime: getTimestamp(game?.startsMS ? game.startsMS : game.starts_ms),
  };
};

const oddsjamParlayGradingData = (game, bet) => {
  console.log("helper game: ", game);
  if (game.origin !== "oddsjam")
    return {}
  if (game.sport === "baseball") {
    return {
      game_id: game.event_id,
      bet_name: _getOddsjamBetName(game, bet),
      league: game.league,
      sport: game.sport,
      market_name: bet.bet_type
          .replaceAll("spread", "Run Line")
          .replaceAll("ml", "Moneyline")
          .replaceAll("ou", "Total Runs"),
    }
  } else {
    return {
      game_id: game.event_id,
      bet_name: _getOddsjamBetName(game, bet),
      league: game.league,
      sport: game.sport,
      market_name: bet.bet_type
          .replaceAll("spread", "Point Spread")
          .replaceAll("ml", "Moneyline")
          .replaceAll("ou", "Total Points"),
    }
  }


};

const oddsjamCoinflipGradingData = (game, bet) => {
  console.log("helper game: ", game);
  return {
    game_id: game.event_id,
    bet_name: game.origin === "oddsjam" ? _getOddsjamBetName(game, bet) : "",
    league: game.league,
    sport: game.sport,
    market_name: game.origin === "oddsjam" ? _getOddsjamMarketName(game, bet) : "",
  };
};

export {
  betLine,
  calcParlayOdds,
  betPrimaryText,
  shortBetPrimaryText,
  betLine2,
  calcParlayOdds2,
  betPrimaryText2,
  niceBetLine,
  emailData,
  adjustRef,
  oddsjamParlayGradingData,
  oddsjamCoinflipGradingData,
};
