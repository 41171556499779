import { getTimestamp } from "../reducers/sportsbook-reducer";
import moment from "moment/moment";
import * as React from "react";
import { Typography } from "@mui/material";

const PICKEM_BET_VALUE = 50;

const formatSpread = (spread) => {
  if (!spread) return "Off";
  if (spread === 0) {
    return "Evens";
  }
  if (spread > 0) {
    return "+" + spread.toString();
  } else {
    return spread.toString();
  }
};

const formatMoneyLine = (moneyline) => {
  if (!moneyline || moneyline === Infinity) return "Off";
  if (moneyline > 0) {
    return "+" + moneyline.toString();
  } else {
    return moneyline.toString();
  }
};

const renderGametime = (timeInMS, event_type) => {
  if (timeInMS) {
    return (
      <small style={{ fontSize: ".65rem", textAlign: "left", float: "left", color: "#888888" }}>
        {getTimestamp(timeInMS)} &nbsp; &nbsp;{" "}
        {(event_type === "live" && <font style={{ color: "#bf0070", fontWeight: 700 }}>LIVE</font>) || (
          <em>{(timeInMS > Date.now() ? "Starts " : "Started ") + moment(timeInMS).fromNow()}</em>
        )}
      </small>
    );
  }
};

const pickemWinAmount = (riskAmount, line) => {
  if (line === 100 || line === -100) return riskAmount * (2.0).toFixed(1);
  else if (line > 100) return Number(riskAmount + (riskAmount * Number(line)) / 100).toFixed(1);
  else if (line < 0) return Number(riskAmount + (riskAmount * -100) / Number(line)).toFixed(1);
};

const getPickFullName = (pick_details, line, points) => {
  // console.log("pick_details", pick_details)
  if (pick_details?.betType === "moneyline") {
    return (
      <div>
        {line[pick_details.betSide] + " ML"} {points && <>({points} pts)</>}
        <br />
        {line.away + " at " + line.home}
      </div>
    );
  }
  if (pick_details?.betType === "spread") {
    const spread = formatSpread(
      (pick_details.betSide === "away" ? -1 : 1) * line.standard?.ft?.[pick_details.betType]?.points
    );
    return (
      <div>
        {line[pick_details.betSide] + " " + spread} {points && <>({points} pts)</>}
        <br />
        {line.away + " at " + line.home}
      </div>
    );
  }
  if (pick_details?.betType === "total") {
    const total =
      (pick_details.betSide === "over" ? "Over " : "Under ") + line.standard?.ft?.[pick_details.betType]?.points;
    return (
      <div>
        {total} {points && <>({points} pts)</>}
        <br />
        {line.away + " at " + line.home}
      </div>
    );
  }
  return <div>Unknown Bet Type</div>;
};

const pointsWon = (entry, risk_amount) => {
  if (entry.result === "pending") {
    return null;
  }
  if (entry.result === "win") {
    return <Typography sx={{ color: "green" }}>+{entry.points}</Typography>;
  }
  if (entry.result === "push") {
    return <Typography sx={{ color: "black" }}>+{risk_amount}</Typography>;
  }
  if (entry.result === "loss") {
    return <Typography sx={{ color: "red" }}>+0</Typography>;
  }
  return false;
};

const ordinal_suffix_of = (i) => {
  let j = i % 10,
    k = i % 100;
  if (j === 1 && k !== 11) {
    return i + "st";
  }
  if (j === 2 && k !== 12) {
    return i + "nd";
  }
  if (j === 3 && k !== 13) {
    return i + "rd";
  }
  return i + "th";
};

export {
  formatSpread,
  formatMoneyLine,
  renderGametime,
  pickemWinAmount,
  PICKEM_BET_VALUE,
  getPickFullName,
  pointsWon,
  ordinal_suffix_of,
};
