import React, { useEffect } from "react";
import firebase from "../../../../firebase";
import PropTypes from "prop-types";
import {List, Typography} from "@mui/material";
import { PickItem } from "./PickItem";
import { useSelector } from "react-redux";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import {Card} from "../../Cards/Card.js";

const selectTournamentEntry = (state) => state.pickemTournamentEntry;

export function TournamentMakePicks(props) {
  const [tournament, setTournament] = React.useState({});
  const [userPicks, setUserPicks] = React.useState({});
  const [userCards, setUserCards] = React.useState([]);
  const tournamentEntryState = useSelector(selectTournamentEntry);

  const db = firebase.firestore();
  const auth = firebase.auth();

  const fetchTournament = async () => {
    await db
      .collection("pickem")
      .doc("tournies")
      .collection("active")
      .doc(props.tournamentID)
      .onSnapshot((docSnapshot) => {
        setTournament(docSnapshot.data());
      });
  };

  const fetchUserPicks = async () => {
    if (!auth?.currentUser?.uid) return;
    if (!props.tournamentID) return;
    const doc = await db
      .collection("pickem")
      .doc("user")
      .collection(auth.currentUser.uid)
      .doc("pending")
      .collection(props.tournamentID)
      .doc("picks");
    await doc.onSnapshot((docSnapshot) => {
      if (!docSnapshot.exists) {
        doc.set({ blank: true });
      }
      setUserPicks(docSnapshot.data());
    });
  };

  const fetchUserCards = async () => {
    if (!auth?.currentUser?.uid) return;
    const docRef = db.collection("pickem")
        .doc("user")
        .collection(firebase.auth().currentUser.uid)
        .doc("public")
    const doc = await docRef.get();
    if (!doc.exists) {
      alert("you have no cards")
    } else {
      let c = doc.data().Cards
      setUserCards(c)
    }
  };

  useEffect(() => {
    fetchTournament();
  }, []);

  useEffect(() => {
    fetchUserPicks();
  }, [auth?.currentUser?.uid]);


  useEffect(() => {
    fetchUserCards();
  }, [auth?.currentUser?.uid]);

  useEffect(async () => {
    if (props.enterTourney) {
      let result = await enterTourney();
      if (result) {
        props.enterTourneyCallback(true);
      } else {
        props.enterTourneyCallback(false);
      }
    }
  }, [props.enterTourney]);

  if (!auth?.currentUser?.uid) return <div>Loading...</div>;

  if (!tournament) return <div>Loading...</div>;

  if (!userPicks) return <div>Loading...</div>;

  if (!userCards) return <div>Loading...</div>;


  const enterTourney = async () => {
    if (!auth?.currentUser?.uid) return;
    if (!props.tournamentID) return;
    const entryData = {
      tournamentID: props.tournamentID,
      ...tournament,
      addedDatetime: firebase.firestore.Timestamp.now(),
      userID: auth.currentUser.uid,
    };
    let pickData = [];
    for (let i = 0; i < tournament.picks; i++) {
      pickData.push({
        pickNumber: i,
        pickDetails: userPicks["pick_" + i],
        game: tournamentEntryState.pickGame[i],
        points: tournamentEntryState.pickValues[i],
        result: "pending",
      });
    }
    entryData["picks"] = pickData;
    const doc = await db.collection("pickem").doc("tournies").collection("entries").add(entryData);
    if (doc) {
      await db
        .collection("pickem")
        .doc("tournies")
        .collection("active")
        .doc(props.tournamentID)
        .update({ numEntries: firebase.firestore.FieldValue.increment(1) });
      await db
        .collection("pickem")
        .doc("user")
        .collection(firebase.auth().currentUser.uid)
        .doc("pending")
        .collection(props.tournamentID)
        .doc("picks")
        .set({ blank: true });
    }
    return true;
  };

  const determinePickNumber = () => {
    console.log("you have this many aces: ",userCards.filter(e => e.includes('A')).length)
    return userCards.filter(e => e.includes('A')).length;
  }

  // TODO //
  // change this from tournament.picks to "how many aces the users has" or whatever //
  // TODO //

  console.log("usercards: ",userCards)
  return (
    <div>
      <Box  sx={{
        marginLeft: 2,
        padding: 2,
        border: 1,
        borderColor: "#bf0070",
        borderRadius: 2,
        marginBottom: 2,
      }}>
        <Typography
            component="span" variant="body1" color="text.primary"
            sx={{ fontWeight: 800, fontSize: "2rem", paddingBottom: 2 }}>
          {determinePickNumber() + " Aces = " + determinePickNumber() + " picks"}
        </Typography>
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
        >
          {userCards.map((i) => (<Card key={i} rank={i[0]} suit={i.slice(-1)}/>))}
        </Grid>
      </Box>

      <List>
        {[...Array(tournament.picks).slice(0,determinePickNumber())].map((e, i) => {
          return (
            <PickItem
              userID={auth.currentUser.uid}
              tournamentID={props.tournamentID}
              pickNumber={i}
              key={i}
              pickDetails={userPicks["pick_" + i]}
              allPicks={userPicks}
              pickTotal={tournament.picks}
              riskAmount={props.riskAmount}
              maxGameStartTime={tournament.maxGameStartTime}
            />
          );
        })}
      </List>
    </div>
  );
}

TournamentMakePicks.propTypes = {
  tournamentID: PropTypes.string.isRequired,
  enterTourney: PropTypes.bool.isRequired,
  enterTourneyCallback: PropTypes.func.isRequired,
  riskAmount: PropTypes.number.isRequired,
};
