import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Divider, Grid, ListItem, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { getTimestamp, gradeWager2, markParlayLegAsPush } from "../../reducers/sportsbook-reducer";
import {
  Adjust,
  AssignmentTurnedIn,
  AutorenewSharp,
  CancelPresentationOutlined,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import { connect } from "react-redux";
import List from "@mui/material/List";

class Bet2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonsDisabled: false,
    };
  }

  _pushLeg = (lineID) => {
    // console.log("Mark Leg as Push", {
    //   betID: this.props.bet.betID,
    //   LineID: lineID,
    //   gid: this.props.bet.gid,
    //   uid: this.props.bet.uid
    // });
    this.props.dispatch(
      markParlayLegAsPush({
        betID: this.props.bet.betID,
        LineID: lineID,
        gid: this.props.bet.gid,
        uid: this.props.bet.uid,
      })
    );
  };
  _getResultIcon = (result) => {
    if (result === "win") {
      return <AssignmentTurnedIn style={{ color: "lightgreen" }} />;
    } else if (result === "push") {
      return <RadioButtonUnchecked style={{ color: "gray" }} />;
    } else if (result === "pending") {
      return <Adjust style={{ color: "gray" }} />;
    } else {
      return <CancelPresentationOutlined style={{ color: "lightsalmon" }} />;
    }
  };
  _getReturn = (result, risk, win) => {
    if (result === "win") {
      return <Typography style={{ color: "lightgreen" }}>{"+" + win.toFixed(2)}</Typography>;
    } else if (result === "push") {
      return <Typography style={{ color: "gray" }}>Push</Typography>;
    } else if (result === "pending") {
      return null;
    } else {
      return <Typography style={{ color: "lightsalmon" }}>{"-" + risk.toFixed(2)}</Typography>;
    }
  };
  _getPickColor = (result) => {
    if (result === "win") {
      return "green";
    } else if (result === "loss") {
      return "red";
    }
  };
  _getParlayTextColor = (result) => {
    if (result === "win") return "green";
    else if (result === "loss") return "red";
    else return null;
  };
  _renderPick = () => {
    // do a lot less work if we can
    if (this.props.bet.betPrimaryText) {
      return (
        <Typography variant="subtitle2" style={{ color: this._getPickColor(this.props.bet.result) }}>
          {this.props.bet.betPrimaryText}
        </Typography>
      );
    }
    // TODO this is only for rendering really old shit.
    let BB = this.props.bet.unconfirmedBet;
    let line;
    if (this.props.bet.unconfirmedBet?.game.format === "2205") {
      let spread = BB?.game?.standard[BB.bet_period].spread?.points;
      if (BB.bet_side === "over" || BB.bet_side === "under") {
        line = `${BB.bet_side} ${BB.game.standard[BB.bet_period].total.points}`;
      } else if (BB.bet_type === "ml") {
        line = `${BB.game[BB.bet_side]} Moneyline`;
      } else if (BB.bet_type === "spread") {
        line = `${BB.game[BB.bet_side]} ${spread}`;
        if (BB.bet_side === "away") {
          line = `${BB.game[BB.bet_side]} ${-1 * spread > 0 ? "+" : ""}${Number(-1 * spread)}`;
        } else {
          line = `${BB.game[BB.bet_side]} ${Number(spread)}`;
        }
      }
    } else {
      let spread = BB?.game?.periods[BB.bet_period].spreads?.default;

      if (BB.bet_side === "over" || BB.bet_side === "under") {
        line = `${BB.bet_side} ${BB.game.periods[BB.bet_period].totals.default}`;
      } else if (BB.bet_type === "ml") {
        line = `${BB.game[BB.bet_side]} Moneyline`;
      } else if (BB.bet_type === "spread") {
        line = `${BB.game[BB.bet_side]} ${spread}`;
        if (BB.bet_side === "away") {
          line = `${BB.game[BB.bet_side]} ${-1 * spread > 0 ? "+" : ""}${Number(-1 * spread)}`;
        } else {
          line = `${BB.game[BB.bet_side]} ${Number(spread)}`;
        }
      }
    }

    return (
      <Typography variant="subtitle2" style={{ color: this._getPickColor(this.props.bet.result) }}>
        {line}
      </Typography>
    );
  };
  _renderGameInfo = () => {
    let BB = this.props.bet.unconfirmedBet;
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ color: "text.secondary", typography: "body2" }}
      >
        <Grid item>{BB.game.away}</Grid>
        <Grid item>&nbsp;at {BB.game.home}</Grid>
        <Grid item>&nbsp;({getTimestamp(this.props.bet.minGameStartTime)})</Grid>
      </Grid>
    );
  };

  _renderGameInfoParlay = (game) => {
    return (
      <Grid
        container
        direction="row"
        justifyContent="left"
        alignItems="center"
        sx={{ color: "text.secondary", typography: "body2" }}
      >
        <Grid item>{game.away}</Grid>
        <Grid item>&nbsp;at {game.home}</Grid>
        <Grid item>&nbsp;({getTimestamp(game.startsMS)})</Grid>
      </Grid>
    );
  };
  _renderWagerInfo = () => {
    let BB = this.props.bet.unconfirmedBet;
    return (
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        sx={{ color: this._getPickColor(this.props.bet.result), typography: "body2" }}
      >
        <Grid item>risk {BB.risk}</Grid>
        <Grid item>&nbsp;to win</Grid>
        <Grid item>&nbsp;{BB.win}</Grid>
        <Grid item>&nbsp;- {this.props.bet.result}</Grid>
      </Grid>
    );
  };
  _gradeBet = (result) => {
    this.setState({ buttonsDisabled: true });

    if (!["win", "push", "loss"].includes(result)) {
      return false;
    }

    this.props.dispatch(
      gradeWager2({
        uid: this.props.bet.uid,
        betID: this.props.bet.betID,
        result: result,
      })
    );
  };
  _renderGradingButtons = () => {
    return (
      <Grid item>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ paddingTop: 1 }}>
          <Grid item>
            <Button variant="outlined" disabled={this.state.buttonsDisabled} onClick={() => this._gradeBet("win")}>
              Win
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" disabled={this.state.buttonsDisabled} onClick={() => this._gradeBet("loss")}>
              Loss
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              disabled={this.state.buttonsDisabled}
              onClick={() => {
                this._gradeBet("push");
              }}
            >
              Push
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  _niceFormatNumber = (num, side = "home") => {
    if (side === "away") {
      num *= -1;
    }
    if (num > 0) {
      return "+" + num;
    } else {
      return num;
    }
  };

  betPrimaryText = (bet, game) => {
    if (bet.bet_type === "ml") {
      return game[bet.bet_side] + " ML";
    }
    if (bet.bet_type === "spread") {
      const defaultSpread = game.periods[bet.bet_period].spreads?.default;
      return game[bet.bet_side] + " " + this._niceFormatNumber(defaultSpread, bet.bet_side);
    }
    if (bet.bet_type === "ou") {
      const defaultTotal = game.periods[bet.bet_period].totals.default;
      if (bet.bet_side === "over") {
        return "Over " + defaultTotal;
      }
      if (bet.bet_side === "under") {
        return "Under " + defaultTotal;
      }
    }
  };

  render() {
    console.log("Bet2 props: ", this.props);

    let minutesSinceStart = (Math.floor((Date.now() / 1000) * 1000) - this.props.bet.maxGameStartTime) / 1000 / 60;
    // console.log("minutes since start: ",minutesSinceStart)
    let B = this.props.bet;
    let BB = this.props.bet.unconfirmedBet;
    let PP;
    let GG;
    if (this.props.isParlay) {
      PP = this.props.bet.unconfirmedBets;
      GG = this.props.bet.games;
    }

    if (!this.props.isParlay) {
      return (
        <ListItem>
          <Grid container direction="column" justifyContent="center" alignItems="center" spacing={1}>
            <Grid item>{(this.props.allowGrading || this.props.fromAdmin) && this.props.bet.email}</Grid>

            <Grid item>
              <Grid container>
                <Grid item>{this._renderPick()}</Grid>
                <Grid item>
                  {BB.game.event_type === "live" && (
                    <Grid item sx={{ paddingLeft: 1 }}>
                      <Typography color="#bf0070" fontSize="small">
                        Live
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <Grid item>{this._renderGameInfo()}</Grid>

            <Grid item>
              <Grid container>
                <Grid item>{this._renderWagerInfo()}</Grid>
                <Grid item>
                  {this.props.bet.isCoinflip && (
                    <Grid item sx={{ paddingLeft: 1 }}>
                      <AutorenewSharp fontSize="small" />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>

            {((this.props.fromSuperAdmin && this.props.bet.result === "pending") ||
              (minutesSinceStart > 300 && this.props.allowGrading && this.props.bet.result === "pending")) &&
              this._renderGradingButtons()}
          </Grid>
        </ListItem>
      );
    } else {
      return (
        <ListItem>
          <Grid container direction="column" justifyContent="center" alignItems="center" spacing={1}>
            <Grid item>{(this.props.allowGrading || this.props.fromAdmin) && this.props.bet.email}</Grid>

            <Grid item>
              Parlay
              {/*{this._renderPick()}*/}
            </Grid>

            <Grid item sx={{ color: "text.secondary", typography: "body2" }}>
              {Object.keys(PP).map((i) => {
                let game = GG[PP[i].doc_id];
                // let side = game[PP[i].bet_side]
                // let betPeriod = PP[i].bet_period
                // let tempSpread = game.periods[betPeriod].spreads.default
                // let spread = game.periods[betPeriod].spreads[tempSpread]
                //
                // console.log("side, ",side, "tempSpread: ",tempSpread, "spread; ",spread)

                return (
                  <List key={i}>
                    <ListItem sx={{ paddingBottom: 0 }}>{this.betPrimaryText(PP[i], game)}</ListItem>
                    <ListItem sx={{ paddingBottom: 0, paddingTop: 0 }}>{this._renderGameInfoParlay(game)}</ListItem>
                    <Divider />
                  </List>
                );
              })}
            </Grid>

            <Grid item>
              {/*Risk {B.risk} to win {B.win}*/}
              {/*{this._renderWagerInfo()}*/}
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{ color: this._getPickColor(this.props.bet.result), typography: "body2" }}
              >
                <Grid item>risk {B.risk}</Grid>
                <Grid item>&nbsp;to win</Grid>
                <Grid item>&nbsp;{B.win}</Grid>
              </Grid>
            </Grid>

            {((this.props.fromSuperAdmin && this.props.bet.result === "pending") ||
              (minutesSinceStart > 300 && this.props.allowGrading && this.props.bet.result === "pending")) &&
              this._renderGradingButtons()}
          </Grid>
        </ListItem>
      );
    }
  }
}

Bet2.defaultProps = {
  isHistory: false,
  allowGrading: false,
};

Bet2.propTypes = {
  bet: PropTypes.object,
  classes: PropTypes.object,
  allowGrading: PropTypes.bool,
  dispatch: PropTypes.func,
  isHistory: PropTypes.bool,
  isParlay: PropTypes.bool,
  fromAdmin: PropTypes.bool,
  fromSuperAdmin: PropTypes.bool,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  pastBetsList: {
    width: "100%",
  },
});

export default connect()(withStyles(styles)(Bet2));
