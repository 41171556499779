import React, { Component } from "react";
import PropTypes from "prop-types";
import BetList from "../Sportsbook/BetList";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import withStyles from "@mui/styles/withStyles";
import GenericBackHeader from "../../components/GenericBackHeader";
import collect from "collect.js";
import { BottomNav } from "../../components";
import Box from "@mui/material/Box";
import BetList2 from "../../components/BetDisplay/BetList2";

class AccountPendingBets extends Component {
  render() {
    console.log("FFF account pending bets: ", this.props);
    return (
      <>
        <Box>
          <GenericBackHeader title="Your Pending Bets" showBackButton />
          {this.props.bets && <BetList2 allowGrading={false} bets={this.props.bets} />}
          {/*{!Object.keys(this.props.accountPendingBets).length && <Container>No Pending Bets</Container>}*/}
        </Box>
        <BottomNav activeTab={2} managerGid={this.props.profile.managerGid} />
      </>
    );
  }
}

AccountPendingBets.propTypes = {
  profile: PropTypes.object,
  accountPendingBets: PropTypes.object,
  bets: PropTypes.object,
  groupConfigs: PropTypes.object,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: "inline-block",
    marginTop: "20px",
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  grid: {
    textAlign: "center",
    marginBottom: "20px",
  },
});

const mapStateToProps = (state) => {
  console.log("state: ", state);

  const getUserPendingBets = (b) => {
    console.log("all pending bets pre filter: ", b);
    const collection = collect(b);
    const pendingBets = collection.filter(
      (val) => val && val.uid === state.firebase.auth.uid && val.result === "pending"
    );
    return pendingBets.all();
  };

  return {
    profile: state.firebase.profile,
    groupConfigs: state.firestore.data.accountUserGroupConfigs,
    bets: getUserPendingBets(state.firestore.data.pending_bets2),
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    console.log("props props ", props);
    return [
      //probably better to query users/uid/bets
      //but we dont have access to uid at this point, only props.profile.gid
      {
        collection: `groups/${props.profile.gid}/bets2`,
        where: [["result", "==", "pending"]],
        storeAs: "pending_bets2",
      },
      {
        collection: `groups`,
        doc: props.profile.gid,
        storeAs: "accountUserGroupConfigs",
      },
    ];
  })
)(withStyles(styles)(AccountPendingBets));
