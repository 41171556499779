import firebase from "./firebase";

import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "../../sft-dev/src/App";
import * as serviceWorker from "./serviceWorker";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";

import { ReactReduxFirebaseProvider } from "react-redux-firebase";
import { createFirestoreInstance } from "redux-firestore";
import history from "./history";
import { createTheme, StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";

import "./fonts/Neuvetica-Regular.otf";
import AppPickem from "./App-pickem.js";

const rrfConfig = {
  userProfile: "users",
  useFirestoreForProfile: true, // Firestore for Profile instead of Realtime DB
};

const rrfProps = {
  firebase,
  config: rrfConfig,
  dispatch: store.dispatch,
  createFirestoreInstance: createFirestoreInstance,
};

if (process.env.REACT_APP_USE_EMUS === "TRUE") {
  console.log("USING EMULATORS");
  firebase.firestore().useEmulator("localhost", 8080);
  firebase.functions().useEmulator("localhost", 5001);
  firebase.auth().useEmulator("http://localhost:9099/");
}

export const themeOptions = createTheme({
  palette: {
    type: "light",
    primary: {
      main: "#ffe867",
      mainLight: "#fff3b3",
      mainDraw: "#FFF8D3",
      live: "#CAFF67",
      liveLight: "#E5FFB3",
    },
    secondary: {
      main: "#bf0070",
    },
    tertiary: {
      main: "#ffffff",
    },
    quaternary: {
      main: "#000000",
    },
    sfbGreen: {
      main: "#39B54A",
    },
    info: {
      main: "#af674c",
    },
    background: {
      default: "#fffdfd",
      paper: "#f8faf8",
    },
    custom: {
      betItemText: {
        color: "#000000",
        // textShadow: "0px 0px 0px rgba(0,0,0,0.70);",
      },
      coinflipColor: {
        color: "#bf0070",
      },
      coinflipBetItemText: {
        color: "#ffffff",
      },
    },
  },
  typography: {
    fontFamily: "Libre Franklin",
    button: {
      fontFamily: "Libre Franklin",
    },
    body1: {
      fontFamily: "Libre Franklin",
    },
    body2: {
      fontFamily: "Libre Franklin",
    },
    subtitle1: {
      fontFamily: "Libre Franklin",
    },
    subtitle2: {
      fontFamily: "Libre Franklin",
    },
    caption: {
      fontFamily: "Libre Franklin",
    },
    overline: {
      fontFamily: "Libre Franklin",
    },
    h3: {
      fontFamily: "Libre Franklin",
      fontWeight: 400,
    },
    h6: {
      fontFamily: "Libre Franklin",
    },
  },
  components: {
    MuiAppBar: {
      colorInherit: {
        backgroundColor: "#689f38",
        color: "#fff",
      },
    },
    MuiBottomNavigationAction: {
      styleOverrides: {
        label: {
          fontFamily: "Libre Franklin",
        },
      },
    },
    MuiToolbar: {
      colorInherit: {
        background: "#be4444",
        backgroundColor: "#7e7777",
        color: "#fff",
      },
    },
    MuiPaper: {
      variants: [
        {
          props: { variant: "coinflip" },
          style: {
            backgroundColor: "#bf0070",
          },
        },
      ],
    },

    MuiTextField: {
      styleOverrides: {
        root: {
          "& label": {
            color: "#bf0070",
          },
          "& label.Mui-focused": {
            color: "#bf0070",
          },
          "& .MuiInput-underline:after": {
            color: "#bf0070",
          },
          "& .MuiOutlinedInput-root": {
            "& fieldset": {
              color: "#bf0070",
              borderColor: "#bf0070",
            },
            "&:hover fieldset": {
              color: "#bf0070",
              borderColor: "#bf0070",
              borderWidth: "0.15rem",
            },
            "&.Mui-focused fieldset": {
              color: "#bf0070",
              borderColor: "#bf0070",
            },
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        outlinedPrimary: {
          color: "#000000",
          borderColor: "#000000",
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        input: {
          color: "#000000",
          borderColor: "#000000",
        },
      },
    },
  },
});

ReactDOM.render(
  <ThemeProvider theme={themeOptions}>
    <StyledEngineProvider injectFirst>
      <CssBaseline />
      <React.StrictMode>
        <ReactReduxFirebaseProvider {...rrfProps}>
          <Provider store={store}>
            <BrowserRouter history={history}>
              {!process.env.REACT_APP_SITE_PICKEM && <App />}
              {process.env.REACT_APP_SITE_PICKEM && <AppPickem />}
            </BrowserRouter>
          </Provider>
        </ReactReduxFirebaseProvider>
      </React.StrictMode>
    </StyledEngineProvider>
  </ThemeProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
