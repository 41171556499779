import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import { firestoreConnect, getFirebase } from "react-redux-firebase";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import GenericBackHeader from "../../../../components/GenericBackHeader";
import { ListItemButton, ListItemSecondaryAction, ListItemText, Typography } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import BottomNav from "../../../../components/BottomNav";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import moment from "moment";

const initialState = {
  openPromoSelector: false,
  selectedPromo: "",
};

class Promos extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {}

  reset = () => {
    this.setState(initialState);
  };

  setActivePromo = async () => {
    const DB = getFirebase().firestore();
    const res = await DB.collection("groups")
      .doc(this.props.profile.managerGid)
      .set({ activePromo: this.state.selectedPromo }, { merge: true });
    return res;
  };

  renderPromoDetails = () => {
    const PROMO = this.props.promos[this.state.selectedPromo];
    const START = moment(PROMO.promoStart).format("MMM DD");
    const END = moment(PROMO.promoEnd).format("MMM DD");
    return (
      <Typography style={{ paddingTop: 10 }}>
        {PROMO.sportTitle ? PROMO.sportTitle : "All sports: "} ({START} to {END})
      </Typography>
    );
  };

  DIALOG = () => {
    return (
      <div>
        <Dialog open={this.state.openPromoSelector}>
          <DialogTitle>Chose a Promo</DialogTitle>
          <DialogContent>
            <FormControl style={{ minWidth: 200 }} fullWidth>
              <Select
                fullWidth
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                // defaultValue={this.americanJuiceFromPcnt(this.props.managerGroupConfig?.groupJuice) || -110}
                // value={this.state.groupJuice}
                onChange={(e) => this.setState({ selectedPromo: e.target.value })}
              >
                <MenuItem key={"none"} value={""}>
                  All Promos Off
                </MenuItem>

                {this.props.promos &&
                  Object.keys(this.props.promos).map((i) => {
                    return (
                      <MenuItem key={i} value={i}>
                        {i}
                      </MenuItem>
                    );
                  })}
              </Select>
              {this.state.selectedPromo && this.renderPromoDetails()}
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.reset}>Cancel</Button>
            <Button
              onClick={() => {
                this.setActivePromo();
                this.reset();
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  getPromoMenuText = () => {
    if (this.props.groupConfigs && this.props.promos) {
      const AP = this.props.groupConfigs.activePromo;
      if (AP) return "Active promo: " + AP;
      else return `You have ${Object.keys(this.props.promos).length} custom promos to choose from`;
    } else return "You must first create a promo";
  };

  render() {
    console.log("Promos this.props: ", this.props);
    console.log("Promos this.state: ", this.state);

    if (this.props.groupConfigs)
      return (
        <>
          <GenericBackHeader title="Promos" showBackButton />

          {this.DIALOG()}

          <ListItemButton
            divider
            onClick={() => {
              this.setState({
                openPromoSelector: true,
              });
            }}
          >
            <ListItemText primary="Turn Promos Off/On" secondary={this.getPromoMenuText()} />
            <ListItemSecondaryAction>
              <ChevronRight />
            </ListItemSecondaryAction>
          </ListItemButton>

          <ListItemButton
            divider
            onClick={() => {
              this.props.history.push("/admin/promos/create-promo");
            }}
          >
            <ListItemText primary="Create Promo" secondary={`Set the terms of your contest`} />
            <ListItemSecondaryAction>
              <ChevronRight />
            </ListItemSecondaryAction>
          </ListItemButton>

          <BottomNav isBookieMode activeTab={3} managerGid={this.props.profile.managerGid} />
        </>
      );
    else return <>loading...</>;
  }
}

Promos.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  groupConfigs: PropTypes.object,
  adminProfile: PropTypes.object,
  history: PropTypes.array,
  profile: PropTypes.object,
  promos: PropTypes.object,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  customCard: {
    borderColor: theme.palette.primary.light,
    borderWidth: 2,
    marginBottom: 5,
    alignItems: "center",
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  pastBetsList: {
    width: "100%",
  },
});

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    email: state.firebase.auth.email,
    profile: state.firebase.profile,
    groupConfigs: state.firestore.data?.groupConfigs,
    promos: state.firestore.data?.promos,
  };
};

export default compose(
  connect(mapStateToProps),
  withRouter,
  firestoreConnect((props) => {
    return [
      {
        collection: "groups",
        doc: props.profile.managerGid,
        storeAs: "groupConfigs",
      },
      {
        collection: `groups/${props.profile.managerGid}/promos`,
        storeAs: "promos",
      },
    ];
  })
)(withStyles(styles)(Promos));
