import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { firestoreConnect } from "react-redux-firebase";
import withStyles from "@mui/styles/withStyles";
import PlayerTool from "../PlayerTool";
import { ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText } from "@mui/material";
import { ChevronRight, SportsHandball } from "@mui/icons-material";
import { Header } from "../../../components";

class PlayerManager extends Component {
  constructor(props) {
    super(props);
    this.state = { user: false };
  }

  _launchPlayerTool = (uid) => {
    this.setState({
      bonusOpen: true,
      user: uid,
    });
  };

  renderPlayerManagementList = () => {
    let B = this.props.balances;
    return Object.keys(B)
      .sort((x, y) => (B[x].balance < B[y].balance ? 1 : -1))
      .map((v) => {
        return (
          <>
            <ListItemButton divider onClick={() => this._launchPlayerTool(v)}>
              <ListItemIcon>
                <SportsHandball color="secondary" />
              </ListItemIcon>
              <ListItemText
                secondary={"balance: " + String(B[v]?.balance?.toFixed(0))}
                primary={B[v]?.email?.length < 26 ? B[v]?.email : B[v]?.email?.slice(0, 23).concat("...")}
              />

              <ListItemSecondaryAction>
                <ChevronRight />
              </ListItemSecondaryAction>
            </ListItemButton>
          </>
        );
      });
  };

  render() {
    return (
      <>
        <Header showBackButton={true} />


        {this.props.balances && this.renderPlayerManagementList()}

        {this.props.balances && this.state.user && (
          <PlayerTool
            open={this.state.bonusOpen}
            closeCallback={() => this.setState({ bonusOpen: false })}
            playerUid={this.state.user}
            balance={this.props.balances[this.state.user]}
            playerGid={this.props.managerGid}
            playerEmail={this.props.balances[this.state.user]?.email.toLowerCase()}
          />
        )}
      </>
    );
  }
}

const styles = (theme) => ({});

PlayerManager.propTypes = {
  managerGid: PropTypes.string.isRequired,
  balances: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    email: state.firebase.auth.email,
    profile: state.firebase.profile,
    managerGid: state.firebase.profile.managerGid,
    sportsbookState: state.sportsbookState,
    showSignup: state.newUserState.showSignUpDialog || false,
    balances: state.firestore.data?.bookie_balances,
    bookConfigs: state.firestore.data?.bookie_bookConfigs,
    newUserState: state.newUserState,
  };
};

export default compose(
  connect(mapStateToProps),
  withRouter,
  firestoreConnect((props) => {
    return [
      {
        collection: `groups/${props.profile.managerGid}/balances`,
        storeAs: "bookie_balances",
      },
      {
        collection: `groups`,
        doc: `${props.profile.managerGid}`,
        storeAs: "bookie_bookConfigs",
      },
    ];
  })
)(withStyles(styles)(PlayerManager));
