import React, { useEffect, useRef } from "react";
import "./App.css";
import { Redirect, Route, Switch, useLocation } from "react-router-dom";
import { Sportsbook } from "./views/Sportsbook/";
import { Signout } from "./components/ForceSignout";
import {
  AccountPastBets,
  AccountPendingBets,
  Cashier,
  PNL,
  SwitchSportsbook,
  Transactions,
  UserAccountSummaryShort,
} from "./views/Cashier";

import { PropBets } from "./views/OLD/PropBets";
import { Register } from "./views/OLD/Register";
import { Leaderboard, PromoLeaderboard, Social, SocialPastBets, SocialPendingBets } from "./views/Social";
import { IssueFreeWager } from "./views/OLD/FreeWager";
import {
  Admin,
  AdminCashier,
  AdminTransactions,
  Bonus,
  BookSummary,
  CreatePromo,
  Financials,
  GroupSettings,
  PastBets,
  PendingBets,
  Promos,
  UserSummary,
} from "./views/OLD/Admin";
import AccountSettings from "./views/OLD/Account/AccountSettings";
import { SuperAdminMenu, SuperAdminNewBets, SuperAdminNewPlayers } from "./views/SuperAdmin";
import { useDispatch, useSelector } from "react-redux";
import { getFirebase, isEmpty, isLoaded, useFirestoreConnect } from "react-redux-firebase";

import PropTypes from "prop-types";
import Loading from "./components/Loading";
import GB from "./views/OLD/HomePage/components/gb";
import RouterHelper from "./views/RouterHelper/RouterHelper";

import DateAdapter from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import Keen from "keen-tracking";
import { setInitialPage } from "./reducers/new-user-reducer";
import OddsCalculator from "./views/OLD/OddsCalculator/OddsCalculator";
import { BookieFaq, BookieQuickstartGuide, GeneralFaq } from "./views/OLD/Faq";
import Bookie from "./views/Bookie/Bookie";
import BookHistory from "./views/Bookie/BookHistory";
import BookwidePendingBets from "./views/Bookie/screens/BookwidePendingBets";
import BookwidePastBets from "./views/Bookie/screens/BookwidePastBets";
import { pageVisibleChange } from "./reducers/app-reducer";
import Profile from "./views/Profile/Profile";
import PlayerManager from "./views/Bookie/screens/PlayerManager";
import PublicBet from "./views/Public/PublicBet";
import AdvancedSettings from "./views/Bookie/screens/AdvancedSettings";
import PropLines from "./views/Sportsbook/PropLines";
import SuperAdminBookSummaries from "./views/SuperAdmin/SuperAdminBookSummaries";
import SuperAdminBetGrading from "./views/SuperAdmin/SuperAdminBetGrading";
import SuperAdminBookSummariesLight from "./views/SuperAdmin/SuperAdminBookSummariesLight";
import SuperAdminOldBets from "./views/SuperAdmin/SuperAdminOldBets";
import { PickemHome } from "./views/Pickem/PickemHome";
import { TournamentDetail } from "./views/Pickem/Tournaments/TournamentDetail";
import { TournamentMakePicks } from "./views/Pickem/Tournaments/MakePicks/TournamentMakePicks";
import { EntriesHome } from "./views/Pickem/Entries/EntriesHome";
import AdminHome from "./views/Pickem/Admin/AdminHome";
import ProfileMenu from "./views/Pickem/Profile/ProfileMenu.js";
import {CardCollection} from "./views/Pickem/Cards/CardCollection.js";

function PrivateRoute({ children, ...rest }) {
  const auth = useSelector((state) => state.firebase.auth);
  console.log("private route this.props: ", this.props);
  console.log("Admin route auth: ", auth);
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!isLoaded(auth)) {
          return <Loading loadingMessage={"Checking Login"} />;
        } else {
          if (!isEmpty(auth)) {
            return children;
          } else {
            return (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: location },
                }}
              />
            );
          }
        }
      }}
    />
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.any,
  rest: PropTypes.any,
};

function AnonRoute({ children, ...rest }) {
  const profile = useSelector((state) => state.firebase.profile);
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!isLoaded(profile)) {
          return <div>Loading</div>;
        } else {
          if (profile.email === "anon") {
            return children;
          } else {
            return (
              <Redirect
                to={{
                  pathname: "/sports",
                  state: { from: location },
                }}
              />
            );
          }
        }
      }}
    />
  );
}

AnonRoute.propTypes = {
  children: PropTypes.any,
  rest: PropTypes.any,
};

function AdminRoute({ children, ...rest }) {
  const auth = useSelector((state) => state.firebase.auth);
  console.log("Admin route this.props: ", this.props);
  console.log("Admin route auth: ", auth);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!isLoaded(auth)) {
          return <div>Loading</div>;
        } else {
          if (!isEmpty(auth)) {
            return children;
          } else {
            return (
              <Redirect
                to={{
                  pathname: "/register",
                  state: { from: location },
                }}
              />
            );
          }
        }
      }}
    />
  );
}

AdminRoute.propTypes = {
  children: PropTypes.any,
  rest: PropTypes.any,
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function App() {
  // console.log("!!!Start app", process.env);
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development" || process.env.REACT_APP_SHOW_LOGS === "TRUE") {
    // dev code
    if (process.env.NODE_ENV === "development") {
      console.log("development");
      // Keen.enabled = false;
    }
  } else {
    // Keen.enabled = false;
    // production code
    console.log = function no_console() {};
  }
  useFirestoreConnect([{ collection: "globalOptions", doc: "client", storeAs: "clientOptions" }]);
  const clientOptions = useSelector((state) => state.firestore.data.clientOptions);
  const initialPage = useSelector((state) => state.newUserState.initialPage);
  const prev = usePrevious({ clientOptions });
  // console.log("!!!!!APP clientVersion:", clientOptions, "Previous", prev);

  const dispatch = useDispatch();
  const location = useLocation();
  let firstLoad = true;

  useEffect(() => {
    if (clientOptions && prev && prev.clientOptions) {
      // console.log("!!!!! GO", clientOptions, "Prev GO", prev.clientOptions);
      if (prev.clientOptions.version !== clientOptions.version) {
        window.location.reload();
      }
    }
  }, [clientOptions]);

  const keepAlive = () => {
    console.log("KEEP ALIVE SUBMIT");
    const placeWager = getFirebase().functions().httpsCallable("user-placeWager");
    placeWager({ bub: "ba" }).then((r) => console.log("dummy wager response: ", r));
  };

  useEffect(() => {
    // window.addEventListener("focus", onFocus);
    // window.addEventListener("blur", onBlur);
    document.addEventListener("visibilitychange", handleVisibilityChange, false);
    return () => {
      // window.removeEventListener("focus", onFocus);
      // window.removeEventListener("blur", onBlur);
      window.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  // const onFocus = () => dispatch(session.effects.checkSessionOnFocus(location))
  // const onFocus = (info) => console.log("Window Focused", info);
  // const onBlur = (info) => console.log("Window Blured", info);
  const handleVisibilityChange = () => dispatch(pageVisibleChange());

  const _setInitial = () => {
    if (!process.env.REACT_APP_SITE_PICKEM && firstLoad) {
      firstLoad = false;
      keepAlive();
      if (initialPage === false) {
        dispatch(setInitialPage(location.pathname + location.search));
      }
    }
    return null;
  };

  return (
    <>
      <LocalizationProvider dateAdapter={DateAdapter} />

      {/*<Route exact path="/">*/}
      {/*  <Redirect to="/sports" />*/}
      {/*</Route>*/}
      <Route exact path="/shareBet/:bid" render={(props) => <PublicBet {...props} />} />

      {/*<Route exact path="/free">*/}
      {/*  {_setInitial()}*/}
      {/*  <Page />*/}
      {/*</Route>*/}

      <Switch>
        <Route exact path="/pickem/tournament/:tournamentID" render={(props) => <TournamentDetail {...props} />} />
        <Route
          exact
          path="/pickem/tournament/:tournamentID/makePicks"
          render={(props) => <TournamentMakePicks {...props} />}
        />

        <Route exact path="/pickem/admin/">
          <AdminHome />
        </Route>

        <Route exact path="/pickem/entries/">
          <EntriesHome />
        </Route>

        <Route exact path="/pickem/">
          <PickemHome />
        </Route>

        <Route exact path="/pickem/profile/card-collection">
          <CardCollection />
        </Route>

        <Route path="/">
          {_setInitial()}
          <RouterHelper />
        </Route>
      </Switch>

      {/*<Route exact path="/place-bets">*/}
      {/*  {_setInitial()}*/}
      {/*  <PageFlex />*/}
      {/*</Route>*/}

      {/*<Route exact path="/take-bets">*/}
      {/*  {_setInitial()}*/}
      {/*  <LandingPage />*/}
      {/*</Route>*/}

      {/*<Route exact path="/take-bets-ad">*/}
      {/*  {_setInitial()}*/}
      {/*  <Sportsbook />*/}
      {/*</Route>*/}
      {/*<Route exact path="/place-bets-ad">*/}
      {/*  {_setInitial()}*/}
      {/*  <Sportsbook />*/}
      {/*</Route>*/}

      <Route exact path="/join/:bookName">
        {_setInitial()}
        <Sportsbook />
      </Route>

      <Route exact path="/kelvin">
        {_setInitial()}
        <Sportsbook />
      </Route>

      <Route exact path="/login">
        <Sportsbook />
      </Route>

      <Route exact path="/signout">
        <Signout />
      </Route>

      <Route exact path="/odds-calculator">
        <OddsCalculator />
      </Route>

      <Route exact path="/sports">
        <Sportsbook />
      </Route>

      {/*<Route exact path="/sports/props/:event_id">*/}
      {/*  <PropLines />*/}
      {/*</Route>*/}

      <Route exact path="/sports/props/:event_id" render={(props) => <PropLines {...props} />} />

      <Route exact path="/account-settings">
        <AccountSettings />
      </Route>

      <Route exact path="/faq">
        <GeneralFaq />
      </Route>
      <Route exact path="/bookie-quickstart-guide">
        <BookieQuickstartGuide />
      </Route>
      <Route exact path="/bookie-faq">
        <BookieFaq />
      </Route>

      <Route exact path="/bookie">
        <Bookie />
      </Route>

      <Route exact path="/bookie/book-history">
        <BookHistory />
      </Route>

      <Route exact path="/bookie/bookwide-pending-bets">
        <BookwidePendingBets />
      </Route>

      <Route exact path="/bookie/player-management">
        <PlayerManager />
      </Route>

      <Route exact path="/bookie/advanced-settings">
        <AdvancedSettings />
      </Route>

      <Route exact path="/bookie/bookwide-past-bets">
        <BookwidePastBets />
      </Route>

      <Route exact path="/admin">
        <Admin />
      </Route>
      <Route exact path="/admin/group-settings">
        <GroupSettings />
      </Route>
      <Route exact path="/admin/promos/create-promo">
        <CreatePromo />
      </Route>
      <Route exact path="/admin/promos">
        <Promos />
      </Route>
      <Route exact path="/admin/cashier">
        <AdminCashier />
      </Route>
      <Route exact path="/admin/financials">
        <Financials />
      </Route>
      <Route exact path="/bookie/book-summary">
        <BookSummary />
      </Route>
      <Route exact path="/admin/user-summary">
        <UserSummary />
      </Route>
      <Route exact path="/admin/bonus">
        <Bonus />
      </Route>
      <Route exact path="/bookie/transactions">
        <AdminTransactions />
      </Route>
      <Route exact path="/admin/pending-bets">
        <PendingBets />
      </Route>
      <Route exact path="/admin/past-bets">
        <PastBets />
      </Route>

      <Route path="/props">
        <PropBets />
      </Route>

      <Route exact path="/super-admin-menu">
        <SuperAdminMenu />
      </Route>

      <Route exact path="/super-admin-new-players">
        <SuperAdminNewPlayers />
      </Route>

      <Route exact path="/super-admin-new-bets">
        <SuperAdminNewBets />
      </Route>

      <Route exact path="/super-admin-old-bets">
        <SuperAdminOldBets />
      </Route>

      <Route path="/super-admin/book-summaries">
        <SuperAdminBookSummaries />
      </Route>

      <Route path="/super-admin/book-summaries-light">
        <SuperAdminBookSummariesLight />
      </Route>

      <Route path="/super-admin/bet-grading">
        <SuperAdminBetGrading />
      </Route>

      <Route path="/signup">
        <Sportsbook />
      </Route>

      <Route path="/signin">
        <Sportsbook />
      </Route>

      <Route path="/switch">
        <Register />
      </Route>

      <Route exact path="/cashier">
        <Cashier />
      </Route>

      <Route exact path="/profile">
        <Profile />
      </Route>

      <Route exact path="/pickem/profile">
        <ProfileMenu />
      </Route>

      <Route exact path="/cashier/transactions">
        <Transactions />
      </Route>
      <Route exact path="/cashier/account-summary">
        <UserAccountSummaryShort />
      </Route>
      <Route exact path="/cashier/pnl">
        <PNL />
      </Route>
      <Route exact path="/cashier/pending">
        <AccountPendingBets />
      </Route>
      <Route exact path="/cashier/past">
        <AccountPastBets />
      </Route>
      <Route exact path="/cashier/switch">
        <SwitchSportsbook />
      </Route>

      <Route exact path="/social">
        <Social />
      </Route>
      <Route exact path="/social/pending">
        <SocialPendingBets />
      </Route>
      <Route exact path="/social/past">
        <SocialPastBets />
      </Route>
      <Route exact path="/social/leaderboard">
        <Leaderboard />
      </Route>
      <Route exact path="/social/promo-leaderboard">
        <PromoLeaderboard />
      </Route>

      <Route path="/free-wager">
        <IssueFreeWager />
      </Route>
      <Route path="/loading">
        <Loading loadingMessage="Checking Auth" />
      </Route>
      <Route exact path="/gb">
        <GB />
      </Route>
    </>
  );
}

export default App;
