import * as React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { setActiveSport } from "../reducers/sportsbook-reducer";
import {
  SportsBaseball,
  SportsBasketball,
  SportsFootball,
  SportsHockey,
  SportsKabaddi,
  SportsSoccer,
} from "@mui/icons-material";

const drawerWidth = 240;

class TopNav extends React.Component {
  constructor(props) {
    super(props);
  }

  sportsIcon = (sportGroup) => {
    if (sportGroup === "American Football") {
      return <SportsFootball />;
    }
    if (sportGroup === "Mixed Martial Arts") {
      return <SportsKabaddi />;
    }
    if (sportGroup === "Basketball") {
      return <SportsBasketball />;
    }
    if (sportGroup === "Ice Hockey") {
      return <SportsHockey />;
    }
    if (sportGroup === "Soccer") {
      return <SportsSoccer />;
    }
    if (sportGroup === "Baseball") {
      return <SportsBaseball />;
    }
  };

  render() {
    console.log("Top nav props: ", this.props);
    const actionClasses = this.props.classes;

    if (this.props.isLines2) {
      return (
        <Box>
          <Tabs
            value={this.props.activeSport || this.props.defaultSport}
            onChange={(e, newValue) => {
              // this.setState({ tabValue: newValue });
              this.props.dispatch(setActiveSport({ activeSport: newValue }));
            }}
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
            aria-label="scrollable auto tabs example"
            TabIndicatorProps={{
              style: { background: "#BF0070", opacity: 0 },
            }}
            textColor={"secondary"}
            style={{ background: "#ffffff" }}
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                "&.Mui-disabled": {
                  opacity: 0.3,
                },
                // "&.Mui-selected": {
                //   color: "secondary"
                // },
              },
            }}
          >
            {this.props.sports &&
              Object.values(this.props.sports)
                .filter(
                  (s) =>
                    s &&
                    "bucket" in s &&
                    (s.dev_only === undefined ||
                      s.dev_only === false ||
                      process.env.NODE_ENV === "development" ||
                      process.env.REACT_APP_SHOW_DEV_LINES === "TRUE")
                )
                .map((s) => {
                  return (
                    <Tab
                      icon={this.sportsIcon(s.group)}
                      iconPosition="start"
                      classes={actionClasses}
                      value={s.bucket}
                      key={s.bucket}
                      label={s.alt_title || s.title}
                    />
                  );
                })}
          </Tabs>
        </Box>
      );
    }

    return (
      <Box>
        <Tabs
          value={this.props.activeSport}
          onChange={(e, newValue) => {
            // this.setState({ tabValue: newValue });
            this.props.dispatch(setActiveSport({ activeSport: newValue }));
          }}
          variant="scrollable"
          scrollButtons
          allowScrollButtonsMobile
          aria-label="scrollable auto tabs example"
          TabIndicatorProps={{
            style: { background: "#BF0070", opacity: 0 },
          }}
          textColor={"secondary"}
          style={{ background: "#ffffff" }}
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              "&.Mui-disabled": {
                opacity: 0.3,
              },
              // "&.Mui-selected": {
              //   color: "secondary"
              // },
            },
          }}
        >
          {this.props.sports &&
            Object.keys(this.props.sports)
              .filter((s) => s.dev_only === undefined || s.dev_only === (process.env.NODE_ENV === "production"))
              .map((s) => {
                return (
                  <Tab
                    icon={this.sportsIcon(this.props.sports[s]?.group)}
                    iconPosition="start"
                    classes={actionClasses}
                    value={this.props.sports[s]?.title}
                    key={this.props.sports[s]?.title}
                    label={this.props.sports[s]?.alt_title || this.props.sports[s]?.title}
                  />
                );
              })}
        </Tabs>
      </Box>
    );
  }
}

TopNav.defaultProps = {
  isLines2: false,
};

TopNav.propTypes = {
  classes: PropTypes.object.isRequired,
  sports: PropTypes.object.isRequired,
  defaultSport: PropTypes.string,
  dispatch: PropTypes.func,
  activeSport: PropTypes.string,
  isLines2: PropTypes.bool,
};

const styles = (theme) => ({
  colors: {
    primary: theme.palette.secondary.main,
  },
  root: {
    display: "flex",
    color: theme.palette.quaternary.main,
    "&$selected": {
      color: theme.palette.secondary.main,
    },
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  drawerDirection: {
    direction: theme.direction,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  hide: {
    display: "none",
  },
  dividerInset: {
    margin: `5px 0 0 ${theme.spacing(9)}`,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
});

const mapStateToProps = (state) => {
  return {
    activeSport: state.sportsbookState.activeSport,
  };
};

//export default withRouter(connect(mapStateToProps)((withyStyles(styles)(Header))));

export default compose(withRouter, connect(mapStateToProps))(withStyles(styles)(TopNav));
