import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { firestoreConnect, getFirebase } from "react-redux-firebase";
import { compose } from "redux";
import Box from "@mui/material/Box";
import ReceiptIcon from "@mui/icons-material/Receipt";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import GroupIcon from "@mui/icons-material/Group";
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import {
  Alert,
  Badge,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import List from "@mui/material/List";
import {ChevronRight, Help, OpenInNew, Settings} from "@mui/icons-material";
import { withRouter } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { Header } from "../../components";
import CountUp from "react-countup";
import { toggleDepositHelperOff } from "../../reducers/cashier-reducer";
import {showSignUpDialog} from "../../reducers/new-user-reducer";
import {SignUpDialog} from "../Auth";

class Bookie extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bonusOpen: false,
      changeBookNameOpen: false,
      newBookName: "",
      copySnackBarOpen: false,
      depositHelperClosed: false,
    };
  }

  _changeBookName = async () => {
    const bookConfigs = getFirebase().firestore().collection("groups").doc(this.props.uid);
    bookConfigs
      .update({ groupName: this.state.newBookName })
      .then(() => {
        getFirebase().updateProfile({ conversionState: "newBookieChangedName" });
        getFirebase().analytics().logEvent("book_created", { uid: this.props.uid });
      })
      .then(() => {
        this.setState({ changeBookNameOpen: false });
      });
  };

  renderBookPNL = () => {
    let B = this.props.balances;
    return Object.keys(B)
      .reduce((a, b) => {
        return a - (B[b].totalWin || 0) + (B[b].totalLoss || 0);
      }, 0)
      .toFixed(0);
  };

  renderTotalBalances = () => {
    let B = this.props.balances;
    return Object.keys(B)
      .reduce((a, b) => {
        return a + (B[b].balance || 0);
      }, 0)
      .toFixed(0);
  };

  renderPendingSummary = () => {
    let B = this.props.balances;
    let totalPastBets = Object.keys(B).reduce((a, b) => {
      return a + (B[b].totalBets || 0);
    }, 0);
    let totalPendingBetCount = Object.keys(B).reduce((a, b) => {
      return a + (B[b].pendingBetCount || 0);
    }, 0);
    let totalPendingRisk = Object.keys(B).reduce((a, b) => {
      return a + (B[b].totalPendingRisk || 0);
    }, 0);
    let totalPendingWin = Object.keys(B).reduce((a, b) => {
      return a + (B[b].totalPendingWin || 0);
    }, 0);
    let totalPendingDeposits = Object.keys(B).reduce((a, b) => {
      return a + (B[b].pendingDepositCount || 0);
    }, 0);
    let totalPendingWithdrawals = Object.keys(B).reduce((a, b) => {
      return a + (B[b].pendingWithdrawalCount || 0);
    }, 0);

    let primaryText;
    let secondaryText;
    if (totalPendingWithdrawals + totalPendingDeposits === 0) {
      secondaryText = "No pending deposits/withdrawals";
      primaryText = "Transactions";
    } else {
      primaryText = "Pending Transactions";
      secondaryText = `${totalPendingDeposits} pending deposits, ${totalPendingWithdrawals} pending withdrawals`;
    }

    return (
      <>
        <ListItemButton divider onClick={() => this.props.history.push("/bookie/transactions")}>
          <ListItemIcon>
            <ReceiptIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary={primaryText} secondary={secondaryText} />
          <ListItemSecondaryAction>
            {totalPendingDeposits + totalPendingWithdrawals > 0 && (
              <Badge badgeContent={totalPendingDeposits + totalPendingWithdrawals} color="secondary">
                <ChevronRight />
              </Badge>
            )}
            {totalPendingDeposits + totalPendingWithdrawals == 0 && <ChevronRight />}
          </ListItemSecondaryAction>
        </ListItemButton>

        <ListItemButton divider onClick={() => this.props.history.push("/bookie/bookwide-pending-bets")}>
          <ListItemIcon>
            <PendingActionsIcon color="secondary" />
          </ListItemIcon>
          <ListItemText
            primary={`${totalPendingBetCount} Pending Bets`}
            secondary={`Risking ${Math.round(totalPendingRisk)} to Win ${Math.round(totalPendingWin)}`}
          />
          <ListItemSecondaryAction>
            <ChevronRight />
          </ListItemSecondaryAction>
        </ListItemButton>

        <ListItemButton divider onClick={() => this.props.history.push("/bookie/bookwide-past-bets")}>
          <ListItemIcon>
            <AssignmentTurnedInIcon color="secondary" />
          </ListItemIcon>
          <ListItemText
            primary={`Past Bets`}
            secondary={`${totalPastBets} bets from ${Object.keys(B).length} players`}
          />

          <ListItemSecondaryAction>
            <ChevronRight />
          </ListItemSecondaryAction>
        </ListItemButton>
      </>
    );
  };

  renderChangeBookName = () => {
    return (
      <Dialog open={this.state.changeBookNameOpen}>
        <DialogTitle style={{ fontFamily: "Libre Franklin", borderBottom: 3 }}>
          <Grid container direction="row" justifyContent="space-around" alignItems="center">
            <Grid item xs={11}>
              Change Book Name
            </Grid>
            <Grid
              item
              xs={1}
              onClick={() => {
                this.setState({ changeBookNameOpen: false });
              }}
            >
              <CloseIcon />
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Stack spacing={2}>
            <ListItemText
              primary={`currently: ${this.props.bookConfigs.groupName}`}
              // secondary={"current balance: "}
            />
            <TextField label={"new name"} onChange={(e) => this.setState({ newBookName: String(e.target.value) })} />

            <Button
              variant={"contained"}
              onClick={() => {
                this._changeBookName();
                // this.props.dispatch(awardBonus(bonus))
                // this.setState(this.initialState)
              }}
              // sx={{ borderRadius: 6, border: 2, borderColor: "#bf0070" }}
            >
              SUBMIT
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    );
  };
  generateURL() {

    if (this.props.profile?.gid === "Y7bqQzvue9Mk3AamrIDKNaFpukN2")
      return "superfunbet.com/kelvin"
    else if (this.props.uid === "NLUUYJXONHee2Woo7ktVMFIquQC3")
      return "superfunbet.com/join/4cduVmV0vNoII1QucHpa"
    else if (this.props.uid === "J4fUKbP3CuhkpKHA6df8acZ88W72")
      return "superfunbet.com/join/iDlxBCFgmTPVYuYVTZuO"
    else if (this.props.uid === "fxKT8I0KIvTCbhI2k8u5nFCs0M93")
      return "superfunbet.com/join/3PfmaqsXa5SYbYkgtvOH"
    else if (this.props.uid === "VYMnLWO3yAe4Q5TpJWQhWOjfqfO2")
      return "superfunbet.com/join/WKgL0pPUMdx62VhPMHDj"
    else
      return "superfunbet.com/join/" + this.props.profile?.gid;
  }


  _handleCloseDepositHelper = () => {
    this.setState({ depositHelperClosed: true });
    this.props.dispatch(toggleDepositHelperOff(this.props.uid));
  };

  render() {
    console.log("BOOKIE this.props: ", this.props);
    console.log("BOOKIE this.state: ", this.state);
    const numPlayers = this.props.balances ? Object.keys(this.props.balances).length : false;
    return (
      <>
        <Header showBackButton={true} />

        {this.props.showSignup && (
            <SignUpDialog
                open={this.props.showSignup || false}
                isBookieReferral={false}
                userEmail={this.props.profile.email}
                dialogText={"You must save your account before friends can join your book."}
            />
        )}


        <Dialog open={this.props.profile.firstDepositInfo === 0 && !this.state.depositHelperClosed}>
          <DialogTitle id="alert-dialog-title">{"Approving Deposits"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {"This is the book console for " + this.props.bookConfigs?.groupName + ".  Only you can " +
                  "access this tool for approving deposits and managing your players."}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this._handleCloseDepositHelper} autoFocus variant="contained" color="secondary">
              Got it!
            </Button>
          </DialogActions>
        </Dialog>

        <Box sx={{ padding: 2 }}>
          {numPlayers && (
            <>
              <Typography align="center">
                Players: {<CountUp end={Object.keys(this.props.balances).length || 0} duration={1.5} />}
              </Typography>
              <Typography align="center">Profit: {<CountUp end={this.renderBookPNL()} duration={1.5} />}</Typography>
              <Typography align="center">
                Total Balances: {<CountUp end={this.renderTotalBalances()} duration={1.5} />}
              </Typography>
            </>
          )}

          <Box textAlign="center" padding={2}>
            <Button
              variant="outlined"
              color="secondary"
              onClick={() => {
                if (this.props.profile.email === "My Demo Account")
                  this.props.dispatch(showSignUpDialog(true))
                else
                  navigator.clipboard.writeText(this.generateURL()).then(() => this.setState({copySnackBarOpen: true}))
              }}
            >
              Invite Friends!
            </Button>
          </Box>
        </Box>

        <List>

          <ListItemButton onClick={() => this.props.history.push("/bookie/player-management")}>
            <ListItemIcon>
              <GroupIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary="Your Players" secondary="Adjust balances, set juice/max bet" />
            <ListItemSecondaryAction>
              <ChevronRight />
            </ListItemSecondaryAction>
          </ListItemButton>

          <Divider />
          <ListItemButton onClick={() => this.props.history.push("/bookie/advanced-settings")}>
            <ListItemIcon>
              <SettingsApplicationsIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary="Advanced Settings" secondary="Change book settings" />
            <ListItemSecondaryAction>
              <ChevronRight />
            </ListItemSecondaryAction>
          </ListItemButton>

          <Divider />
          <ListItemButton
            divider
            onClick={() => {
              this.props.history.push("/bookie/book-summary");
            }}
          >
            <ListItemIcon>
              <LocalAtmIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary={`Book Financials`} secondary={`Total deposits, etc`} />

            <ListItemSecondaryAction>
              <ChevronRight />
            </ListItemSecondaryAction>
          </ListItemButton>

          {this.props.balances && this.renderPendingSummary()}

          <ListItemButton divider onClick={() => (window.location.href = "mailto:admin@superfunbet.com")}>
            <ListItemIcon>
              <Help color="secondary" />
            </ListItemIcon>
            <ListItemText primary="Help" secondary="email admin@superfunbet.com" />

            <ListItemSecondaryAction>
              <OpenInNew />
            </ListItemSecondaryAction>
          </ListItemButton>
        </List>

        {this.state.copySnackBarOpen && (
          <Snackbar
            open={this.state.copySnackBarOpen}
            onClose={() => this.setState({ copySnackBarOpen: false })}
            autoHideDuration={6000}
            message="Copied"
            severity="info"
            sx={{ bottom: { xs: 75 } }}
          >
            <Alert onClose={() => this.setState({ copySnackBarOpen: false })} severity="success" sx={{ width: "100%" }}>
              {String("Copied your signup link!  Share with friends: " + this.generateURL())}
            </Alert>
          </Snackbar>
        )}
      </>
    );
  }
}

Bookie.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  managerGid: PropTypes.string,
  history: PropTypes.array,
  uid: PropTypes.string,
  balances: PropTypes.object,
  bookConfigs: PropTypes.object,
  profile: PropTypes.object,
  newUserState: PropTypes.object,
  showSignup: PropTypes.bool,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: "inline-block",
    marginTop: "20px",
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  grid: {
    textAlign: "center",
    marginBottom: "20px",
  },
  dialogTitle: {
    color: "green",
    paddingBottom: 0,
  },
  dialogSubtitle: {
    paddingTop: 10,
    color: theme.palette.primary.light,
  },
});

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    email: state.firebase.auth.email,
    profile: state.firebase.profile,
    managerGid: state.firebase.profile.managerGid,
    sportsbookState: state.sportsbookState,
    showSignup: state.newUserState.showSignUpDialog || false,
    balances: state.firestore.data?.bookie_balances,
    bookConfigs: state.firestore.data?.bookie_bookConfigs,
    newUserState: state.newUserState,
  };
};

export default compose(
  connect(mapStateToProps),
  withRouter,
  firestoreConnect((props) => {
    return [
      {
        collection: `groups/${props.profile.managerGid}/balances`,
        storeAs: "bookie_balances",
      },
      {
        collection: `groups`,
        doc: `${props.profile.managerGid}`,
        storeAs: "bookie_bookConfigs",
      },
    ];
  })
)(withStyles(styles)(Bookie));
