import { Alert, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText, Snackbar } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import CasinoIcon from "@mui/icons-material/Casino";
import { BottomNav, Header } from "../../components";
import { ChevronRight, ContentCopy, History, Pending, PersonOutline } from "@mui/icons-material";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import { withRouter } from "react-router-dom";
import collect from "collect.js";
import ChangeUsername from "./components/ChangeUsername";
import SendIcon from "@mui/icons-material/Send";
import BadgeIcon from "@mui/icons-material/Badge";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import Button from "@mui/material/Button";
import { sportsbookShowSignup } from "../../reducers/sportsbook-reducer";
// import { ConvertAnonAccount } from "../LandingPageOLD";

class Social extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copySnackBarOpen: false,
    };
  }

  renderRefLink() {
    if (this.props.groupConfigs?.groupName) {
      let cleanName = this.props.groupConfigs?.groupName.replaceAll(" ", "%20");
      return "https://superfunbet.com/join/" + cleanName;
    } else return "https://superfunbet.com";
  }

  getPendingBetInfo() {
    let coll = collect(this.props.groupBets).values();
    let count = coll.count();
    let risk = coll.sum("risk");
    let win = coll.sum("win");
    return `${count} bets risking ${risk} to win ${win}`;
  }

  _closeChangeName = () => {
    this.setState({ changeUsernameDialogOpen: false });
  };

  registerAlert = () => {
    return (
      <ListItemButton
        divider
        onClick={this.showSignup}
        sx={{ borderRadius: 6, margin: 1, backgroundColor: "warning.light" }}
      >
        <ListItemIcon>
          <PersonOutline color="warning" />
        </ListItemIcon>
        <ListItemText primary={"Claim Free Account"} secondary={"save your account history"} />
        <ListItemSecondaryAction>
          <Button color="inherit" size="small">
            SIGN UP
          </Button>
        </ListItemSecondaryAction>
      </ListItemButton>

      // <Stack sx={{ width: '100%' }} spacing={2}>
      //
      //   <Alert
      //     severity="warning"
      //     onClick={this.showSignup}
      //
      //     action={
      //       <Button
      //         color="inherit" size="small">
      //         SIGN UP
      //       </Button>
      //     }
      //   >
      //     <AlertTitle>Claim Free Account</AlertTitle>
      //     save your account history
      //   </Alert>
      // </Stack>
    );
  };

  showSignup = () => {
    this.props.dispatch(sportsbookShowSignup(true));
  };

  showSignup = () => {
    this.props.dispatch(sportsbookShowSignup(true));
  };

  render() {
    console.log("SOCIAL this.props: ", this.props);
    return (
      <>
        <Box>
          <Header title="Social" icon={CasinoIcon} />

          {/*<ConvertAnonAccount*/}
          {/*  open={this.props.showSignup && this.props.profile.email === "anon"}*/}
          {/*  onClose={() => this.props.dispatch(sportsbookShowSignup(false))}*/}
          {/*  profile={this.props.profile}*/}
          {/*/>*/}

          <ChangeUsername
            open={this.state.changeUsernameDialogOpen}
            closeCallback={this._closeChangeName}
            uid={this.props.uid}
            gid={this.props.profile.gid}
            email={this.props.profile.email}
            currentName={this.props.userBalanceDoc?.username}
          />

          <List>
            {this.props.profile.email === "anon" && this.registerAlert()}

            <ListItemButton
              divider
              onClick={() => {
                navigator.clipboard
                  .writeText(this.renderRefLink())
                  .then(() => this.setState({ copySnackBarOpen: true }));
              }}
            >
              <ListItemIcon>
                <SendIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Invite Friends" secondary={this.renderRefLink().slice(8)} />
              <ListItemSecondaryAction style={{ paddingBottom: 12 }}>
                <ContentCopy />
              </ListItemSecondaryAction>
            </ListItemButton>

            {this.props.groupBets && (
              <ListItemButton
                divider
                onClick={() => {
                  this.setState({ changeUsernameDialogOpen: true });
                }}
              >
                <ListItemIcon>
                  <BadgeIcon color="secondary" />
                </ListItemIcon>
                <ListItemText
                  primary={`Change Username`}
                  secondary={`currently: ${this.props.userBalanceDoc?.username}`}
                />
                <ListItemSecondaryAction>
                  <ChevronRight />
                </ListItemSecondaryAction>
              </ListItemButton>
            )}

            {this.props.groupConfigs?.activePromo && this.props.groupBets && (
              <ListItemButton
                divider
                onClick={() => {
                  this.props.history.push("/social/promo-leaderboard");
                }}
              >
                <ListItemIcon>
                  <LeaderboardIcon color="secondary" />
                </ListItemIcon>
                <ListItemText
                  primary={`Promo Leaderboard: ${this.props.groupConfigs.activePromo}`}
                  secondary="First prize is a cadillac, second prize is you're fired"
                />
                <ListItemSecondaryAction>
                  <ChevronRight />
                </ListItemSecondaryAction>
              </ListItemButton>
            )}

            {!this.props.groupConfigs?.activePromo && this.props.groupBets && (
              <ListItemButton
                divider
                onClick={() => {
                  this.props.history.push("/social/leaderboard");
                }}
              >
                <ListItemIcon>
                  <LeaderboardIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Leaderboard" secondary="who's hot this week?" />
                <ListItemSecondaryAction>
                  <ChevronRight />
                </ListItemSecondaryAction>
              </ListItemButton>
            )}

            {this.props.groupBets && (
              <>
                <ListItemButton
                  divider
                  onClick={() => {
                    this.props.history.push("/social/pending");
                  }}
                >
                  <ListItemIcon>
                    <Pending color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary="Friends Pending Bets" secondary={this.getPendingBetInfo()} />
                  <ListItemSecondaryAction>
                    <ChevronRight />
                  </ListItemSecondaryAction>
                </ListItemButton>

                <ListItemButton
                  divider
                  onClick={() => {
                    this.props.history.push("/social/past");
                  }}
                >
                  <ListItemIcon>
                    <History color="secondary" />
                  </ListItemIcon>
                  <ListItemText primary="Friends Past Bets" secondary="last 25 bets by your friends" />
                  <ListItemSecondaryAction>
                    <ChevronRight />
                  </ListItemSecondaryAction>
                </ListItemButton>
              </>
            )}
          </List>
          <Snackbar
            open={this.state.copySnackBarOpen}
            onClose={() => this.setState({ copySnackBarOpen: false })}
            autoHideDuration={6000}
            message="Link Copied"
            severity="info"
            sx={{ bottom: { xs: 75, sm: 0 } }}
          >
            <Alert onClose={() => this.setState({ copySnackBarOpen: false })} severity="success" sx={{ width: "100%" }}>
              Link Copied
            </Alert>
          </Snackbar>
        </Box>
        <BottomNav activeTab={1} managerGid={this.props.profile.managerGid} />
      </>
    );
  }
}

Social.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  email: PropTypes.string,
  uid: PropTypes.string,
  profile: PropTypes.object,
  groupConfigs: PropTypes.object,
  groupBets: PropTypes.object,
  history: PropTypes.object,
  userBalanceDoc: PropTypes.object,
  showSignup: PropTypes.bool,
  allGroupBalances: PropTypes.object,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: "inline-block",
    marginTop: "20px",
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  grid: {
    textAlign: "center",
    marginBottom: "20px",
  },
});

const mapStateToProps = (state) => {
  const getUserBalanceDoc = (obj) => {
    if (state.firebase.auth.uid && state.firestore.data.social_GroupBalances) return obj[state.firebase.auth.uid];
  };

  return {
    uid: state.firebase.auth.uid,
    email: state.firebase.auth.email,
    profile: state.firebase.profile,
    groupConfigs: state.firestore.data?.social_groupConfigs,
    groupBets: state.firestore.data?.social_groupBets,
    userBalanceDoc: getUserBalanceDoc(state.firestore.data?.social_GroupBalances),
    allGroupBalances: state.firestore.data?.social_GroupBalances,
    showSignup: state.sportsbookState.showSignup || false,
  };
};

export default compose(
  connect(mapStateToProps),
  withRouter,
  firestoreConnect((props) => {
    return [
      {
        collection: "groups",
        doc: props.profile.gid,
        storeAs: "social_groupConfigs",
      },
      {
        collection: `groups/${props.profile.gid}/bets/`,
        where: [["result", "==", "pending"]],
        storeAs: "social_groupBets",
      },
      {
        collection: `groups/${props.profile.gid}/balances`,
        storeAs: "social_GroupBalances",
      },
    ];
  })
)(withStyles(styles)(Social));
