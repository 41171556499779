import {
  Alert,
  DialogContent,
  Grid,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText, Snackbar,
  Typography,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { BottomNav, Header } from "../../components";
import collect from "collect.js";
import { sportsbookShowSignup } from "../../reducers/sportsbook-reducer";
import Box from "@mui/material/Box";
import { withRouter } from "react-router-dom";
import List from "@mui/material/List";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import {
  AccountBalance,
  ChevronRight, ContentCopy,
  DateRange,
  History,
  MonetizationOn,
  MonetizationOnOutlined,
  Pending,
  PersonOutline,
  Summarize,
} from "@mui/icons-material";
import LoginIcon from "@mui/icons-material/Login";
import CashierDeposit from "./components/CashierDeposit";
import CashierWithdrawal from "./components/CashierWithdrawal";
import Button from "@mui/material/Button";
import SignUpDialog from "../Auth/SignUpDialog";
import Paper from "@mui/material/Paper";
import { showSignUpDialog } from "../../reducers/new-user-reducer";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import BookieReferralWelcomeHelp from "../Dialogs/BookieReferralWelcomeHelp";
import ReferralLink from "../../components/ReferralLink";
import SettingsIcon from '@mui/icons-material/Settings';

class Cashier extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: 0,
      type: 0,
      depositOpen: false,
      withdrawalOpen: false,
    };
  }

  getPendingBetInfo = () => {
    if (!this.props.balance?.pendingBetCount || this.props.balance?.pendingBetCount===0)
      return "No Pending Bets"
    else
      return `${this.props.balance?.pendingBetCount} Bets, Risk ${this.props.balance?.totalPendingRisk} to Win ${this.props.balance?.totalPendingWin} `;
  };

  getPastBetInfo = () => {
    if (!this.props.balance?.totalBets)
      return "No Bets Placed"
    else
      return `${this.props.balance?.totalBets} Bets, ${Math.round(this.props.balance?.totalWager/this.props.balance?.totalBets)} Avg Risk`;
  };

  _closeDeposit = () => {
    console.log("CLOSE DEPOSIT PROPS: ", this.props);
    // let P = this.props.profile;
    // if (P.activeBookie && P.gid === P.managerGid) {
    //   this.props.history.push("/bookie");
    // }
    this.setState({ depositOpen: false });
  };

  _closeWithdrawal = () => {
    this.setState({ withdrawalOpen: false });
  };

  CTAs = {
    placeBet: {
      title: "Place a Bet",
      desc: "See how the book works for your players.",
      buttonText: "To Book",
    },
    requestDeposit: {
      title: "Deposit",
      desc: "Request and approve a deposit for play money credits.",
      buttonText: "Request",
    },
  };

  getAdminLinkText = (type) => {
    let returnText;
    if (type === "primary") {
      this.props.profile.activeBookie ? (returnText = "Banker View") : (returnText = "Create Your Own Book");
    } else if (type === "secondary") {
      this.props.profile.activeBookie
        ? (returnText = "your book's admin console")
        : (returnText = "take your friends' bets... for free!");
    }
    return returnText;
  };

  showSignup = () => {
    this.props.dispatch(sportsbookShowSignup(true));
  };

  registerAlert = () => {
    return (
      <ListItemButton
        divider
        onClick={this.showSignup}
        sx={{ borderRadius: 6, margin: 1, backgroundColor: "warning.light" }}
      >
        <ListItemIcon>
          <PersonOutline color="warning" />
        </ListItemIcon>
        <ListItemText primary={"Claim Free Account"} secondary={"save your account history"} />
        <ListItemSecondaryAction>
          <Button color="inherit" size="small">
            SIGN UP
          </Button>
        </ListItemSecondaryAction>
      </ListItemButton>
    );
  };

  renderTransStatus = () => {
    let text = "Deposits, Withdrawals, Bonuses";
    if (this.props.trans) {
      if (Object.keys(this.props.trans)) {
        text = `${Object.keys(this.props.trans).length} pending transactions`;
        return text;
      }
    }
    return text;
  };

  renderCTA = (message) => {
    return (
      <>
        <Grid item xs={6} onClick={() => console.log("Click CTA")}>
          <Paper
            elevation={2}
            sx={{
              borderTopLeftRadius: 30,
              borderTopRightRadius: 5,
            }}
          >
            <Box sx={{ borderBottom: 1, borderBottomColor: "secondary.dark" }}>
              <Box
                sx={{
                  padding: 1.5,
                  textAlign: "center",
                  fontSize: ".95rem",
                  backgroundColor: "secondary.main",
                  color: "secondary.contrastText",
                  borderTopLeftRadius: 30,
                  borderTopRightRadius: 5,
                }}
              >
                {this.CTAs[message].title}
              </Box>
            </Box>
            <DialogContent sx={{ padding: 1, height: "100%" }}>
              <Grid container direction="row" justifyContent="right" alignItems="center">
                <Grid item xs={10}>
                  <Typography sx={{ fontSize: ".8rem", height: { xs: "8vh", sm: "8vh", md: "4vh" } }}>
                    {this.CTAs[message].desc}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  <Box textAlign="right">
                    <ChevronRight />
                  </Box>
                </Grid>
              </Grid>
            </DialogContent>
            {/*<DialogActions>*/}
            {/*  /!*<Button fullWidth variant="contained" color={"secondary"} onClick={() => this.props.history.push("/sports")}>*!/*/}
            {/*  /!*  {this.CTAs[message].buttonText}*!/*/}
            {/*  /!*</Button>*!/*/}
            {/*  /!*<Button fullWidth variant="contained" color={"secondary"} onClick={() => this.giveSelfCredits(500)}>*!/*/}
            {/*  /!*  500 Credits*!/*/}
            {/*  /!*</Button>*!/*/}
            {/*</DialogActions>*/}
          </Paper>
        </Grid>
      </>
    );
  };

  renderBookieUI = () => {
    return <></>;
  };

  renderPlayerUI = () => {
    return <></>;
  };

  generateURL() {

   if (this.props.profile?.gid === "Y7bqQzvue9Mk3AamrIDKNaFpukN2")
      return "superfunbet.com/kelvin"
    else if (this.props.uid === "NLUUYJXONHee2Woo7ktVMFIquQC3")
      return "superfunbet.com/join/4cduVmV0vNoII1QucHpa"
    else if (this.props.uid === "J4fUKbP3CuhkpKHA6df8acZ88W72")
      return "superfunbet.com/join/iDlxBCFgmTPVYuYVTZuO"
    else if (this.props.uid === "fxKT8I0KIvTCbhI2k8u5nFCs0M93")
      return "superfunbet.com/join/3PfmaqsXa5SYbYkgtvOH"
    else if (this.props.uid === "VYMnLWO3yAe4Q5TpJWQhWOjfqfO2")
      return "superfunbet.com/join/WKgL0pPUMdx62VhPMHDj"
    else
      return "superfunbet.com/join/" + this.props.profile?.gid;
  }

  render() {
    console.log("CASHIER this.props: ", this.props);
    console.log("CASHIER this.state: ", this.state);
    return (
      <>
        <Box>
          <Header showBackButton={this.props.profile.activeBookie} />

          {this.props.showSignup && (
            <SignUpDialog open={this.props.showSignup} isBookieReferral={false} userEmail={this.props.profile.email} />
          )}

          {
            this.props.balance?.balance === 0
              && !this.props.profile.activeBookie
              && this.props.profile?.conversionPopups?.knowsHowDepositsWork === false
              &&  (
                <BookieReferralWelcomeHelp open={true}/>
              )
          }

          <List>
            {!this.props.uid && (
              <ListItemButton
                divider
                onClick={() => {
                  this.props.history.push("/signup");
                }}
              >
                <ListItemText secondary={"Sign Up / Sign In "} />
                <ListItemSecondaryAction>
                  <LoginIcon color="primary" />
                </ListItemSecondaryAction>
              </ListItemButton>
            )}


            <ListItemButton
                divider
                onClick={() => {
                  navigator.clipboard.writeText(this.generateURL()).then(() => this.setState({ copySnackBarOpen: true }));
                }}
            >
              <ListItemIcon>
                <ContentCopy color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Invite Friends"
                            secondary={`Click to Copy a Signup Link`}

              />
              <ListItemSecondaryAction>
                <ContentCopy />
              </ListItemSecondaryAction>
            </ListItemButton>

            {
              (this.props.groupData?.activePool && this.props.groupData?.activePool?.isActive) && (
                    <ListItemButton
                        divider
                        onClick={() => {
                          this.props.history.push('/social/leaderboard')
                        }}
                    >
                      <ListItemIcon>
                        <EmojiEventsIcon color="secondary" />
                      </ListItemIcon>
                      <ListItemText primary="Leaderboard"
                                    secondary="View Balances and Bets"
                      />
                      <ListItemSecondaryAction>
                        <ChevronRight />
                      </ListItemSecondaryAction>
                    </ListItemButton>
                )
            }




            <ListItemButton
              divider
              onClick={() => {
                if (this.props.profile.email !== "anon") this.setState({ depositOpen: true });
                else alert("you must sign up first");
              }}
            >
              <ListItemIcon>
                <MonetizationOn color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Deposit"
                            secondary={!this.props.balance?.pendingDepositCount ? "Make a Deposit Request" : this.props.balance?.pendingDepositCount + " pending deposits awaiting approval"}

              />
              <ListItemSecondaryAction>
                <ChevronRight />
              </ListItemSecondaryAction>
            </ListItemButton>

            <CashierDeposit
              open={this.state.depositOpen}
              closeCallback={this._closeDeposit}
              uid={this.props.uid}
              gid={this.props.profile.gid}
              email={this.props.profile.email}
            />

            <ListItemButton
              divider
              onClick={() => {
                if (this.props.profile.email !== "anon") this.setState({ withdrawalOpen: true });
                else alert("you must sign up first");
              }}
            >
              <ListItemIcon>
                <MonetizationOnOutlined color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Withdraw"
                            secondary="Make a Withdrawal"
              />
              <ListItemSecondaryAction>
                <ChevronRight />
              </ListItemSecondaryAction>
            </ListItemButton>

            <CashierWithdrawal
              open={this.state.withdrawalOpen}
              closeCallback={this._closeWithdrawal}
              uid={this.props.uid}
              gid={this.props.profile.gid}
              email={this.props.profile.email}
              balance={this.props.balance?.balance}
            />

            {/*<ListItemButton*/}
            {/*  divider*/}
            {/*  onClick={() => {*/}
            {/*    this.props.history.push("/cashier/transactions");*/}
            {/*  }}*/}
            {/*>*/}
            {/*  <ListItemIcon>*/}
            {/*    <ReceiptLongIcon color="secondary" />*/}
            {/*  </ListItemIcon>*/}
            {/*  <ListItemText primary="BookieTransactions" secondary={this.renderTransStatus()} />*/}
            {/*  <ListItemSecondaryAction>*/}
            {/*    <ChevronRight />*/}
            {/*  </ListItemSecondaryAction>*/}
            {/*</ListItemButton>*/}

            <ListItemButton
              divider
              onClick={() => {
                this.props.history.push("/cashier/pending");
              }}
            >
              <ListItemIcon>
                <Pending color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Pending Bets" secondary={this.getPendingBetInfo()} />
              <ListItemSecondaryAction>
                <ChevronRight />
              </ListItemSecondaryAction>
            </ListItemButton>

            <ListItemButton
              divider
              onClick={() => {
                this.props.history.push("/cashier/past");
              }}
            >
              <ListItemIcon>
                <History color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Past Bets" secondary={this.getPastBetInfo()} />
              <ListItemSecondaryAction>
                <ChevronRight />
              </ListItemSecondaryAction>
            </ListItemButton>

            <ListItemButton
              divider
              onClick={() => {
                this.props.history.push("/cashier/account-summary");
              }}
            >
              <ListItemIcon>
                <Summarize color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Account Summary" secondary="Your Account Balance Sheet" />
              <ListItemSecondaryAction>
                <ChevronRight />
              </ListItemSecondaryAction>
            </ListItemButton>

            <ListItemButton
              divider
              onClick={() => {
                this.props.history.push("/cashier/pnl");
              }}
            >
              <ListItemIcon>
                <DateRange color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Daily Figures" secondary="Wins and Losses by time" />
              <ListItemSecondaryAction>
                <ChevronRight />
              </ListItemSecondaryAction>
            </ListItemButton>

            {this.props.profile.email === "My Demo Account" && (
              <ListItemButton divider onClick={() => this.props.dispatch(showSignUpDialog(true))}>
                <ListItemIcon>
                  <BeenhereIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Save Your Account" secondary="you could lose this temp account" />
                <ListItemSecondaryAction>
                  <ChevronRight />
                </ListItemSecondaryAction>
              </ListItemButton>
            )}

            <ListItemButton
                divider
                onClick={() => {
                  this.props.history.push("/switch");
                }}
            >
              <ListItemIcon>
                <SettingsIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Advanced Options" secondary="Join other books and contests" />
              <ListItemSecondaryAction>
                <ChevronRight />
              </ListItemSecondaryAction>
            </ListItemButton>

            {this.props.profile.email === "My Demo Account" && (
              <ListItemButton
                divider
                onClick={() => {
                  this.props.history.push("/signout");
                }}
              >
                <ListItemIcon>
                  <AccountBalance color="grey" />
                </ListItemIcon>
                <ListItemText primary="Delete Temp Account" secondary="you can always make another one" />
                <ListItemSecondaryAction>
                  <ChevronRight />
                </ListItemSecondaryAction>
              </ListItemButton>
            )}

            {this.props.profile.email !== "My Demo Account" && (
              <ListItemButton
                divider
                onClick={() => {
                  this.props.history.push("/signout");
                }}
              >
                <ListItemIcon>
                  <ExitToAppIcon color="grey" />
                </ListItemIcon>
                <ListItemText secondary={this.props.profile.email} primary="Log Out" />
                <ListItemSecondaryAction>
                  <ChevronRight />
                </ListItemSecondaryAction>
              </ListItemButton>
            )}
          </List>
          <Snackbar
              open={this.state.copySnackBarOpen}
              onClose={() => this.setState({ copySnackBarOpen: false })}
              autoHideDuration={6000}
              message={String("Copied: ")}
              severity="info"
              sx={{ bottom: { xs: 16, sm: 16 } }}
          >
            <Alert onClose={() => this.setState({ copySnackBarOpen: false })} severity="success" sx={{ width: "100%" }}>
              {String("Copied: " + this.generateURL())}
            </Alert>
          </Snackbar>
        </Box>
        <BottomNav managerGid={this.props.profile.managerGid} />
      </>
    );
  }
}

Cashier.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  email: PropTypes.string,
  balance: PropTypes.number,
  uid: PropTypes.string,
  profile: PropTypes.object,
  history: PropTypes.array,
  userGroupPastBets: PropTypes.object,
  userGroupPendingBets: PropTypes.object,
  pastBets2: PropTypes.object,
  pendingBets2: PropTypes.object,
  trans: PropTypes.object,
  showSignup: PropTypes.bool,
  groupData: PropTypes.object.isRequired
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: "inline-block",
    marginTop: "20px",
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  grid: {
    textAlign: "center",
    marginBottom: "20px",
  },
});

const mapStateToProps = (state) => {
  // const getPastBets = (b) => {
  //   const collection = collect(b);
  //   const pastBets = collection.filter((val) => val.result !== "pending");
  //   return pastBets.all();
  // };
  // const getPendingBets = (b) => {
  //   const collection = collect(b);
  //   const pendingBets = collection.filter((val) => val.result === "pending");
  //   return pendingBets.all();
  // };

  return {
    uid: state.firebase.auth.uid,
    email: state.firebase.auth.email,
    profile: state.firebase.profile,
    // pastBets2: getPastBets(state.firestore.data.bets2),
    // pendingBets2: getPendingBets(state.firestore.data.bets2),
    balance: state.firestore.data.cashier_userBalance,
    showSignup: state.newUserState.showSignUpDialog || false,
    trans: state.firestore.data.cashier_trans,
    groupData: state.firestore.data?.header_playerGroup,

  };
};

export default compose(
  connect(mapStateToProps),
  withRouter,
  firestoreConnect((props) => {
    // console.log("---------------firestore connecting");
    // console.log("---------------USER ACCOUNT SUMMARY connect props", props);

    return [
      // {
      //   collection: `groups/${props.profile.gid}/bets2/`,
      //   where: [["uid", "==", props.uid ? props.uid : false]],
      //   storeAs: "bets2",
      // },
      {
        collection: `groups/${props.profile.gid}/balances/`,
        doc: props.uid,
        storeAs: "cashier_userBalance",
      },
      {
        collection: `groups/${props.profile.gid}/transactions/`,
        where: [
          ["uid", "==", props.uid ? props.uid : false],
          ["state", "==", "pending"],
        ],
        storeAs: "cashier_trans",
      },
    ];
  })
)(withStyles(styles)(Cashier));
