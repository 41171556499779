import { alpha, InputAdornment, styled, Switch, TextField } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { connect } from "react-redux";
import { compose } from "redux";
import Button from "@mui/material/Button";
import SearchIcon from "@mui/icons-material/Search";
import BetItem2 from "./BetItem2";
import Typography from "@mui/material/Typography";

const CoinflipSwitch = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: alpha(theme.palette.secondary.main, theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: theme.palette.secondary.main,
  },
  "& .MuiSwitch-switchBase": {
    color: theme.palette.secondary.main,
    "&:hover": {
      backgroundColor: alpha(theme.palette.primary.dark, theme.palette.action.hoverOpacity),
    },
  },
}));

class PropLines extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      userWantsCoinflips: false,
      searchText: "",
      searchTextLower: "",
      backgroundOn: false,
      coinflipHintOpen: false,
    };
  }

  americanJuiceFromPcnt = (newJuice) => {
    let impliedA = 0.5;
    impliedA += (impliedA * newJuice) / 100;
    return Math.round((impliedA / (1 - impliedA)) * -100);
  };

  _changeSearch = (event) => {
    if (event.target.value === "") {
      // getFirebase().analytics().logEvent("SEARCH_BAR_CLEARED");
    } else {
      // getFirebase().analytics().logEvent("SEARCH_BAR_CHANGED");
    }
    this.setState({
      searchText: event.target.value,
      searchTextLower: event.target.value.toLowerCase(),
    });
  };



  renderLineXXX() {
    const PP = this.props.lineData;
    const allowedProps = [
      "Player Passing Yards",
      "Player Passing Completions",
      "Player Passing Attempts",
      "Player Passing Touchdowns",
      "Player Interceptions",
      "Player Receptions",
      "Player Receiving Yards",
      "Player Receiving Touchdowns",
      "Player Longest Reception",
      "Player Rushing Attempts",
      "Player Rushing Yards",
      "Player Rushing Touchdowns",
      "Player Longest Rush",
      "Player Kicking Points",
      "Player Field Goals Made",
      "Player Extra Points Made",
      "Player Tackles",
      "Player Sacks",
    ]
    if (PP) {
      return PP.filter((i) => {
        console.log("I: ",i.market_name)
        if (!allowedProps.includes(i.market_name))
          return 0
        if (
          this.state.searchTextLower.length > 1 &&
          !i.name.toLowerCase().includes(this.state.searchTextLower) &&
          !i.market_name.toLowerCase().includes(this.state.searchTextLower)
        ) {
          console.log("no match: ", i.name, i.name.toLowerCase(), this.state.searchTextLower);
          return 0;
        }
        return 1;
      }).map((i, x) => {
        // console.log("i, x: ", i, x);
        let betDisplayName = i.name + i.market_name.replace("Player", "");
        // console.log("I: ",i)
        return (
          <Grid key={i} item container xs={12} direction="row" alignItems="stretch">
            <Grid item xs={12} sm={12}>
              <Grid item container xs={12} direction="column" alignItems="center">
                <Grid xs={12} item width="100%">
                  <BetItem2
                    isLive={false}
                    isHalf={false}
                    price={i.price}
                    rowColor={x % 2 === 0 ? "one" : "two"}
                    betPeriod={"ft"}
                    juice={0}
                    coinflipJuice={0}
                    label={betDisplayName}
                    betName={i.name}
                    market={i.market_name}
                    bucket={this.props.bucket}
                    collection="futures"
                    docId={this.props.event_id}
                    betType="props"
                    betSide="away"
                    lineSource={"lines_standardized"}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        );
      });
    } else return null;
  }

  render() {
    if (!this.props.expanded) {
      return null;
    }
    console.log("proplines this.props", this.props);
    console.log("proplines this.state", this.state);
    const { classes } = this.props;
    const EO = this.props.lineData;
      return (
        <>
          {/*<Header />*/}

          {EO && (
            <Grid
              container
              item
              xs={12}
              direction="column"
              justifyContent="space-evenly"
              alignItems="center"
              style={{ paddingTop: 0 }}
            >
              <Grid item xs={12} sx={{ padding: 2 }}>
                <Typography variant="h8" color="inherit" className={classes.title}>
                  {this.props.away} at {this.props.home}
                  {/*<small>*/}
                  {/*    ({getTimestamp(EO.starts_ms)})*/}
                  {/*</small>*/}
                </Typography>
              </Grid>
              <Grid item xs={12} sx={{ padding: 2 }}>
                <TextField
                  padding={20}
                  id="standard-search"
                  variant="outlined"
                  label="Search Props"
                  type="search"
                  onChange={this._changeSearch}
                  onFocus={(event) => {
                    event.target.select();
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                  size="small"
                  color={"secondary"}
                  sx={{ background: "#FFFFFFAA" }}
                />
              </Grid>
            </Grid>
          )}

          {this.renderLineXXX()}

        </>
      );
  }
}

PropLines.defaultProps = {
  isLive: false,
};

PropLines.propTypes = {
  expanded: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  lineData: PropTypes.object.isRequired,
  home: PropTypes.string.isRequired,
  away: PropTypes.string.isRequired,
  bucket: PropTypes.string,
  event_id: PropTypes.string,
  searchTerm: PropTypes.string,
};

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  drawerDirection: {
    direction: theme.direction,
  },
  title: {
    flexGrow: 1,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  hide: {
    display: "none",
  },
  dividerInset: {
    margin: `5px 0 0 ${theme.spacing(9)}`,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
});

const mapStateToProps = (state) => {
  return {
    parlayInflight: state.sportsbookState.parlayInflight,
    // profile: state.firebase.profile,
    // eventOdds: state.firestore.data.eventOdds,
  };
};

export default compose(connect(mapStateToProps))(withStyles(styles)(PropLines));
