import React, {Component} from "react";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import ChangeGroupMenuItem from "./ChangeGroupMenuItem";
import {alpha, Divider, ListItemButton, ListItemText} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { getFirebase } from "react-redux-firebase";
import { showSignUpDialog } from "../reducers/new-user-reducer";

class BookiePopper extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
    };
    // this.anchorRef = React.useRef(null);
  }

  openSuperAdmin = () => {
    this.props.history.push('/super-admin')
  }

  handleToggle = () => {
    console.log("Handle Toggle");
    this.setState({ open: true });
  };

  handleClose = (event) => {

    if (this.anchorRef.current && this.anchorRef.current.contains(event.target)) {
      return;
    }

    this.setState({ open: false });
  };

  DenseMenu() {
    return (
      <Paper sx={{ width: 320 }}>
        <ClickAwayListener onClickAway={this.handleClose}>
        <MenuList dense>
          <MenuItem>
            <ListItemText>Help</ListItemText>
          </MenuItem>
          <MenuItem>
            <ListItemText onClick={() => {
              this.handleClose();
              window.location = 'mailto:admin@superfunbet.com';
            }} inset>Email Support</ListItemText>
          </MenuItem>
          {
            this.props.email.includes("demo") && (<>
              <MenuItem>
                <ListItemText>Your Temp Account</ListItemText>
              </MenuItem>

              <MenuItem>
                <ListItemText onClick={() => {
                  getFirebase().auth().currentUser.delete().then(() => {
                    this.props.history.push('/')
                    window.location.reload()
                  })
                }} inset>Delete Account</ListItemText>
              </MenuItem>

              <MenuItem>
                <ListItemText onClick={() => {
                  this.handleClose()
                  this.props.dispatch(showSignUpDialog(true))
                }} inset>Create Full Account</ListItemText>
              </MenuItem>
            </>)
          }

          {
            !this.props.email.includes("demo") && (<>
              <MenuItem>
                <ListItemText>{this.props.email}</ListItemText>
              </MenuItem>
              <MenuItem>
                <ListItemText onClick={() => this.props.history.push('/signout')} inset>Sign Out</ListItemText>
              </MenuItem>
            </>)
          }




        </MenuList>
        </ClickAwayListener>
      </Paper>
    );
  }

  render() {
    console.log("!!! click balance 1", this.state);
    console.log("! click balance ", this.props);
    return (
      <Stack direction="row" spacing={2}>
        <div>
          <Button
            ref={(ref) => this.anchorRef = ref}
            id="composition-button"
            style={{ borderColor: alpha("#bf0070",0.00) }}
            aria-controls={this.state.open ? "composition-menu" : undefined}
            aria-expanded={this.state.open ? "true" : undefined}
            aria-haspopup="true"
            onClick={this.handleToggle}
            variant={"outlined"}
          >
            <MenuIcon style={{marginLeft: 10}}/>
          </Button>
          <Popper
            style={{ zIndex: 6 }}
            open={this.state.open}
            anchorEl={this.anchorRef}
            role={undefined}
            placement="bottom-start"
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin: placement === "bottom-start" ? "left top" : "left bottom",
                }}
              >
                {this.DenseMenu()}
              </Grow>
            )}
          </Popper>
        </div>
      </Stack>
    );
  }
}

BookiePopper.propTypes = {
  recentBooks: PropTypes.array,
  currentBook: PropTypes.string,
  func: PropTypes.func,
  uid: PropTypes.string,
  groups: PropTypes.object,
  profile: PropTypes.object,
  history: PropTypes.object,
  signup: PropTypes.func,
  switch: PropTypes.func,
  dispatch: PropTypes.func,
  balance: PropTypes.string,
  email: PropTypes.string,
};

export default BookiePopper;


// <Paper>
//   <ClickAwayListener onClickAway={this.handleClose}>
//     <MenuList autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button">
//       {
//         this.props.profile.isEmpty &&
//         <Button onClick={this.props.signup} > Sign Up First </Button>
//       }
//       {!this.props.profile.isEmpty && (this.props.profile.email.includes("brian.danilo@gmail.com") || this.props.profile.email.includes("ephlind")) &&
//       <MenuItem key={"super"} onClick={this.openSuperAdmin}>
//         SuperAdmin
//       </MenuItem>
//       }
//       {this.props.recentBooks && this.props.recentBooks.map((b) => {
//         return (
//           <ChangeGroupMenuItem gid={b} key={b} closeCallback={this.handleClose}/>
//         );
//       })}
//       {
//         !this.props.recentBooks && (
//           <MenuList
//             sx={{ width: 320 }}
//             dense autoFocusItem={open} id="composition-menu" aria-labelledby="composition-button">
//             <MenuItem>
//               <ListItemText color="secondary" onClick={this.props.switch} inset>Bookie Mode</ListItemText>
//             </MenuItem>
//             <Divider />
//             <MenuItem color="secondary" onClick={this.props.switch} > Switch Sportsbook </MenuItem>
//             <Divider />
//
//             <MenuItem color="secondary" onClick={this.props.switch} > Switch Sportsbook </MenuItem>
//           </MenuList>
//
//         )
//       }
//     </MenuList>
//   </ClickAwayListener>
// </Paper>
