import { createAction, handleActions } from "redux-actions";
import { getFirebase } from "react-redux-firebase";

export const changeGroupJuice = createAction("CHANGE_GROUP_JUICE");
export const changeGroupCoinflipJuice = createAction("CHANGE_GROUP_COINFLIP_JUICE");
export const changeUsersCanGrade = createAction("CHANGE_USERS_CAN_GRADE");
export const changeBookName = createAction("CHANGE_BOOK_NAME");
export const changeUsername = createAction("CHANGE_USERNAME");
export const activateBookie = createAction("ACTIVATE_BOOKIE");

const initialState = {};

export default handleActions(
  {
    CHANGE_GROUP_JUICE: (state, { payload }) => {
      // getFirebase().analytics().logEvent("CHANGE_JUICE");
      const db = getFirebase().firestore();
      let newJuiceForDB = parseFloat(payload.newJuice);
      const groupDocRef = db.collection("groups").doc(payload.gid);
      groupDocRef
        .update({
          groupJuice: newJuiceForDB,
        })
        .then(() => {
          console.log("Document successfully updated!");
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
        });
      return {
        ...state,
      };
    },
    CHANGE_GROUP_COINFLIP_JUICE: (state, { payload }) => {
      // getFirebase().analytics().logEvent("CHANGE_COINFLIP_UICE");
      const db = getFirebase().firestore();
      let newJuiceForDB = parseFloat(payload.newCoinflipJuice);
      const groupDocRef = db.collection("groups").doc(payload.gid);
      groupDocRef
        .update({
          groupCoinflipJuice: newJuiceForDB,
        })
        .then(() => {
          console.log("Document successfully updated!");
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
        });
      return {
        ...state,
      };
    },
    CHANGE_USERS_CAN_GRADE: (state, { payload }) => {
      // getFirebase().analytics().logEvent("CHANGE_COINFLIP_UICE");
      const db = getFirebase().firestore();
      const groupDocRef = db.collection("groups").doc(payload.gid);
      groupDocRef
        .update({
          gradeYourOwnBet: payload.usersCanGrade,
        })
        .then(() => {
          console.log("Document successfully updated!");
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
        });

      return {
        ...state,
      };
    },
    CHANGE_BOOK_NAME: (state, { payload }) => {
      // getFirebase().analytics().logEvent("CHANGE_BOOK_NAME");
      const db = getFirebase().firestore();
      const groupDocRef = db.collection("groups").doc(payload.gid);
      groupDocRef
        .update({
          groupName: payload.newBookName,
        })
        .then(() => {
          console.log("Document successfully updated!");
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
        });
      return {
        ...state,
      };
    },
    CHANGE_USERNAME: (state, { payload }) => {
      // getFirebase().analytics().logEvent("CHANGE_USERNAME");
      const db = getFirebase().firestore();

      const userDocRef = db.collection("users").doc(payload.uid)
      const userGroupBalRef = db.collection("groups").doc(payload.gid).collection("balances").doc(payload.uid);

      //update in group balances
      userGroupBalRef
        .update({
          username: payload.newUsername,
        })
        .then(() => {
          console.log("Document successfully updated!");
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
        });

      //update in user doc
      userDocRef
        .update({
          username: payload.newUsername,
        })
        .then(() => {
          console.log("Document successfully updated!");
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
        });

      return {
        ...state,
      };
    },
    ACTIVATE_BOOKIE: (state, { payload }) => {
      // getFirebase().analytics().logEvent("ACTIVATE_BOOKIE");
      const db = getFirebase().firestore();

      const userDocRef = db.collection("users").doc(payload.uid)

      //update in user doc
      userDocRef
        .update({
          activeBookie: true,
        })
        .then(() => {
          console.log("Bookie Activated!!");
        })
        .catch((error) => {
          console.error("Error updating document: ", error);
        });

      return {
        ...state,
      };
    },
  },
  initialState
);
