import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { getTimestamp } from "../../../reducers/sportsbook-reducer";
import { formatSpread } from "../../../utilities/pickemUtils";

const EntryPick = (props) => {
  const getColor = () => {
    if (props.pick.result === "win") {
      return "green";
    }
    if (props.pick.result === "loss") {
      return "red";
    }
    if (props.pick.result === "pending") return "grey";
    else return "black";
  };

  const pointsWon = () => {
    if (props.pick.result === "pending") {
      return "";
    }
    if (props.pick.result === "win") {
      return "";
    }
    if (props.pick.result === "push") {
      return props.riskAmount + " / ";
    }
    if (props.pick.result === "loss") {
      return 0 + " / ";
    }
    return "";
  };

  const truncateString = (inputString, maxLength) => {
    if (inputString.length <= maxLength) {
      return inputString;
    } else {
      return inputString.slice(0, maxLength) + "...";
    }
  };

  const getPickLabel = () => {
    const spread = formatSpread(
      (props.pick.pickDetails.betSide === "away" ? -1 : 1) *
        props.pick.game.standard?.ft?.[props.pick.pickDetails.betType]?.points
    );

    const total =
      (props.pick.pickDetails.betSide === "over" ? "Over " : "Under ") +
      props.pick.game.standard?.ft?.[props.pick.pickDetails.betType]?.points;

    if (props.pick.pickDetails.betType === "moneyline")
      return "" + props.pick.game[props.pick.pickDetails.betSide] + " ML";

    if (props.pick.pickDetails.betType === "spread") {
      return props.pick.game[props.pick.pickDetails.betSide] + " " + spread;
    }

    if (props.pick.pickDetails.betType === "total") {
      return total;
    }
  };

  console.log("pick display props: ", props);
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{ marginBottom: 1, padding: 0, maxWidth: "400px" }}
    >
      <Grid item xs={8.5} sx={{ padding: 0 }}>
        <Typography sx={{ fontSize: ".9rem", color: getColor() }} textAlign={"left"}>
          {getPickLabel()}
        </Typography>
      </Grid>
      <Grid item xs={3.5} sx={{ padding: 0 }}>
        <Typography sx={{ fontSize: ".9rem", color: getColor() }} textAlign={"right"}>
          {props.pick.points && pointsWon() + props.pick.points + "pts"}
        </Typography>
      </Grid>

      <Grid item xs={8.5} sx={{ marginBottom: 1, paddingTop: 0 }}>
        <Typography sx={{ fontSize: ".6rem", paddingTop: 0 }} textAlign={"left"}>
          {props.pick.game.away + " at " + props.pick.game.home}
        </Typography>
      </Grid>
      <Grid item xs={3.5} sx={{ marginBottom: 1, paddingTop: 0 }}>
        <Typography sx={{ fontSize: ".6rem", paddingTop: 0 }} textAlign={"right"}>
          {getTimestamp(props.pick.game.starts_ms)}
        </Typography>
      </Grid>
    </Grid>
  );
};

EntryPick.propTypes = {
  pick: PropTypes.object.isRequired,
  riskAmount: PropTypes.number.isRequired,
};

export default EntryPick;
