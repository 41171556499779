const juiceMaps = {
  "-100": 0,
  "-101": 0.49,
  "-102": 0.98,
  "-103": 1.46,
  "-104": 1.92,
  "-105": 2.38,
  "-106": 2.83,
  "-107": 3.27,
  "-108": 3.7,
  "-109": 4.13,
  "-110": 4.55,
  "-111": 4.95,
  "-112": 5.36,
  "-113": 5.75,
  "-114": 6.14,
  "-115": 6.75,
};
export default juiceMaps;
