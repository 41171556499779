import React, { useEffect } from "react";
import PropTypes from "prop-types";
import List from "@mui/material/List";
import firebase from "../../../firebase";
import { LeaderboardEntryListItem } from "../Entries/LeaderboardEntryListItem";
import { Grid, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import moment from "moment";

TournamentDetailStarted.propTypes = {
  tournamentID: PropTypes.string.isRequired,
  riskAmount: PropTypes.number.isRequired,
  tournament: PropTypes.object.isRequired,
};

function TournamentDetailStarted(props) {
  const [entries, setEntries] = React.useState([]);
  const [leader, setLeader] = React.useState({}); // [leaderPendingPoints, leaderEarnedPoints, leaderPossiblePoints
  const auth = firebase.auth();
  useEffect(() => {
    const fetchEntries = async () => {
      const db = firebase.firestore();
      await db
        .collection("pickem")
        .doc("tournies")
        .collection("entries")
        .where("tournamentID", "==", props.tournamentID)
        .onSnapshot((querySnapshot) => {
          let entries = [];
          let leaderPendingPoints = 0;
          let leaderEarnedPoints = 0;
          let leaderPossiblePoints = 0;
          querySnapshot.forEach((doc) => {
            const entry = doc.data();
            const originalPoints = entry.picks.map((pick) => parseFloat(pick.points)).reduce((a, b) => a + b, 0.0);
            const pointsPending = entry.picks
              .filter((pick) => pick.result === "pending")
              .map((pick) => parseFloat(pick.points))
              .reduce((a, b) => a + b, 0.0);
            const pointsEarned = entry.picks
              .filter((pick) => pick.result === "win" || pick.result === "push")
              .map((pick) => (pick.result === "win" ? parseFloat(pick.points) : entry.riskAmount))
              .reduce((a, b) => a + b, 0.0);
            if (pointsPending + pointsEarned > leaderPossiblePoints) {
              leaderPendingPoints = pointsPending;
              leaderEarnedPoints = pointsEarned;
              leaderPossiblePoints = pointsPending + pointsEarned;
            }
            entries.push({
              ...entry,
              id: doc.id,
              originalPoints,
              pointsPending,
              pointsEarned,
              pointsPossible: pointsPending + pointsEarned,
            });
          });
          setEntries(entries);
          setLeader({ leaderPendingPoints, leaderEarnedPoints, leaderPossiblePoints });
        });
    };
    fetchEntries();
  }, [props.tournamentID]);

  console.log("tournament detail start: ", auth?.currentUser?.uid);
  return (
    <>
      <Box
        sx={{
          textAlign: "center",
          padding: 1,
          border: 1,
          borderRadius: 1,
          minWidth: "250px",
          borderColor: "#bf0070",
          color: "#bf0070",
          margin: 1,
        }}
      >
        <Grid xs={12} container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Grid xs={12} container direction="row" justifyContent="center" alignItems="center">
              <Grid item xs={1}>
                <EmojiEventsIcon sx={{ color: "#bf0070" }} />
              </Grid>
              <Grid item xs={10}>
                <Typography variant="body1" color="text.primary" sx={{ fontWeight: 800 }}>
                  {props.tournament.tournamentName} Leaderboard
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <EmojiEventsIcon sx={{ color: "#bf0070" }} />
              </Grid>

              <Grid item xs={12}>
                <Grid xs={12} container direction="column" justifyContent="center" alignItems="center">
                  <Grid item>
                    <Typography component="span" variant="body2" color="text.primary">
                      {props.tournament.numEntries} entries
                    </Typography>
                  </Grid>
                  {/*<Grid item>*/}
                  {/*  <Typography component="span" variant="body2" color="text.primary">*/}
                  {/*    ${props.tournament.prizepool} prize pool*/}
                  {/*  </Typography>*/}
                  {/*</Grid>*/}
                  {((props.tournament.startTime < new Date() || props.tournament.hasStarted) && (
                    <Grid item>
                      <Typography component="span" variant="body2" color="text.primary">
                        Tournament Started
                      </Typography>
                    </Grid>
                  )) || (
                    <Grid item>
                      <Typography component="span" variant="body2" color="text.primary">
                        Started: {moment(props.tournament.startTime?.toDate()).calendar()}
                      </Typography>
                    </Grid>
                  )}
                  <Grid item>
                    <Typography component="span" variant="body2" color="text.primary">
                      You have {entries?.filter((entry) => entry.user_id === auth?.currentUser?.uid).length} entries
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <List sx={{ width: "100%" }}>
        {entries
          .sort((a, b) => -1 * (a.pointsEarned - b.pointsEarned))
          // .sort((a, b) => -1 * (a.pointsPossible - b.pointsPossible))
          .map((entry) => {
            return (
              <LeaderboardEntryListItem
                entry={entry}
                key={entry.id}
                hasLeaderData={true}
                leaderEarnedPoints={leader.leaderEarnedPoints}
                leaderPossiblePoints={leader.leaderPossiblePoints}
                leaderPendingPoints={leader.leaderPendingPoints}
              />
            );
          })}
      </List>
    </>
  );
}

export default TournamentDetailStarted;
