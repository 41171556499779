import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Divider, ListItemText } from "@mui/material";
import firebase from "firebase/compat/app";
import moment from "moment/moment";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import CreateTournament from "../Common/CreateTournament.js";

const AdminTournaments = (props) => {
  const [tournaments, setTournaments] = React.useState([]);
  const [createTournamentOpen, setCreateTournamentOpen] = React.useState(false);
  const [editTournamentData, setEditTournamentData] = React.useState({});

  const fetchTournaments = async () => {
    const db = firebase.firestore();
    await db
      .collection("pickem")
      .doc("tournies")
      .collection("active")
      .onSnapshot((querySnapshot) => {
        const newData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        setTournaments(newData);
      });
  };

  const startTournament = async (tournamentID, start) => {
    const db = firebase.firestore();
    await db.collection("pickem").doc("tournies").collection("active").doc(tournamentID).update({ hasStarted: start });
  };

  useEffect(() => {
    fetchTournaments();
  }, []);

  const createTournament = () => {
    setEditTournamentData({});
    setCreateTournamentOpen(true);
  };

  const clickTournament = (tournamentData) => {
    setEditTournamentData({ edit: true, tournamentID: tournamentData.id, ...tournamentData });
    setCreateTournamentOpen(true);
  };

  return (
    <div>
      <CreateTournament
        open={createTournamentOpen}
        handleClose={() => setCreateTournamentOpen(false)}
        {...editTournamentData}
      />
      <Dialog fullScreen open={props.open} onClose={props.handleClose}>
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              Tournament Admin
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ padding: 2 }}>
          <Grid item>
            <Button onClick={createTournament} variant="contained">
              Create New Tournament
            </Button>
          </Grid>
        </Grid>
        <List>
          {tournaments.map((tournament) => (
            <div key={tournament.id}>
              <ListItem
                secondaryAction={
                  <>
                    <Button onClick={() => clickTournament(tournament)} variant="contained">
                      Edit
                    </Button>
                    <Button onClick={() => startTournament(tournament.id, !tournament.hasStarted)} variant="contained">
                      {tournament.hasStarted ? "Stop" : "Start"}
                    </Button>
                  </>
                }
              >
                <ListItemText
                  primary={tournament.tournamentName}
                  secondary={"Scheduled: " + moment(tournament.startTime).calendar()}
                />
              </ListItem>
              <Divider />
            </div>
          ))}
        </List>
      </Dialog>
    </div>
  );
};

AdminTournaments.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AdminTournaments;
