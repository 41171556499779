import React from "react";
import { TournamentList } from "./Tournaments/TournamentList";
import PickemAppBar from "./PickemAppBar";

export function PickemHome() {
  return (
    <div>
      <PickemAppBar title="Pickem" />
      <TournamentList />
    </div>
  );
}
