import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Container from "@mui/material/Container";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import List from "@mui/material/List";
import { Box, Typography } from "@mui/material";
import GenericBackHeader from "../../../components/GenericBackHeader";
import sportsBackgroundImage from "../../../images/sports-background-yellowy.png";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { firestoreConnect } from "react-redux-firebase";
import Link from "@mui/material/Link";

class GeneralFaq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderAccordions() {
    const faq = [
      {
        question: "What is SuperFunBet?",
        answer: `SuperFunBet is casino software that gives everyone their own private sportsbook... completely free!  
        You can invite whoever you want, change the book's name, juice, max bet, etc.`,
      },
      {
        question: "How do I start my own sportsbook?",
        answer: `Every (free) SuperFunBet account comes with a (free) private Sportsbook.  If you want to use it:`,
        linkPath: "/admin",
        linkAnchorText: `Go to your book's admin console`,
      },
      {
        question: "Is this for real money?",
        answer: `SuperFunBet is free in every sense of the word.  We don't take any money, ever.  How you and your friends use the credits is between you, your god, and whatever crooked politicans make your laws.`,
      },
      {
        question: "How does it work?",
        answer: `When you create a sportsbook, you are the bank.  You can invite unlimited friends to place bets with you.  They can request credits through the Cashier, or 
        you can give them a bonus in the Banker Admin.`,
      },
      {
        question: "What's the catch?",
        answer: `There's no catch.  We think it's smarter to bet with your friends than with casinos.`,
      },
      {
        question: "I want human help",
        answer: `Email admin@superfunbet.com.  We're eager for human contact as well.`,
      },
      // {
      //   question: "How do I join a friend's private sportsbook?",
      //   answer: `When you sign up, you are automatically enrolled in a free public sportsbook.  If you want to
      //   join a friend's book, navigate to "Swith Sportsbook" and enter the name of your friend's book.`,
      // },
      // {
      //   question: "How do I get credits from a private sportsbook?",
      //   answer: `Navigate to "Request Funds" and issue a request.  Your sportsbook owner will have to approve the request.  `,
      // },
      // {
      //   question: "Can I bet with multiple books at the same time?",
      //   answer: `Yep!  When you switch sportsbooks, all your acitvity from your old book is preserved  if you
      //   what to switch back.`,
      // },
      // {
      //   question: "How do coinflips work?",
      //   answer: `When you bet using the coinflip feature, the App selects a side for you.  All outcomes are equally likely.
      //   Because you have no control and thus cannot be "sharp", most sportsbooks will offer reduced juice on coinflips.`,
      // },
      // {
      //   question: "How do moneyline coinflips work if one side is a big favorite?",
      //   answer: `All coinflip outcomes are juiced at the same price (usually lower than normal bets).  For example, even if the Giants are -500 and
      //   the Patriots are +600, if you coinflip on the moneyline, both teams are now -102 (or whatever your admin has set coinflip juice).
      //   In the long run, your expected return is always the same, but after the flip, you might have gotten a great price on a heavy favorite, or
      //   a terrible price on a big underdog.`,
      // },
      // {
      //   question: "Can I bet Inplay without coinflips?",
      //   answer: `No.  Our Inplay lines are not yet sharp enough to allow users to choose a side.`,
      // },
      // {
      //   question: "Are coinflips truly random?",
      //   answer: `Yes.  If you think your bookie-friend is scamming you:  a) get better friends, and b) create your own
      //   sportsbook and verify that there isn't a "rig coinflips" button.`,
      // },
      // {
      //   question: "Can I try coinflips without risking anything?",
      //   answer: `Yes.  You can bet 0 just to see how it works.`,
      // },
    ];

    return faq.map((i) => {
      return (
        <Accordion
          key={i}
          elevation={0}
          onChange={() => {
            this.setState({ i: !this.state.i });
          }}
          expanded={this.state.i}
        >
          <AccordionSummary
            sx={{ backgroundColor: "white" }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
          >
            {i.question}
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: "white" }}>
            <List>{i.answer}</List>
            <Link target="_blank" href={i.linkPath} color={"secondary"}>
              {i.linkAnchorText}
            </Link>
            <br />
            <Link target="_blank" href={i.linkPath2} color={"secondary"}>
              {i.linkAnchorText2}
            </Link>
          </AccordionDetails>
        </Accordion>
      );
    });
  }

  render() {
    console.log("faq this.props: ", this.props);
    console.log("faq this.state: ", this.state);
    return (
      <>
        <Box sx={{ minHeight: "100vh", flexGrow: 1, backgroundImage: `url(${sportsBackgroundImage})` }}>
          <GenericBackHeader title="Frequently Asked Questions" showBackButton />
          <Container>
            <h3>
              <Typography color={"quaternary"}>Call (805) 229-1131 for human help</Typography>
            </h3>
            {this.renderAccordions()}
          </Container>
        </Box>
      </>
    );
  }
}

GeneralFaq.propTypes = {
  classes: PropTypes.object.isRequired,
  // dispatch: PropTypes.func,
  // email: PropTypes.string,
  // adminPastBets: PropTypes.object,
  // adminPendingBets: PropTypes.object,
  // adminPendingTrans: PropTypes.object,
  // profile: PropTypes.object,
  history: PropTypes.object,
  // showSportsBookSignup: PropTypes.bool,
  // auth: PropTypes.object,
  // uid: PropTypes.string,
  // sportsbookState: PropTypes.object,
  // managerGroupConfigs: PropTypes.object
};

// <Box style={{margin: 10}}>
//   <Typography> If you are looking to start your own sportsbook: </Typography>
//   <Link target="_blank" href={'/bookie-quickstart-guide'} color={"secondary"}>{`Help starting your own sportsbook`}</Link>
// </Box>

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: "inline-block",
    marginTop: "20px",
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  grid: {
    textAlign: "center",
    marginBottom: "20px",
  },
  dialogTitle: {
    color: "green",
    paddingBottom: 0,
  },
  dialogSubtitle: {
    paddingTop: 10,
    color: theme.palette.primary.light,
  },
});

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    email: state.firebase.auth.email,
    profile: state.firebase.profile,
  };
};

export default compose(
  connect(mapStateToProps),
  withRouter,
  firestoreConnect((props) => {
    return [
      // {
      //   collection: `groups/${props.profile.managerGid}/bets`,
      //   where: [["result", "!=", "pending"]],
      //   storeAs: "adminPastBets",
      // },
      // {
      //   collection: `groups/${props.profile.managerGid}/bets`,
      //   where: [["result", "==", "pending"]],
      //   storeAs: "adminPendingBets",
      // },
      // {
      //   collection: `groups/${props.profile.managerGid}/transactions`,
      //   where: [["state", "==", "pending"]],
      //   storeAs: "pendingTransactions",
      // },
      // {
      //   collection: "groups",
      //   doc: !props.profile.isEmpty ? props.profile.managerGid : "wvDjIgA8N2HYOaCUEjAT",
      //   storeAs: "admin_managerGroupConfigs",
      // },
    ];
  })
)(withStyles(styles)(GeneralFaq));

/*
{this.props.pastTransactions && <UserTool bets={this.props.pastBets} trans={this.props.pastTransactions} balances={this.props.balances} />}


        {this.props.adminViewBets && this.props.adminViewTransactions && <GroupPastBets allBets={this.props.adminViewBets} users={this.props.users} />}
        {this.props.adminViewBets && this.props.adminViewTransactions && <UserTool bets={this.props.adminViewBets} trans={this.props.adminViewTransactions} users={this.props.users} />}
        {this.props.group && this.props.profile.groupCreated && <GroupSettings group={this.props.group} groupCreated={this.props.profile.groupCreated} />}
        {(this.props.profile.email === 'brian.danilo@gmail.com' || this.props.profile.email === 'ephlind@gmail.com') && <GlobalPendingBets allBets={this.props.godViewBets}/>}
 */
