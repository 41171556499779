import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Slide,
  TextField,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { Wheel } from "react-custom-roulette";
import Button from "@mui/material/Button";
import { submitBet2 } from "../../../reducers/sportsbook-reducer";
import { v4 } from "uuid";
import { connect } from "react-redux";
import {
  adjustRef,
  betLine2,
  betPrimaryText2,
  emailData,
  oddsjamCoinflipGradingData,
} from "../components/PendingHelpers";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});

const styles = (theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  betField: {
    margin: 8,
  },
});

const COINFLIP_DEFAULT_STATE = {
  riskAmount: (0).toFixed(2),
  winAmount: (0).toFixed(2),
  startSpin: false,
  prizeNumber: 0,
  spinDone: false,
  lockSide: "win",
};

class CoinFlip2 extends Component {
  constructor(props) {
    super(props);
    this.state = COINFLIP_DEFAULT_STATE;
  }

  getWinAmount = (riskAmount) => {
    return Number(riskAmount * 2).toFixed(2);
  };

  getRiskAmount = (winAmount) => {
    return Number(winAmount / 2).toFixed(2);
  };

  _onClose = () => {
    this.setState(COINFLIP_DEFAULT_STATE);
    this.props.onClose();
  };

  _submitCoinFlip = () => {
    const option = Math.floor(Math.random() * this.props.gameOptions.length);
    this.setState({ prizeNumber: option, startSpin: true });
    // console.log("!!! Eph Coinflip2", {
    //   betID: v4(),
    //   uid: this.props.uid,
    //   gid: this.props.gid,
    //   email: this.props.email || "anon",
    //   unconfirmedBet: {
    //     game: this.props.game,
    //     bet_period: this.props.betPeriod,
    //     bet_side: this.props.gameOptions[option].betSide,
    //     bet_type: this.props.betType,
    //     bucket: this.props.bucket,
    //     collection: this.props.collection,
    //     doc_id: this.props.docId,
    //     price: Number(this.props.coinflipJuice),
    //     risk: Number(this.state.riskAmount),
    //     win: Number(this.state.winAmount),
    //   },
    //   risk: Number(this.state.riskAmount),
    //   win: Number(this.state.winAmount),
    //   unsubmittedToRemove: [],
    //   isCoinflip: true,
    // });
    const bet = {
      game: this.props.game,
      bet_period: this.props.betPeriod,
      bet_side: this.props.gameOptions[option].betSide,
      bet_type: this.props.betType,
      bucket: this.props.bucket,
      collection: this.props.collection,
      doc_id: this.props.docId,
      price: Number(this.props.coinflipJuice),
      risk: Number(this.state.riskAmount),
      win: Number(this.state.winAmount),
    };

    const tempPrice = Number(betLine2(this.props.game, bet, this.props.coinflipJuice));

    const realPrice = tempPrice === Infinity ? 100 : tempPrice;

    if (this.props.game.format === "2205") {
      console.log("game: ", this.props.game);
      console.log("bet: ", bet);
      const fullBet = {
        bet_id: v4(),
        uid: this.props.uid,
        gid: this.props.gid,
        origin: this.props.game.origin,
        // email: this.props.email || "bookie demo account",
        bet_data: {
          line: this.props.game,
          ...bet,
          event_id: this.props.game.event_id,
          adjustRef: adjustRef(this.props.game, bet),
          price: realPrice,
        },
        gradingData: this.props.game.origin === "oddsjam" ? oddsjamCoinflipGradingData(this.props.game, bet) : "",
        isParlay: false,
        isCoinflip: true,
        isLive: this.props.game.event_type === "live" || this.props.game.is_live,
        juice: this.props.coinflipJuice,
        risk: Number(this.state.riskAmount),
        win: Number(this.state.winAmount),
        format: "2205",
        unsubmittedToRemove: [],
        display: {
          betPrimaryText: betPrimaryText2(this.props.game, bet),
        },
        emailData: emailData(this.props.game, bet, this.props.coinflipJuice, true),
      };
      console.log("!!! fullBet", fullBet);
      this.props.dispatch(submitBet2(fullBet));
    } else {
      this.props.dispatch(
        submitBet2({
          betID: v4(),
          uid: this.props.uid,
          gid: this.props.gid,
          origin: this.props.game.origin,
          email: this.props.email || "anon",
          unconfirmedBet: bet,
          risk: Number(this.state.riskAmount),
          win: Number(this.state.winAmount),
          unsubmittedToRemove: [],
          isCoinflip: true,
          isParlay: false,
          betPrimaryText: betPrimaryText2(this.props.game, bet),
          emailData: emailData(this.props.game, bet, this.props.coinflipJuice, true),
        })
      );
    }
  };

  _onChangeRisk = (event) => {
    this.setState({
      riskAmount: event.target.value,
      //lockSide: "risk",
      winAmount: ((1.0 - 2 * (0.01 * this.props.coinflipJuice)) * Number(event.target.value)).toFixed(2),
    });
  };

  _onChangeWin = (event) => {
    this.setState({
      winAmount: event.target.value,
      //lockSide: "win",
      riskAmount: (Number(event.target.value) / (1.0 - 2 * (0.01 * this.props.coinflipJuice))).toFixed(2),
    });
  };

  // _onChangeRiskExternal = (amount) => {
  //   this.setState({
  //     riskAmount: amount,
  //     lockSide: "risk",
  //     winAmount: (0.96 * Number(amount)).toFixed(2),
  //   });
  // };

  _getBackgroundColors = () => {
    return ["#bf0070", "#ffe867"];
  };

  _getForegroundColors = () => {
    return ["#ffffff", "#000000"];
  };

  _betCoinflip = () => {
    this._submitCoinFlip();
  };

  americanJuiceFromPcnt(newJuice) {
    let impliedA = 0.5;
    impliedA += (impliedA * newJuice) / 100;
    return Math.round((impliedA / (1 - impliedA)) * -100);
  }

  render() {
    const { classes } = this.props;
    if (this.props.open) {
      console.log("CoinFlip Props", this.props);
    }
    return (
      <Dialog open={this.props.open} TransitionComponent={Transition} onClose={this._onClose}>
        {/*<CoinflipComponent options={this.props.gameOptions} />*/}
        <DialogTitle id="alert-dialog-title">
          Random Bet at {this.americanJuiceFromPcnt(this.props.coinflipJuice)}
          {this.props.bucket === "WorldCup" && <><br/>No Draws. Advancing team wins.</>}
        </DialogTitle>
        <DialogContent style={{ overflowX: "hidden" }}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item>
              <Wheel
                mustStartSpinning={this.state.startSpin}
                prizeNumber={this.state.prizeNumber}
                data={this.props.gameOptions}
                fontSize={22}
                width={60}
                backgroundColors={this._getBackgroundColors()}
                textColors={this._getForegroundColors()}
                innerRadius={16}
                innerBorderWidth={4}
                onStopSpinning={() => this.setState({ spinDone: true })}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={6} sm={6}>
              <TextField
                id="outlined-required"
                label="Risk"
                type="number"
                variant="outlined"
                className={classes.betField}
                value={this.state.riskAmount}
                onChange={this._onChangeRisk}
                onFocus={(event) => {
                  event.target.select();
                }}
                inputProps={{ inputMode: "numeric" }}
              />
            </Grid>
            <Grid item xs={6} sm={6}>
              <TextField
                id="outlined-required"
                label="Win"
                variant="outlined"
                // disabled={true}
                className={classes.betField}
                value={this.state.winAmount}
                onChange={this._onChangeWin}
                onFocus={(event) => {
                  event.target.select();
                }}
                inputProps={{ inputMode: "numeric" }}
              />
            </Grid>
          </Grid>
          <Box display="flex" justifyContent="center" alignItems="center" style={{ margin: 8 }}>
            {(this.state.startSpin === false && (
              <Button
                variant="contained"
                color="primary"
                onClick={this._betCoinflip}
                disabled={this.state.riskAmount < 0.0}
              >
                Submit Bet
              </Button>
            )) ||
              ((this.props.inFlight || !this.state.spinDone) && <CircularProgress />) || (
                <Grid container direction="column" justifyContent="space-around" alignItems="center">
                  <Grid item>
                    <DialogContentText>
                      <b>The wheel gave you: {this.props.gameOptions[this.state.prizeNumber].option}</b>
                    </DialogContentText>
                  </Grid>
                  <Grid item sx={{ paddingTop: "10px" }}>
                    <Button variant="contained" color="primary" onClick={this._onClose}>
                      Close
                    </Button>
                  </Grid>
                </Grid>
              )}
          </Box>
        </DialogContent>
      </Dialog>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    gid: state.firebase.profile.gid,
    uid: state.firebase.auth.uid,
    email: state.firebase.profile.email,
  };
};

CoinFlip2.propTypes = {
  uid: PropTypes.string.isRequired,
  gid: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  // Below are passed
  gameOptions: PropTypes.array,
  game: PropTypes.object.isRequired,
  onSpinDone: PropTypes.func,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  classes: PropTypes.object,
  dispatch: PropTypes.func,
  adjustJuice: PropTypes.number,
  inFlight: PropTypes.bool,
  coinflipJuice: PropTypes.number,
  betPeriod: PropTypes.string.isRequired,
  betType: PropTypes.string.isRequired,
  bucket: PropTypes.string.isRequired,
  collection: PropTypes.string.isRequired,
  docId: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]).isRequired,
};

export default connect(mapStateToProps)(withStyles(styles)(CoinFlip2));
