import React, { Component } from "react";
import PropTypes from "prop-types";
import { stringToColor } from "../utilities/avatar";
import { Avatar } from "@mui/material";

class SFBAvatar extends Component {
  render() {
    const emailSanitized = this.props.uid ? this.props.uid[0] : "?";
    return (
      <Avatar variant="rounded" sx={{ bgcolor: stringToColor(this.props.uid) }}>
        {emailSanitized[0].toUpperCase()}
      </Avatar>
    );
  }
}

SFBAvatar.propTypes = {
  email: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
};

export default SFBAvatar;
