import React, { Component } from "react";
import PropTypes from "prop-types";
import BetList from "../Sportsbook/BetList";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import withStyles from "@mui/styles/withStyles";
import GenericBackHeader from "../../components/GenericBackHeader";
import collect from "collect.js";
import { BottomNav } from "../../components";
import Box from "@mui/material/Box";
import BetList2 from "../../components/BetDisplay/BetList2";
import { Container } from "@mui/material";
import Loading from "../../components/Loading";

class AccountPastBets extends Component {


  renderPastBets = () => {
    if (!this.props.accountPastBets2)
      return <Loading />

    if (this.props.accountPastBets2 && Object.keys(this.props.accountPastBets2))
    return (
        <BetList2 allowGrading={false} bets={this.props.accountPastBets2} />
    )
    else if (!Object.keys(this.props.accountPastBets2))
    return (
        <Container>
          <>no past bets</>
        </Container>
    )
  }


  render() {
    console.log("cashier/past this.props: ", this.props);
    return (
      <>
        <Box>
          <GenericBackHeader title="Your Past Bets" showBackButton />
          {this.renderPastBets()}
        </Box>
        <BottomNav activeTab={2} managerGid={this.props.profile.managerGid} />
      </>
    );
  }
}

AccountPastBets.propTypes = {
  profile: PropTypes.object,
  // accountPastBets: PropTypes.object,
  accountPastBets2: PropTypes.object,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: "inline-block",
    marginTop: "20px",
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  grid: {
    textAlign: "center",
    marginBottom: "20px",
  },
});

const mapStateToProps = (state) => {
  console.log("state: ", state);

  const getBetsForCurrentGroup = (b) => {
    const collection = collect(b);
    const past = collection.filter((val) => val.gid === state.firebase.profile.gid && val.result !== "pending");
    return past.all();
  };

  return {
    profile: state.firebase.profile,
    uid: state.firebase.auth.uid,
    // accountPastBets: getBetsForCurrentGroup(state.firestore.data.account_pastBets),
    accountPastBets2: getBetsForCurrentGroup(state.firestore.data.account_pastBets2),
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    console.log("props props ", props);
    return [
      //probably better to query users/uid/bets
      //but we dont have access to uid at this point, only props.profile.gid
      // {
      //   collection: `users/${props.uid}/bets`,
      //   orderBy: ["minGameStartTime", "desc"],
      //   limit: 200,
      //   storeAs: "account_pastBets",
      // },
      {
        collection: `groups/${props.profile.gid}/bets2`,
        where: [["uid", "==", props.uid]],
        orderBy: ['minGameStartTime', 'desc'],
        limit: 50,
        storeAs: "account_pastBets2",
      },
    ];
  })
)(withStyles(styles)(AccountPastBets));
