import React, { Component } from "react";
import PropTypes from "prop-types";
import { IconButton, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText } from "@mui/material";
import { DeleteForever, Google } from "@mui/icons-material";
import SuperAdminPlayerListItemSecondary from "./SuperAdminPlayerListItemSecondary";
import SFBAvatar from "../../../components/SFBAvatar";
import PersonIcon from '@mui/icons-material/Person';

class SuperAdminPlayerListItem extends Component {
  openFirebaseUser = () => {
    window.open(
      "https://console.firebase.google.com/u/0/project/sft-dev-a1e2f/firestore/data/~2Fusers~2F" + this.props.playerKey,
      "_blank",
      "noopener,noreferrer"
    );
  };

  openFirebaseGroup = () => {
    window.open(
      "https://console.firebase.google.com/u/0/project/sft-dev-a1e2f/firestore/data/~2Fgroups~2F" + this.props.playerKey,
      "_blank",
      "noopener,noreferrer"
    );
  };

  render() {
    return (
      <ListItemButton divider>
        <ListItemIcon>
          <SFBAvatar email={this.props.player.email} uid={this.props.playerKey} />
        </ListItemIcon>
        <ListItemText
          primary={this.props.player.email}
          secondary={<SuperAdminPlayerListItemSecondary player={this.props.player} />}
        />
        <ListItemSecondaryAction>
          <IconButton onClick={this.openFirebaseUser}>
            <PersonIcon />
          </IconButton>
          <IconButton onClick={this.openFirebaseGroup}>
            <Google />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItemButton>
    );
  }
}

SuperAdminPlayerListItem.propTypes = {
  player: PropTypes.object.isRequired,
  playerKey: PropTypes.string.isRequired,
};

export default SuperAdminPlayerListItem;
