import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import { awardBonus } from "../../../../reducers/cashier-reducer";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import { v4 } from "uuid";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { firestoreConnect } from "react-redux-firebase";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import Typography from "@mui/material/Typography";

class Bonus extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  // renderUserBalances() {
  //   let b = this.props.balances;
  //   return Object.keys(b).map((i) => {
  //     //this.getUserInfo(i)
  //     //let email = this.getUserInfo(i)
  //     return (
  //       <ListItem key={i}>
  //         {b[i].email}: {b[i].balance}
  //       </ListItem>
  //     );
  //   });
  // }

  // renderRefLink() {
  //   if (this.props.groupConfigs.groupName) {
  //     let cleanName = this.props.groupConfigs.groupName.replaceAll(" ", "%20");
  //     return "https://superfunbet.com/join/" + cleanName;
  //   }
  // }

  // renderBalanceList = (key, email,balance) => {
  //   if (key === this.props.uid)
  //     return "This is you"
  //   else
  //     return <>{email} {balance}</>
  //
  // }

  renderDialog() {
    const { classes } = this.props;
    return (
      // <Box sx={{maxHeight: "50vh"}}>
      <Dialog open={this.props.open}>
        <DialogTitle style={{ paddingBottom: 0 }}>Issue Bonus</DialogTitle>
        <DialogContent>
          <FormControl>
            <Typography style={{ marginBottom: 20 }}>credits will be awarded instantly</Typography>

            {/*Choose which user*/}
            <Select
              style={{ paddingTop: 10, maxHeight: "50vh" }}
              fullWidth
              onChange={(e) => {
                this.setState({ player: e.target.value, email: this.props.balances[e.target.value].email });
              }}
            >
              <MenuItem disabled key={"test"} value={"key"}>
                Player (balance)
              </MenuItem>
              {this.props.balances &&
                Object.entries(this.props.balances)
                  .filter(([key, player]) => {
                    return key === this.props.uid || player.email !== "anon";
                  })
                  .map(([key, player]) => (
                    <MenuItem key={key} value={key}>
                      {key === this.props.uid && (
                        <>
                          {`You`} ({player.balance})
                        </>
                      )}
                      {key !== this.props.uid && player.email !== "anon" && (
                        <>
                          {player.email} ({player.balance})
                        </>
                      )}
                    </MenuItem>
                  ))}
            </Select>
            <FormHelperText>Select Player</FormHelperText>

            {/*set bonus amount*/}
            <TextField
              style={{ paddingTop: 10 }}
              id="outlined-number"
              type="number"
              value={this.state.bonusAmount}
              onChange={(e) => this.setState({ bonusAmount: Number(e.target.value) })}
              onFocus={(event) => {
                event.target.select();
              }}
              inputProps={{ inputMode: "numeric" }}
            />
            <FormHelperText>Bonus Amount</FormHelperText>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={this.props.closeCallback}>
            Close
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              let tempUID = this.state.player;
              let tempBonusAmount = this.state.bonusAmount;
              let tempGID = this.props.profile.managerGid;
              this.props.dispatch(
                awardBonus({
                  uid: tempUID,
                  email: this.state.email,
                  gid: tempGID,
                  type: this.state.bonusAmount > 0 ? "bonus" : "seizure",
                  amount: tempBonusAmount,
                  awardedTimestamp: new Date().getTime(),
                  trans_id: v4(),
                  state: "credited",
                })
              );
              this.setState({ bonusAmount: 0 });
            }}
          >
            Issue Bonus
          </Button>
        </DialogActions>
      </Dialog>
      // </Box>
    );
  }

  render() {
    console.log("BONUS PROPS: ", this.props);
    console.log("BONUS STATE: ", this.state);
    if (this.props.profile && this.props.groupConfigs) return this.renderDialog();
    else return <></>;
  }
}

Bonus.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  trans: PropTypes.object,
  bets: PropTypes.object,
  balances: PropTypes.object,
  profile: PropTypes.object,
  groupConfigs: PropTypes.object,
  open: PropTypes.bool,
  closeCallback: PropTypes.func,
  uid: PropTypes.string,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  pastBetsList: {
    width: "100%",
  },
});

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    email: state.firebase.auth.email,
    profile: state.firebase.profile,
    balances: state.firestore.data?.bonus_balances,
    groupConfigs: state.firestore.data?.bonus_groupConfigs,
  };
};

export default compose(
  connect(mapStateToProps),
  withRouter,
  firestoreConnect((props) => {
    return [
      {
        collection: `groups/${props.profile.managerGid}/balances`,
        storeAs: "bonus_balances",
      },
      {
        collection: `groups`,
        doc: props.profile.managerGid,
        storeAs: "bonus_groupConfigs",
      },
    ];
  })
)(withStyles(styles)(Bonus));
