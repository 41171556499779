import * as React from "react";
import { ClickAwayListener, Dialog, DialogContent, DialogTitle } from "@mui/material";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import GoogleIcon from "@mui/icons-material/Google";

import propTypes from "prop-types";
import { signInWithEmail, loginWithGoogle } from "../../Auth/functions/signIn.js";
import { createAccountWithEmail, createAccountWithGoogle } from "../../Auth/functions/createAccount.js";

export function PickemLogin(props) {
  const [showSignup, setShowSignup] = React.useState(true);
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [signinError, setSigninError] = React.useState(false);

  const _handleTextChange = (e) => {
    console.log("e: ", e);
  };

  const signinSignup = () => {
    if (!showSignup) {
      setSigninError(false);
      signInWithEmail(email, password)
        .then(() => props.closeHandler())
        .catch((e) => setSigninError(true));
    }
    if (showSignup) {
      setSigninError(false);
      createAccountWithEmail(email, password)
        .then(() => props.closeHandler())
        .catch((e) => setSigninError(true));
    }
  };
  const googleSigninSignup = () => {
    if (!showSignup) {
      setSigninError(false);
      loginWithGoogle()
        .then(() => props.closeHandler())
        .catch((e) => setSigninError(true));
    }
    if (showSignup) {
      setSigninError(false);
      createAccountWithGoogle()
        .then(() => props.closeHandler())
        .catch((e) => setSigninError(true));
    }
  };

  return (
    <Dialog fullWidth open={props.isOpen} style={{ textAlign: "center" }}>
      <ClickAwayListener onClickAway={() => props.closeHandler()}>
        <Box sx={{ flexGrow: 1 }}>
          <Box elevation={0} sx={{ borderBottom: 2 }}>
            <DialogTitle style={{ fontFamily: "NeuveticaHeavyItalic", borderBottom: 3, textTransform: "uppercase" }}>
              {showSignup ? "Sign Up" : "Log In"}
            </DialogTitle>
          </Box>

          <DialogContent sx={{ margin: 0 }}>
            <Box style={{ marginTop: 0 }} display="flex" justifyContent="center" alignItems="center">
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    label="Email Address"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    label="Password"
                    type={"password"}
                    id="password"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </Grid>

                <Grid item xs={12}>
                  <Button fullWidth variant="contained" color="primary" onClick={signinSignup}>
                    {showSignup ? "Signup with Email" : "Log In with Email"}
                  </Button>
                  {signinError && <>Error signing in</>}
                </Grid>

                <Grid item xs={12}>
                  <List>
                    <Divider> or </Divider>
                  </List>
                </Grid>

                <Grid item xs={12}>
                  <Button
                    style={{ margin: 0, color: "#ffffff" }}
                    fullWidth
                    variant="contained"
                    color="secondary"
                    onClick={googleSigninSignup}
                  >
                    <GoogleIcon style={{ marginRight: 5, color: "white" }} />
                    {showSignup ? "Sign Up with Google" : "Log In with Google"}
                  </Button>
                </Grid>

                <Grid item xs={12}>
                  <Grid container direction="row" justifyContent="flex-end" alignItems="flex-end">
                    <Grid item>
                      <Button
                        sx={{ textAlign: "right", color: "#bf0070", textTransform: "none" }}
                        onClick={() => {
                          setShowSignup(!showSignup);
                        }}
                      >
                        {showSignup ? "Already have an account?" : "Need to create an account?"}
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </DialogContent>
        </Box>
      </ClickAwayListener>
    </Dialog>
  );
}

PickemLogin.propTypes = {
  isOpen: propTypes.bool.isRequired,
  closeHandler: propTypes.func.isRequired,
};
