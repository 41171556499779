import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import firebase from "firebase/compat/app";
import Button from "@mui/material/Button";
import { Divider, ListItemText } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import { getPickFullName } from "../../../utilities/pickemUtils";
import { AccessibilityNew, Pending, ThumbDown, ThumbUp } from "@mui/icons-material";
import Stack from "@mui/material/Stack";
import moment from "moment";

const AdminEntries = (props) => {
  const [entries, setEntries] = React.useState([]);
  const [showGraded, setShowGraded] = React.useState(false);

  const fetchEntries = async () => {
    const db = firebase.firestore();
    await db
      .collection("pickem")
      .doc("tournies")
      .collection("entries")
      .onSnapshot((querySnapshot) => {
        const newData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
        setEntries(newData);
      });
  };

  const setResult = async (entry_id, pickNumber, result, picks) => {
    picks[pickNumber].result = result;
    const db = firebase.firestore();
    await db.collection("pickem").doc("tournies").collection("entries").doc(entry_id).update({ picks: picks });
  };

  useEffect(() => {
    fetchEntries();
  }, []);

  return (
    <Dialog fullScreen open={props.open} onClose={props.handleClose}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Entry Admin
          </Typography>
          <Button autoFocus variant="contained" color="inherit" onClick={() => setShowGraded(!showGraded)}>
            {showGraded ? "Hide Graded" : "Show Graded"}
          </Button>
        </Toolbar>
      </AppBar>
      <List>
        {entries.map((entry) =>
          entry.picks
            .filter((pick) => (showGraded ? true : pick.result === "pending"))
            .sort((a, b) => a.game.starts_ms - b.game.starts_ms)
            .map((pick, index) => (
              <div key={entry.id + index}>
                <ListItem>
                  <ListItemText
                    primary={getPickFullName(pick.pickDetails, pick.game)}
                    secondary={"Graded: " + pick.result + " Starts " + moment(pick.game.starts_ms).calendar()}
                  />
                </ListItem>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                  <IconButton
                    disabled={pick.result === "pending"}
                    onClick={() => setResult(entry.id, pick.pickNumber, "pending", entry.picks)}
                  >
                    <Pending />
                  </IconButton>
                  <IconButton
                    disabled={pick.result === "push"}
                    onClick={() => setResult(entry.id, pick.pickNumber, "push", entry.picks)}
                  >
                    <AccessibilityNew />
                  </IconButton>
                  <IconButton
                    disabled={pick.result === "loss"}
                    sx={{ color: "#660000" }}
                    onClick={() => setResult(entry.id, pick.pickNumber, "loss", entry.picks)}
                  >
                    <ThumbDown />
                  </IconButton>
                  <IconButton
                    disabled={pick.result === "win"}
                    sx={{ color: "#006600" }}
                    onClick={() => setResult(entry.id, pick.pickNumber, "win", entry.picks)}
                  >
                    <ThumbUp />
                  </IconButton>
                </Stack>
                <Typography variant="caption">{entry.id}</Typography>
                <Divider />
              </div>
            ))
        )}
      </List>
    </Dialog>
  );
};

AdminEntries.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
};

export default AdminEntries;
