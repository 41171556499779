import React, { useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Container from "@mui/material/Container";
import { useSelector } from "react-redux";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox } from "@mui/material";
import { BugReport, BugReportOutlined } from "@mui/icons-material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo/";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Button from "@mui/material/Button";
import firebase from "../../../firebase";
import { ordinal_suffix_of } from "../../../utilities/pickemUtils.js";
import moment from "moment-timezone";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const initialPrizes = [
  { id: 1, prize: "50%" },
  { id: 2, prize: "30%" },
  { id: 3, prize: "20%" },
];

const blankTheme = createTheme();

const CreateTournament = (props) => {
  const profile = useSelector((state) => state.firebase.profile);
  const db = firebase.firestore();

  const [tournamentName, setTournamentName] = React.useState("");
  const [tournamentNote, setTournamentNote] = React.useState("");
  const [maxEntries, setMaxEntries] = React.useState(10);
  const [numPicks, setNumPicks] = React.useState(6);
  const [riskAmount, setRiskAmount] = React.useState(50);
  const [startTime, setStartTime] = React.useState(null);
  const [maxGameStartTime, setMaxGameStartTime] = React.useState(null);
  const [devOnly, setDevOnly] = React.useState(false);
  const [numPrizes, setNumPrizes] = React.useState(initialPrizes.length);
  const [prizes, setPrizes] = React.useState(initialPrizes);

  useEffect(() => {
    setTournamentName(props.tournamentName);
  }, [props.tournamentName]);

  useEffect(() => {
    setTournamentNote(props.tournamentNote);
  }, [props.tournamentNote]);

  useEffect(() => {
    setMaxEntries(props.maxEntries);
  }, [props.maxEntries]);

  useEffect(() => {
    setRiskAmount(props.riskAmount);
  }, [props.riskAmount]);

  useEffect(() => {
    setStartTime(moment(props.startTime));
  }, [props.startTime]);

  useEffect(() => {
    setMaxGameStartTime(moment(props.maxGameStartTime));
  }, [props.maxGameStartTime]);

  useEffect(() => {
    setDevOnly(props.devOnly);
  }, [props.devOnly]);

  useEffect(() => {
    setNumPrizes(props.prizes?.length);
  }, [props.prizes]);

  useEffect(() => {
    setPrizes(props.prizes);
  }, [props.prizes]);

  const changeNumberOfPrizes = (num) => {
    setNumPrizes(num);
    setPrizes(
      [...Array(num)].map((x, i) => {
        if (prizes[i]) {
          return prizes[i];
        } else {
          return { id: i + 1, prize: "" };
        }
      })
    );
  };

  const checkCreateDisabled = () => {
    if (tournamentName === "") {
      return true;
    }
    if (!maxEntries || maxEntries < 1) {
      return true;
    }
    if (!numPicks || numPicks < 3) {
      return true;
    }
    if (!riskAmount || riskAmount < 50 || riskAmount > 1000) {
      return true;
    }
    if (!startTime || !startTime.valueOf()) {
      return true;
    }
    if (!maxGameStartTime || !maxGameStartTime.valueOf()) {
      return true;
    }
    if (numPrizes < 1 || numPrizes > maxEntries) {
      return true;
    }
    return prizes.map((item) => item.prize === "").reduce((a, b) => a || b, false);
  };

  const setPrizeForPlace = (place, value) => {
    setPrizes(
      prizes.map((t) => {
        if (t.id === place) {
          return { id: t.id, prize: value };
        } else {
          return t;
        }
      })
    );
  };

  useEffect(() => {
    moment.tz.setDefault("America/New_York");
    changeNumberOfPrizes(numPrizes);
  }, []);

  const createTournament = () => {
    if (!props.edit) {
      db.collection("pickem").doc("events").collection("createTournament").add({
        devOnly: devOnly,
        hasStarted: false,
        maxEntries: maxEntries,
        maxGameStartTime: maxGameStartTime.valueOf(),
        tournamentName: tournamentName,
        tournamentNote: tournamentNote,
        numEntries: 0,
        picks: numPicks,
        prizes: prizes,
        riskAmount: riskAmount,
        startTime: startTime.valueOf(),
      });
      props.handleClose();
    } else {
      db.collection("pickem").doc("events").collection("editTournament").doc(props.tournamentID).set({
        devOnly: devOnly,
        hasStarted: false,
        maxEntries: maxEntries,
        maxGameStartTime: maxGameStartTime.valueOf(),
        tournamentName: tournamentName,
        tournamentNote: tournamentNote,
        numEntries: 0,
        picks: numPicks,
        prizes: prizes,
        riskAmount: riskAmount,
        startTime: startTime.valueOf(),
      });
      props.handleClose();
    }
  };

  return (
    <Dialog fullScreen open={props.open} onClose={props.handleClose}>
      <AppBar sx={{ position: "relative" }}>
        <Toolbar>
          <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            Create Tournament
          </Typography>
        </Toolbar>
      </AppBar>
      <Container sx={{ paddingTop: 2 }}>
        <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
          <Grid xs={12} sx={{ padding: 1 }}>
            <TextField
              id="tournamentName"
              label="Tournament Name"
              variant="outlined"
              fullWidth
              value={tournamentName}
              onChange={(e) => setTournamentName(e.target.value)}
            />
          </Grid>
          <Grid xs={12} sx={{ padding: 1 }}>
            <TextField
              id="tournamentNote"
              label="Tournament Description"
              variant="outlined"
              fullWidth
              value={tournamentNote}
              onChange={(e) => setTournamentNote(e.target.value)}
            />
          </Grid>
          <Grid xs={4} sx={{ padding: 1 }}>
            <TextField
              id="maxEntries"
              label="Max Entries"
              variant="outlined"
              fullWidth
              value={maxEntries}
              onChange={(e) => setMaxEntries(e.target.value)}
            />
          </Grid>
          <Grid xs={4} sx={{ padding: 1 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Number of Picks</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={numPicks}
                label="Number of Picks"
                nChange={(e) => setNumPicks(e.target.value)}
              >
                <MenuItem value={3}>3</MenuItem>
                <MenuItem value={4}>4</MenuItem>
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={6}>6</MenuItem>
                <MenuItem value={7}>7</MenuItem>
                <MenuItem value={8}>8</MenuItem>
                <MenuItem value={9}>9</MenuItem>
                <MenuItem value={10}>10</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid xs={4} sx={{ padding: 1 }}>
            <TextField
              id="riskAmount"
              label="Risk Amount"
              variant="outlined"
              fullWidth
              disabled
              value={riskAmount}
              onChange={(e) => setRiskAmount(e.target.value)}
            />
          </Grid>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <ThemeProvider theme={blankTheme}>
              <Grid xs={6} sx={{ padding: 1 }}>
                <DemoContainer components={["DateTimePicker"]}>
                  <DateTimePicker
                    label="Last Entry Datetime"
                    onChange={(value) => setStartTime(value)}
                    disablePast
                    minutesStep={60}
                    timeSteps={{ hours: 1, minutes: 60, seconds: 60 }}
                    value={startTime}
                  />
                </DemoContainer>
              </Grid>
              <Grid xs={6} sx={{ padding: 1 }}>
                <DemoContainer components={["DateTimePicker"]}>
                  <DateTimePicker
                    label="Latest Game Datetime"
                    onChange={(value) => setMaxGameStartTime(value)}
                    disablePast
                    minutesStep={60}
                    timeSteps={{ hours: 1, minutes: 60, seconds: 60 }}
                    value={maxGameStartTime}
                  />
                </DemoContainer>
              </Grid>
            </ThemeProvider>
          </LocalizationProvider>
          <Grid xs={12} sx={{ padding: 1 }}>
            All times are in ET
          </Grid>
          {profile.isLoaded && profile.is_admin && (
            <Grid xs={12} sx={{ padding: 1 }}>
              <FormControlLabel
                control={<Checkbox icon={<BugReportOutlined />} checkedIcon={<BugReport />} color="secondary" />}
                label="Dev Only"
                checked={devOnly}
                onChange={() => setDevOnly(!devOnly)}
              />
            </Grid>
          )}
          <Grid xs={12} sx={{ padding: 1 }}>
            {numPrizes} Prize{numPrizes > 1 ? "s" : ""}
            <IconButton disabled={numPrizes <= 1} onClick={() => changeNumberOfPrizes(numPrizes - 1)}>
              <RemoveCircleIcon />
            </IconButton>
            <IconButton disabled={false} onClick={() => changeNumberOfPrizes(numPrizes + 1)}>
              <AddCircleIcon />
            </IconButton>
          </Grid>
          {prizes.map((prizeRow) => (
            <>
              <Grid xs={1} sx={{ padding: 1 }}>
                {ordinal_suffix_of(prizeRow.id)}
              </Grid>
              <Grid xs={11} sx={{ padding: 1 }}>
                <TextField
                  id={"prizeNum_" + prizeRow.id}
                  label={"Prize for " + ordinal_suffix_of(prizeRow.id)}
                  variant="outlined"
                  fullWidth
                  value={prizeRow.prize}
                  onChange={(e) => setPrizeForPlace(prizeRow.id, e.target.value)}
                />
              </Grid>
            </>
          ))}
        </Grid>
        <Grid container direction="row" justifyContent="center" alignItems="center" sx={{ padding: 2 }}>
          <Grid item>
            <Button onClick={() => createTournament()} variant="contained" disabled={checkCreateDisabled()}>
              {props.edit ? "Save" : "Create"} Tournament
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Dialog>
  );
};

CreateTournament.defaultProps = {
  edit: false,
};

CreateTournament.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  tournamentName: PropTypes.string,
  tournamentNote: PropTypes.string,
  maxEntries: PropTypes.number,
  numPicks: PropTypes.number,
  riskAmount: PropTypes.number,
  startTime: PropTypes.number,
  maxGameStartTime: PropTypes.number,
  devOnly: PropTypes.bool,
  prizes: PropTypes.array,
  tournamentID: PropTypes.string,
  edit: PropTypes.bool,
};

export default CreateTournament;
