import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { firestoreConnect } from "react-redux-firebase";
import withStyles from "@mui/styles/withStyles";
import { CircularProgress, Dialog, DialogContent, DialogTitle, Divider, Grid, Paper } from "@mui/material";
import Typography from "@mui/material/Typography";

const styles = (theme) => ({
  headerLogo: {
    fontFamily: "NeuveticaHeavyItalic",
    // fontStyle: 'italic',
    // color: "black",
    // fontWeight: 700,
    fontSize: 25,
  },
});

class PublicBet extends Component {
  render() {
    console.log("PublicBet props", this.props);
    const { classes } = this.props;

    if (!this.props.bet) {
      return (
        <Dialog open={true}>
          <CircularProgress />
        </Dialog>
      );
    }

    return (
      <Dialog open={true}>
        <Paper sx={{ padding: 4 }}>
          <DialogTitle>
            <Typography
              className={classes.headerLogo}
              sx={{ display: { sm: "block" } }}
              // style={{fontStyle: 'italic', color: "black", fontWeight: 700}}
              // variant="h6"
              // color="inherit" noWrap className={classes.title}
            >
              SUPERFUNBET
            </Typography>
          </DialogTitle>
          <DialogContent>
            <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
              <Grid item xs={12} sx={{ paddingTop: 2, paddingBottom: 2 }}>
                <Grid container direction="row" justifyContent="space-between" alignItems="stretch">
                  <Grid item>
                    <Typography variant="caption">Bet ID</Typography>
                  </Grid>
                  <Grid item>
                    <Typography variant="caption">{this.props.match.params.bid}</Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Divider />
              <Grid item xs={12}>
                <Typography variant="caption">{this.props.bet.emailData.gameTime}</Typography>
              </Grid>
              <Grid item xs={12}>
                {this.props.bet.emailData.matchName}
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" justifyContent="space-between" alignItems="stretch">
                  <Grid item sx={{ fontWeight: "bold" }}>
                    {this.props.bet.emailData.bet}
                  </Grid>
                  <Grid item>{this.props.bet.emailData.price}</Grid>
                </Grid>
              </Grid>
              <Divider />
              <Grid item xs={12}>
                <Grid container direction="row" justifyContent="space-between" alignItems="stretch">
                  <Grid item>Cost</Grid>
                  <Grid item>{this.props.bet.risk}</Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container direction="row" justifyContent="space-between" alignItems="stretch">
                  <Grid item>To Win</Grid>
                  <Grid item>{this.props.bet.risk + this.props.bet.win}</Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>
        </Paper>
      </Dialog>
    );
  }
}

PublicBet.propTypes = {
  bid: PropTypes.string.isRequired,
  bet: PropTypes.object.isRequired,
  publicBet: PropTypes.object.isRequired,
  match: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    bet: state.firestore.data.publicBet,
  };
};

export default compose(
  connect(mapStateToProps),
  withRouter,
  firestoreConnect((props) => {
    return [
      {
        collection: `bets2`,
        doc: props.match.params.bid ? props.match.params.bid : 0,
        storeAs: "publicBet",
      },
    ];
  })
)(withStyles(styles)(PublicBet));
