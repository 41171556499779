import { createAsyncAction } from "redux-promise-middleware-actions";
// import firebase from "firebase";
import { createAction, handleActions } from "redux-actions";
import { getFirebase } from "react-redux-firebase";

export const groupManagerAddGroup = createAsyncAction("GROUP_MANAGER_ADD_GROUP", (name) => {
  console.log("Sending addGroup");
  const addGroup = getFirebase().functions().httpsCallable("user-addGroup");
  return addGroup({ name: name });
});

export const testAction = createAction("TEST_ACTION", () => {
  console.log("Test Action");
});

export const groupManagerUpdateMessage = createAsyncAction("GROUP_MANAGER_UPDATE_MESSAGE", (gid, title, message) => {
  const updateGroupMessage = getFirebase().functions().httpsCallable("user-updateGroupMessage");
  return updateGroupMessage({ gid, title, message });
});

const initialState = {};

export default handleActions(
  {
    GROUP_MANAGER_UPDATE_MESSAGE_LOADING: (state) => {
      return state;
    },
    GROUP_MANAGER_UPDATE_MESSAGE_SUCCESS: (state) => {
      return state;
    },
  },
  initialState
);
