import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Slide from "@mui/material/Slide";
import propTypes from "prop-types";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import Grid from "@mui/material/Grid";
import { DialogTitle } from "@mui/material";
import ProfileLoginButton from "./Auth/ProfileLoginButton.js";

function HideOnScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export default function PickemAppBar(props) {
  const history = useHistory();

  return (
    <React.Fragment>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar position="relative" color={"tertiary"} elevation={0} sx={{ borderBottom: 2 }}>
          <Toolbar>
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <HomeIcon onClick={() => history.push("/pickem/")} />
              </Grid>
              <Grid item>
                <DialogTitle
                  component="div"
                  sx={{ flexGrow: 1, fontFamily: "NeuveticaHeavyItalic", textTransform: "uppercase" }}
                >
                  {props.title}
                </DialogTitle>
              </Grid>
              <Grid item>
                <ProfileLoginButton />
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Container>
        <Box sx={{ my: 2 }}></Box>
      </Container>
    </React.Fragment>
  );
}

HideOnScroll.propTypes = {
  children: propTypes.isRequired,
  window: propTypes.func,
};

PickemAppBar.defaultProps = {
  isHome: true,
};

PickemAppBar.propTypes = {
  title: PropTypes.string.isRequired,
  isHome: PropTypes.bool.isRequired,
};
