import React, { Component } from "react";
import PropTypes from "prop-types";
import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import { ArrowBack } from "@mui/icons-material";
import { withRouter } from "react-router-dom";

class SuperAdminHeader extends Component {
  render() {
    return (
      <AppBar position="static">
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            sx={{ mr: 2 }}
            onClick={() => this.props.history.goBack()}
          >
            <ArrowBack />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            Super Admin
          </Typography>
        </Toolbar>
      </AppBar>
    );
  }
}

SuperAdminHeader.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(SuperAdminHeader);
