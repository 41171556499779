import { Container } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { issueFreeWager } from "../../../reducers/cashier-reducer";
import FormHelperText from "@mui/material/FormHelperText";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";

class IssueFreeWager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderIssueFreeWagerUI() {
    return (
      <>
        <Container>
          <Grid container alignItems="center" justifyContent="center" spacing={2}>
            <Grid item key={"w"} xs={12} sm={6} sx={{ minWidth: 300 }}>
              <Card>
                {/*<CardHeader*/}
                {/*  title={"Select Player"}*/}
                {/*  titleTypographyProps={{ align: "center" }}*/}
                {/*  subheaderTypographyProps={{ align: "center" }}*/}
                {/*/>*/}

                <CardContent align={"center"}>
                  {this.props.balances && (
                    <FormControl sx={{ m: 1, minWidth: 250 }}>
                      <Select
                        padding={20}
                        onChange={(e) => {
                          this.setState({ player: e.target.value, email: this.props.balances[e.target.value].email });
                        }}
                      >
                        <MenuItem value="Select Player" />
                        {Object.entries(this.props.balances).map(([key, player]) => (
                          <MenuItem key={key} value={key}>
                            {player.email}
                          </MenuItem>
                        ))}
                      </Select>
                      <FormHelperText>Select Player</FormHelperText>
                    </FormControl>
                  )}
                </CardContent>

                <CardContent align={"center"}>
                  <TextField
                    sx={{ m: 1, minWidth: 250 }}
                    padding={20}
                    id="standard-search"
                    variant="outlined"
                    label="Win Amount"
                    helperText={"Player gets a free coinflip"}
                    type="number"
                    value={this.state.win}
                    onChange={(e) => this.setState({ win: Number(e.target.value) })}
                    onFocus={(event) => {
                      event.target.select();
                    }}
                    size="small"
                    inputProps={{ inputMode: "numeric" }}
                  />
                </CardContent>
                <CardActions>
                  <Button
                    fullWidth
                    color="primary"
                    onClick={() => {
                      this.props.dispatch(
                        issueFreeWager({
                          email: this.props.balances[this.state.player].email,
                          uid: this.state.player,
                          gid: this.props.adminProfile.managerGid,
                          win: this.state.win,
                          isCoinflip: true,
                        })
                      );
                      this.setState({ bonusAmount: 0 });
                    }}
                  >
                    {"Submit"}
                  </Button>
                </CardActions>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </>
    );
  }

  renderFreeWagerHistory() {
    if (this.props.groupFreewagerHistory && this.props.balances) {
      let f = this.props.groupFreewagerHistory;
      return Object.keys(f)
        .filter((i) => f[i])
        .filter((i) => f[i].uid)
        .filter((i) => this.props.balances[f[i].uid])
        .filter((i) => this.props.balances[f[i].uid].email)
        .map((i) => (
          <>
            <ListItem uid={f[i].uid}>
              <Grid container>
                <Grid item xs={12} md={9}>
                  {this.props.balances[f[i].uid].email} - {f[i].win} - {f[i].status}
                </Grid>
              </Grid>
            </ListItem>
            <Divider />
          </>
        ));
    }
  }

  render() {
    console.log("ISSUE FREE WAGER this.props: ", this.props);
    console.log("ISSUE FREE WAGER this.state: ", this.state);

    return (
      <Accordion
        onChange={() => {
          this.setState({ showUserTool: !this.state.showUserTool });
        }}
        expanded={this.state.showUserTool}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>Issue Free Bets</AccordionSummary>
        <AccordionDetails>
          {this.renderIssueFreeWagerUI()}
          {this.renderFreeWagerHistory()}
        </AccordionDetails>
      </Accordion>
    );
  }
}

IssueFreeWager.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  profile: PropTypes.object,
  sportsbookState: PropTypes.object,
  balance: PropTypes.number,
  uid: PropTypes.string,
  americanfootball_nfl: PropTypes.string,
  auth: PropTypes.object,
  juice: PropTypes.number,
  coinflipJuice: PropTypes.number,
  unsubmittedBets: PropTypes.object,
  showSportsBookSignup: PropTypes.bool,
  groupConfigs: PropTypes.object,
  email: PropTypes.string,
  groupFreewagerHistory: PropTypes.object,
  balances: PropTypes.object,
  adminProfile: PropTypes.object,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: "inline-block",
    marginTop: "20px",
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  grid: {
    textAlign: "center",
    marginBottom: "20px",
  },
});

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    profile: state.firebase.profile,
    americanfootball_nfl: state.firestore.data.americanfootball_nfl,
    coinflipJuice: state.firestore.data.groupConfigs?.groupCoinflipJuice || 0.98,
    juice: state.firestore.data.groupConfigs?.groupJuice || 3.7,
    email: state.firebase.auth.email,
    groupFreewagerHistory: state.firestore.data.groupFreewagerHistory,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    console.log("---------------firestore connecting");
    console.log("---------------FREE WAGER - COMPOSE connect props", props);
    console.log(`groups/${props.profile.managerGid}/freewagers`);

    return [
      {
        collection: "lines",
        doc: "americanfootball_nfl",
        storeAs: "americanfootball_nfl",
      },
      {
        collection: `groups/${props.profile.managerGid}/freewagers`,
        storeAs: "groupFreewagerHistory",
        where: [["win", "!=", ""]],
      },
    ];
  })
)(withStyles(styles)(IssueFreeWager));

/*
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                    <Select
                      native
                      label="Age"

                      onChange={(e) => {
                        this.setState({ player: e.target.value, email: this.props.balances[e.target.value].email });
                      }}
                    >
                      <option aria-label="None" value="Select Player" />
                      {Object.entries(this.props.balances).map(([key, player]) => (
                        <option key={key} value={key}>
                          {player.email}
                        </option>
                      ))}
                    </Select>
                    </FormControl>
 */
