import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import SuperAdminHeader from "./components/SuperAdminHeader";
import { CircularProgress, List } from "@mui/material";
import SuperAdminPlayerListItem from "./components/SuperAdminPlayerListItem";

class SuperAdminNewPlayers extends Component {
  render() {
      console.log("superadmin new players this.props: ",this.props)
    return (
      <>
        <SuperAdminHeader />
        {!this.props.newPlayers && <CircularProgress />}
        {this.props.newPlayers && (
          <List>
            {Object.entries(this.props.newPlayers).map(([key, player]) => (
              <SuperAdminPlayerListItem key={key} player={player} playerKey={key} />
            ))}
          </List>
        )}
      </>
    );
  }
}

SuperAdminNewPlayers.propTypes = {
  newPlayers: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    newPlayers: state.firestore.data.superAdminNewPlayers,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    const interval = 1000 * 10;
    const roundedNow = Math.round(Date.now() / interval) * interval;
    const sevenDaysAgo = roundedNow - 1000 * 3600 * 24 * 14;

    return [
      {
        type: "child_added",
        collection: "users",
        where: [["createdTimestamp", ">", sevenDaysAgo]],
        orderBy: ["createdTimestamp", "desc"],
        storeAs: "superAdminNewPlayers",
      },
    ];
  })
)(SuperAdminNewPlayers);
