import {
  Alert,
  Badge,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText, Snackbar,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { Header } from "../../components";
import { sportsbookShowSignup } from "../../reducers/sportsbook-reducer";
import Box from "@mui/material/Box";
import { withRouter } from "react-router-dom";
import List from "@mui/material/List";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import BeenhereIcon from "@mui/icons-material/Beenhere";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import {AccountBalance, AdminPanelSettings, ChevronRight, ContentCopy, EmojiEvents} from "@mui/icons-material";
import CTA from "./components/CTA";
import { showSignUpDialog } from "../../reducers/new-user-reducer";
import { SignUpDialog } from "../Auth";
import { firestoreConnect } from "react-redux-firebase";
import Button from "@mui/material/Button";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      // tabValue: 0
    };
  }

  showSignup = () => {
    this.props.dispatch(sportsbookShowSignup(true));
  };

  // handleTabChange = (e, newValue) => {
  //   this.setState({ tabValue: newValue });
  // };

  _checkForFriendCredits = () => {
    if (this.props.balances) {
      let creditsGiven = false;
      let B = this.props.balances;
      Object.keys(B).forEach((i) => {
        if (B[i].totalDepositCount || B[i].totalBonusCount) {
          if (i !== this.props.uid) creditsGiven = true;
        }
      });
      return creditsGiven;
    }
  };
  _checkForFriendBet = () => {
    if (this.props.balances) {
      let betPlaced = false;
      let B = this.props.balances;
      Object.keys(B).forEach((i) => {
        if (B[i].totalBets || B[i].pendingBetCount) {
          if (i !== this.props.uid) betPlaced = true;
        }
      });
      return betPlaced;
    }
  };

  generateURL() {

    if (this.props.profile?.gid === "Y7bqQzvue9Mk3AamrIDKNaFpukN2")
      return "superfunbet.com/kelvin"
    else if (this.props.uid === "NLUUYJXONHee2Woo7ktVMFIquQC3")
      return "superfunbet.com/join/4cduVmV0vNoII1QucHpa"
    else if (this.props.uid === "J4fUKbP3CuhkpKHA6df8acZ88W72")
      return "superfunbet.com/join/iDlxBCFgmTPVYuYVTZuO"
    else if (this.props.uid === "fxKT8I0KIvTCbhI2k8u5nFCs0M93")
      return "superfunbet.com/join/3PfmaqsXa5SYbYkgtvOH"
    else if (this.props.uid === "VYMnLWO3yAe4Q5TpJWQhWOjfqfO2")
      return "superfunbet.com/join/WKgL0pPUMdx62VhPMHDj"
    else
      return "superfunbet.com/join/" + this.props.profile?.gid;
  }

  render() {
    console.log("PROFILE this.props: ", this.props);
    console.log("PROFILE this.state: ", this.state);

    if (this.props.profile?.isLoaded && !this.props.profile.activeBookie) {
      this.props.history.push("/cashier");
    }

    return (
      <>
        <Box>
          <Header />

          {this.props.showSignup && (
            <SignUpDialog
              open={this.props.showSignup || false}
              isBookieReferral={false}
              userEmail={this.props.profile.email}
            />
          )}

          {
            <Dialog open={this.state.awardCreditsHintOpen || false}>
              <DialogTitle id="alert-dialog-title">{"Give Your Players Credits"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {"Your friends need credits to bet.  They can request a deposit, or you can give them a bonus " +
                    "in the"}{" "}
                  <Button color={"secondary"} onClick={() => this.props.history.push("/bookie/player-management")}>
                    Player Management Tool
                  </Button>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => this.setState({ awardCreditsHintOpen: false })}
                  autoFocus
                  variant="contained"
                  color="secondary"
                >
                  Got it!
                </Button>
              </DialogActions>
            </Dialog>
          }

          {
            <Dialog open={this.state.takeBetHintOpen || false}>
              <DialogTitle id="alert-dialog-title">{"Take a Bet"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {"Once your friends have credits, they can place a bet just like you have."}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button
                  onClick={() => this.setState({ takeBetHintOpen: false })}
                  autoFocus
                  variant="contained"
                  color="secondary"
                >
                  Got it!
                </Button>
              </DialogActions>
            </Dialog>
          }

          <Tabs
            // value={this.state.tabValue}
            // onChange={() => console.log("change")}
            // value={0}
            variant="scrollable"
            scrollButtons={true}
            allowScrollButtonsMobile={true}
          >
            <Tab
              value={0}
              icon={
                <CTA
                  title="Welcome"
                  description="Claim initial credits"
                  clickAction={() => console.log("click place a bet")}
                  icon={<EmojiEvents fontSize={"large"} />}
                  completed={this.props.myBalance?.totalBonusCount > 0}
                />
              }
              sx={{ paddingLeft: 0, paddingRight: 0 }}
            />

            <Tab
                value={4}
                icon={
                  <CTA
                      title="Save Account"
                      description="Sign up to protect your account"
                      clickAction={() => {
                        this.props.dispatch(showSignUpDialog(true));
                      }}
                      icon={<EmojiEvents fontSize={"large"} />}
                      completed={this.props.profile.email !== "My Demo Account"}
                  />
                }
                sx={{ paddingLeft: 0, paddingRight: 0 }}
            />

            <Tab
              value={1}
              icon={
                <CTA
                  title="Place a Bet"
                  description="See how the book works for your players"
                  clickAction={() => {
                    this.props.history.push("/sports");
                  }}
                  icon={<EmojiEvents fontSize={"large"} />}
                  completed={this.props.myBalance?.totalBets > 0 || this.props.myBalance?.pendingBetCount > 0}
                />
              }
              sx={{ paddingLeft: 0, paddingRight: 0 }}
            />

            <Tab
              value={2}
              icon={
                <CTA
                  title="Deposit"
                  description="Request play money credits from yourself in player settings"
                  clickAction={() => {
                    this.props.history.push("/cashier");
                  }}
                  icon={<EmojiEvents fontSize={"large"} />}
                  completed={
                    this.props.myBalance?.totalDepositCount > 0 || this.props.myBalance?.pendingDepositCount > 0
                  }
                />
              }
              sx={{ paddingLeft: 0, paddingRight: 0 }}
            />

            <Tab
              value={3}
              icon={
                <CTA
                  title="Approve"
                  description="Approve your credit request"
                  clickAction={() => {
                    this.props.history.push("/bookie");
                  }}
                  icon={<EmojiEvents fontSize={"large"} />}
                  completed={this.props.myBalance?.totalDepositCount > 0}
                />
              }
              sx={{ paddingLeft: 0, paddingRight: 0 }}
            />



            <Tab
              value={5}
              icon={
                <CTA
                  title="Invite Friends"
                  description="Get a player to sign up for your book"
                  clickAction={() => {
                    this.props.history.push("/bookie");
                  }}
                  icon={<EmojiEvents fontSize={"large"} />}
                  completed={this.props.balances && Object.keys(this.props.balances).length > 1}
                />
              }
              sx={{ paddingLeft: 0, paddingRight: 0 }}
            />

            <Tab
              value={6}
              icon={
                <CTA
                  title="Award Credits"
                  description="Give credits to your new player"
                  clickAction={() => {
                    this.setState({ awardCreditsHintOpen: true });
                  }}
                  icon={<EmojiEvents fontSize={"large"} />}
                  completed={this._checkForFriendCredits()}
                />
              }
              sx={{ paddingLeft: 0, paddingRight: 0 }}
            />

            <Tab
              value={7}
              icon={
                <CTA
                  title="Take A Bet"
                  description="Get one of your players to place a bet"
                  clickAction={() => {
                    this.setState({ takeBetHintOpen: true });
                  }}
                  icon={<EmojiEvents fontSize={"large"} />}
                  completed={this._checkForFriendBet()}
                />
              }
              sx={{ paddingLeft: 0, paddingRight: 0 }}
            />
          </Tabs>

          <List>
            <Divider />
            {/*{this.props.profile.superAdmin && (*/}
            {/*  <ListItemButton divider onClick={() => this.props.history.push("/super-admin/light")}>*/}
            {/*    <ListItemIcon>*/}
            {/*      <AdminPanelSettings color="secondary" />*/}
            {/*    </ListItemIcon>*/}
            {/*    <ListItemText primary="Super Admin Light" secondary="No Grading" />*/}
            {/*    <ListItemSecondaryAction>*/}
            {/*      <ChevronRight />*/}
            {/*    </ListItemSecondaryAction>*/}
            {/*  </ListItemButton>*/}
            {/*)}*/}

            {/*{this.props.profile.superAdmin && (*/}
            {/*  <ListItemButton divider onClick={() => this.props.history.push("/super-admin/grade")}>*/}
            {/*    <ListItemIcon>*/}
            {/*      <AdminPanelSettings color="secondary" />*/}
            {/*    </ListItemIcon>*/}
            {/*    <ListItemText primary="Super Admin Heavy" secondary="Careful with grading" />*/}
            {/*    <ListItemSecondaryAction>*/}
            {/*      <ChevronRight />*/}
            {/*    </ListItemSecondaryAction>*/}
            {/*  </ListItemButton>*/}
            {/*)}*/}

            {this.props.profile.superAdmin && (
              <ListItemButton divider onClick={() => this.props.history.push("/super-admin-menu")}>
                <ListItemIcon>
                  <AdminPanelSettings color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Super Admin Menu" secondary="A Schooner is a Sailboat" />
                <ListItemSecondaryAction>
                  <ChevronRight />
                </ListItemSecondaryAction>
              </ListItemButton>
            )}

            <ListItemButton
                divider
                onClick={() => {
                  navigator.clipboard.writeText(this.generateURL()).then(() => this.setState({ copySnackBarOpen: true }));
                }}
            >
              <ListItemIcon>
                <ContentCopy color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Invite Friends"
                            secondary={`Click to copy a signup link`}

              />
              <ListItemSecondaryAction>
                <ContentCopy />
              </ListItemSecondaryAction>
            </ListItemButton>




            <ListItemButton divider onClick={() => this.props.history.push("/bookie")}>
              <ListItemIcon>
                <AccountBalance color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Bookie Settings/Actions" secondary="Only available to you, the Bookie" />
              <ListItemSecondaryAction>
                {this.props.pendingTrans > 0 && (
                  <Badge badgeContent={this.props.pendingTrans} color="secondary">
                    <ChevronRight />
                  </Badge>
                )}

                {!this.props.pendingTrans && <ChevronRight />}
              </ListItemSecondaryAction>
            </ListItemButton>

            <ListItemButton divider onClick={() => this.props.history.push("/cashier")}>
              <ListItemIcon>
                <AccountBoxIcon color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Player Settings/Actions" secondary="Use your app as a player" />
              <ListItemSecondaryAction>
                <ChevronRight />
              </ListItemSecondaryAction>
            </ListItemButton>

            {this.props.profile.email === "My Demo Account" && (
              <ListItemButton divider onClick={() => this.props.dispatch(showSignUpDialog(true))}>
                <ListItemIcon>
                  <BeenhereIcon color="secondary" />
                </ListItemIcon>
                <ListItemText primary="Save Your Account" secondary="You could lose this account" />
                <ListItemSecondaryAction>
                  <ChevronRight />
                </ListItemSecondaryAction>
              </ListItemButton>
            )}

            {this.props.profile.email !== "My Demo Account" && (
              <ListItemButton
                divider
                onClick={() => {
                  this.props.history.push("/signout");
                }}
              >
                <ListItemIcon>
                  <ExitToAppIcon color="grey" />
                </ListItemIcon>
                <ListItemText secondary={this.props.profile.email} primary="Log Out" />
                <ListItemSecondaryAction>
                  <ChevronRight />
                </ListItemSecondaryAction>
              </ListItemButton>
            )}
          </List>
          <Snackbar
              open={this.state.copySnackBarOpen}
              onClose={() => this.setState({ copySnackBarOpen: false })}
              autoHideDuration={6000}
              message={String("Copied: ")}
              severity="info"
              sx={{ bottom: { xs: 16, sm: 16 } }}
          >
            <Alert onClose={() => this.setState({ copySnackBarOpen: false })} severity="success" sx={{ width: "100%" }}>
              {String("Copied: " + this.generateURL())}
            </Alert>
          </Snackbar>
        </Box>
      </>
    );
  }
}

Profile.propTypes = {
  uid: PropTypes.string,
  dispatch: PropTypes.func,
  profile: PropTypes.object,
  history: PropTypes.object,
  showSignup: PropTypes.bool,
  balances: PropTypes.object,
  pendingTrans: PropTypes.number,
  myBalance: PropTypes.object,
};

const styles = (theme) => ({});

const mapStateToProps = (state) => {
  const getPendingTransCount = (B) => {
    if (B) {
      let totalPendingDeposits = Object.keys(B).reduce((a, b) => {
        return a + (B[b].pendingDepositCount || 0);
      }, 0);
      let totalPendingWithdrawals = Object.keys(B).reduce((a, b) => {
        return a + (B[b].pendingWithdrawalCount || 0);
      }, 0);
      return totalPendingDeposits + totalPendingWithdrawals;
    } else return 0;
  };

  const getMyBalance = (m) => {
    if (state.firestore.data?.profile_groupBalances && state.firebase?.auth.uid)
      return state.firestore.data?.profile_groupBalances[state.firebase?.auth.uid];
  };

  return {
    uid: state.firebase.auth.uid,
    profile: state.firebase.profile,
    showSignup: state.newUserState.showSignUpDialog || false,
    balances: state.firestore.data?.profile_groupBalances,
    myBalance: getMyBalance(),
    pendingTrans: getPendingTransCount(state.firestore.data?.profile_groupBalances) || 0,
  };
};

export default compose(
  connect(mapStateToProps),
  withRouter,
  firestoreConnect((props) => {
    return [
      {
        collection: `groups/${props.profile.managerGid}/balances`,
        storeAs: "profile_groupBalances",
      },
    ];
  })
)(withStyles(styles)(Profile));
