import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import withStyles from "@mui/styles/withStyles";
import collect from "collect.js";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import ChangeUsername from "../components/ChangeUsername";

class PromoLeaderboardTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rulesOpen: false,
    };
  }

  fakeUsers = [
    { username: "Mr Ditka", score: 9 },
    { username: "SFD21", score: 8 },
    { username: "go sox boyyyy", score: 7 },
    { username: "gatsby", score: 7 },
    { username: "superfunboy", score: 5 },
    { username: "superfunboy", score: 2 },
  ];

  getScore = (uid) => {
    // Score is unique winning tickets - unique losing tickets
    if (this.props.promoResults[uid]) {
      let W = collect(this.props.promoResults[uid].winningTickets).filter((i) => !i.includes("MoneyLine"));
      let L = collect(this.props.promoResults[uid].losingTickets).filter((i) => !i.includes("MoneyLine"));
      console.log("this.props.promoResults[uid]: ", this.props.promoResults[uid]);
      return Number(W.unique().count() - L.unique().count());
    } else {
      return 0;
    }
  };

  renderTable = () => {
    let B = this.props.balances;
    return (
      <TableContainer key={"bv"} style={{ paddingBottom: 30 }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align={"center"} colSpan={2}>
                Leaderboard
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell />
              <TableCell align={"right"}>Score</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.props.profile.gid === "uU5VEX6AHwMqhdMDqOho" &&
              this.fakeUsers.map((v) => {
                return (
                  <TableRow key={v} className={v === this.props.uid ? this.props.classes.usersRow : ""}>
                    <TableCell component="th" scope="row">
                      {v.username}
                    </TableCell>
                    <TableCell align="right" component="th" scope="row">
                      {v.score}
                    </TableCell>
                  </TableRow>
                );
              })}
            {Object.keys(B)
              .sort((x, y) => {
                if (this.getScore(x) === this.getScore(y)) {
                  return x === this.props.uid ? -1 : 1;
                } else {
                  return this.getScore(x) < this.getScore(y) ? 1 : -1;
                }
              })
              .map((v) => {
                if (B[v].email && !(B[v].email.includes("danilo+") || B[v].email.includes("ephlind+"))) {
                  // console.log("V: ",B[v])
                  return (
                    <TableRow key={v} className={v === this.props.uid ? this.props.classes.usersRow : ""}>
                      <TableCell
                        onClick={() => {
                          if (v === this.props.uid) this.setState({ changeUsernameDialogOpen: true });
                        }}
                        component="th"
                        scope="row"
                      >
                        {B[v].username ? B[v].username : "anon"}{" "}
                        {v === this.props.uid ? "(Click to change name)" : null}
                      </TableCell>
                      <TableCell align="right" component="th" scope="row">
                        {v && this.getScore(v)}
                      </TableCell>
                    </TableRow>
                  );
                }
              })
              .slice(0, 10)}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  _closeChangeName = () => {
    this.setState({ changeUsernameDialogOpen: false });
  };

  render() {
    console.log("leaderboard table this.props: ", this.props);
    return (
      <>
        {this.props.balances && this.props.promoResults && this.props.promoRules && this.renderTable()}

        <ChangeUsername
          open={this.state.changeUsernameDialogOpen}
          closeCallback={this._closeChangeName}
          uid={this.props.uid}
          gid={this.props.profile.gid}
          email={this.props.profile.email}
          currentName={this.props.userBalanceDoc?.username}
        />
      </>
    );
  }
}

PromoLeaderboardTable.propTypes = {
  classes: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  pastBets: PropTypes.object,
  balances: PropTypes.object,
  groupConfigs: PropTypes.object.isRequired,
  userBalanceDoc: PropTypes.object,
  promoRules: PropTypes.object,
  promoResults: PropTypes.object,
  email: PropTypes.string,
  uid: PropTypes.string,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: "inline-block",
    marginTop: "20px",
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  grid: {
    textAlign: "center",
    marginBottom: "20px",
  },
  usersRow: {
    backgroundColor: "#daf5c8",
  },
  tableCell: {
    "$hover:hover &": {
      color: "pink",
    },
  },
  hover: {},
});

const mapStateToProps = (state) => {
  // const getUserBalanceDoc = (obj) => {
  //   if (state.firebase.auth.uid && state.firestore.data.social_promos_balances)
  //    return obj[state.firebase.auth.uid];
  // }
  //
  // const filterPromoResults = () => {
  //   let c = collect(state.firestore.data?.leaderboardTable_results)
  //   console.log("c: ",c)
  //   // if (state.firestore.data?.social_promosRules && state.firestore.data?.social_groupConfigs)
  //   // return state.firestore.data?.social_promos[state.firestore.data?.social_groupConfigs.activePromo]["results"]
  // }
  //
  // filterPromoResults();

  return {
    profile: state.firebase.profile,
    uid: state.firebase.auth.uid,
    email: state.firebase.auth.email,
    promoResults: state.firestore.data?.leaderboardTable_results,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    console.log("props: ", props);
    return [
      {
        collection: `groups/${props.profile.gid}/promos/`,
        doc: props.groupConfigs.activePromo,
        subcollections: [{ collection: "results" }],
        storeAs: "leaderboardTable_results",
      },
    ];
  })
)(withStyles(styles)(PromoLeaderboardTable));

// const filterPublicBets = (allBets) => {
//   if (allBets && state.firestore.data.socialGroupConfigs.publicBettors) {
//     let publicUsers = state.firestore.data.socialGroupConfigs.publicBettors;
//     let filtered;
//     filtered = Object.keys(allBets)
//         .filter((key) => publicUsers.includes(allBets[key].uid))
//         .reduce((obj, key) => {
//           return {
//             ...obj,
//             [key]: allBets[key],
//           };
//         }, {});
//     return filtered;
//   } else return {};
// };
// const filterSocialUIDs = (balances) => {
//   if (balances && state.firestore.data.socialGroupConfigs.publicBettors) {
//     let publicUsers = state.firestore.data.socialGroupConfigs?.publicBettors;
//     let filtered;
//     filtered = Object.keys(balances)
//         .filter((key) =>
//             //console.log("key is: ",key)
//             publicUsers.includes(key)
//         )
//         .reduce((obj, key) => {
//           return {
//             ...obj,
//             [key]: balances[key],
//           };
//         }, {});
//     return filtered;
//   }
// };
