import * as React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import BottomNavigation from "@mui/material/BottomNavigation";
import Paper from "@mui/material/Paper";
import { connect } from "react-redux";
import { showSignUpDialog } from "../reducers/new-user-reducer";
import { Button, Grid, LinearProgress, MobileStepper, Step, StepLabel, Stepper } from "@mui/material";
import { firestoreConnect } from "react-redux-firebase";

class BottomNav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: props.activeTab,
    };
  }

  tabChange = (event, newValue) => {

    if (newValue === 0) {
      this.props.history.push("/sports");
    } else if (newValue === 1) {
        if (this.props.profile.email.includes('demo')) {
          console.log("SHOW SIGNUP FROM BOTTOM NAV")
            this.props.dispatch(showSignUpDialog(true))
            this.props.history.push('/bookie')
        } else {
            this.props.history.push("/bookie");
        }
    }
  };
  stepper = () => {
    return (
        <Stepper activeStep={this.state.activeStep} orientation="horizontal">
          <Step key={0}>
            <StepLabel >Place Bet</StepLabel>
          </Step>
          <Step key={1}>
            <StepLabel>Request Credits</StepLabel>
          </Step>
          <Step key={2}>
            <StepLabel>Be a Bookie</StepLabel>
          </Step>
        </Stepper>

    )
  }
  handleNext = () => {
   console.log("next")
  }
  mobileStepper = () => {
    return (
      <MobileStepper
        variant="dots"
        color={"secondary"}
        steps={6}
        position="static"
        activeStep={2}
        sx={{  flexGrow: 1 }}
        nextButton={
          <Button size="small" onClick={this.handleNext} disabled={this.state.activeStep === 5}>
            Place A Bet
          </Button>
        }
        backButton={
          <Button color="secondary" size="small" onClick={this.handleNext} disabled={this.state.activeStep === 0}>
            Award Credits
          </Button>
        }
      />
    );
  }

  getLastStep = () => {
    if (this.props.profile.conversionState === "createdBook")
      return <>1/4 Create Book</>
    else if (this.props.balanceDoc?.pendingBetCount > 0 || this.props.balanceDoc?.totalBets > 0)
      return <>3/4 Place Bet</>
    else if (this.props.profile.conversionState === "gaveSelfCredits")
      return <>2/4 Get Credits</>
    else
      return <>uknown</>
  }

  getNextStep = () => {
    if (this.props.profile.conversionState === "createdBook")
      return <>2/4 Get Credits</>
    else if (this.props.balanceDoc?.pendingBetCount > 0 || this.props.balanceDoc?.totalBets > 0)
      return <>4/4 Be Bookie</>
    else if (this.props.profile.conversionState === "gaveSelfCredits")
      return <>3/4 Place Bet</>
    else
      return <>uknown</>
  }

  renderSteps = () => {
    return (

        <Paper sx={{ borderTop: 1, position: "fixed", bottom: 0, left: 0, right: 0, top: "auto", zIndex: 20 }} elevation={3}>
          <LinearProgress variant="determinate" value={60}/>
          <BottomNavigation sx={{background:"#ffffff"}}>
            <Grid container padding={.5} justifyContent="center" alignItems="center" >
              <Grid item xs={4} sx={{textAlign: "center"}}>
                {this.getLastStep()}
              </Grid>
              <Grid item xs={4} sx={{textAlign: "center"}}>
                {`--->`}
              </Grid>
              <Grid item xs={4} sx={{textAlign: "center"}}>
                {this.getNextStep()}
              </Grid>
            </Grid>
          </BottomNavigation>
        </Paper>



    )
  }

  render() {
    console.log("bottom nav props: ", this.props);
    const actionClasses = this.props.classes;

    // if (this.props.profile.activeBookie) {
    //   return this.renderSteps()
    // } else {
    return null;
    // return (
    //     <Paper sx={{ borderTop: 2, position: "fixed", bottom: 0, left: 0, right: 0, top: "auto", zIndex: 20 }} elevation={3}>
    //       <BottomNavigation sx={{background:"#ffffff"}} showLabels value={this.state.activeTab} onChange={this.tabChange}>
    //         { (!this.props.profile.activeBookie || this.props.location.pathname.includes("cashier")) && <BottomNavigationAction classes={actionClasses} label={"Sportsbook"} icon={<HomeIcon />} /> }
    //         {/*{ <BottomNavigationAction classes={actionClasses} label="Friends" icon={<PeopleIcon />} />}*/}
    //         {/*{ <BottomNavigationAction classes={actionClasses} label="Cashier" icon={<AttachMoneyIcon />} />}*/}
    //         { this.props.profile.activeBookie && <BottomNavigationAction classes={actionClasses} label="Bookie Console" icon={<AccountBalance />} />}
    //         {/*{this.props.profile.activeBookie && <Divider light={true} color="green" orientation={"vertical"}/>}*/}
    //         {/*{this.props.profile.activeBookie && <BottomNavigationAction label={"Banker Admin"} icon={<AccountBalance color={"green"}/>} />}*/}
    //       </BottomNavigation>
    //     </Paper>
    // );
  }
  // }
}

BottomNav.propTypes = {
  classes: PropTypes.object.isRequired,
  activeTab: PropTypes.number.isRequired,
  history: PropTypes.object,
  isBookieMode: PropTypes.bool,
  managerGid: PropTypes.string,
  profile: PropTypes.object,
  uid: PropTypes.string,
  location: PropTypes.object,
  balanceDoc: PropTypes.object,
  dispatch: PropTypes.func,
};

const styles = (theme) => ({
  root: {
    fontFamily: "Libre Franklin",
    color: theme.palette.quaternary.main,
    "&$selected": {
      color: theme.palette.secondary.main
    }
  },
  selected: {}
});

const mapStateToProps = (state) => {
  return {
    profile: state.firebase.profile,
    uid: state.firebase.auth.uid,
    balanceDoc: state.firestore.data.bottomNav_balanceDoc
  };
};

export default compose(
  connect(mapStateToProps),
  withRouter,
  firestoreConnect((props) => {
    console.log("nav firestore connect props: ",props)
    return [
      {
        collection: `groups/${props.profile.gid}/balances`,
        doc: `${props.uid}`,
        storeAs: "bottomNav_balanceDoc",
      },
    ];
  })
)(withStyles(styles)(BottomNav));
