import React, { Component } from "react";
import { firestoreConnect, getFirebase } from "react-redux-firebase";
import Lines2 from "../Lines2";
import { Box, Grid } from "@mui/material";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { withStyles } from "@mui/styles";
import Loading from "../../../components/Loading";

class SuperLines2 extends Component {
  _removePendingBet = (betId, bet) => {
    getFirebase().firestore().collection("users").doc(bet.uid).collection("pendingBets2").doc(betId).delete();
  };

  render() {
    console.log("SuperLines2", this.props);
    if (this.props.linesData !== this.props.activeSport) {
      console.log("!!!!! eph don't render superlines2");
      return null;
    }
    const hideProps = this.props.linesData === this.props.activeSport ? {} : { display: { xs: "none" } };

    if (this.props[this.props.linesData] && this.props.pendingBets2) {
      Object.entries(this.props.pendingBets2).map(([betId, bet]) => {
        if (bet && bet.collection === "futures" && bet.bucket === this.props.linesData) {
          if (
            !(bet.doc_id in this.props[this.props.linesData]) ||
            this.props[this.props.linesData][bet.doc_id] === null
          ) {
            this._removePendingBet(betId, bet);
          }
        }
      });
    }

    if (
    //     (this.props.gid === "TUXNZzGRc7ZH6NBxLrUi0OMrRQs1" && this.props.title !== "World Cup")
    // ||
        (this.props.groupData?.activePool?.isActive && this.props.title !== this.props.groupData?.activePool?.sport)
    ) {
      return (
        <Box sx={hideProps}>
          <Grid container direction="column" justifyContent="center" alignItems="stretch">
            <Grid
              item
              sx={{
                textAlign: "center",
                color: "#666666",
                padding: 2,
                height: "100%",
                // fontSize: ".65rem",
              }}
            >
              Only {this.props.groupData?.activePool?.sport} bets are allowed. <br/>
              Visit superfunbet.com in a new browser to start your own sportsbook
            </Grid>
          </Grid>
        </Box>
      );
    }

    return (
      <Box sx={hideProps}>
        {this.props[this.props.linesData] === undefined && <Loading loadingMessage="Loading Lines" />}
        {(this.props[this.props.linesData] && (
          <Lines2
            sport={this.props.title}
            lines={this.props[this.props.linesData]}
            juice={!this.props.juice && this.props.juice !== 0 ? 4.55 : this.props.juice}
            sportBucket={this.props.linesData}
            coinflipJuice={
              !this.props.coinflipJuice && this.props.coinflipJuice !== 0 ? 0.98 : this.props.coinflipJuice
            }
            gid={this.props.gid}
            options={this.props.options}
            our_last_update={this.props[this.props.linesData].timestamp}
            sportsGroup={this.props.linesData}
            linesSource={this.props.lineSource}
          />
        )) || (
          <Grid container direction="column" justifyContent="center" alignItems="stretch">
            <Grid
              item
              sx={{
                textAlign: "center",
                color: "#666666",
                padding: 2,
                height: "100%",
                // fontSize: ".65rem",
              }}
            >
              Check back soon for more lines...
            </Grid>
          </Grid>
        )}
      </Box>
    );
  }
}

SuperLines2.propTypes = {
  linesData: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  juice: PropTypes.number,
  coinflipJuice: PropTypes.number,
  gid: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  sportsGroup: PropTypes.string.isRequired,
  // active: PropTypes.bool.isRequired,
  activeSport: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([PropTypes.object]),
  pendingBets2: PropTypes.object,
  lineSource: PropTypes.string,
  groupData: PropTypes.object,
};

const mapStateToProps = (state, ownProps) => {
  // console.log("superlines 2 state: ",state)
  return {
    [ownProps.linesData]: state.firestore.data[ownProps.linesData],
    pendingBets2: state.firestore.data.pendingBets2,
    activeSport: state.sportsbookState.activeSport,
    groupData: state.firestore.data?.sportsbook_groupConfigs,
  };
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  fab: {
    margin: 0,
    top: "auto",
    right: 20,
    bottom: 20,
    left: "auto",
    position: "fixed",
  },
});

export default compose(
  connect(mapStateToProps),
  withRouter,
  firestoreConnect((props) => {
    const lineSource = props.lineSource || "lines_2";
    return [
      {
        collection: `${lineSource}/${props.linesData}/futures`,
        // doc: props.linesData,
        storeAs: props.linesData,
      },
    ];
  })
)(withStyles(styles)(SuperLines2));
