import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Dialog, DialogContent, DialogTitle, Divider } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
// import {convertAnonAccount, createEmailAndPasswordLogin, forgotPassword} from "../../reducers/user-reducer";
import {
  convertAnonAccountWithEmail, convertAnonAccountWithGoogle,
  createAccountWithEmail,
  createAccountWithGoogle,
} from "./functions/createAccount";
import { withRouter } from "react-router-dom";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { showSignUpDialog } from "../../reducers/new-user-reducer";
import {firestoreConnect, getFirebase} from "react-redux-firebase";
import Link from "@mui/material/Link";
import { compose } from "redux";
import Typography from "@mui/material/Typography";

class SignUpDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      signUpButtonDisabled: false
    };
  }

  _handTextChange = (e) => {
    switch (e.target.id) {
      case "email":
        this.setState({ email: e.target.value });
        break;
      case "password":
        this.setState({ password: e.target.value });
        break;
      default:
        console.log(
          "Uncaught onChange target->",
          e.target.id,
          "value ->",
          e.target.value,
          "checked ->",
          e.target.checked
        );
    }
  };

  signin = () => {
    this.props.history.push("/signin");
  };

  _handleClickAway = () => {
    console.log("click");
    // this.props.dispatch(showSignInDialog(false));
  };

  _returnGid = (B) => {
    if (B && this.props.isBookieReferral) return Object.keys(B)[0];
    else return null;
  };

  _getManagerEmail = () => {
    let BD = this.props.bookData;
    let obj = Object.keys(BD)[0];
    return BD[obj]?.managerEmail;
  };

  // googleSignin = (googleUser) => {
  //   const firebase = getFirebase()
  //   var provider = new firebase.auth.GoogleAuthProvider();
  //
  //   console.log("provider: ",provider)
  //
  //   const credential = provider.credential(
  //     googleUser.getAuthResponse().id_token);
  //
  //   console.log("credential: ",credential)
  //
  //   getFirebase().auth().currentUser.linkWithCredential(credential)
  //     .then(function(usercred) {
  //       var user = usercred.user;
  //       console.log("Anonymous account successfully upgraded", user);
  //     }).catch(function(error) {
  //     console.log("Error upgrading anonymous account", error);
  //   });
  //
  //   getFirebase().login({
  //     credential
  //   });
  //
  //   // this.props.handleClose();
  // };

  _dialogOpenState = () => {

    if (this.props.showSignupDialog) {
      return true
    } else if (
        (Date.now() > this.props.profile?.conversionPopups?.nextSignupReminder)
        && this.props.profile?.email === "My Demo Account"
    ) {
      return true
    }
    else {
      return false
    }
  }

  render() {

    let referringGid;
    if (this.props.user.initialPage.includes("join"))
      referringGid = this.props.user?.initialPage.split("/join/")[1]
    else if (this.props.user.initialPage.includes("kelvin"))
      referringGid = "Y7bqQzvue9Mk3AamrIDKNaFpukN2"
    else
      referringGid = null

    console.log("GOOD sign up dialog props  ", this.props);
    console.log("GOOD sign up dialog state  ", this.state);
    console.log("referring Gid  ", referringGid);
    const { classes } = this.props;


    // if (this.props.bookData) {
    //   let temp = this._getManagerEmail()
    //   console.log("temp: ",temp)
    // }

    return (
      <Dialog open={this._dialogOpenState()} style={{ textAlign: "center" }}>
        <ClickAwayListener onClickAway={this._handleClickAway}>
          <Box sx={{ flexGrow: 1 }}>
            <Box elevation={0} sx={{ borderBottom: 2 }}>


              {/*HEADER LOGIC FOR REFERRALS*/}

              {referringGid && !this.props.bookData && (
                <DialogTitle
                  style={{
                    fontFamily: "NeuveticaHeavyItalic",
                    borderBottom: 3,
                  }}
                >
                  {"finding your friend's book..."}
                </DialogTitle>
              )}

              {
                referringGid && this.props.bookData && (<>
                <DialogTitle
                  style={{
                    fontSize: "2rem",
                    fontFamily: "NeuveticaHeavyItalic",
                    borderBottom: 3,
                  }}
                >
                  {this.props.bookData.groupName}
                </DialogTitle>

                <Typography>the private sportsbook of</Typography>

                <Typography style={{ padding: 5 }}>{this.props.bookData.managerEmail}</Typography>

                </>)
              }

              {/*GENERIC HEADER LOGIC*/}

              {!referringGid && (
                  <DialogTitle style={{ fontFamily: "NeuveticaHeavyItalic", borderBottom: 3 }}>
                    CREATE ACCOUNT
                  </DialogTitle>
              )}


            </Box>



            <DialogContent sx={{ margin: 0 }}>
              {!this.props.isBookieReferral && this.props.dialogText && (
                  <>
                    <Typography>{this.props.dialogText}</Typography>
                  </>
              )}
              {!this.props.isBookieReferral && !this.props.dialogText && (
                <>
                  <Typography>{`The app requires an email address to save your account.`}</Typography>
                </>
              )}

              {/*    </>)*/}
              {/*}*/}

              <Box style={{ marginTop: 0 }} display="flex" justifyContent="center" alignItems="center">
                <form className={classes.form} noValidate>
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        InputLabelProps={{ shrink: true }}
                        onChange={this._handTextChange}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        onChange={this._handTextChange}
                      />
                    </Grid>
                  </Grid>
                  <Button
                    fullWidth
                    disabled={this.state.signUpButtonDisabled}
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={() => {
                      this.setState({ signUpButtonDisabled: true })
                      if (!this.props.auth.uid && this.state.email && this.state.password) {
                        createAccountWithEmail(
                          this.state.email,
                          this.state.password,
                          this.props.firstVisitURL,
                            referringGid
                        );
                      } else if (this.props.auth.uid) {
                        convertAnonAccountWithEmail(this.state.email, this.state.password, this.props.auth.uid, this.props.firstVisitURL);
                      }
                    }}
                  >
                    Sign Up With Email
                  </Button>

                    <Divider> or </Divider>
                  <Button
                      style={{ margin: 0, color: "#ffffff" }}
                      fullWidth
                      variant="contained"
                      color="secondary"
                      className={classes.submit}
                      onClick={() => {
                        if (!this.props.auth.uid) {
                          createAccountWithGoogle(this.props.firstVisitURL, referringGid)
                        } else if (this.props.auth.uid) {
                          convertAnonAccountWithGoogle(this.props.auth.uid, this.props.firstVisitURL)
                        }
                      }}
                  >
                  Sign Up With Google
                  </Button>

                  {this.props.user?.signUpError?.message && (
                    <p style={{ color: "red" }}>Error: {this.props.user?.signUpError?.message}</p>
                  )}

                  {!referringGid && (
                    <Link
                      sx={{ color: "#bf0070", float: "right", marginTop: 4 }}
                      onClick={() => {
                        getFirebase().updateProfile({
                          conversionPopups: {
                            nextSignupReminder: Date.now() + 1000 * 60 * 10
                          },
                        });
                        this.props.dispatch(showSignUpDialog(false));
                      }}
                      variant="body2"
                    >
                      sign up later
                    </Link>
                  )}

                  {/*{*/}
                  {/*  this.props.userEmail !== "My Demo Account" && !this.props.isBookieReferral && (*/}
                  {/*        <Link sx={{ color: "#bf0070", float: "right", marginTop: 4 }}*/}
                  {/*              onClick={() => {*/}
                  {/*                this.props.dispatch(showSignInDialog(true))*/}
                  {/*                this.props.dispatch(showSignUpDialog(false))*/}
                  {/*              }}*/}
                  {/*              variant="body2">*/}
                  {/*          already have an account?*/}
                  {/*        </Link>*/}
                  {/*    )*/}
                  {/*}*/}
                </form>
              </Box>
            </DialogContent>
          </Box>
        </ClickAwayListener>
      </Dialog>
    );
  }
}

SignUpDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  auth: PropTypes.object,
  history: PropTypes.object,
  user: PropTypes.object,
  profile: PropTypes.object,
  firstVisitURL: PropTypes.string,
  dialogText: PropTypes.string,
  gid: PropTypes.string,
  referringGid: PropTypes.string,
  open: PropTypes.bool,
  isBookieReferral: PropTypes.bool,
  bookData: PropTypes.object,
  userEmail: PropTypes.string,
  showSignupDialog: PropTypes.bool
};

const styles = (theme) => ({
  dialogTitle: {
    color: theme.palette.primary.dark,
    paddingBottom: 0,
  },
  dialogSubtitle: {
    paddingTop: 10,
    color: theme.palette.primary.light,
  },
  paper: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  values: {
    borderColor: theme.palette.primary.light,
    color: theme.palette.primary.dark,
    borderWidth: 2,
    marginBottom: 15,
    alignItems: "left",
    padding: 0,
  },
});

const mapStateToProps = (state) => {
  // const getGid = (b) => {
  //   if (b)
  //     return Object.keys(b)
  //   else
  //     return ""
  // }
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    user: state.newUserState,
    firstVisitURL: state.newUserState.initialPage,
    bookData: state.firestore.data.referringBook,
    showSignupDialog: state.newUserState.showSignUpDialog,
    entireState: state
  };
};

export default compose(
  connect(mapStateToProps),
  withRouter,
  firestoreConnect((props) => {
    if (props.user.initialPage.includes("join")) {
      const referringGid = props.user.initialPage.split("/join/")[1]
      return [
        {
          collection: "groups",
          doc: referringGid,
          // where: [["groupName", "==", props.bookName || "Just For Fun"]],
          // limit: 1,
          storeAs: "referringBook",
        },
      ];
    } else if (props.user.initialPage.includes("kelvin")) {
      const referringGid = "Y7bqQzvue9Mk3AamrIDKNaFpukN2";
      return [
        {
          collection: "groups",
          doc: referringGid,
          // where: [["groupName", "==", props.bookName || "Just For Fun"]],
          // limit: 1,
          storeAs: "referringBook",
        },
      ];
    } else return [];
  })
)(withStyles(styles)(SignUpDialog));
