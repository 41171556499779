import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import { Redirect, withRouter } from "react-router-dom";
import { getFirebase } from "react-redux-firebase";

class Signout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  reload = () => {
    window.location.reload();
  };

  render() {
    const { classes } = this.props;
    console.log("SIGNOUT COMPONENT this.props: ", this.props);
    if (this.props.profile.email === "My Demo Account") {
      getFirebase()
        .auth()
        .currentUser.delete()
        .then(() => {
          this.props.history.push("/");
          window.location.reload();
        });
      return <Redirect to={{ pathname: "/" }} />;
    } else {
      console.log("REAL ACCOUNT!");
      getFirebase().auth().signOut().then(this.reload);
      return <Redirect to={{ pathname: "/" }} />;
    }
  }
}

Signout.propTypes = {
  classes: PropTypes.object.isRequired,
  firebaseAuth: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  user: PropTypes.shape({
    signInError: PropTypes.any,
  }),
  errorMessage: PropTypes.any,
  profile: PropTypes.object,
};

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

const mapStateToProps = (state) => {
  return {
    user: state.userState,
    firebaseAuth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(withRouter(Signout)));
