import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import clsx from "clsx";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {firestoreConnect, getFirebase} from "react-redux-firebase";
import {compose} from "redux";
import {Dialog, DialogContent, DialogTitle, Grid, ListItemText, TextField} from "@mui/material";
import BookiePopper from "./BookiePopper";
import CloseIcon from "@mui/icons-material/Close";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import SignUpDialog from "../views/Auth/SignUpDialog";

const drawerWidth = 240;

class BookieHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false,
      listOfBookNames: [],
      changeBookNameOpen: false
    };
  }

  handleDrawerOpen = () => {
    this.setState({
      drawerOpen: true,
    });
  };

  handleDrawerClose = () => {
    this.setState({
      drawerOpen: false,
    });
  };


  _changeBookName = async() => {
    let docID = this.props.uid
    if (this.props.profile.managerGid)
      docID = this.props.profile.managerGid

    const bookConfigs = getFirebase().firestore().collection("groups").doc(docID)
    bookConfigs.update({ groupName: this.state.newBookName })
      .then(() => {
        getFirebase().updateProfile({ conversionState: "newBookieChangedName" })
      })
      .then(() => {
        this.setState({ changeBookNameOpen: false })
      })
  }

  renderChangeBookName = () => {
    return (
      <Dialog open={this.state.changeBookNameOpen}>
        <DialogTitle style={{ fontFamily: "Libre Franklin", borderBottom: 3 }}>
          <Grid
            container
            direction="row"
            justifyContent="space-around"
            alignItems="center"
          >
            <Grid item xs={11} >
              Change Book Name
            </Grid>
            <Grid item xs={1} onClick={() => { this.setState({ changeBookNameOpen: false}) }}>
              <CloseIcon/>
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Stack spacing={2}>
            <ListItemText
              primary={`currently: ${this.props.managerGroup?.groupName}`}
              // secondary={"current balance: "}
            />
            <TextField
              label={"new name"}
              onChange={(e) => this.setState({ newBookName: String(e.target.value) })}
            />

            <Button
              variant={"contained"}
              onClick={() => {
                this._changeBookName();
                // this.props.dispatch(awardBonus(bonus))
                // this.setState(this.initialState)
              }}
              // sx={{ borderRadius: 6, border: 2, borderColor: "#bf0070" }}
            >
              SUBMIT
            </Button>

          </Stack>
        </DialogContent>
      </Dialog>

    )
  }

  renderBookName = () => {
    if (this.props.managerGroup?.groupName) {
      return <>{this.props.managerGroup?.groupName} - Bookie Console</>
    } else {
      return <>loading...</>
    }


  }

  render() {
    const { classes } = this.props;

    console.log("bookie header this.props: ", this.props);
    console.log("bookie header this.state: ", this.state);

    return (
      <div className={classes.root}>

        <AppBar
          position="relative"
          elevation={0}
          sx={{borderBottom: 2}}
          className={clsx(classes.appBar, {
            [classes.appBarShift]: this.state.drawerOpen,
          })}
        >

          <Toolbar >
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >

              {/*Book Name on the left*/}
              <Grid item xs={9} onClick={() => {
                this.setState({ changeBookNameOpen: true })
              }}>
                <Grid
                  container
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="center"
                >
                  <Grid item>
                    {this.renderBookName()}
                  </Grid>
                </Grid>
              </Grid>


              {/*Brand logo in middle*/}
              {/*<Grid item xs={0} md={2}>*/}
              {/*  <Grid*/}
              {/*    container*/}
              {/*    direction="row"*/}
              {/*    justifyContent="flex-start"*/}
              {/*    alignItems="center"*/}
              {/*  >*/}
              {/*    <Grid item>*/}
              {/*      <Typography*/}
              {/*        className={classes.headerLogo}*/}
              {/*        sx={{ display: { xs: 'none', sm: 'block' } }}*/}
              {/*        // variant="h6"*/}
              {/*        // color="inherit" noWrap className={classes.title}*/}
              {/*      >SUPERFUNBET*/}
              {/*      </Typography>*/}
              {/*    </Grid>*/}
              {/*  </Grid>*/}
              {/*</Grid>*/}

              {/*settings icon*/}
              <Grid item xs={3} sx={{paddingRight: 0}}>
                <Grid
                  container
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <BookiePopper
                    email={this.props.email}
                    dispatch={this.props.dispatch}
                    recentBooks={this.props.allUserGroups && Object.keys(this.props.allUserGroups)}
                    history={this.props.history} />
                   {/*<MenuIcon*/}
                   {/*    onClick={() => this.props.history.push('/signout')}*/}
                   {/*/>*/}
                </Grid>
              </Grid>
            </Grid>


          </Toolbar>
        </AppBar>
        {this.renderChangeBookName()}
      </div>
    );
  }
}

BookieHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  history: PropTypes.object,
  uid: PropTypes.string,
  profile: PropTypes.object,
  pastBets: PropTypes.object,
  pendingBets: PropTypes.object,
  auth: PropTypes.object,
  headerUserBalance: PropTypes.number,
  playerGroup: PropTypes.object,
  managerGroup: PropTypes.object,
  email: PropTypes.string,
  allUserGroups: PropTypes.object,
  showSignup: PropTypes.bool
};

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  headerLogo: {
    fontFamily: "NeuveticaHeavyItalic",
    // fontStyle: 'italic',
    // color: "black",
    // fontWeight: 700,
    fontSize: 25,
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  drawerDirection: {
    direction: theme.direction,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  hide: {
    display: "none",
  },
  dividerInset: {
    margin: `5px 0 0 ${theme.spacing(9)}`,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
});
const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    email: state.firebase.profile.email,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    managerGroup: state.firebase.profile.managerGid ? state.firestore.data.header_managerGroup : null,
    playerGroup: state.firebase.profile.gid ? state.firestore.data.header_playerGroup : null,
    allUserGroups: state.firestore.data.bookieHeader_userGroups,
    showSignUp: state.newUserState.showSignUpDialog,
  };
};

//export default withRouter(connect(mapStateToProps)((withyStyles(styles)(Header))));

export default compose(
  withRouter,
  connect(mapStateToProps),
  firestoreConnect((props) => {
    console.log("firestore connect props: ", props);
    return [
      {
        collection: "groups",
        doc: props.profile.managerGid,
        storeAs: "header_managerGroup",
      },
      {
        collection: "groups",
        doc: props.profile.gid,
        storeAs: "header_playerGroup",
      },
      {
        collection: `users/${props.auth.uid}/balances/`,
        storeAs: "bookieHeader_userGroups",
      },
    ];
  })
)(withStyles(styles)(BookieHeader));
