import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Button, Divider, Grid, TextField, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { submitBet2 } from "../../../reducers/sportsbook-reducer";
import { firestoreConnect, getFirebase } from "react-redux-firebase";
import { renderGametime } from "../../../utilities/lineUtilities";
import { v4 } from "uuid";
import { adjustRef, betLine2, betPrimaryText2, emailData, niceBetLine } from "./PendingHelpers";

const styles = () => ({
  pendingItem: {
    margin: 8,
    padding: 16,
  },
  betField: {
    flex: 1,
    paddingRight: 8,
    marginTop: 16,
  },
});

class PendingBet2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      riskAmount: (0).toFixed(2),
      winAmount: (0).toFixed(2),
      lockSide: "risk",
      oldPrice: 0,
      betButtonDisabled: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.game &&
      prevState.oldPrice !==
        betLine2(
          this.props.game,
          this.props.bet,
          this.props.game.event_type === "live" || this.props.game.is_live ? 6.75 : this.props.juice
        )
    ) {
      this.setState({
        oldPrice: betLine2(
          this.props.game,
          this.props.bet,
          this.props.game.event_type === "live" || this.props.game.is_live ? 6.75 : this.props.juice
        ),
      });
      if (this.state.lockSide === "risk") {
        this._onChangeRiskFromExternal(this.state.riskAmount);
      } else {
        this._onChangeWinFromExternal(this.state.winAmount);
      }
    }
  }

  getWinAmount = (riskAmount) => {
    const line = betLine2(
      this.props.game,
      this.props.bet,
      this.props.game.event_type === "live" || this.props.game.is_live ? 6.75 : this.props.juice
    );
    if (line === 100 || line === -100) return riskAmount;
    else if (line > 100) return Number((riskAmount * Number(line)) / 100).toFixed(2);
    else if (line < 0) return Number((riskAmount * -100) / Number(line)).toFixed(2);
  };

  getRiskAmount = (winAmount) => {
    const line = betLine2(
      this.props.game,
      this.props.bet,
      this.props.game.event_type === "live" || this.props.game.is_live ? 6.75 : this.props.juice
    );
    if (line === 100 || line === -100) return Number(winAmount);
    else if (line > 100) return Number((100 / Number(line)) * winAmount).toFixed(2);
    else if (line < 0) return Number((Number(line) / -100) * winAmount).toFixed(2);
  };

  _onChangeRisk = (event) => {
    this.setState({
      riskAmount: event.target.value,
      lockSide: "risk",
      winAmount: this.getWinAmount(event.target.value),
    });
  };

  _onChangeRiskFromExternal = (amount) => {
    this.setState({
      winAmount: this.getWinAmount(amount),
    });
  };

  _onChangeWin = (event) => {
    this.setState({
      winAmount: event.target.value,
      lockSide: "win",
      riskAmount: this.getRiskAmount(event.target.value),
    });
  };

  _onChangeWinFromExternal = (amount) => {
    this.setState({
      riskAmount: this.getRiskAmount(amount),
    });
  };

  _removePendingBet = () => {
    // getFirebase().analytics().logEvent("REMOVE_BET");
    getFirebase()
      .firestore()
      .collection("users")
      .doc(this.props.auth.uid)
      .collection("pendingBets2")
      .doc(this.props.betDoc)
      .delete();
  };

  _getMarketName = () => {
    if (this.props.bet.bet_type === "props")
      return this.props.bet?.oddsjamData?.market_name || null;
    if (this.props.bet.bet_period === "ft" && this.props.bet.bet_type === "spread" && this.props.game.sport === "baseball")
      return "Run Line";
    else if (this.props.bet.bet_period === "ft" && this.props.bet.bet_type === "spread")
      return "Point Spread";
    else if (this.props.bet.bet_period === "1h" && this.props.bet.bet_type === "spread" && this.props.game.sport === "baseball")
      return "1st Half Run Line";
    else if (this.props.bet.bet_period === "1h" && this.props.bet.bet_type === "spread")
      return "1st Half Point Spread";
    else if (this.props.bet.bet_period === "ft" && this.props.bet.bet_type === "ml")
      return "Moneyline";
    else if (this.props.bet.bet_period === "1h" && this.props.bet.bet_type === "ml")
      return "1st Half Moneyline";
    else if (this.props.bet.bet_period === "ft" && this.props.bet.bet_type === "ou" && this.props.game.sport === "baseball")
      return "Total Runs";
    else if (this.props.bet.bet_period === "ft" && this.props.bet.bet_type === "ou")
      return "Total Points";
    else if (this.props.bet.bet_period === "1h" && this.props.bet.bet_type === "ou")
      return "1st Half Total Points";
    else return null;
  };

  _getBetName = () => {


    let side = this.props.game[this.props.bet.bet_side];
    let homeSpreadPoints = Number(this.props.game.standard[this.props.bet.bet_period].spread.points);
    let awaySpreadPoints = -1*Number(this.props.game.standard[this.props.bet.bet_period].spread.points);

    if (this.props.bet.bet_type === "props") {
      return this.props.bet.oddsjamData.bet_name;
    }

    if (this.props.bet.bet_type === "spread" && this.props.bet.bet_side === "home" && homeSpreadPoints > 0)
      return side + " +"  + homeSpreadPoints
    else if (this.props.bet.bet_type === "spread" && this.props.bet.bet_side === "home" && 0 >= homeSpreadPoints)
      return side + " "  + homeSpreadPoints
    else if (this.props.bet.bet_type === "spread" && this.props.bet.bet_side === "away" && awaySpreadPoints >= 0)
      return side + " +"  + awaySpreadPoints
    else if (this.props.bet.bet_type === "spread" && this.props.bet.bet_side === "away" && 0 >= awaySpreadPoints)
      return side + " "  + awaySpreadPoints

    if (this.props.bet.bet_side === "over")
      return "Over" + " " + this.props.game.standard[this.props.bet.bet_period].total.points;
    else if (this.props.bet.bet_side === "under")
      return "Under" + " " + this.props.game.standard[this.props.bet.bet_period].total.points;
    else if (this.props.bet.bet_type === "ml")
      return this.props.game[this.props.bet.bet_side];
    else
      return null;
  };

  _onSubmitBet = () => {
    this.setState({ betButtonDisabled: false });
    let gradingData;

    if (this.props.game.origin === "oddsjam") {
      gradingData = {
          game_id: this.props.bet.doc_id,
          bet_name: this._getBetName() || null,
          league: this.props.game.league,
          sport: this.props.game.sport,
          market_name: this._getMarketName() || null,
      }
    } else {
      gradingData = {}
    }

    if (this.state.winAmount > this.props.maxBet) {
      alert(`Max Bet exceeded. Bet cannot win more than ${this.props.maxBet}`);
    } else {
      if (this.props.game.format === "2205") {
        this.props.dispatch(
          submitBet2({
            bet_id: v4(),
            uid: this.props.auth.uid,
            gid: this.props.bet.gid,
            // email: this.props.profile.email || "bookie demo account",
            promo: this.props.groupConfigs.activePromo || null,
            origin: this.props.game.origin,
            gradingData: gradingData,
            bet_data: {
              line: this.props.game,
              ...this.props.bet,
              event_id: this.props.bet.event_id,
              adjustRef: adjustRef(this.props.game, this.props.bet),
              price: Number(
                betLine2(
                  this.props.game,
                  this.props.bet,
                  this.props.game.event_type === "live" || this.props.game.is_live ? 6.75 : this.props.juice
                )
              ),
            },
            isParlay: false,
            isCoinflip: false,
            isLive: this.props.game.event_type === "live" || this.props.game.is_live,
            juice: this.props.game.event_type === "live" ? 6.75 : this.props.juice,
            risk: Number(this.state.riskAmount),
            win: Number(this.state.winAmount),
            format: "2205",
            unsubmittedToRemove: this.props.betDoc,
            display: {
              betPrimaryText: betPrimaryText2(this.props.game, this.props.bet),
            },
            emailData: emailData(
              this.props.game,
              this.props.bet,
              this.props.game.event_type === "live" || this.props.game.is_live ? 6.75 : this.props.juice
            ),
          })
        );
      } else {
        this.props.dispatch(
          submitBet2({
            betID: v4(),
            uid: this.props.auth.uid,
            gid: this.props.bet.gid,
            // email: this.props.profile.email || "bookie demo account",
            promo: this.props.groupConfigs.activePromo || null,
            origin: this.props.game.origin,
            is_half: this.props.game.is_half || false,
            unconfirmedBet: {
              game: this.props.game,
              ...this.props.bet,
              price: Number(
                betLine2(
                  this.props.game,
                  this.props.bet,
                  this.props.game.event_type === "live" || this.props.game.is_live ? 6.75 : this.props.juice
                )
              ),
              risk: Number(this.state.riskAmount),
              win: Number(this.state.winAmount),
            },
            isParlay: false,
            isCoinflip: false,
            isLive: this.props.game.event_type === "live" || this.props.game.is_live,
            juice: this.props.game.event_type === "live" ? 6.75 : this.props.juice,
            risk: Number(this.state.riskAmount),
            win: Number(this.state.winAmount),
            unsubmittedToRemove: this.props.betDoc,
            betPrimaryText: betPrimaryText2(this.props.game, this.props.bet),
            emailData: emailData(
              this.props.game,
              this.props.bet,
              this.props.game.event_type === "live" || this.props.game.is_live ? 6.75 : this.props.juice
            ),
          })
        );
      }
    }
  };

  _getSecondaryText = (liveLines) => {
    if (liveLines.HasOutRights) {
      return liveLines.Title;
    }
    return liveLines.AwayTeam + " @ " + liveLines.HomeTeam;
  };

  render() {
    // console.log("*** Render PendingBet2", this.props);
    // console.log("market name: ", this._getMarketName());
    // console.log("market name: ", this._getBetName());

    // console.log("*** Render PendingBet2", this.state);
    const { classes } = this.props;
    if (!this.props.game || !this.props.bet) {
      return <></>;
    }

    return (
      <>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item xs={12} md={this.props.isParlay ? 12 : 6}>
            <Grid
              container
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              style={{ paddingTop: 10 }}
            >
              <Grid item xs>
                <Typography variant="body1">
                  {betPrimaryText2(this.props.game, this.props.bet)} (
                  {niceBetLine(this.props.game, this.props.bet, this.props.juice)})
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography variant="subtitle2">
                  {this.props.game.away} at {this.props.game.home}
                </Typography>
              </Grid>
            </Grid>
            {/*<Grid item>*/}
            {/*  <Typography variant="subtitle2">{this._getSecondaryText(liveLines)}</Typography>*/}
            {/*</Grid>*/}
            <Grid item>
              <Typography variant="subtitle2" sx={{ fontSize: ".70rem" }}>
                {this.props.game.format === "2205" &&
                  renderGametime(this.props.game?.starts_ms, this.props.game?.event_type)}
                {this.props.game.format !== "2205" &&
                  renderGametime(this.props.game?.startsMS, this.props.game?.event_type)}
              </Typography>
            </Grid>
          </Grid>
          {!this.props.isParlay && (
            <>
              <Grid item xs={12} md={this.props.isParlay ? 12 : 6}>
                <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-required"
                      label="Risk"
                      variant="outlined"
                      type="number"
                      size="small"
                      color={"secondary"}
                      fullWidth
                      InputLabelProps={{ shrink: true }}
                      className={classes.betField}
                      value={this.state.riskAmount}
                      onChange={this._onChangeRisk}
                      onFocus={(event) => {
                        event.target.select();
                      }}
                      inputProps={{ inputMode: "numeric" }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      id="outlined-required"
                      label="Win"
                      variant="outlined"
                      type="number"
                      size="small"
                      color={"secondary"}
                      InputLabelProps={{ shrink: true }}
                      fullWidth
                      className={classes.betField}
                      value={this.state.winAmount}
                      onChange={this._onChangeWin}
                      style={{ paddingRight: 0 }}
                      onFocus={(event) => {
                        event.target.select();
                      }}
                      inputProps={{ inputMode: "numeric" }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Grid>

        <Grid item xs={12}>
          <Grid container direction="row" justifyContent="space-between" alignItems="center" sx={{ marginTop: 1 }}>
            <Grid item>
              <Button variant="outlined" color="secondary" size="small" onClick={this._removePendingBet}>
                Remove
              </Button>
            </Grid>
            <Grid item>
              {!this.props.isParlay && (
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={this._onSubmitBet}
                  size="small"
                  disabled={
                    this.props.inflightBets.includes(this.props.betDoc) ||
                    this.state.riskAmount < 0.01 ||
                    niceBetLine(this.props.game, this.props.bet, this.props.juice) === "Off" ||
                    this.state.betButtonDisabled
                  }
                >
                  Bet
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>

        {this.props.showDivider && <Divider style={{ paddingTop: 16 }} />}
      </>
    );
    // } else {
    //   // This is terrible cheating for now
    //   console.log("Pending Bet should remove", this.props.betDoc);
    //   // this._removePendingBet();
    //   return <></>;
    // }
  }
}

PendingBet2.propTypes = {
  bet: PropTypes.object,
  game: PropTypes.object,
  classes: PropTypes.object,
  auth: PropTypes.object,
  dispatch: PropTypes.func,
  betDoc: PropTypes.string,
  inflightBets: PropTypes.array,
  isParlay: PropTypes.bool,
  groupConfigs: PropTypes.object,
  profile: PropTypes.object,
  maxBet: PropTypes.number,
  juice: PropTypes.number,
  coinflipJuice: PropTypes.number,
  showDivider: PropTypes.bool,
};

const mapStateToProps = (state, props) => {
  const getMaxBet = (custom, general) => {
    if (custom) return custom;
    else if (general) return general;
    else return null;
  };
  return {
    profile: state.firebase.profile,
    auth: state.firebase.auth,
    inflightBets: state.sportsbookState.inflightBets,
    // if bookie has set a max bet, use it, otherwise, use group max bet
    maxBet: getMaxBet(state.firestore.data?.userBalanceDoc?.customMaxBet, props.groupConfigs?.maxBet),
  };
};

export default compose(
  connect(mapStateToProps),
  withRouter,
  firestoreConnect((props) => {
    return [
      {
        collection: `groups/${props.profile.gid}/balances`,
        doc: `${props.auth.uid}`,
        storeAs: "userBalanceDoc",
      },
    ];
  })
)(withStyles(styles)(PendingBet2));
