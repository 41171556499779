import React, { Component } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import BetItem2 from "../BetItem2";
import CoinFlipBetItem2 from "../CoinFlipBetItem2";
import { renderGametime } from "../../../utilities/lineUtilities";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";

class Line extends Component {
  _getLastWord = (str) => {
    const n = str.split(" ");
    return n[n.length - 1];
  };

  _formatMoneyLine(moneyline) {
    if (!moneyline || moneyline === Infinity) return "Off";
    if (moneyline > 0) {
      return "+" + moneyline.toString();
    } else {
      return moneyline.toString();
    }
  }

  _formatHandicap = (handicap, side) => {
    if (side === "home") {
      return this._formatMoneyLine(handicap);
    }
    if (side === "away") {
      return this._formatMoneyLine(-1 * handicap);
    }
    return null;
  };

  _renderMoneyLineCoinflip = () => {
    return (
      <Grid item container xs={6} direction="column" alignItems="center" width="100%">
        <Grid xs={12} item width="100%">
          <CoinFlipBetItem2
            flipOptions={[
              {
                displayName: this.props.lineData.away,
                betSide: "away",
                wheelName: this._getLastWord(this.props.lineData.away),
              },
              {
                displayName: this.props.lineData.home,
                betSide: "home",
                wheelName: this._getLastWord(this.props.lineData.home),
              },
            ]}
            coinflipJuice={this.props.coinflipJuice}
            betPeriod={this.props.betPeriod}
            bucket={this.props.sportsBucket}
            collection="futures"
            docId={this.props.lineID}
            betType="ml"
            game={this.props.lineData}
            lineSource={this.props.lineSource}
          />
        </Grid>
      </Grid>
    );
  };

  _renderMoneyLine = (renderAsCoinflip) => {
    if (this.props.lineData.periods[this.props.betPeriod]?.money_line || this.props.coinflip) {
      if (this.props.coinflip || renderAsCoinflip) {
        return this._renderMoneyLineCoinflip();
      } else {
        if (this.props.options.onlyMoneyline) {
          return (
            <Grid item container xs={12} direction="row" alignItems="stretch">
              <Grid item xs={8} sm={10}>
                <Grid item container xs={12} direction="column" alignItems="center">
                  <Grid xs={12} item width="100%">
                    <BetItem2
                      isLive={this.props.lineData.event_type === "live"}
                      price={this.props.lineData.periods[this.props.betPeriod]?.money_line?.away}
                      rowColor={"one"}
                      betPeriod={this.props.betPeriod}
                      juice={this.props.juice}
                      coinflipJuice={this.props.coinflipJuice}
                      label={this.props.lineData.away}
                      bucket={this.props.sportsBucket}
                      collection="futures"
                      docId={this.props.lineID}
                      betType="ml"
                      betSide="away"
                      lineSource={this.props.lineSource}
                    />
                  </Grid>
                  <Grid xs={12} item width="100%">
                    <BetItem2
                      isLive={this.props.lineData.event_type === "live"}
                      price={this.props.lineData.periods[this.props.betPeriod]?.money_line?.home}
                      rowColor={"two"}
                      betPeriod={this.props.betPeriod}
                      juice={this.props.juice}
                      coinflipJuice={this.props.coinflipJuice}
                      label={this.props.lineData.home}
                      bucket={this.props.sportsBucket}
                      collection="futures"
                      docId={this.props.lineID}
                      betType="ml"
                      betSide="home"
                      lineSource={this.props.lineSource}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4} sm={2} sx={{ paddingBottom: 0.5 }}>
                <BetItem2
                  isLive={this.props.lineData.event_type === "live"}
                  price={this.props.lineData.periods[this.props.betPeriod]?.money_line?.draw}
                  rowColor={"draw"}
                  betPeriod={this.props.betPeriod}
                  juice={this.props.juice}
                  coinflipJuice={this.props.coinflipJuice}
                  label="Draw"
                  bucket={this.props.sportsBucket}
                  collection="futures"
                  docId={this.props.lineID}
                  betType="ml"
                  betSide="draw"
                  lineSource={this.props.lineSource}
                />
              </Grid>
            </Grid>
          );
        }
        return (
          <Grid item container xs={6} direction="column" alignItems="center">
            <Grid xs={12} item width="100%">
              <BetItem2
                isLive={this.props.lineData.event_type === "live"}
                price={this.props.lineData.periods[this.props.betPeriod]?.money_line?.away}
                rowColor={"one"}
                betPeriod={this.props.betPeriod}
                juice={this.props.juice}
                coinflipJuice={this.props.coinflipJuice}
                label={this.props.lineData.away}
                bucket={this.props.sportsBucket}
                collection="futures"
                docId={this.props.lineID}
                betType="ml"
                betSide="away"
                lineSource={this.props.lineSource}
              />
            </Grid>
            <Grid xs={12} item width="100%">
              <BetItem2
                isLive={this.props.lineData.event_type === "live"}
                price={this.props.lineData.periods[this.props.betPeriod]?.money_line?.home}
                rowColor={"two"}
                betPeriod={this.props.betPeriod}
                juice={this.props.juice}
                coinflipJuice={this.props.coinflipJuice}
                label={this.props.lineData.home}
                bucket={this.props.sportsBucket}
                collection="futures"
                docId={this.props.lineID}
                betType="ml"
                betSide="home"
                lineSource={this.props.lineSource}
              />
            </Grid>
          </Grid>
        );
      }
    }
    if (this.props.coinflip || renderAsCoinflip) {
      return this._renderMoneyLineCoinflip();
    }
    return (
      <Grid item container xs={6} direction="column" alignItems="center">
        <Grid xs={12} item width="100%">
          <BetItem2
            isLive={this.props.lineData.event_type === "live"}
            price={null}
            rowColor={"one"}
            betPeriod={this.props.betPeriod}
            juice={this.props.juice}
            coinflipJuice={this.props.coinflipJuice}
            label={this.props.lineData.away}
            bucket={this.props.sportsBucket}
            collection="futures"
            docId={this.props.lineID}
            betType="ml"
            betSide="away"
            lineSource={this.props.lineSource}
          />
        </Grid>
        <Grid xs={12} item width="100%">
          <BetItem2
            isLive={this.props.lineData.event_type === "live"}
            price={null}
            rowColor={"two"}
            betPeriod={this.props.betPeriod}
            juice={this.props.juice}
            coinflipJuice={this.props.coinflipJuice}
            label={this.props.lineData.home}
            bucket={this.props.sportsBucket}
            collection="futures"
            docId={this.props.lineID}
            betType="ml"
            betSide="home"
            lineSource={this.props.lineSource}
          />
        </Grid>
      </Grid>
    );
  };

  _renderSpreads = (renderAsCoinflip) => {
    const spreadID = this.props.lineData.periods[this.props.betPeriod]?.spreads?.default;
    if (spreadID) {
      if (this.props.coinflip || renderAsCoinflip) {
        return (
          <Grid item container xs={3} direction="column" alignItems="center" width="100%">
            <Grid xs={12} item width="100%">
              <CoinFlipBetItem2
                flipOptions={[
                  {
                    displayName: this._formatHandicap(
                      this.props.lineData.periods[this.props.betPeriod]?.spreads[spreadID]?.hdp,
                      "away"
                    ),
                    wheelName:
                      this._getLastWord(this.props.lineData.away) +
                      " " +
                      this._formatHandicap(
                        this.props.lineData.periods[this.props.betPeriod]?.spreads[spreadID]?.hdp,
                        "away"
                      ),
                    betSide: "away",
                  },
                  {
                    displayName: this._formatHandicap(
                      this.props.lineData.periods[this.props.betPeriod]?.spreads[spreadID]?.hdp,
                      "home"
                    ),
                    wheelName:
                      this._getLastWord(this.props.lineData.home) +
                      " " +
                      this._formatHandicap(
                        this.props.lineData.periods[this.props.betPeriod]?.spreads[spreadID]?.hdp,
                        "home"
                      ),
                    betSide: "home",
                  },
                ]}
                coinflipJuice={this.props.coinflipJuice}
                betPeriod={this.props.betPeriod}
                bucket={this.props.sportsBucket}
                collection="futures"
                docId={this.props.lineID}
                betType="spread"
                game={this.props.lineData}
              />
            </Grid>
          </Grid>
        );
      }
      return (
        <Grid item container xs={3} direction="column" alignItems="center">
          <Grid xs={12} item width="100%">
            <BetItem2
              isLive={this.props.lineData.event_type === "live"}
              price={this.props.lineData.periods[this.props.betPeriod]?.spreads[spreadID]?.away}
              number={-1 * this.props.lineData.periods[this.props.betPeriod]?.spreads[spreadID]?.hdp}
              betPeriod={this.props.betPeriod}
              rowColor={"one"}
              juice={this.props.juice}
              coinflipJuice={this.props.coinflipJuice}
              label={this._formatHandicap(
                this.props.lineData.periods[this.props.betPeriod]?.spreads[spreadID]?.hdp,
                "away"
              )}
              bucket={this.props.sportsBucket}
              collection="futures"
              docId={this.props.lineID}
              betType="spread"
              betSide="away"
              lineSource={this.props.lineSource}
            />
          </Grid>
          <Grid xs={12} item width="100%">
            <BetItem2
              isLive={this.props.lineData.event_type === "live"}
              price={this.props.lineData.periods[this.props.betPeriod]?.spreads[spreadID]?.home}
              number={this.props.lineData.periods[this.props.betPeriod]?.spreads[spreadID]?.hdp}
              betPeriod={this.props.betPeriod}
              rowColor={"two"}
              juice={this.props.juice}
              coinflipJuice={this.props.coinflipJuice}
              label={this._formatHandicap(
                this.props.lineData.periods[this.props.betPeriod]?.spreads[spreadID]?.hdp,
                "home"
              )}
              bucket={this.props.sportsBucket}
              collection="futures"
              docId={this.props.lineID}
              betType="spread"
              betSide="home"
              lineSource={this.props.lineSource}
            />
          </Grid>
        </Grid>
      );
    }

    if (this.props.coinflip || renderAsCoinflip) {
      return (
        <Grid item container xs={3} direction="column" alignItems="center">
          <Grid item container direction="column" alignItems="center" width="100%">
            <Grid xs={12} item width="100%">
              <CoinFlipBetItem2
                flipOptions={[
                  {
                    displayName: "Off",
                  },
                  {
                    displayName: "Off",
                  },
                ]}
                coinflipJuice={this.props.coinflipJuice}
                disable={true}
                docId={"Off"}
              />
            </Grid>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid item container xs={3} direction="column" alignItems="center">
        <Grid xs={12} item width="100%">
          <BetItem2
            isLive={this.props.lineData.event_type === "live"}
            price={null}
            rowColor={"one"}
            betPeriod={this.props.betPeriod}
            bucket={this.props.sportsBucket}
            collection="futures"
            docId={this.props.lineID}
            betType="spread"
            betSide="home"
            label="Off"
            lineSource={this.props.lineSource}
          />
        </Grid>
        <Grid xs={12} item width="100%">
          <BetItem2
            isLive={this.props.lineData.event_type === "live"}
            price={null}
            rowColor={"two"}
            betPeriod={this.props.betPeriod}
            bucket={this.props.sportsBucket}
            collection="futures"
            docId={this.props.lineID}
            betType="spread"
            betSide="away"
            label="Off"
            lineSource={this.props.lineSource}
          />
        </Grid>
      </Grid>
    );
  };

  _renderOverUnder = (renderAsCoinflip) => {
    const overUnderID = this.props.lineData.periods[this.props.betPeriod]?.totals?.default;
    if (overUnderID) {
      if (this.props.coinflip || renderAsCoinflip) {
        return (
          <Grid
            item
            container
            xs={this.props.options.disableSpreads ? 6 : 3}
            direction="column"
            alignItems="center"
            width="100%"
          >
            <Grid xs={12} item width="100%">
              <CoinFlipBetItem2
                flipOptions={[
                  {
                    displayName: "o" + this.props.lineData.periods[this.props.betPeriod]?.totals[overUnderID]?.points,
                    betSide: "over",
                  },
                  {
                    displayName: "u" + this.props.lineData.periods[this.props.betPeriod]?.totals[overUnderID]?.points,
                    betSide: "under",
                  },
                ]}
                coinflipJuice={this.props.coinflipJuice}
                betPeriod={this.props.betPeriod}
                bucket={this.props.sportsBucket}
                collection="futures"
                docId={this.props.lineID}
                betType="ou"
                game={this.props.lineData}
                lineSource={this.props.lineSource}
              />
            </Grid>
          </Grid>
        );
      }
      return (
        <Grid item container xs={this.props.options.disableSpreads ? 6 : 3} direction="column" alignItems="center">
          <Grid xs={12} item width="100%">
            <BetItem2
              isLive={this.props.lineData.event_type === "live"}
              price={this.props.lineData.periods[this.props.betPeriod]?.totals[overUnderID]?.over}
              number={this.props.lineData.periods[this.props.betPeriod]?.totals[overUnderID]?.points}
              betPeriod={this.props.betPeriod}
              rowColor={"one"}
              juice={this.props.juice}
              coinflipJuice={this.props.coinflipJuice}
              label={"o" + this.props.lineData.periods[this.props.betPeriod]?.totals[overUnderID]?.points}
              bucket={this.props.sportsBucket}
              collection="futures"
              docId={this.props.lineID}
              betType="ou"
              betSide="over"
              lineSource={this.props.lineSource}
            />
          </Grid>
          <Grid xs={12} item width="100%">
            <BetItem2
              isLive={this.props.lineData.event_type === "live"}
              price={this.props.lineData.periods[this.props.betPeriod]?.totals[overUnderID]?.under}
              number={-1 * this.props.lineData.periods[this.props.betPeriod]?.totals[overUnderID]?.points}
              betPeriod={this.props.betPeriod}
              rowColor={"two"}
              juice={this.props.juice}
              coinflipJuice={this.props.coinflipJuice}
              label={"u" + this.props.lineData.periods[this.props.betPeriod]?.totals[overUnderID]?.points}
              bucket={this.props.sportsBucket}
              collection="futures"
              docId={this.props.lineID}
              betType="ou"
              betSide="under"
              lineSource={this.props.lineSource}
            />
          </Grid>
        </Grid>
      );
    }
    if (this.props.coinflip || renderAsCoinflip) {
      return (
        <Grid
          item
          container
          xs={this.props.options.disableSpreads ? 6 : 3}
          direction="column"
          alignItems="center"
          width="100%"
        >
          <Grid xs={12} item width="100%">
            <CoinFlipBetItem2
              flipOptions={[
                {
                  displayName: "Off",
                },
                {
                  displayName: "Off",
                },
              ]}
              coinflipJuice={this.props.coinflipJuice}
              disable={true}
              lineSource={this.props.lineSource}
              docId={"Off"}
            />
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid item container xs={this.props.options.disableSpreads ? 6 : 3} direction="column" alignItems="center">
        <Grid xs={12} item width="100%">
          <BetItem2
            isLive={this.props.lineData.event_type === "live"}
            price={null}
            rowColor={"one"}
            betPeriod={this.props.betPeriod}
            label="Off"
            bucket={this.props.sportsBucket}
            collection="futures"
            docId={this.props.lineID}
            betType="ou"
            betSide="over"
            lineSource={this.props.lineSource}
          />
        </Grid>
        <Grid xs={12} item width="100%">
          <BetItem2
            isLive={this.props.lineData.event_type === "live"}
            price={null}
            rowColor={"two"}
            betPeriod={this.props.betPeriod}
            label="Off"
            bucket={this.props.sportsBucket}
            collection="futures"
            docId={this.props.lineID}
            betType="ou"
            betSide="under"
            lineSource={this.props.lineSource}
          />
        </Grid>
      </Grid>
    );
  };

  _renderPeriod = (renderAsCoinflip) => {
    return (
      <Grid container item xs={12} direction="row" alignItems="center" justifyContent="space-evenly" spacing={0}>
        {this._renderMoneyLine(renderAsCoinflip)}
        {!this.props.options.disableSpreads &&
          !this.props.options.onlyMoneyline &&
          this._renderSpreads(renderAsCoinflip)}
        {!this.props.options.onlyMoneyline && this._renderOverUnder(renderAsCoinflip)}
      </Grid>
    );
  };

  render() {
    // console.log("!!! eph Line", this.props.liveWhitelist?.teams);
    const minutesSinceStart = (Date.now() - this.props.lineData?.startsMS) / 1000 / 60;
    const whitelist = this.props.liveWhitelist?.teams;
    // if (this.props.lineData?.home.includes(this.props.liveWhitelist?.teams[0])) {
    //     console.log("nova game minutes since start: ",minutesSinceStart)
    // }
    if (!this.props.lineData || this.props.lineData?.category === "Futures") {
      return null;
    }
    if (this.props.lineData?.event_type === "live" && this.props.lineData?.periods?.num_0?.period_status === 2) {
      return null;
    }
    if (
      this.props.lineData?.home.includes(" Runs ") ||
      this.props.lineData?.away.includes(" Runs ") ||
      this.props.lineData?.home.includes(" Goals ") ||
      this.props.lineData?.away.includes(" Goals ")
    ) {
      return null;
    }
    if (
      this.props.searchTerm.length > 1 &&
      !this.props.lineData?.home.toLowerCase().includes(this.props.searchTerm) &&
      !this.props.lineData?.away.toLowerCase().includes(this.props.searchTerm)
    ) {
      return null;
    }
    if (this.props.lineData?.home.includes("(To Qualify)") || this.props.lineData?.away.includes("(To Qualify)")) {
      return null;
    }
    let renderAsCoinflip = false;
    if (this.props.lineData?.event_type === "live" && !this.props.coinflip) {
      // console.log("Allow? ", this.props.lineData)
      let allow = false;
      if (whitelist) {
        whitelist.forEach((i) => {
          if (this.props.lineData.home.includes(i)) {
            allow = true;
          }
        });
      }
      if (allow) console.log("ALLOW DUE TO WHITELIST");
      else {
        renderAsCoinflip = true;
      }
    }
    const oldLineSelector =
      (((this.props.betPeriod === "num_0" || this.props.betPeriod === "num_1") &&
        this.props.lineData.periods[this.props.betPeriod]?.cutoffMs > Date.now()) ||
        this.props.betPeriod === "num_2") &&
      (this.props.lineData?.periods[this.props.betPeriod]?.money_line ||
        this.props.lineData?.periods[this.props.betPeriod]?.spreads ||
        this.props.lineData?.periods[this.props.betPeriod]?.totals);

    const newLineSelector = true;

    if (
      (this.props.lineSource === "lines_standardized" && newLineSelector) ||
      (this.props.lineSource !== "lines_standardized" && oldLineSelector)
    ) {
      return (
        <Grid item xs={12} key={this.props.lineData?.event_id} width="100%">
          {/*<Paper*/}
          {/*  // className={classes.coinflipCard}*/}
          {/*  elevation={0}*/}
          {/*  sx={{ paddingBottom: 1, background: "#ffffff" }}*/}
          {/*>*/}
          {this._renderPeriod(renderAsCoinflip)}
          <Grid container justifyContent="space-between">
            <Grid sx={{ padding: 0, paddingLeft: 3, paddingTop: 0.5 }}>
              {renderGametime(this.props.lineData.startsMS, this.props.lineData.event_type)}
            </Grid>
            {(this.props.email?.includes("brian.danilo") || this.props.email?.includes("ephlind")) && (
              <Grid>
                <small
                  style={{
                    fontSize: ".65rem",
                    textAlign: "left",
                    float: "left",
                    color: "#888888",
                    marginRight: 3,
                    padding: 5,
                  }}
                >
                  {this.props.lineID} - {this.props.lineData?.periods?.num_0?.period_status}
                </small>
              </Grid>
            )}
          </Grid>
          {/*</Paper>*/}
        </Grid>
      );
    }

    return null;
  }
}

Line.defaultProps = {
  isLive: false,
  options: {},
};

Line.propTypes = {
  isLive: PropTypes.bool.isRequired,
  lineID: PropTypes.string.isRequired,
  lineData: PropTypes.object.isRequired,
  liveWhitelist: PropTypes.object,
  betPeriod: PropTypes.string.isRequired,
  sportsBucket: PropTypes.string.isRequired,
  juice: PropTypes.number.isRequired,
  coinflipJuice: PropTypes.number.isRequired,
  searchTerm: PropTypes.string,
  email: PropTypes.string,
  coinflip: PropTypes.bool.isRequired,
  options: PropTypes.object.isRequired,
  lineSource: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    email: state.firebase.auth.email,
  };
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
});

export default connect(mapStateToProps)(withStyles(styles)(Line));
