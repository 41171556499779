import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import { Grid, Typography } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import Divider from "@mui/material/Divider";
import moment from "moment";
import GenericBackHeader from "../../components/GenericBackHeader";
import { getTimestamp } from "../../reducers/sportsbook-reducer";

class Transactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLongSummary: false,
    };
  }

  renderTransStatus(t) {
    let timestamp = moment(t.pendingTimestamp).format("MMM DD h:mm a");
    if (t.state === "pending") return t.state + " --- " + timestamp;
    else if (t.state === "approved" || t.state === "denied") return t.state + " --- " + timestamp;
    else return "";
  }

  _formatTrans = (amount, type, state) => {
    if ((state === "approved" && type === "deposit") || type === "bonus") {
      return (
        <Typography variant="subtitle2" style={{ color: "green" }}>
          {" "}
          {amount} credit {type} {state}{" "}
        </Typography>
      );
    } else if ((state === "withdrawal" && type === "approved") || type === "correction") {
      return (
        <Typography variant="subtitle2" style={{ color: "red" }}>
          {" "}
          {amount} {type} {state}{" "}
        </Typography>
      );
    } else {
      return (
        <Typography variant="subtitle2">
          {" "}
          {amount} {type} {state}{" "}
        </Typography>
      );
    }
  };

  _cleanUpTimestamp = (t) => {
    // this is to handle old ways of naming timestamps
    if (t.timestamp) {
      return getTimestamp(t.timestamp);
    } else if (t.awardedTimestamp) {
      return getTimestamp(t.awardedTimestamp);
    } else if (t.pendingTimestamp) {
      return getTimestamp(t.pendingTimestamp);
    } else return "";
  };

  renderTransactionList() {
    let T = this.props.trans;
    return Object.keys(T)
      .sort((x, y) => (T[x].timestamp < T[y].timestamp ? 1 : -1))
      .map((i) => (
        <>
          <ListItem key={i}>
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                {this._formatTrans(T[i].amount, T[i].type, T[i].state)}
              </Grid>
              <Grid item xs={12}>
                <Typography variant={"subtitle2"}>{this._cleanUpTimestamp(T[i])}</Typography>
              </Grid>
            </Grid>
          </ListItem>
          <Divider />
        </>
      ));
  }

  render() {
    console.log("Transactions this.props: ", this.props);
    console.log("Transactions this.state: ", this.state);

    return (
      <>
        <GenericBackHeader title="Deposit/Withdraw" showBackButton />
        {this.props.trans && this.renderTransactionList()}
      </>
    );
  }
}

Transactions.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  trans: PropTypes.object,
  bets: PropTypes.object,
  email: PropTypes.string,
  uid: PropTypes.string,
  profile: PropTypes.object,
  currentGroup: PropTypes.string,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  pastBetsList: {
    width: "100%",
  },
  table: {
    minWidth: 650,
  },
});

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    email: state.firebase.auth.email,
    profile: state.firebase.profile,
    trans: state.firestore.data?.userGroupTransactions,
    currentGroup: state.firestore.data?.currentGroup,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: `groups/${props.profile.gid}/transactions/`,
        where: [["uid", "==", props.uid ? props.uid : false]],
        storeAs: "userGroupTransactions",
      },
      {
        collection: "groups",
        doc: props.profile.gid,
        storeAs: "currentGroup",
      },
    ];
  })
)(withStyles(styles)(Transactions));
