import React, { Component } from "react";
import { Box } from "@mui/material";
import gb from "../../../../images/glen.jpg";

class GB extends Component {
  render() {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" minHeight={64}>
        <img src={gb} alt="glen" />
      </Box>
    );
  }
}

GB.propTypes = {};

export default GB;
