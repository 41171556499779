import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import GenericBackHeader from "../../components/GenericBackHeader";
import { BottomNav } from "../../components";
import Box from "@mui/material/Box";

class UserAccountSummaryShort extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showLongSummary: false,
    };
  }

  //
  // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

  calcBalance = () => {};

  renderUserAccountSummary() {
    let s = this.props.summary;

    // let newUserBonus = 0;
    // if (this.props.profile.gid == "uU5VEX6AHwMqhdMDqOho")
    //   newUserBonus = 1000;

    return (
      <TableContainer>
        <Table aria-label="simple table">
          <TableBody>
            <TableRow key={"bet"}>
              <TableCell style={{ borderBottom: "none" }} component="th" scope="row">
                Lifetime Win/Loss{" "}
              </TableCell>
              <TableCell style={{ borderBottom: "none" }} align="right" component="th" scope="row">
                {Math.round((s.totalWin || 0) - (s.totalLoss || 0))}
              </TableCell>
            </TableRow>
            <TableRow key={"bet"}>
              <TableCell style={{ borderBottom: "none" }} component="th" scope="row">
                Deposits + Bonuses{" "}
              </TableCell>
              <TableCell style={{ borderBottom: "none" }} align="right" component="th" scope="row">
                {Math.round((s.totalDepositAmount || 0) + (s.totalBonusAmt || 0))}
              </TableCell>
            </TableRow>
            <TableRow key={"bet"}>
              <TableCell style={{ borderBottom: "none" }} component="th" scope="row">
                Pending Risk
              </TableCell>
              <TableCell style={{ borderBottom: "none" }} align="right" component="th" scope="row">
                {Math.round(s.totalPendingRisk || 0)}
              </TableCell>
            </TableRow>
            <TableRow key={"bet"}>
              <TableCell component="th" scope="row">
                Withdrawals
              </TableCell>
              <TableCell align="right" component="th" scope="row">
                {Math.round((s.totalWithdrawalAmt || 0) + (s.pendingWithdrawalAmt || 0) + (s.totalSeizureAmt || 0))}
              </TableCell>
            </TableRow>
            <TableRow key={"bet"}>
              <TableCell style={{ borderBottom: "none" }} component="th" scope="row">
                Balance
              </TableCell>
              <TableCell style={{ borderBottom: "none" }} align="right" component="th" scope="row">
                {Math.round(
                  (s.totalWin || 0) -
                    (s.totalLoss || 0) +
                    (s.totalDepositAmount || 0) +
                    (s.totalBonusAmt || 0) -
                    (s.totalPendingRisk || 0) -
                    (s.totalWithdrawalAmt || 0) -
                    (s.pendingWithdrawalAmt || 0) +
                    (s.totalCorrectionAmt || 0)
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  render() {
    console.log("userAccountSummaryShortTTT this.props: ", this.props);
    console.log("userAccountSummaryShortTTT this.state: ", this.state);

    return (
      <>
        <Box>
          <GenericBackHeader title="Account Summary" showBackButton />
          {this.props.summary && this.renderUserAccountSummary()}
        </Box>
        <BottomNav activeTab={2} managerGid={this.props.profile.managerGid} />
      </>
    );
  }
}

UserAccountSummaryShort.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  trans: PropTypes.object,
  bets: PropTypes.object,
  summary: PropTypes.object,
  profile: PropTypes.object,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  pastBetsList: {
    width: "100%",
  },
  table: {
    minWidth: 650,
  },
});

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    email: state.firebase.auth.email,
    profile: state.firebase.profile,
    summary: state.firestore.data?.summary,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    console.log("---------------firestore connecting");
    console.log("---------------USer Account Summary - COMPOSE connect props", props);

    return [
      {
        collection: `groups/${props.profile.gid}/balances/`,
        doc: `${props.uid}`,
        storeAs: "summary",
      },
    ];
  })
)(withStyles(styles)(UserAccountSummaryShort));
