import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { firestoreConnect } from "react-redux-firebase";
import GenericBackHeader from "../../../../components/GenericBackHeader";
import BottomNav from "../../../../components/BottomNav";
import ReferralLink from "../../../../components/ReferralLink";
import collect from "collect.js";

class UserSummary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showUserTool: false,
    };
  }

  renderNetWin = (win, loss) => {
    let W = win || 0;
    let L = loss || 0;
    if (W - L >= 0) {
      return `${Math.round(W - L)}`;
    } else return `(${Math.round(W - L)})`;
  };

  renderUserSummary() {
    let s = this.props.balances;

    let b = collect(s)
      .filter((i) => {
        return i.balance >= 0;
      })
      .map((i, j) => {
        console.log("mapping i: ", i, " j: ", j);
        return {
          balance: i.balance || 0,
          email: this.props.uid === j ? "My Demo Account" : i.email,
          losingTickets: i.losingTickets || [],
          pendingBetCount: i.pendingBetCount || 0,
          pendingDepositAmt: i.pendingDepositAmt || 0,
          pendingDepositCount: i.pendingDepositCount || 0,
          pendingWithdrawalAmt: i.pendingWithdrawalAmt || 0,
          pendingWithdrawalCount: i.pendingWithdrawalCount || 0,
          totalBets: i.totalBets || 0,
          totalBonusAmt: i.totalBonusAmt || 0,
          totalBonusCount: i.totalBonusCount || 0,
          totalCorrectionAmt: i.totalCorrectionAmt || 0,
          totalSeizureAmt: i.totalSeizureAmt || 0,
          totalDepositAmount: i.totalDepositAmount || 0,
          totalDepositCount: i.totalDepositCount || 0,
          totalFreeBetWin: i.totalFreeBetWin || 0,
          totalLoss: i.totalLoss || 0,
          totalPendingRisk: i.totalPendingRisk || 0,
          totalPendingWin: i.totalPendingWin || 0,
          totalWager: i.totalWager || 0,
          totalWin: i.totalWin || 0,
          totalWithdrawalAmt: i.totalWithdrawalAmt || 0,
          totalWithdrawalCount: i.totalWithdrawalCount || 0,
          username: i.username || "",
          weeklyLosses: i.weeklyLosses || 0,
          weeklyWins: i.weeklyWins || 0,
          winningTickets: i.winningTickets || [],
        };
      });

    let cleanUserData = b.values().items;

    return cleanUserData
      .sort((x, y) => (x.balance < y.balance ? 1 : -1))
      .map((i, k) => {
        console.log("I: ", i, "k: ", k);
        return (
          <TableContainer key={i.email}>
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={2}>
                    {i.email}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell style={{ borderBottom: "none" }} component="th" scope="row">
                    Balance
                  </TableCell>
                  <TableCell style={{ borderBottom: "none" }} align="right" component="th" scope="row">
                    {Math.round(i.balance) || 0}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ borderBottom: "none" }} component="th" scope="row">
                    Total Wager Amt
                  </TableCell>
                  <TableCell style={{ borderBottom: "none" }} align="right" component="th" scope="row">
                    {Math.round(i.totalWager) || 0}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ borderBottom: "none" }} component="th" scope="row">
                    Past Bet Count
                  </TableCell>
                  <TableCell style={{ borderBottom: "none" }} align="right" component="th" scope="row">
                    {i.totalBets || 0}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ borderBottom: "none" }} component="th" scope="row">
                    Total Deposit Amt
                  </TableCell>
                  <TableCell style={{ borderBottom: "none" }} align="right" component="th" scope="row">
                    {Math.round(i.totalDepositAmount) || 0}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ borderBottom: "none" }} component="th" scope="row">
                    Total Withdrawal + Seizure Amt
                  </TableCell>
                  <TableCell style={{ borderBottom: "none" }} align="right" component="th" scope="row">
                    {Math.round(i.totalWithdrawalAmt + i.totalSeizureAmt) || 0}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ borderBottom: "none" }} component="th" scope="row">
                    Total Bonus Amt
                  </TableCell>
                  <TableCell style={{ borderBottom: "none" }} align="right" component="th" scope="row">
                    {Math.round(i.totalBonusAmt) || 0}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ borderBottom: "none" }} component="th" scope="row">
                    Net Win (Loss)
                  </TableCell>
                  <TableCell style={{ borderBottom: "none" }} align="right" component="th" scope="row">
                    {this.renderNetWin(i.totalWin, i.totalLoss)}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell style={{ borderBottom: "none" }} component="th" scope="row">
                    Pending Bet Count
                  </TableCell>
                  <TableCell style={{ borderBottom: "none" }} align="right" component="th" scope="row">
                    {i.pendingBetCount || 0}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell component="th" scope="row">
                    Pending Risk
                  </TableCell>
                  <TableCell align="right" component="th" scope="row">
                    {i.totalPendingRisk || 0}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        );
      });
  }

  render() {
    console.log("11USER SUMMARY this.props: ", this.props);
    console.log("USER SUMMARY this.state: ", this.state);

    return (
      <>
        <GenericBackHeader title="User Summary" showBackButton />
        {this.props.balances && this.renderUserSummary()}
        {!this.props.balances && this.props.groupConfigs && <ReferralLink message={"You have no users."} />}
        <BottomNav isBookieMode activeTab={3} managerGid={this.props.profile.managerGid} />
      </>
    );
  }
}

UserSummary.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  trans: PropTypes.object,
  bets: PropTypes.object,
  pendingBets: PropTypes.object,
  balances: PropTypes.object,
  groupConfigs: PropTypes.object,
  profile: PropTypes.object,
  uid: PropTypes.string,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  pastBetsList: {
    width: "100%",
  },
  table: {
    minWidth: 650,
  },
});

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    email: state.firebase.auth.email,
    profile: state.firebase.profile,
    balances: state.firestore.data?.userSummaryBalances,
    groupConfigs: state.firestore.data?.userSummary_groupConfigs,
  };
};

export default compose(
  connect(mapStateToProps),
  withRouter,
  firestoreConnect((props) => {
    return [
      {
        collection: `groups/${props.profile.managerGid}/balances`,
        storeAs: "userSummaryBalances",
      },
      {
        collection: `groups`,
        doc: props.profile.managerGid,
        storeAs: "userSummary_groupConfigs",
      },
    ];
  })
)(withStyles(styles)(UserSummary));
//
// renderUserPnL(user){
//   console.log("rendering pnl for user: ",user)
//   let b = this.props.bets
//   let wagerCount = 0; let winAmt = 0; let lossAmt = 0;
//   Object.keys(b).forEach((i) => {
//     if (i.email === user && i.result !== "pending"){
//       wagerCount ++;
//       if (i.result === "win") winAmt += i.win;
//       if (i.result === "loss") lossAmt += i.risk;
//     }
//   })
//   return <>Net Win/Loss: {(winAmt - lossAmt).toFixed(2)}.  <br/> Total wagers: {wagerCount}.  Total win: {winAmt.toFixed(2)}.  Total loss: {lossAmt.toFixed(2)}.</>
// }
//
// renderUserPendingBetSummary(user){
//   let b = this.props.bets
//   let pendingWagerCount = 0; let pendingWagerRisk = 0; let pendingWagerWin = 0;
//   Object.keys(b).forEach((i) => {
//     if (i.email === user && i.result === "pending"){
//       pendingWagerCount ++;
//       pendingWagerRisk += i.risk;
//       pendingWagerWin += i.win
//     }
//   })
//   return <>{pendingWagerCount} pending wagers risking {pendingWagerRisk.toFixed(2)} to win {pendingWagerWin.toFixed(2)}</>
// }
//
// renderUserPendingTransSummary(user){
//   let t = this.props.trans
//   let pendingDepositCount = 0; let pendingWithdrawalCount = 0;
//   let totalWithdrawals = 0; let totalDeposits = 0;
//   Object.keys(t).forEach((i) => {
//     if (t[i].email === user && t[i].type === "deposit" && t[i].state === "pending") pendingDepositCount ++;
//     if (t[i].email === user && t[i].type === "withdrawal" && t[i].state === "pending") pendingWithdrawalCount ++;
//     if (t[i].email === user && t[i].type === "deposit" && t[i].state === "approved") totalDeposits += t[i].amount;
//     if (t[i].email === user && t[i].type === "withdrawal" && t[i].state === "approved") totalWithdrawals += t[i].amount;
//   })
//   return <>
//     Total Deposits: {totalDeposits}.  Total Withdrawals: {totalWithdrawals} <br/>
//     {pendingDepositCount} pending deposits and {pendingWithdrawalCount} pending withdrawals</>
// }
