import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import BottomNav from "../../components/BottomNav";
import Box from "@mui/material/Box";
import { Alert, ListItemButton, ListItemSecondaryAction, ListItemText, Snackbar } from "@mui/material";
import List from "@mui/material/List";
import { ChevronRight } from "@mui/icons-material";
import { withRouter } from "react-router-dom";
import collect from "collect.js";

import GenericBackHeader from "../../components/GenericBackHeader";

class BookHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSignup: true,
      amount: 0,
      type: 0,
      bonusOpen: false,
    };
  }

  getPendingBetInfo() {
    // if (this.props.adminPendingBets && !this.props.profile.showNewLines) {
    //   let b = this.props.adminPendingBets
    //   Object.keys(b).filter(i => b[i])
    //   let coll = collect(b).filter(i => i).values()
    //   let count = coll.count()
    //   let risk = coll.sum('risk')
    //   let win = coll.sum('win')
    //   return `${count} bets risking ${risk} to win ${win}`
    // } else
    //
    if (this.props.bookHistoryPendingBets) {
      let b = this.props.bookHistoryPendingBets;
      Object.keys(b).filter((i) => b[i]);
      let coll = collect(b)
        .filter((i) => i)
        .values();
      let count = coll.count();
      let risk = coll.sum("risk");
      let win = coll.sum("win");
      return `${count} bets risking ${risk} to win ${win}`;
    } else {
      return "no pending bets";
    }
  }

  getPastBetInfo() {
    // if (this.props.adminPastBets && !this.props.profile.showNewLines) {
    //   let b = this.props.adminPastBets
    //   Object.keys(b).filter(i => b[i])
    //   let coll = collect(b).filter(i => i).values()
    //   let count = coll.count()
    //   let bettors = coll.countBy('uid').count()
    //   return `${count} bets by ${bettors} bettors`
    // } else
    if (this.props.bookHistoryPastBets) {
      let b = this.props.bookHistoryPastBets;
      Object.keys(b).filter((i) => b[i]);
      let coll = collect(b)
        .filter((i) => i)
        .values();
      let count = coll.count();
      let bettors = coll.countBy("uid").count();
      return `${count} bets by ${bettors} bettors`;
    } else {
      return "no past bets";
    }
  }

  getTransCount = () => {
    if (this.props.balanceDoc) {
      let B = this.props.balanceDoc;
      let totalPendingDeposits = Object.keys(B).reduce((a, b) => {
        return a + (B[b].pendingDepositCount || 0);
      }, 0);
      let totalPendingWithdrawals = Object.keys(B).reduce((a, b) => {
        return a + (B[b].pendingWithdrawalCount || 0);
      }, 0);
      return <>{totalPendingDeposits + totalPendingWithdrawals} pending transactions</>;
    } else {
      return "view old transactions";
    }
  };

  renderNewAdmin() {
    console.log("bookie / book-history: ", this.props);

    return (
      <>
        <Box>
          <GenericBackHeader title="Bets and BookieTransactions" showBackButton />
          <List>
            <ListItemButton
              divider
              onClick={() => {
                this.props.history.push("/bookie/bookwide-pending-bets");
              }}
            >
              <ListItemText primary="Pending Bets" secondary={this.getPendingBetInfo()} />
              <ListItemSecondaryAction>
                <ChevronRight />
              </ListItemSecondaryAction>
            </ListItemButton>

            <ListItemButton
              divider
              onClick={() => {
                this.props.history.push("/bookie/bookwide-past-bets");
              }}
            >
              <ListItemText primary="Past Bets" secondary={this.getPastBetInfo()} />
              <ListItemSecondaryAction>
                <ChevronRight />
              </ListItemSecondaryAction>
            </ListItemButton>

            <ListItemButton
              divider
              onClick={() => {
                this.props.history.push("/admin/transactions");
              }}
            >
              <ListItemText primary="BookieTransactions" secondary={this.getTransCount()} />
              <ListItemSecondaryAction>
                <ChevronRight />
              </ListItemSecondaryAction>
            </ListItemButton>
          </List>

          <Snackbar
            open={this.state.copySnackBarOpen}
            onClose={() => this.setState({ copySnackBarOpen: false })}
            autoHideDuration={6000}
            message="Link Copied"
            severity="info"
            sx={{ bottom: { xs: 75, sm: 0 } }}
          >
            <Alert onClose={() => this.setState({ copySnackBarOpen: false })} severity="success" sx={{ width: "100%" }}>
              Link Copied
            </Alert>
          </Snackbar>
        </Box>
        <BottomNav isBookieMode activeTab={3} managerGid={this.props.profile.managerGid} />
      </>
    );
  }

  render() {
    console.log("admin this.props: ", this.props);
    console.log("admin this.state: ", this.state);
    return <>{this.renderNewAdmin()}</>;
  }
}

BookHistory.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  email: PropTypes.string,
  adminPastBets: PropTypes.object,
  adminPendingBets: PropTypes.object,
  bookHistoryPastBets: PropTypes.object,
  bookHistoryPendingBets: PropTypes.object,
  profile: PropTypes.object,
  history: PropTypes.array,
  showSportsBookSignup: PropTypes.bool,
  auth: PropTypes.object,
  uid: PropTypes.string,
  balanceDoc: PropTypes.object,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: "inline-block",
    marginTop: "20px",
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  grid: {
    textAlign: "center",
    marginBottom: "20px",
  },
  dialogTitle: {
    color: theme.palette.primary.dark,
    paddingBottom: 0,
  },
  dialogSubtitle: {
    paddingTop: 10,
    color: theme.palette.primary.light,
  },
});

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    email: state.firebase.auth.email,
    profile: state.firebase.profile,
    adminPastBets: state.firestore.data?.adminPastBets,
    adminPendingBets: state.firestore.data?.adminPendingBets,
    bookHistoryPendingBets: state.firestore.data?.bookHistoryPendingBets,
    bookHistoryPastBets: state.firestore.data?.bookHistoryPastBets,
    balanceDoc: state.firestore.data?.bookHistory_balances,
  };
};

export default compose(
  connect(mapStateToProps),
  withRouter,
  firestoreConnect((props) => {
    return [
      {
        collection: `groups/${props.profile.managerGid}/bets`,
        where: [["result", "!=", "pending"]],
        storeAs: "adminPastBets",
      },
      {
        collection: `groups/${props.profile.managerGid}/bets2`,
        where: [["result", "!=", "pending"]],
        storeAs: "bookHistoryPastBets",
      },
      {
        collection: `groups/${props.profile.managerGid}/bets2`,
        where: [["result", "==", "pending"]],
        storeAs: "bookHistoryPendingBets",
      },
      {
        collection: `groups/${props.profile.managerGid}/bets`,
        where: [["result", "==", "pending"]],
        storeAs: "adminPendingBets",
      },
      {
        collection: `groups/${props.profile.managerGid}/balances`,
        storeAs: "bookHistory_balances",
      },
    ];
  })
)(withStyles(styles)(BookHistory));

/*
{this.props.pastTransactions && <UserTool bets={this.props.pastBets} trans={this.props.pastTransactions} balances={this.props.balances} />}


        {this.props.adminViewBets && this.props.adminViewTransactions && <GroupPastBets allBets={this.props.adminViewBets} users={this.props.users} />}
        {this.props.adminViewBets && this.props.adminViewTransactions && <UserTool bets={this.props.adminViewBets} trans={this.props.adminViewTransactions} users={this.props.users} />}
        {this.props.group && this.props.profile.groupCreated && <GroupSettings group={this.props.group} groupCreated={this.props.profile.groupCreated} />}
        {(this.props.profile.email === 'brian.danilo@gmail.com' || this.props.profile.email === 'ephlind@gmail.com') && <GlobalPendingBets allBets={this.props.godViewBets}/>}
 */
