import React from "react";
import PropTypes from "prop-types";
import Dialog from "@mui/material/Dialog";
import { DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { ordinal_suffix_of } from "../../../utilities/pickemUtils.js";

const PrizesModal = (props) => {
  return (
    <Dialog
      open={props.open}
      onClose={props.closeHandler}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Prizes</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {props.prizes && (
            <Grid container margin={2} padding={1} spacing={1}>
              {props.prizes.map((prize) => (
                <Grid key={prize.id} item xs={12}>
                  <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                    <Grid item xs={6} sm={3} md={2}>
                      <Typography component="span" variant="body1" color="text.primary">
                        {ordinal_suffix_of(prize.id)} Place:
                      </Typography>
                    </Grid>
                    <Grid item xs={6} sm={3} md={2}>
                      <Typography component="span" variant="body1" color="text.primary">
                        {prize.prize}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.closeHandler} autoFocus variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

PrizesModal.propTypes = {
  open: PropTypes.bool.isRequired,
  closeHandler: PropTypes.func.isRequired,
  prizes: PropTypes.array.isRequired,
};

export default PrizesModal;
