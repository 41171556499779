import { Container, Divider, Grid } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { firestoreConnect, getFirebase } from "react-redux-firebase";
import { compose } from "redux";
import CasinoIcon from "@mui/icons-material/Casino";
import { Header } from "../../components";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import collect from "collect.js";
import { withRouter } from "react-router-dom";
import BetList2 from "../../components/BetDisplay/BetList2";
import SuperAdminHeader from "./components/SuperAdminHeader";

class SuperAdminBookSummaries extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderGroupName = (gid) => {
    // let name = gid;
    // if (this.props.groups[gid]?.managerEmail) name = this.props.groups[gid]?.managerEmail;
    // if (gid === "uU5VEX6AHwMqhdMDqOho") name = "Just For Fun";
    // if (gid === "o7kMgi7LQg2B1bcLcNMH") name = "Layoff Book";
    // if (gid === "iDlxBCFgmTPVYuYVTZuO") name = "Eddie";
    // if (gid === "WKgL0pPUMdx62VhPMHDj") name = "Josh";

    return this.props.groups[gid]?.groupName
    // return name;
  };

  _changeGID = (gid) => {
    getFirebase()
      .firestore()
      .collection("users")
      .doc(this.props.uid)
      .update({ gid, managerGid: gid }, { merge: true })
      .then(() => {
        this.props.history.push("/bookie");
      });
  };

  renderTable = () => {
    let betArray = [];
    Object.entries(this.props.bets).forEach((key) => {
      betArray.push(key[1]);
    });

    let allBetsClean = collect(betArray);
    let filteredAllBetsClean = collect(allBetsClean)
      .filter(
        (i) =>
          !(
            (i.email?.includes("brian.danilo") &&
              i.gid !== "WKgL0pPUMdx62VhPMHDj" &&
              i.email !== "brian.danilo+nojuice@gmail.com") ||
            i.email?.includes("ephlind") ||
            i.email?.includes("asfd") ||
            i.risk === 0 ||
            i.gid === "DmLWVwO1cry9aFLaLn03" ||
            i.gid === "mS9AtMd8HZa87deikWd4" ||
            i.gid === "hNvS8pQgul0AG1lJme0T" ||
            i.gid === "43wbEaCEcBHwGIA1U9vB" ||
            i.gid === "k9qXYspRJJy2tgv6KUh9" ||
            i.gid === "iyBhwxpqZxF5C5tUve1r" ||
            i.gid === "0Q4Z3z6kz5AFJ1lxTo27" ||
            i.gid === "q80aAj9v8t4F163yTa8R"
          )
      )
      .groupBy("gid");

    let dataForTable = [];

    filteredAllBetsClean.each((i, v) => {
      console.log("i, v", i, v);
      dataForTable.push({
        email: "fc",
        // email:  this.props.groups[v].managerEmail,
        gid: i.items[0]?.gid,
        bettors: collect(i).unique("uid").count(),
        pendingBets: collect(i)
          .filter((i) => i.result === "pending")
          .count(),
        pendingRisk: Math.round(
          collect(i)
            .filter((i) => i.result === "pending")
            .reduce((x, y) => x + y.risk)
        ),
        pendingWin: Math.round(
          collect(i)
            .filter((i) => i.result === "pending")
            .reduce((x, y) => x + y.win)
        ),
        totalWager: Math.round(
          collect(i)
            .filter((i) => i.result !== "pending")
            .reduce((x, y) => x + y.risk)
        ),
        pnl: Math.round(
          collect(i)
            .filter((i) => i.result === "loss")
            .reduce((x, y) => x + y.risk) -
            collect(i)
              .filter((i) => i.result === "win")
              .reduce((x, y) => x + y.win)
        ),
      });
    });
    //
    // console.log("df: ", filteredAllBetsClean);
    // console.log("df: ", filteredAllBetsClean);
    // console.log("data for table: ", dataForTable);

    return (
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Book</TableCell>
              <TableCell align="left">Bettors</TableCell>
              <TableCell align="left">Pending Bets</TableCell>
              <TableCell align="left">Pending Risk/Win</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataForTable.map((v, i, arr) => {
              return (
                <TableRow key={i}>
                  <TableCell
                    onClick={() => this._changeGID(v.gid)}
                    style={{ borderBottom: "none" }}
                    component="th"
                    scope="row"
                  >
                    {this.renderGroupName(v.gid)}
                  </TableCell>
                  <TableCell style={{ borderBottom: "none" }} component="th" scope="row">
                    {v.bettors}
                  </TableCell>
                  <TableCell style={{ borderBottom: "none" }} component="th" scope="row">
                    {v.pendingBets}
                  </TableCell>
                  <TableCell style={{ borderBottom: "none" }} component="th" scope="row">
                    {v.pendingRisk} / {v.pendingWin}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  renderTable2 = () => {
    let betArray = [];
    Object.entries(this.props.bets2).forEach((key) => {
      betArray.push(key[1]);
    });

    let allBetsClean = collect(betArray);
    let filteredAllBetsClean = collect(allBetsClean)
      .filter((i) => i.email !== "brian.danilo+convdddertddaaAnodn23f@gmail.com")
      .filter(
        (i) =>
          !(
            // (i.email.includes("brian.danilo") && i.gid !== "WKgL0pPUMdx62VhPMHDj") && i.email !== "brian.danilo+nojuice@gmail.com" ||
            // i.email.includes("ephlind") ||
            (
              // i.email.includes("asfd") ||
              i.risk === 0 ||
              i.gid === "DmLWVwO1cry9aFLaLn03" ||
              i.gid === "mS9AtMd8HZa87deikWd4" ||
              i.gid === "hNvS8pQgul0AG1lJme0T" ||
              i.gid === "43wbEaCEcBHwGIA1U9vB" ||
              i.gid === "k9qXYspRJJy2tgv6KUh9" ||
              i.gid === "iyBhwxpqZxF5C5tUve1r" ||
              i.gid === "0Q4Z3z6kz5AFJ1lxTo27" ||
              i.gid === "q80aAj9v8t4F163yTa8R"
            )
          )
      )
      .groupBy("gid");

    let dataForTable = [];



    filteredAllBetsClean.each((i, v) => {
      // console.log("i, v", i, v);
      dataForTable.push({
        email: "fc",
        // email:  this.props.groups[v].managerEmail,
        gid: i.items[0]?.gid,
        bettors: collect(i).unique("uid").count(),
        pendingBets: collect(i)
          .filter((i) => i.result === "pending")
          .count(),
        pendingRisk: Math.round(
          collect(i)
            .filter((i) => i.result === "pending")
            .reduce((x, y) => x + y.risk)
        ),
        pendingWin: Math.round(
          collect(i)
            .filter((i) => i.result === "pending")
            .reduce((x, y) => x + y.win)
        ),
        totalWager: Math.round(
          collect(i)
            .filter((i) => i.result !== "pending")
            .reduce((x, y) => x + y.risk)
        ),
        pnl: Math.round(
          collect(i)
            .filter((i) => i.result === "loss")
            .reduce((x, y) => x + y.risk) -
            collect(i)
              .filter((i) => i.result === "win")
              .reduce((x, y) => x + y.win)
        ),
      });
    });

    // console.log("df: ", filteredAllBetsClean);
    // console.log("df: ", filteredAllBetsClean);
    // console.log("data for table: ", dataForTable);

    return (
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Book</TableCell>
              <TableCell align="left">Bettors</TableCell>
              <TableCell align="left">Total Wager</TableCell>
              <TableCell align="left">Pending Bets</TableCell>
              <TableCell align="left">Pending Risk/Win</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dataForTable
                .sort((x,y) => {
                  if (x.bettors > y.bettors)
                    return -1
                  if (x.bettors < y.bettors)
                    return 1
                  if (x.pendingBets > y.pendingBets)
                    return -1
                  if (x.pendingBets < y.pendingBets)
                    return 1
                  if (x.totalWager > y.totalWager)
                    return -1
                  if (x.totalWager < y.totalWager)
                    return 1
                })
                .map((v, i, arr) => {
              return (
                <TableRow key={i}>
                  <TableCell
                    onClick={() => this._changeGID(v.gid)}
                    style={{ borderBottom: "none" }}
                    component="th"
                    scope="row"
                  >
                    {this.renderGroupName(v.gid)}
                  </TableCell>
                  <TableCell style={{ borderBottom: "none" }} component="th" scope="row">
                    {v.bettors}
                  </TableCell>
                  <TableCell style={{ borderBottom: "none" }} component="th" scope="row">
                    {v.totalWager}
                  </TableCell>
                  <TableCell style={{ borderBottom: "none" }} component="th" scope="row">
                    {v.pendingBets}
                  </TableCell>
                  <TableCell style={{ borderBottom: "none" }} component="th" scope="row">
                    {v.pendingRisk} / {v.pendingWin}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  render() {
    console.log(" SuperAdminBookSummaries this.props: ", this.props);
    console.log(" SuperAdminBookSummaries this.state: ", this.state);
    if (
      this.props.email?.includes("brian.danilo") ||
      this.props.email?.includes("ephlind") ||
      this.props.email?.includes("pointlightdigital")
    ) {
      return (
        <>
          <SuperAdminHeader />
          <Container>{this.props.groups && this.props.bets2 && this.renderTable2()}</Container>
          {/*<Divider />*/}
          {/*Old SuperAdmin*/}
          {/*<Divider />*/}
          {/*<Container>*/}
          {/*  {this.props.groups && this.props.bets && this.renderTable()}*/}
          {/*</Container>*/}
          {/*<Divider />*/}
          {/*<Grid container justifyContent={"center"}>*/}
          {/*  -- Placed in last 7 Days --*/}
          {/*</Grid>*/}
          {/*<Divider />*/}
          {/*<Container>*/}
          {/*  {this.props.bets2 && this.props.match.url.includes('grade') && (*/}
          {/*    <BetList2 profile={this.props.profile} fromSuperAdmin fromAdmin allowGrading={this.props.match.params.props.includes("grade")} bets={this.props.bets2} />*/}
          {/*  )}*/}
          {/*</Container>*/}
        </>
      );
    } else {
      return <>access denied</>;
    }
  }
}

SuperAdminBookSummaries.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  email: PropTypes.string,
  uid: PropTypes.string,
  profile: PropTypes.object,
  groups: PropTypes.object,
  bets: PropTypes.object,
  bets2: PropTypes.object,
  history: PropTypes.object,
  match: PropTypes.object,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: "inline-block",
    marginTop: "20px",
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  grid: {
    textAlign: "center",
    marginBottom: "20px",
  },
});

const mapStateToProps = (state) => {
  const filterGroups = (g) => {
    const collection = collect(g);
    const groups = collection.filter((val) => val.managerEmail);
    return groups.all();
  };

  const filterAdmins = (b) => {
    let coll = collect(b).values();
    // console.log("coll: ", coll);
    return b;
    // let filteredBets;
    // if (state.firebase.profile.showNewLines) {
    //   let coll = collect(b).filter(i => i).values();
    //   filteredBets = Object.keys(b).filter(i => !b[i].email === "brian.danilo@gmail.com")
    //   console.log("filtered bets: ",filteredBets)
    //   return filteredBets
    // } else
    //   return b
  };

  return {
    uid: state.firebase.auth.uid,
    email: state.firebase.auth.email,
    profile: state.firebase.profile,
    groups: state.firestore.data?.boookSummaryGroupsHeavy,
    // bets: state.firestore.data?.superAdminBets,
    bets2: state.firestore.data?.bookSummaryHeavyBets,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {

    var oneHour = 1000 * 60 * 60;
    var roundedNow = Math.round(Date.now() / oneHour) * oneHour
    var sevenDaysAgo = roundedNow - 1000*3600*24*7
    var thirtyDaysAgo = roundedNow - 1000*3600*24*30

    return [
      {
        collection: "groups",
        where: [["managerEmail", "!=", "anon"]],
        storeAs: "boookSummaryGroupsHeavy",
      },
      // {
      //   collection: "bets",
      //   where: [["betPlaced", ">", 1651425362000]],
      //   storeAs: "superAdminBets",
      // },
      {
        collection: "bets2",
        where: [["minGameStartTime", ">", thirtyDaysAgo]],
        storeAs: "bookSummaryHeavyBets",
      },
    ];
  })
)(withRouter(withStyles(styles)(SuperAdminBookSummaries)));
