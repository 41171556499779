import * as React from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import PickMatch from "./PickMatch";
import moment from "moment/moment";
import Stack from "@mui/material/Stack";

export default function PickDialog(props) {
  const [selectedSport, setSelectedSport] = React.useState(false);
  return (
    <div>
      <Dialog fullScreen open={props.open} onClose={props.handleClose}>
        <AppBar position="relative" color={"tertiary"} elevation={0} sx={{ borderBottom: 2 }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Stack direction="column" justifyContent="center" alignItems="flex-start" spacing={0}>
              <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                Pick Number {props.pickNumber + 1}
              </Typography>
              <Typography sx={{ ml: 2, flex: 1, fontSize: 10 }} component="span" variant="body2" color="text.secondary">
                Game must start by {moment(props.maxGameStartTime).format("lll")}
              </Typography>
            </Stack>
          </Toolbar>
        </AppBar>
        <PickMatch
          selectSport={setSelectedSport}
          sport={selectedSport}
          pickNumber={props.pickNumber}
          tournamentID={props.tournamentID}
          handleClose={props.handleClose}
          riskAmount={props.riskAmount}
          maxGameStartTime={props.maxGameStartTime}
        />
      </Dialog>
    </div>
  );
}

PickDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  pickNumber: PropTypes.number.isRequired,
  tournamentID: PropTypes.string.isRequired,
  riskAmount: PropTypes.number.isRequired,
  maxGameStartTime: PropTypes.number,
};
