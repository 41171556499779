import React, { useEffect } from "react";
import PropTypes from "prop-types";
import firebase from "../../../firebase";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import PersonIcon from "@mui/icons-material/Person";
import ReceiptIcon from "@mui/icons-material/Receipt";

export function LeaderboardEntryPointsBar(props) {
  const auth = firebase.auth();
  const [userID, setUserID] = React.useState(false);
  const [playerName, setPlayerName] = React.useState("Loading...");
  const [emailStart, setEmailStart] = React.useState("Loading...");

  useEffect(() => {
    if (!auth?.currentUser?.uid) return;
    setUserID(auth.currentUser.uid);
    // fetchPlayerName(auth.currentUser.uid);
  }, [auth?.currentUser?.uid]);

  const fetchPlayerName = async (uid) => {
    if (!uid) return;
    const db = firebase.firestore();
    const doc = await db.collection("users").doc(uid).get();
    setPlayerName(doc.data().username);
    setEmailStart(doc.data().email.split("@")[0]);
  };

  useEffect(() => {
    if (!props.entry) return;
    fetchPlayerName(props.entry.userID);
  }, []);

  const calculatePercentage = (value, max) => {
    return (value / max) * 100;
  };

  const stringPercentage = (value, max) => {
    let x = calculatePercentage(value, max);
    return String(x) + "%";
  };

  const getName = () => {
    if (!props.hasLeaderData) {
      return <Typography>Max points:&nbsp;</Typography>;
    }
    if (userID === props.entry.userID) {
      return (
        <Grid container spacing={1}>
          <Grid item sx={{ color: "#bf0070" }} xs={2} sm={1}>
            <PersonIcon />
          </Grid>
          {/*<Grid item xs={4}>*/}
          {/*    <Typography>*/}
          {/*        <i>You</i>*/}
          {/*    </Typography>*/}
          {/*</Grid>*/}
          <Grid item sx={{ color: "#bf0070" }} xs={2} sm={1}>
            <ReceiptIcon />
          </Grid>
        </Grid>
      );
    } else {
      return (
        <Typography>
          {/*{playerName} {emailStart}*/}
          {emailStart}
        </Typography>
      );
    }
  };

  return (
    <Grid container>
      <Grid item xs={12} sx={{ marginBottom: 1 }}>
        <Grid container direction="row" justifyContent="space-between" alignItems="center">
          <Grid item xs={6}>
            <Typography variant="subtitle1">{getName()}</Typography>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: "right" }}>
            {props.entry.pointsPending > 0 && (
              <Typography variant="subtitle2">
                {props.entry.pointsEarned.toFixed(1)} / {props.entry.pointsPossible.toFixed(1)} pts
              </Typography>
            )}
            {props.entry.pointsPending === 0 && (
              <Typography variant="subtitle2">{props.entry.pointsEarned.toFixed(1)} pts</Typography>
            )}
          </Grid>
        </Grid>
      </Grid>
      <div
        style={{
          width: stringPercentage(props.entry.pointsPossible, props.leaderPossiblePoints),
          height: "20px",
          display: "flex",
        }}
      >
        <div
          style={{
            flex: calculatePercentage(props.entry.pointsEarned, props.entry.pointsPossible),
            backgroundColor: "green",
            borderRadius: props.entry.pointsPending === 0 ? "10px 10px 10px 10px" : "10px 0px 0px 10px",
            textAlign: "center",
            color: "white",
            fontSize: "10px",
            verticalAlign: "middle",
            lineHeight: "20px",
          }}
        >
          {props.entry.pointsEarned > 0 ? props.entry.pointsEarned.toFixed(1) : ""}
        </div>
        <div
          style={{
            flex: calculatePercentage(props.entry.pointsPending, props.entry.pointsPossible),
            backgroundColor: "grey",
            borderRadius: props.entry.pointsEarned === 0 ? "10px 10px 10px 10px" : "0px 10px 10px 0px",
            textAlign: "center",
            color: "white",
            fontSize: "10px",
            verticalAlign: "middle",
            lineHeight: "20px",
          }}
        >
          {props.entry.pointsPending > 0 ? props.entry.pointsPending.toFixed(1) : ""}
        </div>
      </div>
      {/*{props.hasLeaderData && (*/}
      {/*  <div>*/}
      {/*    <div style={{ marginBottom: 10, marginTop: 10, width: "100%", height: "20px", display: "flex" }}>*/}
      {/*      Leader max points: {props.leaderPossiblePoints}*/}
      {/*    </div>*/}
      {/*    <div style={{ width: "100%", height: "20px", display: "flex" }}>*/}
      {/*      <div*/}
      {/*        style={{*/}
      {/*          flex: calculatePercentage(props.leaderEarnedPoints, props.leaderPossiblePoints),*/}
      {/*          backgroundColor: "green",*/}
      {/*          borderRadius: props.leaderPendingPoints === 0 ? "10px 10px 10px 10px" : "10px 0px 0px 10px",*/}
      {/*        }}*/}
      {/*      ></div>*/}
      {/*      <div*/}
      {/*        style={{*/}
      {/*          flex: calculatePercentage(props.leaderPendingPoints, props.leaderPossiblePoints),*/}
      {/*          backgroundColor: "grey",*/}
      {/*          borderRadius: props.leaderEarnedPoints === 0 ? "10px 10px 10px 10px" : "0px 10px 10px 0px",*/}
      {/*        }}*/}
      {/*      ></div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*)}*/}
    </Grid>
  );
}

LeaderboardEntryPointsBar.propTypes = {
  entry: PropTypes.object.isRequired,
  hasLeaderData: PropTypes.bool.isRequired,
  leaderPendingPoints: PropTypes.number,
  leaderEarnedPoints: PropTypes.number,
  leaderPossiblePoints: PropTypes.number,
};
