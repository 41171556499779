const materialColors = [
  "#e5737366",
  "#f0629266",
  "#ba68c866",
  "#9575cd66",
  "#7986cb66",
  "#64b5f666",
  "#4fc3f766",
  "#4dd0e166",
  "#4db6ac66",
  "#81c78466",
  "#aed58166",
  "#ff8a6566",
  "#d4e15766",
  "#ffd54f66",
  "#ffb74d66",
  "#a1887f66",
  "#90a4ae66",
];

const javaHashCode = (str) => {
  if (str && typeof str === "string") {
    return Array.from(str).reduce((s, c) => (Math.imul(31, s) + c.charCodeAt(0)) | 0, 0);
  } else {
    return 0;
  }
};

const getAvatarColor = (str) => {
  if (str === undefined) {
    str = "";
  }
  return materialColors[Math.abs(javaHashCode(str)) % materialColors.length];
};

const stringToColor = (string) => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export { getAvatarColor, stringToColor };
