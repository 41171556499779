import { getFirebase } from "react-redux-firebase";
import { adj, nouns } from "./RandomWords";
import {
  newUserSignUpError,
  showSignInDialog,
  showSignUpDialog,
  updateAnonAccountState
} from "../../../reducers/new-user-reducer";
import store from "../../../store";
import axios from "axios";
import { v4 } from "uuid";

const _getBonusObj = (uid) => {
  return {
    uid: uid,
    email: "My Demo Account",
    gid: uid,
    type: "bonus",
    amount: 100,
    timestamp: new Date().getTime(),
    trans_id: v4(),
    state: "credited",
  };
};

const postToHubspot = async (email, uid, gid, firstVisitURL) => {
  console.log("post to hubspot email, uid, gid, firstVisitURL: ", email, uid, gid, firstVisitURL);

  if (!firstVisitURL) firstVisitURL = "none";

  const portalId = "21538969";
  const formGuid = "6f4c166b-6055-4ecf-af09-b3be2f013da5";
  const config = { headers: { "Content-Type": "application/json" } };
  const refLink = "www.superfunbet.com/join/" + uid;

  let isBookieReferral = false;
  if (gid) isBookieReferral = true;

  try {
    let res = axios.post(
      `https://api.hsforms.com/submissions/v3/integration/submit/${portalId}/${formGuid}`,
      {
        portalId,
        formGuid,
        fields: [
          { objectTypeId: "0-1", name: "email", value: email },
          { objectTypeId: "0-1", name: "signuplink", value: refLink },
          { objectTypeId: "0-1", name: "isbookiereferral", value: isBookieReferral },
          { objectTypeId: "0-1", name: "firstvisiturl", value: firstVisitURL },
          // { objectTypeId: "0-1", name: "website", value: firstVisitURL },
        ],
      },
      config
    );
    console.log("res: ", res);
  } catch (e) {
    console.log("E: ", e);
  }
  getFirebase().analytics().logEvent("signup", {
    email,
    refLink,
    isBookieReferral,
    firstVisitURL,
  });
};

const capFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const defaultUsername =
  String(capFirstLetter(adj[Math.floor(Math.random() * adj.length)])) +
  "-" +
  String(capFirstLetter(nouns[Math.floor(Math.random() * nouns.length)]));

const _setUsersBookConfigs = (user, bookName) => {
  return {
    managerUid: user.uid,
    activePromo: "",
    maxBet: 2500,
    groupName: bookName || "NameYourSportsbook",
    groupJuice: 4.55,
    groupCoinflipJuice: 0.98,
    managerEmail: user.email || "My Demo Account",
    initialBalance: 0,
    gradeYourOwnBet: false,
    createdTimestamp: Date.now(),
  };
};

const _setPickemProfile = (user) => {
  return {
    email: user.email,
    uid: user.uid,
    createdTimestamp: Date.now(),
  }
}

const _setProfile = (user, firstVisitURL, conversionState, gid) => {
  let recentBooksArray = [user.uid];
  if (gid) recentBooksArray.push(gid);

  return {
    email: user.email || "My Demo Account",
    uid: user.uid,
    activeBookie: !firstVisitURL.includes("join") && !firstVisitURL.includes("kelvin"),
    managerGid: user.uid,
    gid: gid ? gid : user.uid,
    username: defaultUsername,
    conversionState: (firstVisitURL.includes("join") || firstVisitURL.includes("kelvin")) ? "newReferral" : conversionState,
    firstVisitURL: firstVisitURL,
    openBetslipOnClick: false,
    defaultRiskAmount: 25,
    recentBooks: recentBooksArray,
    createdTimestamp: Date.now(),
    conversionPopups: {
      needsSelfBonus: !firstVisitURL.includes("join") && !firstVisitURL.includes("kelvin"),
      knowsHowDepositsWork: !firstVisitURL.includes("join") && !firstVisitURL.includes("kelvin"),
      knowsWhatCoinflipsAre: false,
      nextSignupReminder: Date.now() + 1000 * 60 * 5,
    },
  };
};

const defaultGroupBalanceDoc = (user) => {
  return {
    balance: 0,
    username: defaultUsername,
    email: user.email || "My Demo Account",
    customJuice: 4.55,
    customMaxBet: 500,
  };
};

export const createAnonAccount = async (firstVisitURL, bookName) => {
  console.log("createAnonAccount: ", firstVisitURL, bookName);
  const db = getFirebase().firestore();
  try {
    var U = await getFirebase().auth().signInAnonymously();
    console.log("anon account u: ", U);
    await db.collection("users").doc(U.user.uid).set(_setProfile(U.user, firstVisitURL, "anonAccount"));
    await db.collection("groups").doc(U.user.uid).set(_setUsersBookConfigs(U.user, bookName));
    await db
      .collection("groups")
      .doc(U.user.uid)
      .collection("balances")
      .doc(U.user.uid)
      .set(defaultGroupBalanceDoc(U.user));
    getFirebase().analytics().logEvent("sign_up_anon", { uid: U.user.uid });
    getFirebase().analytics().logEvent("book_created", { uid: U.user.uid });
    // this.props.history.push('/sports')
    // store.dispatch(awardBonus(_getBonusObj(U.user.uid)))
    store.dispatch(updateAnonAccountState({ status: "accountCreated" }));
  } catch (e) {
    console.log("anon account error e: ", e);
    store.dispatch(newUserSignUpError(e));
  }
};

const joinWhatGroup = (uid, gid) => {
  if (gid) return gid;
  else return uid;
};

export const createAccountWithGoogle = async (firstVisitURL, gid) => {

  if (firstVisitURL && gid) {
    console.log("create sfb account with google ", firstVisitURL, gid);
    const db = getFirebase().firestore();
    const firebase = getFirebase();
    var provider = new firebase.auth.GoogleAuthProvider();
    try {
      var U = await getFirebase().auth().signInWithPopup(provider);
      console.log("FIREBASE AUTH RESPONSE: ", U);
      if (U.additionalUserInfo.isNewUser) {
        console.log("Making profile stuff cause its a new user");
        await db.collection("users").doc(U.user.uid).set(_setProfile(U.user, firstVisitURL, "googleAuth", gid));
        await db.collection("groups").doc(U.user.uid).set(_setUsersBookConfigs(U.user));
        await db
            .collection("groups")
            .doc(joinWhatGroup(U.user.uid, gid))
            .collection("balances")
            .doc(U.user.uid)
            .set(defaultGroupBalanceDoc(U.user));
        if (gid)
          await db
              .collection("groups")
              .doc(joinWhatGroup(U.user.uid))
              .collection("balances")
              .doc(U.user.uid)
              .set(defaultGroupBalanceDoc(U.user));
        postToHubspot(U.user.email, U.user.uid, gid, firstVisitURL);
        getFirebase().analytics().logEvent("sign_up", { uid: U.user.uid });
        store.dispatch(showSignUpDialog(false));
        store.dispatch(showSignInDialog(false));
      } else {
        console.log("returning user, no db stuff needed");
      }
    } catch (e) {
      store.dispatch(newUserSignUpError(e));
    }
  } else {
    console.log("create pickem account with google");
    const db = getFirebase().firestore();
    const firebase = getFirebase();
    var provider2 = new firebase.auth.GoogleAuthProvider();
    try {
      var pickemUser = await getFirebase().auth().signInWithPopup(provider2);
      console.log("FIREBASE AUTH RESPONSE: ", pickemUser);
      if (pickemUser.additionalUserInfo.isNewUser) {
        console.log("Making profile stuff cause its a new user");
        await db.collection("users").doc(pickemUser.user.uid).set(_setPickemProfile(pickemUser.user));
        getFirebase().analytics().logEvent("pickem_sign_up", { uid: pickemUser.user.uid });
        store.dispatch(showSignUpDialog(false));
        store.dispatch(showSignInDialog(false));
      } else {
        console.log("returning user, no db stuff needed");
      }
    } catch (e) {
      store.dispatch(newUserSignUpError(e));
    }
  }



};

export const convertAnonAccountWithGoogle = async (uid, firstVisitURL) => {
  console.log("convert annon account with google ", uid);
  const firebase = getFirebase();

  try {
    var provider = new firebase.auth.GoogleAuthProvider();
    const result = await getFirebase().auth().currentUser.linkWithPopup(provider);
    // console.log("!!! BD & EPH google result", result);
    const email = result.additionalUserInfo.profile.email;
    await getFirebase().updateProfile({ email: email, conversionState: "convertedAnonAccount" });
    await getFirebase().firestore().collection("groups").doc(uid).collection("balances").doc(uid).update({
      email: email,
    });
    await getFirebase().firestore().collection("groups").doc(uid).update({
      managerEmail: email,
    });
    postToHubspot(email, uid, null, firstVisitURL);
    store.dispatch(showSignUpDialog(false));
  } catch (e) {
    store.dispatch(newUserSignUpError(e));
  }
};

export const createAccountWithEmail = async (email, pass, firstVisitURL, gid) => {

  if (firstVisitURL && gid) {
    console.log("create super fun bet account");
    const db = getFirebase().firestore();
    try {
      var U = await getFirebase().auth().createUserWithEmailAndPassword(email, pass);
      await db.collection("users").doc(U.user.uid).set(_setProfile(U.user, firstVisitURL, "realEmail", gid));
      await db.collection("groups").doc(U.user.uid).set(_setUsersBookConfigs(U.user));
      await db
          .collection("groups")
          .doc(joinWhatGroup(U.user.uid, gid))
          .collection("balances")
          .doc(U.user.uid)
          .set(defaultGroupBalanceDoc(U.user));
      if (gid)
        await db
            .collection("groups")
            .doc(joinWhatGroup(U.user.uid))
            .collection("balances")
            .doc(U.user.uid)
            .set(defaultGroupBalanceDoc(U.user));
      postToHubspot(U.user.email, U.user.uid, gid, firstVisitURL);
      getFirebase().analytics().logEvent("sign_up", { uid: U.user.uid });
      store.dispatch(showSignUpDialog(false));
      store.dispatch(showSignInDialog(false));
    } catch (e) {
      store.dispatch(newUserSignUpError(e));
    }
  } else if (email && pass && !firstVisitURL && !gid) {
    console.log("create pickem account");
    const db = getFirebase().firestore();
    try {
      var pickemUser = await getFirebase().auth().createUserWithEmailAndPassword(email, pass);
      await db.collection("users").doc(pickemUser.user.uid).set(_setPickemProfile(pickemUser.user));
      getFirebase().analytics().logEvent("pickem_sign_up", { uid: pickemUser.user.uid });
      store.dispatch(showSignUpDialog(false));
      store.dispatch(showSignInDialog(false));
    } catch (e) {
      store.dispatch(newUserSignUpError(e));
    }
  } else {
    console.log("trying to create account for unknown product");
    // const db = getFirebase().firestore();
    // try {
    //   var UU = await getFirebase().auth().createUserWithEmailAndPassword(email, pass);
    //   await db.collection("users").doc(U.user.uid).set(_setProfile(UU.user, firstVisitURL, "realEmail", gid));
    //   await db.collection("groups").doc(U.user.uid).set(_setUsersBookConfigs(UU.user));
    //   await db
    //       .collection("groups")
    //       .doc(joinWhatGroup(U.user.uid, gid))
    //       .collection("balances")
    //       .doc(U.user.uid)
    //       .set(defaultGroupBalanceDoc(U.user));
    //   if (gid)
    //     await db
    //         .collection("groups")
    //         .doc(joinWhatGroup(U.user.uid))
    //         .collection("balances")
    //         .doc(U.user.uid)
    //         .set(defaultGroupBalanceDoc(U.user));
    //   postToHubspot(U.user.email, U.user.uid, gid, firstVisitURL);
    //   getFirebase().analytics().logEvent("sign_up", { uid: U.user.uid });
    //   store.dispatch(showSignUpDialog(false));
    //   store.dispatch(showSignInDialog(false));
    // } catch (e) {
    //   store.dispatch(newUserSignUpError(e));
    // }
  }






};

export const convertAnonAccountWithEmail = async (email, password, uid, firstVisitURL) => {
  console.log("convert!: ", email, password, uid);
  const credential = getFirebase().auth.EmailAuthProvider.credential(email, password);
  console.log("credential: ", credential);
  try {
    await getFirebase().auth().currentUser.linkWithCredential(credential);
    await getFirebase().updateProfile({ email: email, conversionState: "convertedAnonAccount" });
    await getFirebase().firestore().collection("groups").doc(uid).collection("balances").doc(uid).update({
      email: email,
    });
    await getFirebase().firestore().collection("groups").doc(uid).update({
      managerEmail: email,
    });
    postToHubspot(email, uid, null, firstVisitURL);

    // TODO change any bets in groups/bets so emails match

    store.dispatch(showSignUpDialog(false));
  } catch (e) {
    store.dispatch(newUserSignUpError(e));
  }
};

// export const convertAnonAccount = (email, password, uid, gid, managerGid) => async (dispatch) => {
//
//   store.dispatch(claimFreeAccount(true));
//
//   console.log("convert!: ", email, password, uid);
//   const credential = getFirebase().auth.EmailAuthProvider.credential(email, password);
//   console.log("credential ", credential);
//   getFirebase()
//     .auth()
//     .currentUser.linkWithCredential(credential)
//     .then(
//       function(user) {
//         console.log("Anonymous account successfully upgraded", user);
//         //update users email in their profile
//         getFirebase().updateProfile({
//             email: email,
//             conversionState: "bookieWizardWithEmail"
//           })
//           //update users email in their group
//           .then((res) => {
//             console.log("updating user email in group collection")
//             getFirebase().firestore().collection("groups").doc(gid).collection("balances").doc(uid).update({
//               email: email,
//             });
//           })
//           //update the manager email in the book the user owns
//           .then((res) => {
//             console.log("updating user email in group configs")
//             getFirebase().firestore().collection("groups").doc(managerGid).update({
//               managerEmail: email,
//             });
//           })
//           .then(() => {
//             store.dispatch(showSignUpDialog(false))
//             store.dispatch(claimFreeAccount(false));
//           })
//       },
//       function(error) {
//         console.log("Error upgrading anonymous account", error);
//         store.dispatch(newUserSignUpError(error));
//         store.dispatch(claimFreeAccount(false));
//         store.dispatch(showSignUpDialog(true))
//       },
//     );
// };
//
// export const createEmailAndPasswordLogin = (email, password, firstVisitURL, gid) => async (dispatch) => {
//   const db = getFirebase().firestore();
//   const adjustBalance = getFirebase().functions().httpsCallable("user-adjustBalanceGetRidOfMeLater");
//
//   let joinBookGID = "uU5VEX6AHwMqhdMDqOho";
//
//   if (gid) {
//     joinBookGID = gid
//   }
//
//   if (!firstVisitURL) {
//     firstVisitURL = "UNKNOWN";
//   }
//
//   // TODO fix this later
//   // if (this.props.refLink)
//   //   defaultJoinBookGID = await this.getGidFromName(this.props.refLink)
//
//   return getFirebase()
//     .auth()
//     .createUserWithEmailAndPassword(email, password)
//     .then((res) => {
//       dispatch(showSignUpDialog(false));
//       let user = res.user;
//       try {
//         return db
//           .collection("users")
//           .doc(user.uid)
//           .get()
//           .then((doc) => {
//             if (!doc.exists) {
//               return db
//                 .collection("groups")
//                 .add(getDefaultBook(user))
//                 .then((groupDoc) => {
//                   return db
//                     .collection("users")
//                     .doc(user.uid)
//                     .set(
//                       {
//                         email: user.email || "anon",
//                         welcomePromoEligible: true,
//                         managerGid: groupDoc.id,
//                         activeBookie: false,
//                         gid: joinBookGID,
//                         username: defaultUsername,
//                         conversionState: firstVisitURL === "/claim-free-credits" ? "claimedFreeCredits" : "noClicksYet",
//                         firstVisitURL: firstVisitURL,
//                       },
//                       { merge: true },
//                     )
//                     .then(() => {
//                       getFirebase().analytics().logEvent('sign_up', {
//                         type: 'email',
//                         fromAnonAccount: false,
//                         bankerReferral: gid ? true : false,
//                         gid: joinBookGID,
//                         firstVisitURL: firstVisitURL
//                       });
//                       adjustBalance({ uid: user.uid, gid: joinBookGID, adjust: 1000 });
//                       // getFirebase().analytics().logEvent("ANON_SIGNUP_SUCCESS");
//                       return { user, managerGID: groupDoc.id };
//                     })
//                     .then(() =>  dispatch(claimFreeAccount(false)))
//                     .catch((err) => {
//                       dispatch(signUpError("Something went wrong, we couldn't create your account. Please try again.", err));
//                     })
//                 });
//             }
//           });
//       } catch (error) {
//         dispatch(signUpError("2 Something went wrong, we couldn't create your account. Please try again.", error));
//         // dispatch(claimFreeAccount(false));
//         // dispatch(showSignUpDialog(false));
//         return false;
//       }
//     })
//     .catch((err) => {
//       dispatch(signUpError("Error.", err));
//     });
// };
//
// export const forgotPassword = (Email) => {
//   // getFirebase().analytics().logEvent("FORGOT_PASSWORD");
//   getFirebase().auth()
//     .sendPasswordResetEmail(Email)
//     .then(() => {
//       alert("Please check your email...");
//     })
//     .catch((e) => {
//       console.log(e);
//     });
// };
//
// export const signOut = (callback) => async (dispatch) => {
//   console.log("signing out!");
//   // getFirebase().analytics().logEvent("SIGN_OUT");
//   try {
//     getFirebase().auth()
//       .signOut()
//       .then(() => {
//         getFirebase().auth().onAuthStateChanged(() => {
//           dispatch(logoutSuccess());
//         });
//         callback();
//       })
//       .catch((error) => {
//         dispatch(logoutError("2 Something went wrong, we couldn't log you out.", error));
//       });
//   } catch (error) {
//     dispatch(logoutError("3 Something went wrong, we couldn't log you out.", error));
//   }
// };
//
// export const signIn = (email, password, callback) => async (dispatch) => {
//   // getFirebase().analytics().logEvent("SIGN_IN");
//   console.log("manual sign in!")
//   getFirebase().analytics().logEvent('sign_in_manual', {
//     email: email,
//   });
//   try {
//     getFirebase().auth()
//       .signInWithEmailAndPassword(email, password)
//       .then(() => {
//         getFirebase().auth().onAuthStateChanged((user) => {
//           // dispatch(claimFreeAccount(false));
//           // dispatch(showSignInDialog(false));
//         });
//         dispatch(signInSuccess());
//         console.log("getting ready to hit callback");
//         callback();
//       })
//       .then(() => {
//         dispatch(claimFreeAccount(false));
//         dispatch(showSignInDialog(false));
//       })
//       .catch((err) => {
//         dispatch(signInError("2 Something went wrong, we couldn't create your account. Please try again.", err));
//       });
//   } catch (err) {
//     dispatch(signInError("2 Something went wrong, we couldn't create your account. Please try again.", err));
//   }
// };
//
