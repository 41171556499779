import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Container from "@mui/material/Container";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import List from "@mui/material/List";
import { Box, Typography } from "@mui/material";
import GenericBackHeader from "../../../components/GenericBackHeader";
import Link from "@mui/material/Link";
import sportsBackgroundImage from "../../../images/sports-background-yellowy.png";

class BookieFaq extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderAccordions() {
    const faq = [
      // {
      //   question: "What is Super Fun Bet?",
      //   answer: `SuperFunBet is casino software that gives everyone their own private sportsbook... completely free!
      //   You can invite whoever you want, change the book's name, juice, max bet, etc.`,
      //   linkPath: '/admin',
      //   linkAnchorText: 'Change Your Book Settings'
      // },
      {
        question: "How Do I Start My Own Book?",
        answer: `When you arrived at SuperFunBet, we created a free account for you.  With this account, you get your own private sportsbook
        where you can take your friends' bets.  You can bet against yourself at your own book, or you can  place bets at a friends' book.  
        `,
        linkPath: "/admin",
        linkAnchorText: "Bookie View",
        linkPath2: "/sports",
        linkAnchorText2: "Player View",
      },
      {
        question: "How Does This All Work?",
        answer: `If you'd like to call the number above, we'll explain it to you.  Or, place a bet at your own book to see how it all works. In one window,
        open the Player View.  In another, open the Bookie View.`,
        linkPath: "/cashier/switch",
        linkAnchorText: `Be the Player at your Sportsbook`,
        linkPath2: "/admin",
        linkAnchorText2: "Be the Bookie at your Sportsbook",
      },
      // {
      //   question: "Why do I have 100 credits?",
      //   answer: `When we created your account, we enrolled you in your own private book and gave you a 100 credit bonus so that you can see what
      //   it's like to bet at your sportsbook.  You can give yourself a bigger bonus, seize the credits, withdraw, place a bet, etc.`,
      //   linkPath: '/sports',
      //   linkAnchorText: 'Check Lines and Place a Bet'
      // },
      // {
      //   question: "How can this all work?  I never created an account.",
      //   answer: `Exactly!  Right now you're using a temporary account tied to this specific browser.
      //   You really should create an account so that you can save everything and use Super Fun Bet on different devices.
      //      All you need is an email address.`,
      //   linkPath: '/admin',
      //   linkAnchorText: 'Go here, click CLAIM FREE ACCOUNT'
      // },
      // {
      //   question: "Can I bet with multiple books at the same time?",
      //   answer: `Yep!  When you switch sportsbooks, all your acitvity from your old book is preserved  if you
      //   what to switch back.`,
      // },
      // {
      //   question: "How do coinflips work?",
      //   answer: `When you bet using the coinflip feature, the App selects a side for you.  All outcomes are equally likely.
      //   Because you have no control and thus cannot be "sharp", most sportsbooks will offer reduced juice on coinflips.`,
      // },
      // {
      //   question: "How do moneyline coinflips work if one side is a big favorite?",
      //   answer: `All coinflip outcomes are juiced at the same price (usually lower than normal bets).  For example, even if the Giants are -500 and
      //   the Patriots are +600, if you coinflip on the moneyline, both teams are now -102 (or whatever your admin has set coinflip juice).
      //   In the long run, your expected return is always the same, but after the flip, you might have gotten a great price on a heavy favorite, or
      //   a terrible price on a big underdog.`,
      // },
      // {
      //   question: "Can I bet Inplay without coinflips?",
      //   answer: `No.  Our Inplay lines are not yet sharp enough to allow users to choose a side.`,
      // },
      // {
      //   question: "Are coinflips truly random?",
      //   answer: `Yes.  If you think your bookie-friend is scamming you:  a) get better friends, and b) create your own
      //   sportsbook and verify that there isn't a "rig coinflips" button.`,
      // },
      // {
      //   question: "Can I try coinflips without risking anything?",
      //   answer: `Yes.  You can bet 0 just to see how it works.`,
      // },
    ];

    return faq.map((i) => {
      return (
        <Accordion
          key={i}
          elevation={0}
          onChange={() => {
            this.setState({ i: !this.state.i });
          }}
          expanded={this.state.i}
        >
          <AccordionSummary
            sx={{ backgroundColor: "white" }}
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
          >
            {i.question}
          </AccordionSummary>
          <AccordionDetails sx={{ backgroundColor: "white" }}>
            <List>{i.answer}</List>
            <Link target="_blank" href={i.linkPath} color={"secondary"}>
              {i.linkAnchorText}
            </Link>
            <br />
            <Link target="_blank" href={i.linkPath2} color={"secondary"}>
              {i.linkAnchorText2}
            </Link>
          </AccordionDetails>
        </Accordion>
      );
    });
  }

  render() {
    console.log("bookie quickstart guide this.props: ", this.props);
    return (
      <>
        <Box sx={{ minHeight: "100vh", flexGrow: 1, backgroundImage: `url(${sportsBackgroundImage})` }}>
          <GenericBackHeader title="Bookie FAQ" showBackButton />
          <Container>
            <h3>
              <Typography color={"quaternary"}>Call (805) 229-1131 for human help</Typography>
            </h3>
            {this.renderAccordions()}
          </Container>
        </Box>
      </>
    );
  }
}

BookieFaq.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: "inline-block",
    marginTop: "20px",
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  grid: {
    textAlign: "center",
    marginBottom: "20px",
  },
});

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    profile: state.firebase.profile,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(BookieFaq));
