import React from "react";
import PropTypes from "prop-types";

export function Card(props) {

    const getSuitDisplay = (suit) => {
        if (suit === "h")
            return "♥"
        if (suit === "d")
            return "♦"
        if (suit === "c")
            return "♣"
        if (suit === "s")
            return "♠"
    }

    const getRank = (rank) => {
        if (rank === "1")
            return "10"
        else
            return rank
    }

    const getSuitColor = (suit) => {
        if (suit === "h" || suit === "d")
            return "red"
        else
            return "black"
    }

    return (
        <div style={{
            width: 60,
            height: 77,
            border: "2px solid black",
            // marginLeft: -1 * 15,
            backgroundColor: "white",
            color: getSuitColor(props.suit),
            // justifyContent: "flex-start",
            alignText: "left",
            fontSize: "22",
            fontColor: getSuitColor(props.suit),
            // display: "flex",
            // paddingLeft: 4,
            borderRadius: "5px",
        }}>
            {getRank(props.rank)}{getSuitDisplay(props.suit)}
        </div>
    );
}

Card.propTypes = {
    rank: PropTypes.string.isRequired,
    suit: PropTypes.string.isRequired,
};




// Card.defaultProps = {
//   width: 60,
//   height: 77,
//   backgroundColor: "white",
//   overlap: 15,
// };

