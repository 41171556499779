import React, { Component } from "react";
import PropTypes from "prop-types";
import {Box, Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import TextField from "@mui/material/TextField";
import { requestTransaction } from "../../../reducers/cashier-reducer";
import { v4 } from "uuid";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";

class CashierDeposit extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  _doDeposit = () => {
    if (Number(this.state.amount) > 0) {
      this.props.dispatch(
        requestTransaction({
          type: "deposit",
          state: "pending",
          timestamp: new Date().getTime(),
          amount: Number(this.state.amount),
          uid: this.props.uid,
          gid: this.props.gid,
          email: this.props.email,
          trans_id: v4(),
        })
      );
      this.props.closeCallback();
    } else alert("please enter an amount");
  };

  render() {
    return (
        <Dialog open={this.props.open}>
            <Box sx={{ borderColor: "black", border: 1 }}>
                <DialogTitle textAlign="center" sx={{ borderBottom: 1}}>Deposit</DialogTitle>
                <DialogContent sx={{ margin: 2 }}>
                    <TextField
                        onChange={(e) => this.setState({ amount: e.target.value })}
                        id="standard-number"
                        label="Amount"
                        type="number"
                        color={"secondary"}
                        value={this.state.amount}
                        InputLabelProps={{
                            shrink: true,
                        }}
                        sx={{ marginTop: 2 }}
                        inputProps={{ inputMode: "numeric" }}
                    />
                </DialogContent>
                <DialogActions>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        <Grid>
                            <Button color={"secondary"} variant="outlined" onClick={this.props.closeCallback}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid>
                            <Button
                                // disabled={this.state.buttonsDisabled}
                                variant="contained" color="secondary" onClick={this._doDeposit}
                            >
                                Deposit
                            </Button>
                        </Grid>
                    </Grid>
                </DialogActions>
            </Box>
        </Dialog>
    );
  }
}

CashierDeposit.propTypes = {
  open: PropTypes.bool,
  closeCallback: PropTypes.func,
  uid: PropTypes.string.isRequired,
  gid: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  groupDisplayName: PropTypes.string.isRequired,
  dispatch: PropTypes.func,
};

export default connect()(CashierDeposit);
