import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import FormHelperText from "@mui/material/FormHelperText";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { firestoreConnect, getFirebase } from "react-redux-firebase";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import JuiceMap from "./JuiceMap";
import Typography from "@mui/material/Typography";

class CustomPlayerJuice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderJuiceOptions = () => {
    let returnArray = [];
    for (let juice = -100; juice > -116; juice--) {
      returnArray.push(<MenuItem value={juice}>{juice}</MenuItem>);
    }
    return returnArray;
  };

  juicePcntFromAmerican = (juice) => {
    let hardCodedJuice = Number(JuiceMap[String(juice)]).toFixed(2);
    return Number(hardCodedJuice).toFixed(2);
  };

  getAmericanfromJuicePcnt = (num) => {
    return Object.keys(JuiceMap).find((key) => JuiceMap[key] === num);
  };

  _doJuiceChange = async () => {
    const updateUser = await getFirebase()
      .firestore()
      .collection("groups")
      .doc(this.props.profile.managerGid)
      .collection("balances")
      .doc(this.state.player)
      .update({ customJuice: Number(this.juicePcntFromAmerican(this.state.newCustomJuice)) });
  };

  renderDialog() {
    const { classes } = this.props;
    let B;
    this.props.balances ? (B = this.props.balances) : (B = []);
    let GCF = this.props.groupConfigs;
    return (
      <Dialog open={this.props.open}>
        <DialogTitle style={{ paddingBottom: 0 }}>Change Player Juice</DialogTitle>
        <DialogContent>
          {this.props.balances && (
            <FormControl style={{ minWidth: 250 }}>
              <Typography style={{ marginBottom: 20, fontSize: "medium" }}>
                this does not affect coinflip juice
              </Typography>
              <Select
                style={{ paddingTop: 10 }}
                fullWidth
                onChange={(e) => {
                  this.setState({ player: e.target.value, email: B[e.target.value].email });
                }}
              >
                {Object.entries(B).map(([key, player]) => (
                  <MenuItem key={key} value={key}>
                    {player.email} (
                    {player.customJuice >= 0
                      ? this.getAmericanfromJuicePcnt(player.customJuice)
                      : this.getAmericanfromJuicePcnt(GCF.groupJuice)}
                    )
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText style={{ paddingBottom: 20 }}>Select Player</FormHelperText>
              <Select
                style={{ paddingTop: 10 }}
                fullWidth
                value={this.state.newCustomJuice}
                onChange={(e) => this.setState({ newCustomJuice: e.target.value })}
              >
                {this.renderJuiceOptions()}
              </Select>
              <FormHelperText>Custom Player Juice</FormHelperText>
            </FormControl>
          )}
          {!this.props.balances && (
            <Typography style={{ marginBottom: 20, fontSize: "medium" }}>
              once you have some players who have registered with email addresses, you will be able to set their juice
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={this.props.closeCallback}>
            Close
          </Button>
          {this.props.balances && (
            <Button
              variant="contained"
              onClick={() => {
                console.log("UPDATE JUICE");
                this._doJuiceChange();
                this.setState({});
              }}
            >
              Update Juice
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }

  render() {
    console.log("custom juice props: ", this.props);
    console.log("custom juice state: ", this.state);
    if (this.props.profile && this.props.groupConfigs) return this.renderDialog();
    else return <></>;
  }
}

CustomPlayerJuice.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  trans: PropTypes.object,
  bets: PropTypes.object,
  balances: PropTypes.object,
  profile: PropTypes.object,
  groupConfigs: PropTypes.object,
  open: PropTypes.bool,
  closeCallback: PropTypes.func,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  pastBetsList: {
    width: "100%",
  },
});

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    email: state.firebase.auth.email,
    profile: state.firebase.profile,
    balances: state.firestore.data?.customJuice_balances,
    groupConfigs: state.firestore.data?.customJuice_groupConfigs,
  };
};

export default compose(
  connect(mapStateToProps),
  withRouter,
  firestoreConnect((props) => {
    return [
      {
        collection: `groups/${props.profile.managerGid}/balances`,
        where: [["email", "!=", "anon"]],
        storeAs: "customJuice_balances",
      },
      {
        collection: `groups`,
        doc: props.profile.managerGid,
        storeAs: "customJuice_groupConfigs",
      },
    ];
  })
)(withStyles(styles)(CustomPlayerJuice));
