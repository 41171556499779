import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Grid, Skeleton } from "@mui/material";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";

class SuperAdminPlayerListItemSecondary extends Component {
  render() {
    return (
      <Grid container direction="column" justifyContent="center" alignItems="flex-start">
        <Grid xs={12}>{"Created " + moment(this.props.player.createdTimestamp).calendar()}</Grid>
        <Grid xs={12}>{!this.props.playerGroup ?
          <Skeleton variant="text" /> :
          "Group: " + this.props.playerGroup.groupName}
        </Grid>
      </Grid>
    );
  }
}

SuperAdminPlayerListItemSecondary.propTypes = {
  player: PropTypes.object.isRequired,
  playerGroup: PropTypes.object.isRequired,
};

const mapStateToProps = (state, props) => {
  return {
    playerGroup: state.firestore.data["superAdminGroups-" + props.player.gid],
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: "groups",
        doc: props.player.gid,
        storeAs: "superAdminGroups-" + props.player.gid,
      },
    ];
  }),
)(SuperAdminPlayerListItemSecondary);
