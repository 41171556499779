import React, { Component } from "react";
import PropTypes from "prop-types";
import BetList from "../../Sportsbook/BetList";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import withStyles from "@mui/styles/withStyles";
import GenericBackHeader from "../../../components/GenericBackHeader";
import { BottomNav } from "../../../components";
import Box from "@mui/material/Box";
import collect from "collect.js";

class SocialPastBets extends Component {
  render() {
    console.log("social past bets ", this.props);
    return (
      <>
        <Box>
          <GenericBackHeader title="Group Past Bets" showBackButton />
          {this.props.socialBalances && this.props.pastBets && (
            <BetList noAccordion isSocial balances={this.props.socialBalances} bets={this.props.pastBets} />
          )}
        </Box>
        <BottomNav activeTab={1} managerGid={this.props.profile.managerGid} />
      </>
    );
  }
}

SocialPastBets.propTypes = {
  profile: PropTypes.object,
  pastBets: PropTypes.object,
  socialBalances: PropTypes.object,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: "inline-block",
    marginTop: "20px",
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  grid: {
    textAlign: "center",
    marginBottom: "20px",
  },
});

const mapStateToProps = (state) => {
  const getPastBets = (b) => {
    const collection = collect(b);
    const pendingBets = collection.filter(
      (val) => val.result !== "pending" && !(val.email.includes("brian.danilo+") && val.gid === "3PfmaqsXa5SYbYkgtvOH")
    );
    return pendingBets.take(25).all();
  };

  return {
    profile: state.firebase.profile,
    pastBets: getPastBets(state.firestore.data?.social_allBets),
    socialGroupConfigs: state.firestore.data?.socialGroupConfigs,
    socialBalances: state.firestore.data?.socialBalances,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: `groups/${props.profile.gid}/balances`,
        storeAs: "socialBalances",
      },
      {
        collection: `groups/${props.profile.gid}/bets`,
        orderBy: ["minGameStartTime", "desc"],
        limit: 100,
        storeAs: "social_allBets",
      },
      {
        collection: "groups",
        doc: props.profile.gid,
        storeAs: "socialGroupConfigs",
      },
    ];
  })
)(withStyles(styles)(SocialPastBets));

// const filterPublicBets = (allBets) => {
//   if (allBets && state.firestore.data.socialGroupConfigs.publicBettors) {
//     let publicUsers = state.firestore.data.socialGroupConfigs.publicBettors;
//     let filtered;
//     filtered = Object.keys(allBets)
//         .filter((key) => publicUsers.includes(allBets[key].uid))
//         .reduce((obj, key) => {
//           return {
//             ...obj,
//             [key]: allBets[key],
//           };
//         }, {});
//     return filtered;
//   } else return {};
// };
// const filterSocialUIDs = (balances) => {
//   if (balances && state.firestore.data.socialGroupConfigs.publicBettors) {
//     let publicUsers = state.firestore.data.socialGroupConfigs?.publicBettors;
//     let filtered;
//     filtered = Object.keys(balances)
//         .filter((key) =>
//             //console.log("key is: ",key)
//             publicUsers.includes(key)
//         )
//         .reduce((obj, key) => {
//           return {
//             ...obj,
//             [key]: balances[key],
//           };
//         }, {});
//     return filtered;
//   }
// };
