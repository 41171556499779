import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { firestoreConnect, getFirebase } from "react-redux-firebase";
import CloseIcon from "@mui/icons-material/Close";
import { adjustRef, betLine2, betPrimaryText2, calcParlayOdds2, emailData, oddsjamParlayGradingData } from "./components/PendingHelpers";
import { clearPlaceBetError, setBetslipOpen, submitBet2 } from "../../reducers/sportsbook-reducer";
import PendingBet2 from "./components/PendingBet2";
import { v4 } from "uuid";

const styles = (theme) => ({
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
  dialogTitle: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  betField: {
    margin: 8,
  },
});

class BestSlip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isParlay: false,
      riskAmount: (0).toFixed(2),
      winAmount: (0).toFixed(2),
      lockSide: "win",
      oldPrice: 0,
    };
  }

  getWinAmount = (riskAmount) => {
    return Number(riskAmount * 2).toFixed(2);
  };

  getRiskAmount = (winAmount) => {
    return Number(winAmount / 2).toFixed(2);
  };

  _onChangeRisk = (event) => {
    this.setState({
      riskAmount: event.target.value,
      lockSide: "risk",
      winAmount: (
        calcParlayOdds2(this.props.pendingBets, this.props.neededLiveLines, this.props.juice) *
          Number(event.target.value) -
        event.target.value
      ).toFixed(2),
    });
  };

  _onChangeRiskExternal = (amount) => {
    this.setState({
      riskAmount: amount,
      lockSide: "risk",
      winAmount: (
        calcParlayOdds2(this.props.pendingBets, this.props.neededLiveLines, this.props.juice) * Number(amount) -
        amount
      ).toFixed(2),
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      this.props.neededLiveLines &&
      this.props.neededLiveLines.length === 1 &&
      prevProps.neededLiveLines &&
      prevProps.neededLiveLines.length !== 1 &&
      this.state.isParlay
    ) {
      this.setState({ isParlay: false });
    }
    if (
      prevProps.pendingBets &&
      Object.keys(prevProps.pendingBets).length >= 2 &&
      Object.keys(this.props.pendingBets).length < 2
    ) {
      this.setState({ isParlay: false });
    }
    if (
      this.props.neededLiveLines &&
      prevProps.neededLiveLines &&
      Object.keys(this.props.neededLiveLines).length !== Object.keys(prevProps.neededLiveLines).length
    ) {
      this._onChangeRiskExternal(this.state.riskAmount);
    }
  }

  _onSubmitParlay = () => {
    if (this.state.winAmount > this.props.maxBet) {
      alert(`Max Bet exceeded. Parlay cannot win more than ${this.props.maxBet}`);
    } else {
      // if (this.props.neededLiveLines[Object.values(this.props.pendingBets)[0].doc_id].format === "2205") {
      const parlayCard = {};
      Object.values(this.props.pendingBets)
        .filter((bet) => bet)
        .map((bet) => {
          const game = this.props.neededLiveLines[bet.doc_id];
          parlayCard[v4()] = {
            ...bet,
            result: "pending",
            line: game,
            event_id: game.event_id,
            adjustRef: adjustRef(game, bet),
            price: Number(betLine2(game, bet, this.props.juice)),
            betPrimaryText: betPrimaryText2(game, bet),
            gradingData: oddsjamParlayGradingData(game, bet)
          };
        });

      const submitBetObject = {
        bet_id: v4(),
        format: "2205",
        uid: this.props.auth.uid,
        gid: this.props.gid,
        email: this.props.profile.email || "bookie demo account",
        promo: this.props.groupConfigs.activePromo || null,
        isParlay: true,
        parlayCard: parlayCard,
        isCoinflip: false,
        juice: 0,
        risk: Number(this.state.riskAmount),
        win: Number(this.state.winAmount),
        unsubmittedToRemove: Object.keys(this.props.pendingBets),
        emailData: Object.values(this.props.pendingBets)
          .filter((bet) => bet)
          .map((bet) => emailData(this.props.neededLiveLines[bet.doc_id], bet, this.props.juice)),
      };
      this.props.dispatch(submitBet2(submitBetObject));
      // } else {
      //   this.props.dispatch(
      //     submitBet2({
      //       betID: v4(),
      //       uid: this.props.auth.uid,
      //       gid: this.props.gid,
      //       email: this.props.profile.email || "bookie demo account",
      //       promo: this.props.groupConfigs.activePromo || null,
      //       isParlay: true,
      //       unconfirmedBets: this.props.pendingBets,
      //       isLive: false,
      //       isCoinflip: false,
      //       juice: this.props.juice,
      //       games: this.props.neededLiveLines,
      //       risk: Number(this.state.riskAmount),
      //       win: Number(this.state.winAmount),
      //       unsubmittedToRemove: Object.keys(this.props.pendingBets),
      //       emailData: Object.values(this.props.pendingBets).map((bet) =>
      //         emailData(this.props.neededLiveLines[bet.doc_id], bet, this.props.juice)
      //       ),
      //     })
      //   );
      // }
    }
  };

  _onCloseFromClearing = () => {
    this.props.dispatch(setBetslipOpen(false));
    this.setState({ riskAmount: 0, winAmount: 0, isParlay: false });
  };

  _onClose = () => {
    this.props.dispatch(setBetslipOpen(false));
  };

  getParlayCard = () => {
    return this.props.parlayCard;
  };

  _checkParlayCard = () => {
    console.log("check parlay card: ", this.props);
    const lineIDs = [];
    // if (this.props.pendingBets) {
    //   if (Object.keys(this.props.pendingBets).length < 2)
    //     return false
    // }
    if (!this.props.pendingBets) {
      return true;
    }
    for (const bet of Object.values(this.props.pendingBets)) {
      if (!bet) {
        continue;
      }
      if (lineIDs.includes(this.props.neededLiveLines[bet.doc_id].event_id)) {
        return false;
      }
      if (this.props.neededLiveLines[bet.doc_id].event_type === "live") {
        return false;
      }
      // neededLiveLines and pendingBets don't use bet.doc_id the same way?
      if (bet.bet_period === "1h" || bet.bet_type === "props" || bet.bucket === "WorldCup" || bet.bucket === "UFC") {
        return false;
      }
      lineIDs.push(this.props.neededLiveLines[bet.doc_id].event_id);
    }
    if (calcParlayOdds2(this.props.pendingBets, this.props.neededLiveLines, this.props.juice) === 0) {
      return false;
    }
    return true;
  };

  render() {
    console.log("BEST SLIP PROPS: ", this.props);
    const { classes } = this.props;
    if (
      this.props.profile &&
      this.props.betslipOpen &&
      (!this.props.pendingBets ||
        Object.entries(this.props.pendingBets).filter(([key, bet]) => bet && !this.props.inflightBets.includes(key))
          .length === 0)
    ) {
      // this.setState({ riskAmount: 0, winAmount: 0, isParlay: false });
      this._onCloseFromClearing();
    }
    return (
      <Dialog
        open={this.props.betslipOpen || false}
        onClose={this._onClose}
        maxWidth="xl"
        // fullWidth
        scroll="paper"
        sx={{ minHeight: "100vh", maxHeight: "100vh" }}
      >
        {/*<Paper className={classes.paper}>*/}
        <DialogContent dividers>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle2">BETSLIP</Typography>
            <IconButton aria-label="close" onClick={this._onClose}>
              <CloseIcon />
            </IconButton>
          </Grid>
          <Divider />
          <DialogContentText
            id="scroll-dialog-description"
            // ref={descriptionElementRef}
            tabIndex={-1}
          >
            {this.props.pendingBets &&
              this.props.neededLiveLines &&
              Object.entries(this.props.pendingBets)
                .filter(([key, bet]) => bet && !this.props.inflightBets.includes(key))
                .filter(([key, bet]) => this.props.neededLiveLines[bet.doc_id])
                .map(([key, bet], index) => {
                  let numberOfBets = Object.entries(this.props.pendingBets).length;
                  return (
                    <PendingBet2
                      bet={bet}
                      key={key}
                      betDoc={key}
                      game={this.props.neededLiveLines[bet.doc_id]}
                      isParlay={this.state.isParlay}
                      groupConfigs={this.props.groupConfigs}
                      juice={this.props.juice}
                      coinflipJuice={this.props.coinflipJuice}
                      showDivider={numberOfBets - index !== 1}
                    />
                  );
                })}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.isParlay}
                    onChange={() => {
                      if (!this.state.isParlay) {
                        // getFirebase().analytics().logEvent("PARLAY_TURN_ON");
                      } else {
                        // getFirebase().analytics().logEvent("PARLAY_TURN_OFF");
                      }
                      this.setState({ isParlay: !this.state.isParlay });
                    }}
                    name="isParlay"
                    color="primary"
                    disabled={
                      !(
                        this.props.pendingBets &&
                        Object.values(this.props.pendingBets).filter((item) => item !== null).length > 1
                      )
                    }
                  />
                }
                label={
                  !(
                    this.props.pendingBets &&
                    Object.values(this.props.pendingBets).filter((item) => item !== null).length > 1
                  )
                    ? "Parlay needs 2 or more games"
                    : "Parlay"
                }
              />
            </Grid>
            {this.state.isParlay &&
              ((this._checkParlayCard() && (
                <>
                  <Grid item xs={12} sm={5}>
                    <TextField
                      id="outlined-required"
                      label="Risk"
                      type="number"
                      variant="outlined"
                      size="small"
                      className={classes.betField}
                      value={this.state.riskAmount}
                      onChange={this._onChangeRisk}
                      onFocus={(event) => {
                        event.target.select();
                      }}
                      inputProps={{ inputMode: "numeric" }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={5}>
                    <TextField
                      id="outlined-required"
                      label="Win"
                      variant="outlined"
                      className={classes.betField}
                      value={this.state.winAmount}
                      size="small"
                      // onChange={this._onChangeWin}
                      // onFocus={(event) => {
                      //   event.target.select();
                      // }}
                      inputProps={{ inputMode: "numeric" }}
                    />
                  </Grid>
                </>
              )) || (
                <Grid item xs>
                  Can&apos;t Parlay Same Games, Soccer, UFC
                </Grid>
              ))}
          </Grid>
          {this.state.isParlay && (
            <Box display="flex" justifyContent="flex-end" alignItems="center" style={{ margin: 8 }}>
              <Button
                variant="contained"
                color="primary"
                onClick={this._onSubmitParlay}
                disabled={this.props.parlayInflight || this.state.riskAmount < 0.01 || !this._checkParlayCard()}
              >
                Bet Parlay
              </Button>
            </Box>
          )}
        </DialogActions>
        <Snackbar
          open={this.props.placeBetError}
          autoHideDuration={10000}
          onClose={() => this.props.dispatch(clearPlaceBetError())}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={() => this.props.dispatch(clearPlaceBetError())} severity="error">
            {this.props.placeBetError}
          </Alert>
        </Snackbar>
      </Dialog>
    );
  }
}

BestSlip.propTypes = {
  profile: PropTypes.object,
  classes: PropTypes.object,
  pendingBets: PropTypes.array,
  parlayCard: PropTypes.array,
  auth: PropTypes.object,
  neededLiveLines: PropTypes.object,
  dispatch: PropTypes.func,
  parlayInflight: PropTypes.bool,
  gid: PropTypes.string,
  placeBetError: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  groupConfigs: PropTypes.object,
  maxBet: PropTypes.number,
  juice: PropTypes.number,
  coinflipJuice: PropTypes.number,
  inflightBets: PropTypes.array.isRequired,
  betslipOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state, props) => {
  const getMaxBet = (custom, general) => {
    if (custom) return custom;
    else if (general) return general;
    else return null;
  };

  const _createdNeededLiveLines = (state) => {
    const liveLines = {};
    const uid = state.firebase.auth.uid;
    if (state.firestore.data.pendingBets2) {
      Object.entries(state.firestore.data.pendingBets2)
        .filter(([betKey, bet]) => bet)
        .map(([betKey, bet]) => {
          if (bet.is_half && !state.firestore.data[bet.bucket][bet.doc_id].is_half) {
            getFirebase().firestore().collection("users").doc(uid).collection("pendingBets2").doc(betKey).delete();
          }
          if (!bet || !(bet.bucket in state.firestore.data) || !(bet.doc_id in state.firestore.data[bet.bucket])) {
            return false;
          } else {
            liveLines[bet.doc_id] = state.firestore.data[bet.bucket][bet.doc_id];
          }
        });
    }

    return liveLines;
  };

  return {
    profile: state.firebase.profile,
    auth: state.firebase.auth,
    pendingBets: state.firestore.data.pendingBets2,
    parlayInflight: state.sportsbookState.parlayInflight,
    placeBetError: state.sportsbookState.placeBetError,
    maxBet: getMaxBet(state.firestore.data?.userBalanceDoc?.customMaxBet, props.groupConfigs?.maxBet),
    neededLiveLines: _createdNeededLiveLines(state),
    inflightBets: state.sportsbookState.inflightBets,
    betslipOpen: state.sportsbookState.betslipOpen,
  };
};

export default compose(
  connect(mapStateToProps),
  withRouter,
  firestoreConnect((props) => {
    return [
      {
        collection: `groups/${props.profile.gid}/balances`,
        doc: `${props.auth.uid}`,
        storeAs: "userBalanceDoc",
      },
    ];
  })
)(withStyles(styles)(BestSlip));
