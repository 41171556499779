import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Chip, Divider, Grid, Hidden, ListItem, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { getTimestamp, gradeWager, markParlayLegAsPush } from "../reducers/sportsbook-reducer";
import {
  Adjust,
  AssignmentTurnedIn,
  AutorenewSharp,
  CancelPresentationOutlined,
  Face,
  LocalAtm,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import { connect } from "react-redux";
import { getAvatarColor } from "../utilities/avatar";

class Bet extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonsDisabled: false,
    };
  }

  _gradeBet = (result) => {
    this.setState({ buttonsDisabled: true });

    if (!["win", "push", "loss"].includes(result)) {
      return false;
    }

    this.props.dispatch(
      gradeWager({
        uid: this.props.bet.uid,
        betID: this.props.bet.betID,
        result: result,
      })
    );
  };

  _pushLeg = (lineID) => {
    // console.log("Mark Leg as Push", {
    //   betID: this.props.bet.betID,
    //   LineID: lineID,
    //   gid: this.props.bet.gid,
    //   uid: this.props.bet.uid
    // });
    this.props.dispatch(
      markParlayLegAsPush({
        betID: this.props.bet.betID,
        LineID: lineID,
        gid: this.props.bet.gid,
        uid: this.props.bet.uid,
      })
    );
  };

  _getResultIcon = (result) => {
    if (result === "win") {
      return <AssignmentTurnedIn style={{ color: "lightgreen" }} />;
    } else if (result === "push") {
      return <RadioButtonUnchecked style={{ color: "gray" }} />;
    } else if (result === "pending") {
      return <Adjust style={{ color: "gray" }} />;
    } else {
      return <CancelPresentationOutlined style={{ color: "lightsalmon" }} />;
    }
  };

  _getReturn = (result, risk, win) => {
    if (result === "win") {
      return <Typography style={{ color: "lightgreen" }}>{"+" + win.toFixed(2)}</Typography>;
    } else if (result === "push") {
      return <Typography style={{ color: "gray" }}>Push</Typography>;
    } else if (result === "pending") {
      return null;
    } else {
      return <Typography style={{ color: "lightsalmon" }}>{"-" + risk.toFixed(2)}</Typography>;
    }
  };

  _getPickColor = (result) => {
    if (!this.props.isSocial) {
      return <Typography variant="subtitle2">{this.props.bet.team}</Typography>;
    } else if (result === "win") {
      return (
        <Typography variant="subtitle2" style={{ color: "green" }}>
          {this.props.bet.team}
        </Typography>
      );
    } else if (result === "loss") {
      return (
        <Typography variant="subtitle2" style={{ color: "red" }}>
          {this.props.bet.team}
        </Typography>
      );
    } else if (result === "push" || result === "pending") {
      return <Typography variant="subtitle2">{this.props.bet.team}</Typography>;
    }
  };

  _getParlayTextColor = (result) => {
    if (result === "win") return "green";
    else if (result === "loss") return "red";
    else return null;
  };

  getLabel = () => {
    if (!this.props.isSocial) {
      return "Anon User";
    } else if (this.props.isSocial && this.props.balances && this.props.balances[this.props.bet.uid]?.username) {
      return this.props.balances[this.props.bet.uid].username;
    } else return "Anonymous";
  };

  _renderBody = () => {
    // console.log("rendering body: ", this.props);
    if (this.props.bet.game.HasOutRights) {
      //this is an outright
      return (
        <Grid item xs={12} sm={8} container direction="column" justifyContent="center" alignItems="center" spacing={2}>
          {(this.props.fromAdmin || this.props.isSocial) && (
            <Grid item>
              <Chip
                icon={<Face />}
                label={this.props.activePromo ? this.getLabel() : this.props.activePromo}
                sx={{ backgroundColor: getAvatarColor(this.props.bet.uid) }}
              />
            </Grid>
          )}
          <Grid item>
            <Typography variant="subtitle2" style={{ color: this._getPickColor(this.props.bet.result) }}>
              {this.props.bet.team} ({this.props.bet.juice})
            </Typography>
          </Grid>
          <Grid item sx={{ color: "text.secondary", typography: "body2" }}>
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item>{this.props.bet.game.Title}</Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    } else if (this.props.bet.team !== "Parlay") {
      return (
        <Grid item xs={12} sm={8} container direction="column" justifyContent="center" alignItems="center" spacing={2}>
          {(this.props.fromAdmin || this.props.isSocial) && (
            <Grid item>
              <Chip
                icon={<Face />}
                label={this.props.activePromo ? `hidden for ${this.props.activePromo}` : this.getLabel()}
                sx={{ backgroundColor: getAvatarColor(this.props.bet.uid) }}
              />
            </Grid>
          )}
          <Grid item>{this._getPickColor(this.props.bet.result)}</Grid>
          <Grid item sx={{ color: "text.secondary", typography: "body2" }}>
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item>{this.props.bet.game.AwayTeam}</Grid>
              <Grid item>&nbsp;at {this.props.bet.game.HomeTeam}</Grid>
              <Grid item>&nbsp;({getTimestamp(this.props.bet.game.StartTime)})</Grid>
            </Grid>
          </Grid>
        </Grid>
      );
    } else if (this.props.bet.team === "Parlay") {
      return (
        <Grid item xs={12} sm={8} container direction="column" justifyContent="center" alignItems="center" spacing={2}>
          {(this.props.fromAdmin || this.props.isSocial) && (
            <Grid item>
              <Chip
                icon={<Face />}
                label={this.props.activePromo ? `hidden for ${this.props.activePromo}` : this.getLabel()}
                sx={{ backgroundColor: getAvatarColor(this.props.bet.uid) }}
              />
            </Grid>
          )}
          <Grid item>
            <Typography variant="subtitle1" style={{ color: this._getParlayTextColor(this.props.bet.result) }}>
              Parlay
            </Typography>
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Grid item xs={12}>
                <Divider style={{ marginTop: 5, marginBottom: 5 }} />
              </Grid>
            </Grid>
          </Grid>
          {this.props.bet.parlayCard.map((leg) => (
            <Grid container key={leg.LineID} direction="row" justifyContent="center" alignItems="center" p={0}>
              <Grid item xs={12} sm={8} container direction="column" justifyContent="center" alignItems="center">
                <Grid item>
                  <Typography variant="subtitle2">{leg.team}</Typography>
                </Grid>
                <Grid item sx={{ color: "text.secondary", typography: "body2" }}>
                  <Grid container wrap={"wrap"} direction="row" justifyContent="center" alignItems="center" spacing={0}>
                    <Grid item>
                      {" "}
                      <Typography variant="body2" style={{ overflowWrap: "break-word" }}>
                        {leg.game.AwayTeam}
                      </Typography>
                    </Grid>
                    <Grid item>
                      {" "}
                      <Typography variant="body2" style={{ overflowWrap: "break-word" }}>
                        &nbsp;at {leg.game.HomeTeam}
                      </Typography>
                    </Grid>
                    <Grid item>
                      {" "}
                      <Typography variant="body2" style={{ overflowWrap: "break-word" }}>
                        ({getTimestamp(leg.game.StartTime)})
                      </Typography>
                    </Grid>
                  </Grid>
                  {!this.props.allowGrading && (
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                      <Grid item xs={12}>
                        <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                      </Grid>
                    </Grid>
                  )}
                </Grid>
              </Grid>
              {this.props.allowGrading && (
                <>
                  <Grid item xs={12} sm={2}>
                    <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2}>
                      <Grid item>
                        <Button
                          style={{ marginBottom: 5, marginTop: 10 }}
                          variant="outlined"
                          size="small"
                          disabled={this.state.buttonsDisabled}
                          onClick={() => {
                            this._pushLeg(leg.game.LineID);
                          }}
                        >
                          Push Leg
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid container direction="row" justifyContent="center" alignItems="center">
                    <Grid item xs={12}>
                      <Divider style={{ marginTop: 5, marginBottom: 5 }} />
                    </Grid>
                  </Grid>
                </>
              )}
            </Grid>
          ))}
        </Grid>
      );
    }
  };

  render() {
    // console.log("BET props: ", this.props);
    return (
      // <Card sx={{ margin: 2, paddingBottom: 1, paddingTop: 1, overflow: "hidden" }}>
      //   <CardContent style={{ padding: 4 }}>
      <ListItem divider>
        <Grid container direction="column" justifyContent="center" alignItems="center">
          <Grid item>
            <Grid container direction="row" justifyContent="center" alignItems="center">
              <Hidden mdDown>
                <Grid item xs={12} sm={2} container direction="column" justifyContent="center" alignItems="center">
                  <Grid item>{this._getResultIcon(this.props.bet.result)}</Grid>
                </Grid>
              </Hidden>
              {this._renderBody()}
              <Grid item xs={12} sm={2} container direction="column" justifyContent="center" alignItems="center">
                <Grid item>
                  {!this.props.isSocial && (
                    <Typography variant="subtitle2">
                      {this._getReturn(this.props.bet.result, this.props.bet.risk, this.props.bet.win)}
                    </Typography>
                  )}
                </Grid>
                <Grid item>
                  <Grid container direction="row" justifyContent="center" alignItems="center">
                    {this.props.bet.isCoinflip && (
                      <Grid item>
                        <AutorenewSharp fontSize="small" />
                      </Grid>
                    )}
                    {this.props.bet.isFreeWager && (
                      <Grid item>
                        <LocalAtm fontSize="small" />
                      </Grid>
                    )}
                    <Grid item>
                      {!this.props.isSocial && (
                        <Typography variant="subtitle2">
                          {this.props.bet.risk} to win {this.props.bet.win}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {this.props.allowGrading && (
            <Grid item>
              <Grid
                container
                direction="row"
                justifyContent="center"
                alignItems="center"
                spacing={2}
                sx={{ paddingTop: 1 }}
              >
                <Grid item>
                  <Button
                    variant="outlined"
                    disabled={this.state.buttonsDisabled}
                    onClick={() => this._gradeBet("win")}
                  >
                    Win
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    disabled={this.state.buttonsDisabled}
                    onClick={() => this._gradeBet("loss")}
                  >
                    Loss
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="outlined"
                    disabled={this.state.buttonsDisabled}
                    onClick={() => {
                      this._gradeBet("push");
                    }}
                  >
                    Push
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </ListItem>
      //   </CardContent>
      // </Card>
    );
  }
}

Bet.defaultProps = {
  fromAdmin: false,
  allowGrading: false,
  isSocial: false,
};

Bet.propTypes = {
  bet: PropTypes.object,
  classes: PropTypes.object,
  fromAdmin: PropTypes.bool,
  allowGrading: PropTypes.bool,
  dispatch: PropTypes.func,
  ui: PropTypes.string,
  balances: PropTypes.object,
  isSocial: PropTypes.bool,
  activePromo: PropTypes.string,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  pastBetsList: {
    width: "100%",
  },
});

export default connect()(withStyles(styles)(Bet));
