import React from "react";
import firebase from "../../../firebase";
import AdminTournaments from "./AdminTournaments";
import AdminEntries from "./AdminEntries";
import PickemAppBar from "../PickemAppBar";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Avatar, CircularProgress, ListItemAvatar, ListItemText } from "@mui/material";
import { EmojiEvents, LocalActivity } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

const AdminHome = () => {
  const auth = firebase.auth();
  const db = firebase.firestore();
  const history = useHistory();

  const [userDetails, setUserDetails] = React.useState({});
  const [tournamentsOpen, setTournamentsOpen] = React.useState(false);
  const [entriesOpen, setEntriesOpen] = React.useState(false);
  const profile = useSelector((state) => state.firebase.profile);

  if (!profile.isLoaded) {
    return <CircularProgress />;
  }

  if (profile.isLoaded && !profile.is_admin) {
    return <div>Not an admin</div>;
  }

  return (
    <div>
      <AdminTournaments open={tournamentsOpen} handleClose={() => setTournamentsOpen(false)} />
      <AdminEntries open={entriesOpen} handleClose={() => setEntriesOpen(false)} />
      <PickemAppBar title="Admin" isHome={false} />
      <List sx={{ width: "100%", bgcolor: "background.paper" }}>
        <ListItem onClick={() => setTournamentsOpen(true)} divider>
          <ListItemAvatar>
            <Avatar>
              <EmojiEvents />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Tournaments" secondary="Tournament Manager" />
        </ListItem>

        <ListItem onClick={() => setEntriesOpen(true)} divider>
          <ListItemAvatar>
            <Avatar>
              <LocalActivity />
            </Avatar>
          </ListItemAvatar>
          <ListItemText primary="Entries" secondary="Entry Manager" />
        </ListItem>
      </List>
    </div>
  );
};

AdminHome.propTypes = {};

export default AdminHome;
