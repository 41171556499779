import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { firestoreConnect } from "react-redux-firebase";
import GenericBackHeader from "../../../../components/GenericBackHeader";
import BetList from "../../../Sportsbook/BetList";
import BottomNav from "../../../../components/BottomNav";
import ReferralLink from "../../../../components/ReferralLink";
import Box from "@mui/material/Box";
import collect from "collect.js";
import { Container } from "@mui/material";

class PendingBets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    console.log("admin past bets bets: ", this.props);
    return (
      <div>
        <Box>
          <GenericBackHeader title="Past Bets" showBackButton />
          {this.props.bets && <BetList noAccordion fromAdmin bets={this.props.bets} />}
          {this.props.bets && !this.props.bets.length && (
            <Container>
              <ReferralLink />
            </Container>
          )}
        </Box>
        <BottomNav spacing={2} isBookieMode activeTab={3} managerGid={this.props.profile.managerGid} />
      </div>
    );
  }
}

PendingBets.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  trans: PropTypes.object,
  bets: PropTypes.object,
  profile: PropTypes.object,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  pastBetsList: {
    width: "100%",
  },
  table: {
    minWidth: 650,
  },
});

const mapStateToProps = (state) => {
  const getPastBets = (b) => {
    const collection = collect(b);
    const pendingBets = collection.filter((val) => val.result !== "pending");
    return pendingBets.take(75).all();
  };

  return {
    uid: state.firebase.auth.uid,
    email: state.firebase.auth.email,
    profile: state.firebase.profile,
    bets: getPastBets(state.firestore.data?.admin_pastBets),
  };
};

export default compose(
  connect(mapStateToProps),
  withRouter,
  firestoreConnect((props) => {
    return [
      {
        collection: `groups/${props.profile.managerGid}/bets`,
        orderBy: ["minGameStartTime", "desc"],
        limit: 200,
        storeAs: "admin_pastBets",
      },
    ];
  })
)(withStyles(styles)(PendingBets));
//
// renderUserPnL(user){
//   console.log("rendering pnl for user: ",user)
//   let b = this.props.bets
//   let wagerCount = 0; let winAmt = 0; let lossAmt = 0;
//   Object.keys(b).forEach((i) => {
//     if (b[i].email === user && b[i].result !== "pending"){
//       wagerCount ++;
//       if (b[i].result === "win") winAmt += b[i].win;
//       if (b[i].result === "loss") lossAmt += b[i].risk;
//     }
//   })
//   return <>Net Win/Loss: {(winAmt - lossAmt).toFixed(2)}.  <br/> Total wagers: {wagerCount}.  Total win: {winAmt.toFixed(2)}.  Total loss: {lossAmt.toFixed(2)}.</>
// }
//
// renderUserPendingBetSummary(user){
//   let b = this.props.bets
//   let pendingWagerCount = 0; let pendingWagerRisk = 0; let pendingWagerWin = 0;
//   Object.keys(b).forEach((i) => {
//     if (b[i].email === user && b[i].result === "pending"){
//       pendingWagerCount ++;
//       pendingWagerRisk += b[i].risk;
//       pendingWagerWin += b[i].win
//     }
//   })
//   return <>{pendingWagerCount} pending wagers risking {pendingWagerRisk.toFixed(2)} to win {pendingWagerWin.toFixed(2)}</>
// }
//
// renderUserPendingTransSummary(user){
//   let t = this.props.trans
//   let pendingDepositCount = 0; let pendingWithdrawalCount = 0;
//   let totalWithdrawals = 0; let totalDeposits = 0;
//   Object.keys(t).forEach((i) => {
//     if (t[i].email === user && t[i].type === "deposit" && t[i].state === "pending") pendingDepositCount ++;
//     if (t[i].email === user && t[i].type === "withdrawal" && t[i].state === "pending") pendingWithdrawalCount ++;
//     if (t[i].email === user && t[i].type === "deposit" && t[i].state === "approved") totalDeposits += t[i].amount;
//     if (t[i].email === user && t[i].type === "withdrawal" && t[i].state === "approved") totalWithdrawals += t[i].amount;
//   })
//   return <>
//     Total Deposits: {totalDeposits}.  Total Withdrawals: {totalWithdrawals} <br/>
//     {pendingDepositCount} pending deposits and {pendingWithdrawalCount} pending withdrawals</>
// }
