import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { connect } from "react-redux";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { joinGroup } from "../../../../reducers/register-reducer";

class JoinPublicBook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: 0,
    };
  }

  _joinGroup = () => {
    this.props.dispatch(joinGroup({ uid: this.props.uid, gid: this.state.gid }));
    this.props.closeCallback();
  };

  render() {
    return (
      <Dialog open={this.props.open}>
        <DialogTitle>Your Past Books</DialogTitle>
        <DialogContent sx={{ margin: 2, padding: 0 }}>
          <Select
            fullWidth
            labelId="demo-simple-select-helper-label"
            id="demo-simple-select-helper"
            onChange={(e) => {
              this.setState({ gid: e.target.value });
            }}
          >
            {
              this.props.profile.recentBooks && this.props.allGroups && this.props.profile.recentBooks.map((i) => {
                return (
                    <MenuItem key={i} value={i}>
                      {
                        this.props.allGroups[i].groupName === "NameYourSportsbook" ? "Your Own Book" : this.props.allGroups[i].groupName
                      }
                    </MenuItem>
                )
              })
            }
          </Select>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={this.props.closeCallback}>
            Cancel
          </Button>
          <Button variant="contained" onClick={this._joinGroup}>
            Join
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

JoinPublicBook.propTypes = {
  open: PropTypes.bool,
  closeCallback: PropTypes.func,
  uid: PropTypes.string.isRequired,
  gid: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  groupDisplayName: PropTypes.string.isRequired,
  dispatch: PropTypes.func,
  profile: PropTypes.object,
  allGroups: PropTypes.object,
};

export default connect()(JoinPublicBook);
