import React, { useEffect } from "react";
import PickemAppBar from "../PickemAppBar";
import firebase from "../../../firebase";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import {ListItemButton, ListItemIcon, ListItemText, Typography} from "@mui/material";
import Grid from "@mui/material/Grid";
import {TournamentListItem} from "../Tournaments/TournamentListItem.js";
import CheckForHandle from "../Common/CheckForHandle.js";
import ListItem from "@mui/material/ListItem";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents.js";
import moment from "moment";
import StyleIcon from '@mui/icons-material/Style';
import {FieldValue} from "@google-cloud/firestore/build/src/index.js";
import {Card} from "./Card.js";
import Button from "@mui/material/Button";
import {getFirebase} from "react-redux-firebase";

export function CardCollection() {
  const auth = firebase.auth();
  const db = firebase.firestore();
  const [cards, setCards] = React.useState([]);

  const fetchCards = async () => {
    if (!auth?.currentUser?.uid) return;
    const docRef = db.collection("pickem")
        .doc("user")
        .collection(firebase.auth().currentUser.uid)
        .doc("public")
    const doc = await docRef.get();
    if (!doc.exists) {
      alert("you have no cards")
    } else {
      let c = doc.data().Cards
      setCards(c)
    }
  };

  const buyCards = async () => {

    if (!auth?.currentUser?.uid)
        return;

    const ranks = ['2','3','4','5','6','7','8','9','10','J','Q','K','A'];
    const suits = ['h','c','s','d']

    var newCard = ranks[Math.floor(Math.random()*ranks.length)] + suits[Math.floor(Math.random()*suits.length)]

    const docRef = db.collection("pickem")
        .doc("user")
        .collection(firebase.auth().currentUser.uid)
        .doc("public")

     await docRef.update({
      Cards: getFirebase().firestore.FieldValue.arrayUnion(newCard)
    })

      await fetchCards()

  }


  useEffect(() => {
    fetchCards();
  }, [auth?.currentUser?.uid]);

  return (
      <>
        <PickemAppBar title="Pickem Cards" />
        <Box  sx={{
            marginLeft: 2,
            padding: 2,
            border: 1,
            borderColor: "#bf0070",
            borderRadius: 2,
            marginBottom: 2,
        }}>
            <Typography
                component="span" variant="body1" color="text.primary"
                sx={{ fontWeight: 800, fontSize: "2rem", paddingBottom: 2 }}>
                Your Cards
            </Typography>
            <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
            >
                    {cards.map((i) => (<Card key={i} rank={i[0]} suit={i.slice(-1)}/>))}
            </Grid>
        </Box>


          <Grid
              container
              direction="row"
              justifyContent="center"
              alignItems="center"
          >
              <Button
                  style={{ marginBottom: 5, marginTop: 10 }}
                  variant="outlined"
                  size="large"
                  onClick={buyCards}
              >
                  Buy Cards
              </Button>
          </Grid>



          </>

  );
}
