import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import { getFirebase } from "react-redux-firebase";
import Loading from "../../components/Loading";
import {claimFreeAccount, showSignInDialog, showSignUpDialog} from "../../reducers/new-user-reducer";
import { Page } from "../LandingPage";
import SignUpDialog from "../Auth/SignUpDialog";
import {createAnonAccount} from "../Auth/functions/createAccount";

class RouterHelper extends Component {
  constructor(props) {
    super(props);
    this.state = { firstLoad: true };
  }

  render() {
    console.log("ROUTER HELPER state, props", this.state, this.props);

    // eph stuff
    if (this.props.location.pathname.includes("shareBet/")) {
      return null;
    }



    if (this.props.location.pathname.includes("create")) {
      this.props.dispatch(showSignInDialog(false));
      this.props.dispatch(claimFreeAccount(true))
      console.log("pete traffic!")
      console.log("pete traffic this.props!",this.props)
      const newBookName = this.props.location.search.split("=")[1]
          .replace("%20"," ")
          .replace("+"," ")
          .replace("%27","'")

      createAnonAccount(this.props.location.pathname, newBookName)
      return <Redirect to={{ pathname: "/sports" }} />;
    }


    if (this.props.auth.isLoaded && !this.props.auth.isEmpty) {
      this.props.dispatch(showSignInDialog(false));
    }

    if (this.props.auth.isLoaded && this.props.auth.isEmpty && this.props.location.pathname === "/sports") {
      this.props.dispatch(showSignInDialog(true));
    }

    // if a user navigates directly to /sports, they're probably a returning user
    // so just keep them at /sports and show log in dialog
    if (this.props.auth.isLoaded && this.props.auth.isEmpty && this.props.location.pathname === "/sports") {
      this.props.dispatch(showSignInDialog(true));
    }

    // user has clicked 'login' from the landing page
    if (this.props.auth.isLoaded && this.props.auth.isEmpty && this.props.location.pathname === "/login") {
      this.props.dispatch(showSignInDialog(true));
      return <Redirect to={{ pathname: "/sports" }} />;
    }

    //
    // if (
    //   this.props.auth.isLoaded &&
    //   this.props.auth.isEmpty &&
    //   this.props.location.pathname !== "/sports" &&
    //   this.props.location.pathname.includes("welcome") &&
    //   !this.props.location.pathname.includes("join")
    // ) {
    //   console.log(
    //     "ROUTER: auth is loaded, auth is empty, path includes welcome, path !==  sports, path !includes join"
    //   );
    //   return <Page />;
    // }

    // redirect after creating an account from a landing page
    if (this.props.auth.isLoaded && !this.props.auth.isEmpty && (this.props.location.pathname === "/" || this.props.location.pathname === "/take-bets")) {
      return <Redirect to={{ pathname: "/sports" }} />;
    }

    // if (
    //   this.props.auth.isLoaded &&
    //   this.props.auth.isEmpty &&
    //   this.props.location.pathname !== "/sports" &&
    //   this.props.location.pathname.includes("take-bets") &&
    //   !this.props.location.pathname.includes("join")
    // ) {
    //   console.log(
    //     "ROUTER: auth is loaded, auth is empty, path includes welcome, path !==  sports, path !includes join"
    //   );
    //   return <Page2Bookies />;
    // }

    if (
      this.props.auth.isLoaded &&
      this.props.auth.isEmpty &&
      this.props.location.pathname !== "/sports" &&
        !this.props.location.pathname.includes("join") &&
        !this.props.location.pathname.includes("kelvin")
    ) {
      console.log("ROUTER: auth is loaded, auth is empty, path !==  sports, path !includes join");
      return <Page url={this.props.location.pathname}/>;
    }

    if (this.props.auth.isLoaded && this.props.auth.isEmpty && this.props.location.pathname === "/login") {
      this.props.dispatch(showSignInDialog(true));
      return <Redirect to={{ pathname: "/sports" }} />;
    }

    if ((this.props.location.pathname.includes("join") || this.props.location.pathname.includes("kelvin")) && this.props.auth.isLoaded && this.props.auth.isEmpty) {
      console.log("ROUTER: path includes join, auth loaded, auth empty");
      console.log("ROUTER: show signup dialog");
      this.props.dispatch(showSignInDialog(false));
      this.props.dispatch(showSignUpDialog(true));
      return <Redirect to={{ pathname: "/sports" }} />;
      // this.props.dispatch(showSignUpDialog(true))
      // const referringGid = this.props.location.pathname.split("/join/")[1];
      // console.log("REFERRED BY GID: ",referringGid)
      // return <SignUpDialog open={this.props.showSignUpDialog} isBookieReferral referringGid={referringGid}/>
      // this.props.dispatch(showSignUpDialog(true))
      // return <SignUpDialog open={true} isBookieReferral referringGid={referringGid} />;
    }

    // if (this.props.auth.isLoaded && !this.props.auth.isEmpty) {
    //   console.log("ROUTER: auth is loaded, auth is not empty");
    //   console.log("ROUTER: redirect to /sports");
    //   return <Redirect to={{ pathname: "/sports" }} />;
    // }

    // if (this.props.location.pathname === '/free' ||
    //     this.props.location.pathname === '/take-bets' ||
    //     this.props.location.pathname === '/place-bets'
    // )
    //   return null

    if (!this.props.auth.isLoaded) {
      console.log("ROUTER: auth is not loaded");
      console.log("ROUTER: show loading message");
      return <Loading loadingMessage="Bet with Friends, not Casinos" />;
    }

    // if (this.props.profile.isLoaded) {
    //   if (this.props.profile.conversionState === "newBookie") {
    //     return <Redirect to={{ pathname: "/bookie" }} />
    //   }
    // }

    // no logging data for admins
    if (
      this.props.profile.isSuperAdmin ||
      (this.props.profile.email &&
        (this.props.profile.email.startsWith("ephlind") || this.props.profile.email.startsWith("brian.danilo")))
    ) {
      getFirebase().analytics().setAnalyticsCollectionEnabled(false);
    }

    // if (this.props.auth.isLoaded && this.props.auth.isEmpty && this.props.location.pathname === "/sports") {
    //   console.log("ROUTER: auth is loaded, auth is empty, page is /sports")
    //   console.log("ROUTER: sign in dialog and /sports")
    //   this.props.dispatch(showSignInDialog(true))
    //   return <Redirect to={{ pathname: "/sports" }} />
    // }

    return null;
  }
}

RouterHelper.propTypes = {
  auth: PropTypes.object,
  uid: PropTypes.string,
  profile: PropTypes.object,
  dispatch: PropTypes.func,
  match: PropTypes.object,
  location: PropTypes.object,
  firebase: PropTypes.object,
  history: PropTypes.object,
  newUserState: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    newUserState: state.firebase.newUserState,
  };
};
export default withRouter(connect(mapStateToProps)(RouterHelper));
