import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import TextField from "@mui/material/TextField";
import { firestoreConnect, getFirebase } from "react-redux-firebase";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import GenericBackHeader from "../../../../components/GenericBackHeader";
import { ListItemButton, ListItemSecondaryAction, ListItemText } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import BottomNav from "../../../../components/BottomNav";
import DatePicker from "@mui/lab/DatePicker";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DateAdapter from "@mui/lab/AdapterMoment";
import moment from "moment";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

const initialState = {
  activeDialog: "",
  title: "",
  text: "",
  promoName: "",
  promoStart: null,
  promoEnd: null,
};

class CreatePromo extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {}

  reset = () => {
    this.setState(initialState);
  };

  _formatDate = () => {};

  fixDate = (date) => {
    // get midnight for the date selected
    const newDate = moment(date).format("MM-DD-YYYY");
    return moment(newDate).valueOf();
  };

  savePromo = () => {
    const DB = getFirebase().firestore();

    if (this.state.promoName && this.state.promoStart && this.state.promoEnd) {
      return DB.collection("groups")
        .doc(this.props.profile.managerGid)
        .collection("promos")
        .doc(this.state.promoName)
        .set({
          // starts at 12:01am of promoStart, ends at 11:59pm of promoEnd
          promoStart: this.fixDate(this.state.promoStart),
          promoEnd: Number(this.fixDate(this.state.promoEnd) + 1000 * 60 * 60 * 24 - 1000 * 60),
          sportTitle: this.state.sportTitle || null,
        })
        .then((res) => {
          return DB.collection("groups")
            .doc(this.props.profile.managerGid)
            .collection("promos")
            .doc(this.state.promoName)
            .collection("results")
            .add({ dummy: "data" });
        })
        .then((res) => {
          console.log("res", res);
          this.reset();
          alert("Promo Successfully Created!");
        });
    } else {
      alert("missing some info");
    }
  };

  renderEligibleSports = () => {
    const AS = this.props.availableSports;
    let returnArray = [];
    for (const v in AS) {
      console.log("V: ", v);
      returnArray.push(<MenuItem value={AS[v].title}>{AS[v].title}</MenuItem>);
    }
    return returnArray;
  };

  renderDialogText = () => {
    // const convertDate = (field) => {
    //   const newDate = moment(this.state[field]).format("MM-DD-YYYY");
    //   return moment.utc(newDate).valueOf();
    // }

    if (this.state.activeDialog === "promoStart") {
      return (
        <DialogContentText sx={{ padding: 2 }}>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <DatePicker
              label="Start Date"
              value={this.state.promoStart}
              onChange={(newDate) => this.setState({ promoStart: newDate })}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </DialogContentText>
      );
    }

    if (this.state.activeDialog === "promoEnd") {
      return (
        <DialogContentText sx={{ padding: 2 }}>
          <LocalizationProvider dateAdapter={DateAdapter}>
            <DatePicker
              label="End Date"
              value={this.state.promoEnd}
              onChange={(newDate) => this.setState({ promoEnd: newDate })}
              renderInput={(params) => <TextField {...params} />}
            />
          </LocalizationProvider>
        </DialogContentText>
      );
    }

    if (this.state.activeDialog === "promoName") {
      return (
        <>
          <DialogContentText>{this.state.text}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            // label={this.getLabel()}
            // type="email"
            fullWidth
            variant="standard"
            onChange={(e) => this.setState({ promoName: e.target.value })}
          />
        </>
      );
    }

    if (this.state.activeDialog === "sportTitle") {
      return (
        <>
          <DialogContentText>{this.state.text}</DialogContentText>
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-helper-label"
              id="demo-simple-select-helper"
              defaultValue=""
              value={this.state.sportTitle}
              onChange={(e) => this.setState({ sportTitle: e.target.value })}
            >
              {this.renderEligibleSports()}
            </Select>
          </FormControl>
        </>
      );
    }

    if (this.state.activeDialog === "save") {
      return (
        <>
          <DialogContentText>{this.state.text}</DialogContentText>
        </>
      );
    }
  };

  DIALOG = () => {
    let d = this.state.activeDialog;
    return (
      <div>
        <Dialog open={d !== ""}>
          <DialogTitle>{this.state.title}</DialogTitle>
          <DialogContent>{this.renderDialogText()}</DialogContent>
          <DialogActions>
            <Button onClick={this.reset}>Cancel</Button>
            <Button
              onClick={() => {
                this.setState({ activeDialog: "" });
                // this.updateFirestore(d);
                // this.reset();
              }}
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  render() {
    console.log("create promo this.props: ", this.props);
    console.log("create promo this.state: ", this.state);
    console.log("this.fixDate(this.state.promoStart): ", this.fixDate(this.state.promoStart));

    if (this.props.groupConfigs)
      return (
        <>
          <GenericBackHeader title="Create Promo" showBackButton />

          {this.DIALOG()}

          <ListItemButton
            divider
            onClick={() => {
              this.setState({
                activeDialog: "promoName",
                title: "Create Promo Name",
                text: "Once the promo starts, you can't change this.",
              });
            }}
          >
            <ListItemText
              primary="Promo Name"
              secondary={this.state.promoName ? this.state.promoName : "Once named, you cannot change"}
            />
            <ListItemSecondaryAction>
              <ChevronRight />
            </ListItemSecondaryAction>
          </ListItemButton>

          <ListItemButton
            divider
            onClick={() => {
              this.setState({
                activeDialog: "promoStart",
                title: "Select Start Date",
                text: "Bets must occur on or after this date to count",
              });
            }}
          >
            <ListItemText
              primary="Promo Start"
              secondary={
                this.state.promoStart
                  ? moment(this.state.promoStart).format("MMM DD YYYY") + " at 12:01 am"
                  : "Select a start date"
              }
            />
            <ListItemSecondaryAction>
              <ChevronRight />
            </ListItemSecondaryAction>
          </ListItemButton>

          <ListItemButton
            divider
            onClick={() => {
              this.setState({
                activeDialog: "promoEnd",
                title: "Select End Date",
                text: "Bets must occur on or before this date to count",
              });
            }}
          >
            <ListItemText
              primary="Promo End"
              secondary={
                this.state.promoEnd
                  ? moment(this.state.promoEnd).format("MMM DD YYYY") + " at 11:59pm"
                  : "Select an end date"
              }
            />
            <ListItemSecondaryAction>
              <ChevronRight />
            </ListItemSecondaryAction>
          </ListItemButton>

          <ListItemButton
            divider
            onClick={() => {
              this.setState({
                activeDialog: "sportTitle",
                title: "Eligible Sports",
                text: "Which sports count toward the promo?",
              });
            }}
          >
            <ListItemText
              primary="Eligible Sport"
              secondary={
                this.state.sportTitle
                  ? this.state.sportTitle
                  : "Restrict leaderboard to a single sport, or leave this field blank to allow all."
              }
            />
            <ListItemSecondaryAction>
              <ChevronRight />
            </ListItemSecondaryAction>
          </ListItemButton>

          <ListItemButton
            divider
            onClick={() => {
              // this.setState({
              //   activeDialog: "save",
              //   title: "Save This Promo?",
              //   text: "It will not go live yet",
              // });
              this.savePromo();
            }}
          >
            <ListItemText primary="SAVE" secondary="This creates the promo, but it will not go live yet." />
          </ListItemButton>

          <BottomNav isBookieMode activeTab={3} managerGid={this.props.profile.managerGid} />
        </>
      );
    else return <>loading...</>;
  }
}

CreatePromo.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  groupConfigs: PropTypes.object,
  adminProfile: PropTypes.object,
  history: PropTypes.array,
  profile: PropTypes.object,
  availableSports: PropTypes.object,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  customCard: {
    borderColor: theme.palette.primary.light,
    borderWidth: 2,
    marginBottom: 5,
    alignItems: "center",
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  pastBetsList: {
    width: "100%",
  },
});

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    email: state.firebase.auth.email,
    profile: state.firebase.profile,
    groupConfigs: state.firestore.data?.groupConfigs,
    availableSports: state.firestore.data?.createPromos_availableSports,
  };
};

export default compose(
  connect(mapStateToProps),
  withRouter,
  firestoreConnect((props) => {
    return [
      {
        collection: "groups",
        doc: props.profile.managerGid,
        storeAs: "groupConfigs",
      },
      {
        collection: "available_lines",
        where: [
          ["active", "==", true],
          ["weight", ">", 0],
        ],
        storeAs: "createPromos_availableSports",
        orderBy: ["weight"],
      },
    ];
  })
)(withStyles(styles)(CreatePromo));
