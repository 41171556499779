import { combineReducers } from "redux";
import { firebaseReducer } from "react-redux-firebase";
import { firestoreReducer } from "redux-firestore";
import userReducer from "./user-reducer";
import sportsBookReducer from "./sportsbook-reducer";
import cashierReducer from "./cashier-reducer";
import groupManagerReducer from "./group-manager-reducer";
import registerReducer from "./register-reducer";
import adminReducer from "./admin-reducer";
import userDetailsReducer from "./user-details-reducer";
import newUserReducer from "./new-user-reducer";
import appReducer from "./app-reducer";
import pickemTournamentBase from "./pickem/tournamentEntry";

const reducers = combineReducers({
  firebase: firebaseReducer,
  firestore: firestoreReducer,
  cashierState: cashierReducer,
  sportsbookState: sportsBookReducer,
  groupManagerState: groupManagerReducer,
  registerState: registerReducer,
  adminState: adminReducer,
  userDetailState: userDetailsReducer,
  newUserState: newUserReducer,
  userState: userReducer,
  appState: appReducer,
  pickemTournamentEntry: pickemTournamentBase,
});

export default reducers;
