import React, { useState } from "react";
import { useSelector } from "react-redux";
import PersonIcon from "@mui/icons-material/Person.js";
import LoginIcon from "@mui/icons-material/Login.js";
import { useHistory } from "react-router-dom";
import { CircularProgress } from "@mui/material";
import Button from "@mui/material/Button";
import { PickemLogin } from "./PickemLogin.js";

const authSelector = (state) => state.firebase.auth;

const ProfileLoginButton = (props) => {
  const history = useHistory();
  const authState = useSelector(authSelector);
  const [loginOpen, setLoginOpen] = useState(false);

  if (!authState.isLoaded) return <CircularProgress />;
  return (
    <div>
      {authState.isLoaded && !authState.isEmpty && <PersonIcon onClick={() => history.push("/pickem/profile/")} />}
      {authState.isLoaded && authState.isEmpty && (
        <>
          <Button variant="outlined" startIcon={<LoginIcon />} onClick={() => setLoginOpen(true)}>
            Login
          </Button>
          <PickemLogin isOpen={loginOpen} closeHandler={() => setLoginOpen(false)} />
        </>
      )}
    </div>
  );
};

ProfileLoginButton.propTypes = {};

export default ProfileLoginButton;
