import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { TournamentMakePicks } from "./MakePicks/TournamentMakePicks";
import Box from "@mui/material/Box";
import { useDispatch, useSelector } from "react-redux";
import { pickemTournamentBaseEntry } from "../../../reducers/pickem/tournamentEntry";
import Dialog from "@mui/material/Dialog";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import moment from "moment";
import firebase from "../../../firebase";
import List from "@mui/material/List";
import { EntryListItem } from "../Entries/EntryListItem";
import { PickemLogin } from "../Auth/PickemLogin.js";
import PrizesModal from "./PrizesModal.js";

const selectTournamentEntry = (state) => state.pickemTournamentEntry;
const selectAuth = (state) => state.firebase.auth;

export function TournamentDetailPreStart(props) {
  const [enterTournament, setEnterTournament] = React.useState(false);
  const tournamentEntryState = useSelector(selectTournamentEntry);
  const authState = useSelector(selectAuth);
  const dispatch = useDispatch();
  const [successDialogOpen, setSuccessDialogOpen] = React.useState(false);
  const [tournamentStarted, setTournamentStarted] = React.useState(false);
  const [tournamentEntries, setTournamentEntries] = React.useState([]);
  const [prizesOpen, setPrizesOpen] = React.useState(false);

  useEffect(() => {
    if (!firebase.auth().currentUser) return;
    if (!props.tournamentID) return;
    const db = firebase.firestore();
    const unsubscribe = db
      .collection("pickem")
      .doc("tournies")
      .collection("entries")
      .where("userID", "==", firebase.auth().currentUser.uid)
      .where("tournamentID", "==", props.tournamentID)
      .onSnapshot((snapshot) => {
        let entries = [];
        snapshot.forEach((doc) => {
          const entry = doc.data();
          const originalPoints = entry.picks.map((pick) => parseFloat(pick.points)).reduce((a, b) => a + b, 0.0);
          const pointsPending = entry.picks
            .filter((pick) => pick.result === "pending")
            .map((pick) => parseFloat(pick.points))
            .reduce((a, b) => a + b, 0.0);
          const pointsEarned = entry.picks
            .filter((pick) => pick.result === "win" || pick.result === "push")
            .map((pick) => (pick.result === "win" ? parseFloat(pick.points) : entry.riskAmount))
            .reduce((a, b) => a + b, 0.0);
          entries.push({
            ...entry,
            id: doc.id,
            originalPoints,
            pointsPending,
            pointsEarned,
            pointsPossible: pointsPending + pointsEarned,
          });
        });
        setTournamentEntries(entries);
      });
    return () => unsubscribe();
  }, [firebase.auth().currentUser?.uid, props.tournamentID]);

  useEffect(() => {
    dispatch(pickemTournamentBaseEntry(props.tournamentID, props.tournament?.picks));
    checkTournamentStarted();
  }, [props.tournamentID]);

  useEffect(() => {
    const interval = setInterval(checkTournamentStarted, 15000);
    return () => clearInterval(interval);
  }, []);

  const submitDisabled = () => {
    let picksGood = !tournamentEntryState?.pickGood.reduce((a, b) => a && b, true);
    if (picksGood) {
      return true;
    }
    if (tournamentStarted) {
      return true;
    }
  };

  const checkTournamentStarted = () => {
    if (props.tournament?.hasStarted) {
      setTournamentStarted(true);
    }
    if (props.tournament?.startTime < Date.now()) {
      setTournamentStarted(true);
    }
  };

  const enterTournamentCallback = (success) => {
    if (success) {
      setSuccessDialogOpen(true);
    }
    setEnterTournament(false);
  };


  return (
    <Box>
      <Box
        sx={{
          textAlign: "center",
          padding: 1,
          border: 1,
          borderRadius: 1,
          minWidth: "250px",
          borderColor: "#bf0070",
          color: "#bf0070",
          margin: 1,
        }}
      >
        <Grid xs={12} container direction="row" justifyContent="center" alignItems="center">
          <Grid item xs={12}>
            <Grid xs={12} container direction="row" justifyContent="center" alignItems="center">
              <Grid item xs={2}>
                <EmojiEventsIcon sx={{ color: "#bf0070" }} />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body1" color="text.primary" sx={{ fontWeight: 800 }}>
                  {props.tournament.tournamentName}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <EmojiEventsIcon sx={{ color: "#bf0070" }} />
              </Grid>

              <Grid item xs={12}>
                <Grid xs={12} container direction="column" justifyContent="center" alignItems="center">
                  <Grid item>
                    <Typography component="span" variant="body2" color="text.primary">
                      {props.tournament.numEntries}/{props.tournament.maxEntries} Entries
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography component="span" variant="body2" color="text.primary">
                      Starts: {moment(props.tournament.startTime).calendar()}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography component="span" variant="body2" color="text.primary">
                      You have {tournamentEntries.length} entries
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Typography component="span" variant="body2" color="text.secondary">
                      Games must start by {moment(props.tournament.maxGameStartTime).format("lll")}
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Button variant="outlined" onClick={() => setPrizesOpen(true)} size="small">
                      Prizes
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <PrizesModal open={prizesOpen} closeHandler={() => setPrizesOpen(false)} prizes={props.tournament.prizes} />
      </Box>

      <PickemLogin isOpen={authState.isLoaded && authState.isEmpty} />

      {authState.isLoaded && !authState.isEmpty && props.tournamentID && (
        <div>
          <TournamentMakePicks
            tournamentID={props.tournamentID}
            enterTourney={enterTournament}
            enterTourneyCallback={enterTournamentCallback}
            riskAmount={props.tournament.riskAmount}
          />
          <Grid container direction="column" justifyContent="center" alignItems="center">
            <Grid item sx={{ padding: 2 }}>
              <Button
                size="small"
                variant="contained"
                // disabled={submitDisabled()}
                onClick={() => setEnterTournament(true)}
              >
                Submit Entry
              </Button>
            </Grid>
            <Grid item>{tournamentStarted && <Typography>Tournament has started.</Typography>}</Grid>
          </Grid>
        </div>
      )}
      {/*<ListItem*/}
      {/*  key="total"*/}
      {/*  alignItems="flex-start"*/}
      {/*  divider*/}
      {/*  secondaryAction={*/}
      {/*    <Typography variant="h6" sx={{ paddingRight: 6 }}>*/}
      {/*      {getTotal()}*/}
      {/*    </Typography>*/}
      {/*  }*/}
      {/*>*/}
      {/*  <ListItemIcon sx={{ color: "#bf0070" }}>*/}
      {/*    <ConfirmationNumberIcon />*/}
      {/*  </ListItemIcon>*/}
      {/*  <ListItemText primary={<Typography variant="h6">Total</Typography>} />*/}
      {/*</ListItem>*/}

      {tournamentEntries.length > 0 && (
        <div>
          <Grid xs={12} container direction="row" justifyContent="center" alignItems="center">
            <Typography
              sx={{
                textAlign: "center",
                padding: 1,
                borderBottom: 1,
                minWidth: "250px",
                borderColor: "#bf0070",
                color: "#bf0070",
              }}
            >
              Current Entries
            </Typography>
          </Grid>
          <List sx={{ width: "100%" }}>
            {tournamentEntries.map((entry) => {
              return <EntryListItem entry={entry} key={entry.id} navigate_on_click={false} />;
            })}
          </List>
        </div>
      )}

      <Dialog
        open={successDialogOpen}
        onClose={() => successDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Entry Confirmed"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">Success, you are in the tournament!</DialogContentText>
        </DialogContent>
        <DialogActions sx={{ padding: 2 }}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <Grid item>
              <Button variant="contained" onClick={() => setSuccessDialogOpen(false)} autoFocus>
                Ok
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

TournamentDetailPreStart.propTypes = {
  tournament: PropTypes.object.isRequired,
  tournamentID: PropTypes.string.isRequired,
};
