import React, { useEffect } from "react";
import firebase from "../../../firebase";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { pickemTournamentBaseEntry } from "../../../reducers/pickem/tournamentEntry";
import PickemAppBar from "../PickemAppBar";
import { TournamentDetailPreStart } from "./TournamentDetailPreStart";
import TournamentDetailStarted from "./TournamentDetailStarted";

export function TournamentDetail(props) {
  const [tournament, setTournament] = React.useState({});
  const dispatch = useDispatch();

  const fetchTournament = async () => {
    const db = firebase.firestore();
    await db
      .collection("pickem")
      .doc("tournies")
      .collection("active")
      .doc(props.match.params.tournamentID)
      .onSnapshot((docSnapshot) => {
        setTournament({ ...docSnapshot.data(), id: docSnapshot.id });
      });
  };

  useEffect(() => {
    if (!tournament.hasStarted) {
      dispatch(pickemTournamentBaseEntry(tournament.id, tournament.picks));
    }
  }, [tournament.id, tournament.hasStarted]);

  useEffect(() => {
    fetchTournament();
  }, []);

  return (
    <div>
      <PickemAppBar title="Pickem" isHome={false} />
      {!tournament?.hasStarted && <TournamentDetailPreStart tournament={tournament} tournamentID={tournament.id} />}
      {tournament?.hasStarted && (
        <TournamentDetailStarted
          tournament={tournament}
          tournamentID={tournament.id}
          riskAmount={tournament.riskAmount}
        />
      )}
    </div>
  );
}

TournamentDetail.propTypes = {
  match: PropTypes.object.isRequired,
};
