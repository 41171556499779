import { getFirebase } from "react-redux-firebase";
// import { auth } from "../firebase";
import { showSignInDialog, signInError } from "../../../reducers/new-user-reducer";
import store from "../../../store";

export const forgotPassword = (Email) => {
  // getFirebase().analytics().logEvent("FORGOT_PASSWORD");
  getFirebase()
    .auth()
    .sendPasswordResetEmail(Email)
    .then(() => {
      alert("Please check your email...");
    })
    .catch((e) => {
      console.log(e);
    });
};

export const signInWithEmail = async (email, password) => {
  console.log("sign in with email function: email,pass: ", email, password);
  try {
    await getFirebase().auth().signInWithEmailAndPassword(email, password);
    store.dispatch(showSignInDialog(false));
  } catch (e) {
    store.dispatch(signInError(e));
    throw new Error(e);
  }
};

export const loginWithGoogle = async () => {
  try {
    const firebase = getFirebase();
    var provider = new firebase.auth.GoogleAuthProvider();
    console.log("provider: ", provider);
    const loginRes = await getFirebase().auth().signInWithPopup(provider);
    console.log("loginRes: ", loginRes);
    console.log("loginRes.loginRes.additionalUserInfo: ", loginRes.additionalUserInfo);

    if (loginRes.additionalUserInfo.isNewUser) {
      getFirebase()
        .auth()
        .currentUser.delete()
        .then(function () {
          console.log("user deleted");
        })
        .catch(function (error) {
          console.log("error deleting user: ", error);
        });
      alert("You must create an account first to login");
      window.location.href = "/";
    }
  } catch (e) {
    store.dispatch(signInError(e));
  }
};
