import React, { useEffect, useRef } from "react";
import "./App.css";
import { Redirect, Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import { isEmpty, isLoaded, useFirestoreConnect } from "react-redux-firebase";

import PropTypes from "prop-types";
import Loading from "./components/Loading";

import DateAdapter from "@mui/lab/AdapterMoment";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
// import Keen from "keen-tracking";
import { PickemHome } from "./views/Pickem/PickemHome";
import { TournamentDetail } from "./views/Pickem/Tournaments/TournamentDetail";
import { TournamentMakePicks } from "./views/Pickem/Tournaments/MakePicks/TournamentMakePicks";
import { EntriesHome } from "./views/Pickem/Entries/EntriesHome";
import AdminHome from "./views/Pickem/Admin/AdminHome";
import ProfileMenu from "./views/Pickem/Profile/ProfileMenu.js";

function PrivateRoute({ children, ...rest }) {
  const auth = useSelector((state) => state.firebase.auth);
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!isLoaded(auth)) {
          return <Loading loadingMessage={"Checking Login"} />;
        } else {
          if (!isEmpty(auth)) {
            return children;
          } else {
            return (
              <Redirect
                to={{
                  pathname: "/",
                  state: { from: location },
                }}
              />
            );
          }
        }
      }}
    />
  );
}

PrivateRoute.propTypes = {
  children: PropTypes.any,
  rest: PropTypes.any,
};

function AnonRoute({ children, ...rest }) {
  const profile = useSelector((state) => state.firebase.profile);
  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!isLoaded(profile)) {
          return <div>Loading</div>;
        } else {
          if (profile.email === "anon") {
            return children;
          } else {
            return (
              <Redirect
                to={{
                  pathname: "/sports",
                  state: { from: location },
                }}
              />
            );
          }
        }
      }}
    />
  );
}

AnonRoute.propTypes = {
  children: PropTypes.any,
  rest: PropTypes.any,
};

function AdminRoute({ children, ...rest }) {
  const auth = useSelector((state) => state.firebase.auth);
  console.log("Admin route this.props: ", this.props);
  console.log("Admin route auth: ", auth);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        if (!isLoaded(auth)) {
          return <div>Loading</div>;
        } else {
          if (!isEmpty(auth)) {
            return children;
          } else {
            return (
              <Redirect
                to={{
                  pathname: "/register",
                  state: { from: location },
                }}
              />
            );
          }
        }
      }}
    />
  );
}

AdminRoute.propTypes = {
  children: PropTypes.any,
  rest: PropTypes.any,
};

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function AppPickem() {
  // console.log("!!!Start app", process.env);
  if (!process.env.NODE_ENV || process.env.NODE_ENV === "development" || process.env.REACT_APP_SHOW_LOGS === "TRUE") {
    // dev code
    if (process.env.NODE_ENV === "development") {
      console.log("development");
      // Keen.enabled = false;
    }
  } else {
    // Keen.enabled = false;
    // production code
    console.log = function no_console() {};
  }
  useFirestoreConnect([{ collection: "globalOptions", doc: "client", storeAs: "clientOptions" }]);
  const clientOptions = useSelector((state) => state.firestore.data.clientOptions);
  const prev = usePrevious({ clientOptions });

  useEffect(() => {
    if (clientOptions && prev && prev.clientOptions) {
      // console.log("!!!!! GO", clientOptions, "Prev GO", prev.clientOptions);
      if (prev.clientOptions.version !== clientOptions.version) {
        window.location.reload();
      }
    }
  }, [clientOptions]);

  return (
    <>
      <LocalizationProvider dateAdapter={DateAdapter} />
      <Route exact path="/">
        <Redirect to="/pickem/" />
      </Route>
      <Switch>
        <Route exact path="/pickem/tournament/:tournamentID" render={(props) => <TournamentDetail {...props} />} />
        <Route
          exact
          path="/pickem/tournament/:tournamentID/makePicks"
          render={(props) => <TournamentMakePicks {...props} />}
        />

        <Route exact path="/pickem/admin/">
          <AdminHome />
        </Route>

        <Route exact path="/pickem/entries/">
          <EntriesHome />
        </Route>

        <Route exact path="/pickem/profile/">
          <ProfileMenu />
        </Route>

        <Route path="/pickem/">
          <PickemHome />
        </Route>
      </Switch>
    </>
  );
}

export default AppPickem;
