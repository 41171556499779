import { createAction, handleActions } from "redux-actions";
import { getFirebase } from "react-redux-firebase";

export const requestTransaction = createAction("REQUEST_TRANSACTION");
export const issueFreeWager = createAction("ISSUE_FREEWAGER");
export const claimFreeWager = createAction("CLAIM_FREEWAGER");

export const togglePrivacy = createAction("TOGGLE_PRIVACY");

export const fulfillTransaction = createAction("FULFILL_TRANSACTION", (state, transaction) => {
  return { state, transaction };
});

export const awardBonus = createAction("AWARD_BONUS", (state, transaction) => {
  return { state, transaction };
});

export const toggleDepositHelperOff = createAction("TOGGLE_DEPOSIT_HELPER_OFF", (uid) => {
  return { uid };
})


const initialState = {};

const adjustBalanceWithClient = async(payload) => {
    console.log("adjust payload with client: ",payload)
  const db = getFirebase().firestore();
  const groupBalanceRef = db.collection("groups").doc(payload.gid).collection("balances").doc(payload.uid);
  try {
    await db.runTransaction(async (t) => {
      const doc = await t.get(groupBalanceRef);
      const newBalance = doc.data().balance + payload.amount;
      t.update(groupBalanceRef, { balance: newBalance });
    });
    console.log("Updated Group Doc Balance");
  } catch (e) {
    console.log("Group Doc Balance Err:", e);
  }
}

export default handleActions(
  {
    REQUEST_TRANSACTION: (state, { payload }) => {
      // getFirebase().analytics().logEvent("REQUEST_TRANS");
      console.log("request transaction payload ", payload);

      const db = getFirebase().firestore();
      const adjustBalance = getFirebase().functions().httpsCallable("user-adjustBalanceGetRidOfMeLater");

      //write to global transactions
      db.collection("transactions").add(payload);

      //write to users transactions
      db.collection("users").doc(payload.uid).collection("transactions").add(payload);

      //write to group transactions
      db.collection("groups").doc(payload.gid).collection("transactions").add(payload);

      //immediately deduct balance on withdrawal attempts
      if (payload.type === "withdrawal")
        adjustBalance({ uid: payload.uid, gid: payload.gid, adjust: -1 * payload.amount });

      db.collection("users").doc(payload.uid).get()
        .then((userDocRef) => {
          const userData = userDocRef.data();
          if(!userData.firstDepositInfo) {
            db.collection("users").doc(payload.uid).set({firstDepositInfo: 0}, {merge: true})
          }
        })

      //email group owner there is a new pending transaction
      db.collection("groups")
        .doc(payload.gid)
        .get()
        .then((res) => {
          db.collection("mail").add({
            to: res.data().managerEmail,
            message: {
              subject: `Super Fun Bet Transaction Request`,
              text: `${payload.email} has requested a ${payload.amount} ${payload.type}`,
            },
          });
        });

      return {
        ...state,
      };
    },
    TOGGLE_DEPOSIT_HELPER_OFF: (state, { payload }) => {
      const db = getFirebase().firestore();
      db.collection("users").doc(payload.uid).set({firstDepositInfo: 1}, {merge: true});
      return {
        ...state,
      }
    },
    FULFILL_TRANSACTION: (state, { payload }) => {
      console.log("Fulfill trans payload: ", payload);
      // getFirebase().analytics().logEvent("FULFILL_TRANS");
      const db = getFirebase().firestore();
      const adjustBalance = getFirebase().functions().httpsCallable("user-adjustBalanceGetRidOfMeLater");

      //settle transaction in global transactions
      db.collection("transactions")
        .where("trans_id", "==", payload.state.trans_id)
        .get()
        .then((res) => {
          let doc = res.docs[0];
          let data = doc.data();
          data.state = payload.state.state;
          doc.ref.update(data);
        });

      //settle in users transaction collection
      db.collection("users")
        .doc(payload.state.uid)
        .collection("transactions")
        .where("trans_id", "==", payload.state.trans_id)
        .get()
        .then((res) => {
          let doc = res.docs[0];
          let data = doc.data();
          data.state = payload.state.state;
          doc.ref.update(data);
        });

      //settle in group trans collection
      db.collection("groups")
        .doc(payload.state.gid)
        .collection("transactions")
        .where("trans_id", "==", payload.state.trans_id)
        .get()
        .then((res) => {
          let doc = res.docs[0];
          let data = doc.data();
          data.state = payload.state.state;
          doc.ref.update(data);
        });

      //update balance to approve deposit or reverse withdrawal attempt
      if (
        (payload.state.type === "deposit" && payload.state.state === "approved") ||
        (payload.state.type === "withdrawal" && payload.state.state === "denied")
      )
        adjustBalance({ uid: payload.state.uid, gid: payload.state.gid, adjust: payload.state.amount });

      //email user that transaction has been settled
      db.collection("users")
        .doc(payload.state.uid)
        .get()
        .then((r) => {
          db.collection("mail").add({
            to: r.data().email,
            message: {
              subject: `Transaction Settled`,
              text: `Your ${payload.state.amount} ${payload.state.type} request has been ${payload.state.state}`,
            },
          });
        });

      return {
        ...state,
      };
    },
    AWARD_BONUS: (state, { payload }) => {
      // getFirebase().analytics().logEvent("AWARD_BONUS");
      console.log("award payload ", payload);
      payload = payload.state;

      const db = getFirebase().firestore();
      const adjustBalance = getFirebase().functions().httpsCallable("user-adjustBalanceGetRidOfMeLater");

      //write to global transactions
      db.collection("transactions").add(payload);

      //write to users transactions
      db.collection("users").doc(payload.uid).collection("transactions").add(payload);

      //write to group transactions
      db.collection("groups").doc(payload.gid).collection("transactions").add(payload);

      //immediately deduct balance on withdrawal attempts
      // if (payload.type === "withdrawal")
      //   adjustBalance({ uid: payload.uid, gid: payload.gid, adjust: -1 * payload.amount });

      //immediately credit balance on bonuses/seizure
      // if (payload.type === "bonus" || payload.type === "seizure") {
      //     console.log("about to call adjustBal function: ",payload.uid, payload.gid, payload.amount)
      //     adjustBalance({ uid: payload.uid, gid: payload.gid, adjust: payload.amount });
      // }

      adjustBalanceWithClient(payload).then(r => {
          console.log("response from adjust bal: ",r)
          let subject = "You have a bonus";
          let text = `You've been awarded a bonus of ${payload.amount}`;

          if (payload.type === "correction" || payload.type === "seizure") {
            subject = "Balance Adjustment";
            text = `Your account balance has been adjusted: ${payload.amount}`;
          }

          //email group owner there is a new pending transaction
          db.collection("users")
            .doc(payload.uid)
            .get()
            .then((res) => {
              db.collection("mail").add({
                to: res.data().email,
                message: {
                  subject: subject,
                  text: text,
                },
              });
            });
      })

      return {
        ...state,
      };
    },

    ISSUE_FREEWAGER: (state, { payload }) => {

      // getFirebase().analytics().logEvent("ISSUE_FREEWAGER");
      const db = getFirebase().firestore();
      console.log("ISSUE_FREEWAGER payload ", payload);

      //write to group/user/freewager/available for player to access
      db
        .collection("groups")
        .doc(payload.gid)
        .collection("freewagers")
        .doc(payload.uid)
        .collection("available")
        .add({isCoinflip: payload.isCoinflip, win: payload.win })
        .then(res => {
          //write to group/freewager for manager to access
          db
            .collection("groups")
            .doc(payload.gid)
            .collection("freewagers")
            .doc(res.id)
            .set({uid: payload.uid, isCoinflip: payload.isCoinflip, win: payload.win, status: "available"})
            .then(res => console.log("group level res: ",res))
        })

      //email player there is a new free wager
      db.collection("mail").add({
        to: payload.email,
        message: {
          subject: `You Have a new Free Bet!`,
          text: `You've been awarded a free $${payload.win} coinflip.  Visit www.superfunbet.com to claim it!`,
        },
      });

      return {
        ...state,
      };
    },

    CLAIM_FREEWAGER: (state, { payload }) => {
      // getFirebase().analytics().logEvent("CLAIM_FREEWAGER");
      const db = getFirebase().firestore();
      console.log("CLAIM_FREEWAGER payload ", payload);

      //this flags the freewager so it wont appear in group level queries
      //server removes it from group/user level when claimed
      let freeWagerDocRef = db.collection("groups").doc(payload.gid).collection("freewagers").doc(payload.freeWagerID)
      freeWagerDocRef.update({
        status: "claimed"
      })
        .then(() => {
          console.log("Document successfully updated!");
        })
        .catch((error) => {
          // The document probably doesn't exist.
          console.error("Error updating document: ", error);
        });




      //email group owner there is a new pending transaction
      // db.collection("users")
      //   .doc(payload.uid)
      //   .get()
      //   .then((res) => {
      //     db.collection("mail").add({
      //       to: res.data().email,
      //       message: {
      //         subject: `You Have a Bonus`,
      //         text: `You've been awarded a bonus of ${payload.amount}`,
      //       },
      //     });
      //   });

      return {
        ...state,
      };
    },


    TOGGLE_PRIVACY: (state, { payload }) => {

      // getFirebase().analytics().logEvent("TOGGLE_PRIVACY");
      const db = getFirebase().firestore();
      var groupRef =  db.collection("groups").doc(payload.gid)

      // add or remove user from array of public bettors
      if (payload.action === "goPublic"){
        groupRef.update({
          publicBettors: getFirebase().firestore.FieldValue.arrayUnion(payload.uid)
        });
      } else if (payload.action === "goPrivate"){
        groupRef.update({
          publicBettors: getFirebase().firestore.FieldValue.arrayRemove(payload.uid)
        });
      } else
        console.log("this did not meet conditions to toggle privacy")

      return {
        ...state,
      };
    },
  },
  initialState
);
