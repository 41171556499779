import { createAction, handleActions } from "redux-actions";

export const pickemTournamentBaseEntry = createAction("PICKEM_TOURNAMENT_SET_PICKS", (tournament_id, picks) => {
  return { tournament_id, picks };
});

export const pickemUpdatePickValue = createAction("PICKEM_UPDATE_PICK_VALUE", (pickNumber, value) => {
  return { pickNumber, value };
});

export const pickemSetPickGood = createAction("PICKEM_UPDATE_PICK_GOOD", (pickNumber, good) => {
  return { pickNumber, good };
});

export const pickemSetGame = createAction("PICKEM_UPDATE_GAME", (pickNumber, game) => {
  return { pickNumber, game };
});

const initialState = {
  tournament_id: false,
  picks: false,
  pickValues: [],
  pickGood: [],
  pickGame: [],
};

export default handleActions(
  {
    PICKEM_TOURNAMENT_SET_PICKS: (state, { payload }) => {
      return {
        ...state,
        tournament_id: payload.tournament_id,
        picks: payload.picks,
        pickValues: new Array(payload.picks).fill(0),
        pickGood: new Array(payload.picks).fill(false),
        pickGame: new Array(payload.picks).fill(false),
      };
    },
    PICKEM_UPDATE_PICK_VALUE: (state, { payload }) => {
      let pickValues = [...state.pickValues];
      pickValues[payload.pickNumber] = payload.value;
      return {
        ...state,
        pickValues: pickValues,
      };
    },
    PICKEM_UPDATE_PICK_GOOD: (state, { payload }) => {
      let pickGoodValues = [...state.pickGood];
      pickGoodValues[payload.pickNumber] = payload.good;
      return {
        ...state,
        pickGood: pickGoodValues,
      };
    },
    PICKEM_UPDATE_GAME: (state, { payload }) => {
      let pickGame = [...state.pickGame];
      pickGame[payload.pickNumber] = payload.game;
      return {
        ...state,
        pickGame: pickGame,
      };
    },
  },
  initialState
);
