import React, { Component } from "react";
import PropTypes from "prop-types";
import { Badge, Grid, Typography } from "@mui/material";
import { CheckCircle } from "@mui/icons-material";

class CTA extends Component {
  render() {
    return (
      <Grid
        item
        xs={12}
        onClick={() => !this.props.completed && this.props.clickAction()}
        sx={{ width: "200px", padding: 2 }}
      >
        <Grid
          container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          sx={{
            padding: 1.5,
            textAlign: "center",
            fontSize: ".95rem",
            // background: `linear-gradient(90deg, rgba(97,1,57,1) 0%, rgba(191,0,112,1) 35%, rgba(203,55,142,1) 100%)`,
            background: `linear-gradient(166deg, rgba(97,1,57,1) 0%, rgba(191,0,112,1) 35%, rgba(203,55,142,1) 100%)`,
            backgroundColor: "secondary.main",
            color: "secondary.contrastText",
            height: "90px",
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            boxShadow: "2px 2px 2px #D3D3D3",
          }}
        >
          <Grid item xs={12} sx={{ height: "60%" }}>
            {this.props.completed && (
              <Badge showZero={true} badgeContent={<CheckCircle sx={{ color: "lightgreen" }} />}>
                {this.props.icon}
              </Badge>
            )}
            {!this.props.completed && <>...</>}
            {/*<EmojiEventsIcon fontSize={"large"} />*/}
          </Grid>
          <Grid item xs={12} sx={{ height: "40%" }}>
            <Typography sx={{ fontSize: "1rem" }}>{this.props.title}</Typography>
          </Grid>
        </Grid>

        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          sx={{
            height: "100px",
            padding: 1.5,
            textAlign: "center",
            fontSize: ".95rem",
            backgroundColor: "#ffffff",
            borderTop: 0,
            borderBottom: 2,
            borderLeft: 1.5,
            borderRight: 1.5,
            borderColor: "#bf0070",
            boxShadow: "2px 2px 2px #D3D3D3",
          }}
        >
          <Grid item xs={12}>
            {(!this.props.completed && this.props.description) || (
              <Typography sx={{ textTransform: "none", fontWeight: "bold" }}>Completed</Typography>
            )}
          </Grid>
          {/*<Grid item xs={2}>*/}
          {/*  <Box textAlign="right">*/}
          {/*    <ChevronRight />*/}
          {/*  </Box>*/}
          {/*</Grid>*/}
        </Grid>
      </Grid>
    );
  }
}

CTA.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  clickAction: PropTypes.func.isRequired,
  completed: PropTypes.bool,
};

export default CTA;
