import { List, Skeleton } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import Accordion from "@mui/material/Accordion";
import Bet from "../../components/Bet";
import Typography from "@mui/material/Typography";

class BetList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPendingBets: false,
    };
  }

  getColor = (result) => {
    if (result === "win") return "#90ee90";
    else if (result === "loss") return "red";
    else return "white";
  };

  renderBets() {
    if (this.props.bets) {
      let b = this.props.bets;
      return Object.keys(b)
        .filter((i) => b[i])
        .filter((i) => b[i] && b[i].result)
        .sort((x, y) => {
          return (b[x].game.StartTime ? b[x].game.StartTime : b[x].minGameStartTime) <
            (b[y].game.StartTime ? b[y].game.StartTime : b[y].minGameStartTime)
            ? 1
            : -1;
        })
        .map((j) => (
          <Bet
            key={j}
            bet={b[j]}
            isSocial={this.props.isSocial}
            balances={this.props.balances}
            allowGrading={this.props.allowGrading}
            fromAdmin={this.props.fromAdmin}
            activePromo={this.props.activePromo}
          />
        ));
    }
  }

  render() {
    const { classes } = this.props;
    //console.log("betlist count", this.props.bets === undefined);
    console.log("betlist props: ", this.props);
    let countOfPending = this.props.bets === undefined ? false : 0;
    if (this.props.bets) {
      countOfPending = Object.keys(this.props.bets).length;
    }
    if (!this.props.noAccordion) {
      return (
        <Accordion
          onChange={() => {
            if (countOfPending > 0) {
              this.setState({ showPendingBets: !this.state.showPendingBets });
            }
          }}
          expanded={countOfPending > 0 && this.state.showPendingBets}
        >
          <AccordionSummary expandIcon={countOfPending > 0 && <ExpandMoreIcon />} aria-controls="panel1a-content">
            {countOfPending === false && (
              <>
                <Skeleton variant="circular" width={24} height={24} />
                <Skeleton animation="wave" variant="text" width="50%" sx={{ marginLeft: 2 }} />
              </>
            )}
            {countOfPending !== false && (
              <>
                {/*{this.props.isBetHistory ? (*/}
                {/*  <HistoryEdu sx={{ marginRight: 2, color: "green" }} />*/}
                {/*) : (*/}
                {/*  <QueryStats sx={{ marginRight: 2, color: "green" }} />*/}
                {/*)}*/}
                <Typography>
                  {this.props.isSocial && "Friends"} {this.props.isBetHistory ? "Past" : "Pending"} Bets (
                  {countOfPending})
                </Typography>
              </>
            )}
          </AccordionSummary>
          <AccordionDetails>
            <List className={classes.pastBetsList}>
              {this.props.bets && this.state.showPendingBets && this.renderBets()}
            </List>
          </AccordionDetails>
        </Accordion>
      );
    } else {
      return <List className={classes.pastBetsList}>{this.props.bets && this.renderBets()}</List>;
    }
  }
}

BetList.defaultProps = {
  allowGrading: false,
  fromAdmin: false,
  isBetHistory: false,
  isSocial: false,
  noAccordion: false,
};

BetList.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  bets: PropTypes.object,
  balances: PropTypes.object,
  allowGrading: PropTypes.bool,
  fromAdmin: PropTypes.bool,
  isBetHistory: PropTypes.bool,
  isSocial: PropTypes.bool,
  noAccordion: PropTypes.bool,
  activePromo: PropTypes.string,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  pastBetsList: {
    width: "100%",
    // padding: 0,
    // margin: 0
  },
});

export default connect()(withStyles(styles)(BetList));
