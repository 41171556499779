import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import TextField from "@mui/material/TextField";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { firestoreConnect, getFirebase } from "react-redux-firebase";

class ChangeUsername extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
    };
  }

  _doChange = async () => {
    if (this.state.name.length > 2) {
      const updateUser = await getFirebase()
        .firestore()
        .collection("groups")
        .doc(this.props.gid)
        .collection("balances")
        .doc(this.props.uid)
        .update({ username: this.state.name });

      console.log("updateUser: ", updateUser);
    } else alert("name must be three letters or more");
  };

  render() {
    console.log("change username props: ", this.props);
    return (
      <Dialog onBackdropClick={this.props.closeCallback} open={this.props.open}>
        <DialogTitle>Change Username ({this.props.user?.username})</DialogTitle>
        <DialogContent sx={{ margin: 2 }}>
          <TextField
            onChange={(e) => this.setState({ name: e.target.value })}
            id="standard-text"
            label={`new username`}
            type="text"
            color={"secondary"}
            value={this.state.name}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ margin: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button color={"secondary"} variant="outlined" onClick={this.props.closeCallback}>
            Close
          </Button>
          <Button variant="contained" onClick={this._doChange}>
            Change
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

ChangeUsername.propTypes = {
  open: PropTypes.bool,
  closeCallback: PropTypes.func,
  uid: PropTypes.string.isRequired,
  gid: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  profile: PropTypes.object,
  usernames: PropTypes.object,
  user: PropTypes.object,
};

const mapStateToProps = (state) => {
  const getUserBalanceDoc = (obj) => {
    if (state.firestore.data.usernames && state.firebase.auth.uid) return obj[state.firebase.auth.uid];
  };

  return {
    uid: state.firebase.auth.uid,
    email: state.firebase.auth.email,
    profile: state.firebase.profile,
    usernames: state.firestore.data.usernames,
    user: getUserBalanceDoc(state.firestore.data.usernames),
  };
};

export default compose(
  connect(mapStateToProps),
  withRouter,
  firestoreConnect((props) => {
    console.log("connect props: ", props);
    return [
      {
        collection: `groups/${props.profile.gid}/balances`,
        storeAs: "usernames",
      },
    ];
  })
)(ChangeUsername);
