import React, {Component} from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import {withRouter} from "react-router-dom";
import {IconButton} from "@mui/material";
import {ArrowBack} from "@mui/icons-material";
import Button from "@mui/material/Button";
import {compose} from "redux";
import {connect} from "react-redux";
import {firestoreConnect} from "react-redux-firebase";
import withStyles from "@mui/styles/withStyles";
import clsx from "clsx";

class GenericBackHeader extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    console.log("Back header props: ", this.props);
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <AppBar
          position="sticky"
          elevation={0}
          sx={{borderBottom: 2}}
          className={clsx(classes.appBar, {
            [classes.appBarShift]: this.state.drawerOpen,
          })}
        >
          <Toolbar elevation={0}
                   style={(this.props.location.pathname.includes("/bookie") || this.props.location.pathname.includes("/admin")) ? { backgroundColor: "white" } : { backgroundColor: "white" }}>
            <Typography variant="h6" color="inherit" noWrap className={classes.title}>
              {this.props.showBackButton && (
                <IconButton onClick={() => this.props.history.goBack()}>
                  <ArrowBack sx={{ color: "quaternary" }} />
                </IconButton>
              )}
              <Button color="quaternary" aria-label="open drawer" edge="end">
                {this.props.title}
              </Button>
            </Typography>
            {/*<Typography*/}
            {/*  style={{fontStyle: 'italic', color: "black", fontWeight: 700}}*/}
            {/*  variant="h6"*/}
            {/*  color="inherit" noWrap className={classes.title}*/}
            {/*>SUPERFUNBET*/}
            {/*</Typography>*/}
          </Toolbar>
        </AppBar>
      </div>);
  }
}

GenericBackHeader.propTypes = {
  title: PropTypes.string.isRequired,
  showBackButton: PropTypes.bool.isRequired,
  history: PropTypes.object,
  location: PropTypes.object,
  balance: PropTypes.number,
  classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  drawerDirection: {
    direction: theme.direction,
  },
  title: {
    flexGrow: 1,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  hide: {
    display: "none",
  },
  dividerInset: {
    margin: `5px 0 0 ${theme.spacing(9)}`,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
});


const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    email: state.firebase.auth.email,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    balance: state.firestore.data.backHeader_balance?.balance,
  };
};


export default compose(
  withRouter,
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: `groups/${props.profile.gid}/balances`,
        doc: `${props.uid}`,
        storeAs: "backHeader_balance",
      },
    ];
  }),
)(withStyles(styles)(GenericBackHeader));
