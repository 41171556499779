import { Alert, Box, Fab, Snackbar, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import CasinoIcon from "@mui/icons-material/Casino";
import { Header, TopNav } from "../../components";
import { ScrollToTop } from "../../utilities";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import SuperLines2 from "./components/SuperLines2";
import BestSlip from "./BestSlip";
import SignInDialog from "../Auth/SignInDialog";
import SignUpDialog from "../Auth/SignUpDialog";
import { clearPlaceBetError, setBetslipOpen } from "../../reducers/sportsbook-reducer";
import ConversionDialogs from "../Dialogs/ConversionDialogs";

class Sportsbook extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showYourBetHistory: false,
      creditBtnDisabled: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    // Object.entries(this.props).forEach(
    //   ([key, val]) => prevProps[key] !== val && console.log(`Sportsbook Prop '${key}' changed`)
    // );
    // if (this.state) {
    //   Object.entries(this.state).forEach(
    //     ([key, val]) => prevState[key] !== val && console.log(`State '${key}' changed`)
    //   );
    // }
    if (
      prevProps.pendingBets2 === null &&
      this.props.pendingBets2 &&
      Object.values(this.props.pendingBets2).filter((bet) => bet).length === 1
    ) {
      this.props.dispatch(setBetslipOpen(true));
    }
  }

  renderActiveSportLines() {
    console.log("process.env.REACT_APP_SHOW_LOGS ", process.env.REACT_APP_SHOW_LOGS);
    console.log("process.env.NODE_ENV ", process.env.NODE_ENV);

    return Object.values(this.props.availableLines2)
      .filter(
        (s) =>
          s &&
          "bucket" in s &&
          (s.dev_only === undefined ||
            s.dev_only === false ||
            process.env.NODE_ENV === "development" ||
            process.env.REACT_APP_SHOW_DEV_LINES === "TRUE")
      )
      .map((s) => (
        <SuperLines2
          lineSource="lines_standardized"
          linesData={s.bucket}
          coinflipJuice={this.props.coinflipJuice}
          gid={this.props.profile.gid}
          juice={this.props.juice}
          uid={this.props.uid}
          email={this.props.email}
          title={s.alt_title || s.title}
          sportsGroup={s.group}
          description={s.description}
          key={s.bucket}
          options={s.options ? s.options : {}}
        />
      ));
    // Random Change
  }

  _onBetterSlipOpen = () => {
    this.props.dispatch(setBetslipOpen(true));
  };

  render() {
    console.log("sportsbook this.props", this.props);
    console.log("sportsbook this.state", this.state);

    const numPendingBets = this.props.pendingBets2
      ? Object.entries(this.props.pendingBets2).filter(([key, bet]) => bet && !this.props.inflightBets.includes(key))
          .length
      : 0;

    return (
      <>
        <ConversionDialogs />
        <SignInDialog />
        <SignUpDialog />

        {this.props.availableLines2 && (
          <BestSlip
            parlayCard={this.props.parlay}
            gid={this.props.profile.gid}
            groupConfigs={this.props.groupConfigs}
            juice={this.props.juice}
            coinflipJuice={this.props.coinflipJuice}
          />
        )}

        <ScrollToTop />

        {this.props.availableLines2 && (
          <>
            <Header title="Sportsbook" icon={CasinoIcon} />
            <TopNav isLines2={true} sports={this.props.availableLines2} defaultSport={"NCAAF"} />
          </>
        )}

        {this.props.availableLines2 && this.renderActiveSportLines()}

        {numPendingBets > 0 && (
          <Fab
            aria-label="edit"
            variant="extended"
            className={this.props.classes.fab}
            onClick={this._onBetterSlipOpen}
            // style={{ backgroundColor: "#22d222" }}
          >
            {/*<Badge*/}
            {/*  color="green"*/}
            {/*  style={{ fontSize: 31 }}*/}
            {/*  badgeContent={Object.values(this.props.unsubmittedBets).filter((item) => item !== null).length}*/}
            {/*>*/}
            <Box display="flex" justifyContent="center" alignItems="center" style={{ margin: 8 }}>
              <ContentPasteIcon style={{ fill: "white" }} />
              <Typography style={{ color: "white" }}>betslip ({numPendingBets})</Typography>
            </Box>
            {/*</Badge>*/}
          </Fab>
        )}
        <Snackbar
          open={this.props.placeBetError}
          autoHideDuration={10000}
          onClose={() => this.props.dispatch(clearPlaceBetError())}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
        >
          <Alert onClose={() => this.props.dispatch(clearPlaceBetError())} severity="error">
            {this.props.placeBetError}
          </Alert>
        </Snackbar>
      </>
    );
  }
}

Sportsbook.propTypes = {
  classes: PropTypes.object.isRequired,
  parlay: PropTypes.array,
  dispatch: PropTypes.func,
  email: PropTypes.string,
  uid: PropTypes.string,
  gid: PropTypes.string,
  auth: PropTypes.object,
  profile: PropTypes.object,
  juice: PropTypes.number,
  coinflipJuice: PropTypes.number,
  newUserState: PropTypes.object,
  showSignup: PropTypes.bool,
  showConversionDialog: PropTypes.bool,
  groupConfigs: PropTypes.object,

  recentBets: PropTypes.array,
  availableLines2: PropTypes.object,
  firstVisitURL: PropTypes.string,
  claimingFreeAccount: PropTypes.bool,
  pendingBets2: PropTypes.array,
  balanceDoc: PropTypes.object,
  history: PropTypes.array,
  location: PropTypes.object,
  inflightBets: PropTypes.array.isRequired,
  placeBetError: PropTypes.string,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  fab: {
    backgroundColor: theme.palette.secondary.main,
    margin: 0,
    marginBottom: 2,
    top: "auto",
    // right: "auto",
    bottom: 60,
    left: "50%",
    transform: "translateX(-50%)",
    justify: "center",
    position: "fixed",
    zIndex: 3,
  },
});

const mapStateToProps = (state) => {
  const getPlayerJuice = (groupDoc, playerDoc) => {
    console.log("player doc: ", playerDoc)
    if (playerDoc?.customJuice >= 0) return playerDoc.customJuice;
    else return groupDoc;
  };

  console.log("sportsbook_groupConfigs",state.firestore.data.sportsbook_groupConfigs)

  return {
    parlay: state.sportsbookState.parlay,
    auth: state.firebase.auth,
    uid: state.firebase.auth.uid,
    email: state.firebase.auth.email,
    profile: state.firebase.profile,
    gid: state.firebase.profile.gid,
    juice: getPlayerJuice(state.firestore.data.sportsbook_groupConfigs?.groupJuice, state.firestore.data.sportsbook_userBalance),
    coinflipJuice: state.firestore.data.sportsbook_groupConfigs?.groupCoinflipJuice,
    groupConfigs: state.firestore.data.sportsbook_groupConfigs,
    newUserState: state.newUserState,
    showSignup: state.newUserState.showSignUpDialog,
    availableLines2: state.firestore.data.availableLines2,
    firstVisitURL: state.newUserState.initialPage,
    claimingFreeAccount: state.newUserState.claimingFreeAccount,
    pendingBets2: state.firestore.data.pendingBets2,
    inflightBets: state.sportsbookState.inflightBets,
    placeBetError: state.sportsbookState.placeBetError,
  };
};

export default compose(
  connect(mapStateToProps),
  withRouter,
  firestoreConnect((props) => {
    // console.log("---------------firestore connecting");
    // console.log("---------------SPORTSBOOK connect props", props);
    return [
      // {
      //   collection: "available_lines",
      //   where: [
      //     ["active", "==", true],
      //     ["weight", ">", 0],
      //   ],
      //   storeAs: "availableLines",
      //   orderBy: ["weight"],
      // },
      {
        collection: "available_lines_2",
        where: [
          ["active", "==", true],
          ["weight", ">", 0],
        ],
        storeAs: "availableLines2",
        orderBy: ["weight"],
      },
      // {
      //   collection: "available_lines_2",
      //   where: [
      //     ["dev_only", "==", true],
      //     // ["weight", "==", 1],
      //   ],
      //   storeAs: "availableLines2",
      //   // orderBy: ["weight"],
      // },
      // {
      //   collection: `users/${props.uid}/pendingBets`,
      //   storeAs: "unsubmittedBets",
      // },
      {
        collection: `users/${props.uid}/pendingBets2`,
        storeAs: "pendingBets2",
        where: [["gid", "==", props.profile.gid || "unknown"]],
      },
      {
        collection: "groups",
        doc: props.profile.gid,
        storeAs: "sportsbook_groupConfigs",
      },
      // {
      //   collection: `groups/${props.profile.gid}/freewagers/${props.uid}/available`,
      //   storeAs: "userAvailableFreewagers",
      // },
      // {
      //   collection: `groups/${props.profile.gid}/freewagers`,
      //   where: [["status", "!=", "claimed"]],
      //   storeAs: "groupFreewagers",
      // },
      // {
      //   collection: "promos",
      //   doc: "newUserFreeCoinflip",
      //   storeAs: "welcomeFreewager",
      // },
      // TODO turning this off now as it's killing performance
      // {
      //   collection: `groups/${props.profile.gid}/bets`,
      //   limit: 50,
      //   orderBy: ["betPlaced", "desc"],
      //   storeAs: "recentBets",
      // },
      {
        collection: `groups/${props.profile.gid}/balances`,
        doc: `${props.uid}`,
        storeAs: "sportsbook_userBalance",
      },
    ];
  })
)(withStyles(styles)(Sportsbook));
