import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { firestoreConnect, getFirebase } from "react-redux-firebase";
import { compose } from "redux";
import BottomNav from "../../../../components/BottomNav";
import Box from "@mui/material/Box";
import {
  Alert,
  alpha,
  Badge,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Snackbar,
} from "@mui/material";
import { SignUp } from "../../SignUp";
import List from "@mui/material/List";
import { ChevronRight } from "@mui/icons-material";
import { withRouter } from "react-router-dom";
import collect from "collect.js";
import { sportsbookShowSignup } from "../../../../reducers/sportsbook-reducer";
import sportsBackgroundImage from "../../../../images/sports-background-yellowy.png";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import PeopleIcon from "@mui/icons-material/People";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import Bonus from "./Bonus";
import GenericBackHeader from "../../../../components/GenericBackHeader";

class AdminCashier extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showSignup: true,
      amount: 0,
      type: 0,
      bonusOpen: false,
    };
  }

  getPendingBetInfo() {
    if (this.props.adminPendingBets) {
      let b = this.props.adminPendingBets;
      Object.keys(b).filter((i) => b[i]);
      let coll = collect(b)
        .filter((i) => i)
        .values();
      let count = coll.count();
      let risk = coll.sum("risk");
      let win = coll.sum("win");
      return `${count} bets risking ${risk} to win ${win}`;
    }
  }

  getPastBetInfo() {
    if (this.props.adminPastBets) {
      let b = this.props.adminPastBets;
      Object.keys(b).filter((i) => b[i]);
      let coll = collect(b)
        .filter((i) => i)
        .values();
      let count = coll.count();
      let bettors = coll.countBy("uid").count();
      return `${count} bets by ${bettors} bettors`;
    }
  }

  getPendingTransCount() {
    let coll = collect(this.props.adminPendingTrans).values();
    return coll.count();
  }

  getRefLink() {
    let cleanName = this.props.managerGroupConfigs?.groupName.replaceAll(" ", "%20");
    return "https://superfunbet.com/join/" + cleanName;
  }

  _handleClick = (e) => {
    // getFirebase().updateProfile({ conversionState: "madeClickAsBanker" });
    console.log("CLick: ", e.target);
    if (e.target.value === "settings") {
      this.props.history.push("/admin/group-settings");
    } else if (e.target.value === "ux") {
      this.props.history.push("/sports");
    }
  };

  renderDialog() {
    const { classes } = this.props;
    const MGC = this.props.managerGroupConfigs;
    return (
      <Dialog fullWidth open={true} style={{ textAlign: "center" }}>
        <Box sx={{ flexGrow: 1, backgroundImage: `url(${sportsBackgroundImage})` }}>
          <DialogTitle style={{ paddingTop: 10, paddingBottom: 0 }}>
            <Typography className={classes.dialogTitle} variant={"h5"}>
              SPORTSBOOK CREATED!
            </Typography>
            <Typography className={classes.dialogSubtitle} variant={"h6"}>{`you own: ${MGC.groupName}`}</Typography>
          </DialogTitle>

          <DialogContent sx={{ margin: 2, padding: 0 }}>
            <Grid container spacing={2} fulWidth>
              <Grid item xs={12} sm={12}>
                <Stack spacing={2}>
                  <Paper
                    fullWidth
                    variant="elevation2"
                    style={{ backgroundColor: alpha("#ffffff", 0.4), padding: 0, justifyContent: "center" }}
                  >
                    <List style={{ padding: 0 }}>
                      <Card style={{ backgroundColor: alpha("#ffffff", 0.2), padding: 0, justifyContent: "center" }}>
                        <ListItemButton
                          divider
                          onClick={() => {
                            getFirebase().updateProfile({ conversionState: "madeClickAsBanker" });
                            this.props.history.push("/admin/group-settings");
                          }}
                        >
                          <ListItemIcon>
                            <ChangeCircleIcon color="green" />
                          </ListItemIcon>
                          <ListItemText primary="Change Book Name" secondary="and the juice, max bet, etc" />
                        </ListItemButton>
                      </Card>

                      <Card style={{ backgroundColor: alpha("#ffffff", 0.2), padding: 0, justifyContent: "center" }}>
                        <ListItemButton
                          divider
                          onClick={() => {
                            navigator.clipboard
                              .writeText(this.getRefLink())
                              .then(() => this.setState({ copySnackBarOpen: true }));
                          }}
                        >
                          <ListItemIcon>
                            <PeopleIcon color="green" />
                          </ListItemIcon>
                          <ListItemText primary="Invite Friends Link" secondary={"register users to your sportsbook"} />
                        </ListItemButton>
                      </Card>

                      <Card style={{ backgroundColor: alpha("#ffffff", 0.2), padding: 0, justifyContent: "center" }}>
                        <ListItemButton
                          divider
                          onClick={() => {
                            getFirebase().updateProfile({ conversionState: "madeClickAsBanker" });
                            this.props.history.push("/sports");
                          }}
                        >
                          <ListItemIcon>
                            <PlayArrowIcon color="green" />
                          </ListItemIcon>
                          <ListItemText
                            primary="User Experience"
                            secondary="see what its like to bet at your sportsbook"
                          />
                        </ListItemButton>
                      </Card>
                    </List>
                  </Paper>
                  <Button
                    value={"clickedNoThanks"}
                    onClick={() => {
                      getFirebase().updateProfile({ conversionState: "madeClickAsBanker", dontShowTips: true });
                    }}
                    variant="outlined"
                    fullWidth
                  >
                    {" "}
                    Stop Showing Tips{" "}
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </DialogContent>
        </Box>
      </Dialog>
    );
  }

  DIALOG() {
    const { classes } = this.props;
    const MGC = this.props.managerGroupConfigs;

    return this.renderDialog();

    // return (
    //   <Dialog fullWidth open={true} style={{ textAlign: "center"}} >
    //     <Box sx={{ flexGrow: 1, backgroundImage: `url(${sportsBackgroundImage})` }} >
    //
    //       <DialogTitle style={{paddingTop: 10, paddingBottom: 10}} >
    //         <Typography className={classes.dialogTitle} variant={"h5"}>SPORTSBOOK CREATED!</Typography>
    //         <Typography className={classes.dialogSubtitle} variant={"h6"}>{`your book is: ${MGC.groupName}`}</Typography>
    //       </DialogTitle>
    //
    //       <DialogContent sx={{ margin: 2, paddingTop: 20 }}>
    //
    //
    //           <ListItemButton divider onClick={() => {
    //             getFirebase().updateProfile({ conversionState: "madeClickAsBanker" });
    //             console.log("PUSHY to groupsettings");
    //             this.props.history.push("/admin/group-settings");
    //           }}>
    //             <ListItemText primary="Change Sportsbook Name" secondary="and the juice, max bet, etc" />
    //           </ListItemButton>
    //
    //           <ListItemButton divider onClick={() => {
    //             getFirebase().updateProfile({ conversionState: "madeClickAsBanker" })
    //             this.props.history.push("/sports")
    //           }}>
    //             <ListItemText primary="User Experience" secondary="see what its like to bet at your sportsbook" />
    //           </ListItemButton>
    //
    //
    //
    //       </DialogContent>
    //
    //       <DialogActions>
    //
    //       </DialogActions>
    //
    //     </Box>
    //
    //   </Dialog>
    //   )
  }

  renderNewAdmin() {
    console.log("render within admin: ", this.props);

    return (
      <>
        {this.props.profile.conversionState === "wantsToBeBanker" && this.DIALOG()}

        <Box>
          <GenericBackHeader title="Profile" showBackButton />

          <List>
            {/*{*/}
            {/*  this.props.managerGroupConfigs && (*/}
            {/*    <ListItemButton*/}
            {/*      divider*/}
            {/*      onClick={() => {*/}
            {/*        navigator.clipboard*/}
            {/*          .writeText(this.getRefLink())*/}
            {/*          .then(() => this.setState({ copySnackBarOpen: true }));*/}
            {/*      }}*/}
            {/*    >*/}
            {/*      <ListItemText primary="Invite Friends Link" secondary={this.getRefLink()} />*/}
            {/*      <ListItemSecondaryAction>*/}
            {/*        <ContentCopy />*/}
            {/*      </ListItemSecondaryAction>*/}
            {/*    </ListItemButton>*/}
            {/*  )*/}
            {/*}*/}

            {/*<ListItemButton divider onClick={()=> { this.props.history.push("/admin/group-settings")}}>*/}
            {/*  <ListItemText primary="Group Settings" secondary="change book name, juice, max bet, etc..." />*/}
            {/*  <ListItemSecondaryAction>*/}
            {/*    <ChevronRight />*/}
            {/*  </ListItemSecondaryAction>*/}
            {/*</ListItemButton>*/}

            {/*<ListItemButton divider onClick={()=> { this.props.history.push("/admin/promos")}}>*/}
            {/*  <ListItemText primary="Promos" secondary="Create, enable, disable" />*/}
            {/*  <ListItemSecondaryAction>*/}
            {/*    <ChevronRight />*/}
            {/*  </ListItemSecondaryAction>*/}
            {/*</ListItemButton>*/}

            {/*<ListItemButton divider onClick={()=> { this.props.history.push("/admin/book-summary")}}>*/}
            {/*  <ListItemText primary="Financials" secondary="book and user level stats" />*/}
            {/*  <ListItemSecondaryAction>*/}
            {/*    <ChevronRight />*/}
            {/*  </ListItemSecondaryAction>*/}
            {/*</ListItemButton>*/}

            {/*<ListItemButton divider onClick={()=> { this.props.history.push("/admin/book-summary")}}>*/}
            {/*  <ListItemText primary="Book Summary" secondary="high level stats" />*/}
            {/*  <ListItemSecondaryAction>*/}
            {/*    <ChevronRight />*/}
            {/*  </ListItemSecondaryAction>*/}
            {/*</ListItemButton>*/}

            {/*<ListItemButton divider onClick={()=> { this.props.history.push("/admin/user-summary")}}>*/}
            {/*  <ListItemText primary="User Summary" secondary="user level stats" />*/}
            {/*  <ListItemSecondaryAction>*/}
            {/*    <ChevronRight />*/}
            {/*  </ListItemSecondaryAction>*/}
            {/*</ListItemButton>*/}

            {this.props.uid && this.props.profile && (
              <Bonus
                open={this.state.bonusOpen}
                closeCallback={() => this.setState({ bonusOpen: false })}
                uid={this.props.uid}
                gid={this.props.profile.gid}
                email={this.props.profile.email}
              />
            )}

            {/*<ListItemButton divider onClick={()=> { this.setState({bonusOpen: true})}} >*/}
            {/*  <ListItemText primary="Profile" secondary="adjust player balances, view bets, etc" />*/}
            {/*  <ListItemSecondaryAction>*/}
            {/*    <ChevronRight />*/}
            {/*  </ListItemSecondaryAction>*/}
            {/*</ListItemButton>*/}

            <ListItemButton
              divider
              onClick={() => {
                this.setState({ bonusOpen: true });
              }}
            >
              <ListItemText primary="Issue Bonuses" secondary="add/remove funds to player balances" />
              <ListItemSecondaryAction>
                <ChevronRight />
              </ListItemSecondaryAction>
            </ListItemButton>

            <ListItemButton
              divider
              onClick={() => {
                this.props.history.push("/admin/transactions");
              }}
            >
              <ListItemText primary="BookieTransactions" secondary="approve/deny or view old transactions" />
              <ListItemSecondaryAction>
                <Badge badgeContent={this.getPendingTransCount()} color="success">
                  <ChevronRight />
                </Badge>
              </ListItemSecondaryAction>
            </ListItemButton>

            <ListItemButton
              divider
              onClick={() => {
                this.props.history.push("/admin/pending-bets");
              }}
            >
              <ListItemText primary="Pending Bets" secondary={this.getPendingBetInfo()} />
              <ListItemSecondaryAction>
                <ChevronRight />
              </ListItemSecondaryAction>
            </ListItemButton>

            <ListItemButton
              divider
              onClick={() => {
                this.props.history.push("/admin/past-bets");
              }}
            >
              <ListItemText primary="Past Bets" secondary={this.getPastBetInfo()} />
              <ListItemSecondaryAction>
                <ChevronRight />
              </ListItemSecondaryAction>
            </ListItemButton>
          </List>

          <Snackbar
            open={this.state.copySnackBarOpen}
            onClose={() => this.setState({ copySnackBarOpen: false })}
            autoHideDuration={6000}
            message="Link Copied"
            severity="info"
            sx={{ bottom: { xs: 75, sm: 0 } }}
          >
            <Alert onClose={() => this.setState({ copySnackBarOpen: false })} severity="success" sx={{ width: "100%" }}>
              Link Copied
            </Alert>
          </Snackbar>
        </Box>
        <BottomNav isBookieMode activeTab={3} managerGid={this.props.profile.managerGid} />
      </>
    );
  }

  render() {
    console.log("admin this.props: ", this.props);
    console.log("admin this.state: ", this.state);
    return (
      <>
        <Dialog
          open={!this.props.uid && this.props.sportsbookState.showSignup}
          onClose={() => this.props.dispatch(sportsbookShowSignup(false))}
        >
          <SignUp noFooter={true} />
        </Dialog>
        {this.renderNewAdmin()}
      </>
    );
  }
}

AdminCashier.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  email: PropTypes.string,
  adminPastBets: PropTypes.object,
  adminPendingBets: PropTypes.object,
  adminPendingTrans: PropTypes.object,
  profile: PropTypes.object,
  history: PropTypes.array,
  showSportsBookSignup: PropTypes.bool,
  auth: PropTypes.object,
  uid: PropTypes.string,
  sportsbookState: PropTypes.object,
  managerGroupConfigs: PropTypes.object,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: "inline-block",
    marginTop: "20px",
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  grid: {
    textAlign: "center",
    marginBottom: "20px",
  },
  dialogTitle: {
    color: theme.palette.primary.dark,
    paddingBottom: 0,
  },
  dialogSubtitle: {
    paddingTop: 10,
    color: theme.palette.primary.light,
  },
});

const mapStateToProps = (state) => {
  const setDummyGid = (obj) => {
    if (obj?.managerUid === "JAtTKX4s3cNy2bntJ7iTb1PDjdw2" || !obj) {
      return { ...obj, groupName: "SIGN_UP_DUDE" };
    } else {
      return obj;
    }
  };

  return {
    uid: state.firebase.auth.uid,
    email: state.firebase.auth.email,
    profile: state.firebase.profile,
    adminPastBets: state.firestore.data?.adminPastBets,
    adminPendingBets: state.firestore.data?.adminPendingBets,
    adminPendingTrans: state.firestore.data?.pendingTransactions,
    managerGroupConfigs: setDummyGid(state.firestore.data?.admin_managerGroupConfigs),
    sportsbookState: state.sportsbookState,
  };
};

export default compose(
  connect(mapStateToProps),
  withRouter,
  firestoreConnect((props) => {
    return [
      {
        collection: `groups/${props.profile.managerGid}/bets`,
        where: [["result", "!=", "pending"]],
        storeAs: "adminPastBets",
      },
      {
        collection: `groups/${props.profile.managerGid}/bets`,
        where: [["result", "==", "pending"]],
        storeAs: "adminPendingBets",
      },
      {
        collection: `groups/${props.profile.managerGid}/transactions`,
        where: [["state", "==", "pending"]],
        storeAs: "pendingTransactions",
      },
      {
        collection: "groups",
        doc: !props.profile.isEmpty ? props.profile.managerGid : "wvDjIgA8N2HYOaCUEjAT",
        storeAs: "admin_managerGroupConfigs",
      },
    ];
  })
)(withStyles(styles)(AdminCashier));

/*
{this.props.pastTransactions && <UserTool bets={this.props.pastBets} trans={this.props.pastTransactions} balances={this.props.balances} />}


        {this.props.adminViewBets && this.props.adminViewTransactions && <GroupPastBets allBets={this.props.adminViewBets} users={this.props.users} />}
        {this.props.adminViewBets && this.props.adminViewTransactions && <UserTool bets={this.props.adminViewBets} trans={this.props.adminViewTransactions} users={this.props.users} />}
        {this.props.group && this.props.profile.groupCreated && <GroupSettings group={this.props.group} groupCreated={this.props.profile.groupCreated} />}
        {(this.props.profile.email === 'brian.danilo@gmail.com' || this.props.profile.email === 'ephlind@gmail.com') && <GlobalPendingBets allBets={this.props.godViewBets}/>}
 */
