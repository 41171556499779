import { Backdrop, CircularProgress, Grid } from "@mui/material";
import Paper from "@mui/material/Paper";
import React from "react";
import PropTypes from "prop-types";
import { pickemWinAmount } from "../../../../utilities/pickemUtils";
import firebase from "../../../../firebase";

export default function PickemBetItem(props) {
  const db = firebase.firestore();
  const auth = firebase.auth();

  const [backdropOpen, setBackdropOpen] = React.useState(false);

  const makePick = async () => {
    if (!auth?.currentUser?.uid) return;
    setBackdropOpen(true);
    await db
      .collection("pickem")
      .doc("user")
      .collection(auth.currentUser.uid)
      .doc("pending")
      .collection(props.tournamentID)
      .doc("picks")
      .set(
        {
          ["pick_" + props.pickNumber]: {
            betType: props.betType,
            betSide: props.betSide,
            bucket: props.bucket,
            collection: props.collection,
            docId: props.docId,
            label: props.label,
          },
        },
        { merge: true }
      );
    setBackdropOpen(false);
    props.handleClose();
  };

  return (
    <div>
      <Paper
        elevation={0}
        sx={{
          padding: 1,
          margin: 2 / 8,
          fontSize: "0.85rem",
          textAlign: "center",
          cursor: "pointer",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          height: "100%",
          backgroundColor: props.rowColor === "one" ? "primary.mainLight" : "primary.main",
        }}
        onClick={() => makePick()}
      >
        <Grid item container direction="column" justifyContent="center" alignItems="stretch">
          <Grid container direction="row" justifyContent="center" alignItems="center" height="100%">
            <Grid item height="100%" xs={12}>
              <Grid container direction="column" justifyContent="center" alignItems="center" height="100%">
                <Grid item sx={{ color: props.price ? null : "#666666" }}>
                  {props.label}
                </Grid>
                <Grid item sx={{ color: props.price ? null : "#666666" }}>
                  {pickemWinAmount(props.riskAmount, props.price) + " pts"}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={backdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </div>
  );
}

PickemBetItem.propTypes = {
  rowColor: PropTypes.string,
  price: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  betType: PropTypes.string.isRequired,
  bucket: PropTypes.string.isRequired,
  collection: PropTypes.string.isRequired,
  docId: PropTypes.string.isRequired,
  betSide: PropTypes.string.isRequired,
  pickNumber: PropTypes.number.isRequired,
  tournamentID: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  riskAmount: PropTypes.number.isRequired,
};
