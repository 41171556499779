import { Divider, ListItem, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { firestoreConnect, getFirebase } from "react-redux-firebase";
import { compose } from "redux";
import CasinoIcon from "@mui/icons-material/Casino";
import { BottomNav, Header } from "../../../components";
import collect from "collect.js";
import { signOut } from "../../../reducers/user-reducer";
import { sportsbookShowSignup } from "../../../reducers/sportsbook-reducer";
import Box from "@mui/material/Box";
import { withRouter } from "react-router-dom";
import List from "@mui/material/List";
import { AccountBalance, ChevronRight, Info, PersonOutline, PriceChange } from "@mui/icons-material";
import LogoutIcon from "@mui/icons-material/Logout";
import LoginIcon from "@mui/icons-material/Login";
import { version } from "../../../../package.json";
// import { ConvertAnonAccount } from "../LandingPageOLD";
import Button from "@mui/material/Button";

class AccountSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: 0,
      type: 0,
      depositOpen: false,
      withdrawalOpen: false,
    };
  }

  getAdminLinkText = (type) => {
    let returnText;
    if (type === "primary") {
      this.props.profile.activeBookie ? (returnText = "Bookie Console") : (returnText = "Create Your Own Book");
    } else if (type === "secondary") {
      this.props.profile.activeBookie
        ? (returnText = "your book's admin console")
        : (returnText = "take your friends' bets... for free!");
    }
    return returnText;
  };

  showSignup = () => {
    console.log("Show!");
    this.props.dispatch(sportsbookShowSignup(true));
  };

  registerAlert = () => {
    return (
      <ListItemButton
        divider
        onClick={this.showSignup}
        sx={{ borderRadius: 6, margin: 1, backgroundColor: "warning.light" }}
      >
        <ListItemIcon>
          <PersonOutline color="warning" />
        </ListItemIcon>
        <ListItemText primary={"Claim Free Account"} secondary={"save your account history"} />
        <ListItemSecondaryAction>
          <Button color="inherit" size="small">
            SIGN UP
          </Button>
        </ListItemSecondaryAction>
      </ListItemButton>

      // <Stack sx={{ width: '100%' }} spacing={2}>
      //
      //   <Alert
      //     severity="warning"
      //     onClick={this.showSignup}
      //
      //     action={
      //       <Button
      //         color="inherit" size="small">
      //         SIGN UP
      //       </Button>
      //     }
      //   >
      //     <AlertTitle>Claim Free Account</AlertTitle>
      //     save your account history
      //   </Alert>
      // </Stack>
    );
  };

  render() {
    console.log("ACCOUNT SETTINGS this.props: ", this.props);
    console.log("ACCOUNT SETTINGS  this.state: ", this.state);
    return (
      <>
        <Box>
          <Header title="Social" icon={CasinoIcon} />

          {/*<ConvertAnonAccount*/}
          {/*  open={this.props.showSignup && this.props.profile.email === "anon"}*/}
          {/*  onClose={() => this.props.dispatch(sportsbookShowSignup(false))}*/}
          {/*  profile={this.props.profile}*/}
          {/*/>*/}

          <List>
            {this.props.profile.email === "anon" && this.registerAlert()}
            {!this.props.uid && (
              <ListItemButton
                divider
                onClick={() => {
                  this.props.history.push("/signup");
                }}
              >
                <ListItemText secondary={"Sign Up / Sign In "} />
                <ListItemSecondaryAction>
                  <LoginIcon color="primary" />
                </ListItemSecondaryAction>
              </ListItemButton>
            )}

            <ListItemButton
              divider
              onClick={() => {
                getFirebase().updateProfile({
                  activeBookie: true,
                });
                this.props.history.push("/admin");
              }}
              sx={{ borderRadius: 6, margin: 1, backgroundColor: "#ffe867" }}
            >
              <ListItemIcon>
                <AccountBalance color="black" />
              </ListItemIcon>
              <ListItemText primary={this.getAdminLinkText("primary")} secondary={this.getAdminLinkText("secondary")} />
              <Divider />
              <ListItemSecondaryAction>
                <ChevronRight color="black" />
              </ListItemSecondaryAction>
            </ListItemButton>

            <ListItemButton
              divider
              onClick={() => {
                this.props.history.push("/cashier/switch");
              }}
            >
              <ListItemIcon>
                <PriceChange color="secondary" />
              </ListItemIcon>
              <ListItemText primary="Switch Sportsbook" secondary="bet at a different book" />
              <ListItemSecondaryAction>
                <ChevronRight />
              </ListItemSecondaryAction>
            </ListItemButton>

            {/*{*/}
            {/*  !this.props.profile.activeBookie &&*/}
            {/*  <ListItemButton*/}
            {/*    divider*/}
            {/*    onClick={() => {*/}
            {/*      getFirebase().updateProfile({ activeBookie: true });*/}
            {/*      this.props.history.push("/admin");*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <ListItemIcon color="green">*/}
            {/*      <AccountBalance color="green"/>*/}
            {/*    </ListItemIcon>*/}
            {/*    <ListItemText primary={this.getAdminLinkText("primary")} secondary={this.getAdminLinkText("secondary")} />*/}
            {/*    <ListItemSecondaryAction >*/}
            {/*      <ChevronRight color="green"/>*/}
            {/*    </ListItemSecondaryAction>*/}
            {/*  </ListItemButton>*/}
            {/*}*/}

            <ListItem divider>
              <ListItemIcon>
                <Info />
              </ListItemIcon>
              <ListItemText primary="App Version" secondary={version} />
            </ListItem>

            {this.props.uid && (
              <ListItemButton
                divider
                onClick={() => {
                  this.props.dispatch(signOut(() => this.props.history.push("/")));
                }}
              >
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary={"Logout"} secondary={this.props.profile.email} />
              </ListItemButton>
            )}

            {/*{this.props.profile.email === "anon" &&  (*/}
            {/*  <ListItemButton*/}
            {/*    divider*/}
            {/*    onClick={() => {*/}
            {/*      this.props.dispatch(sportsbookShowSignup(true))*/}
            {/*    }}*/}
            {/*  >*/}
            {/*    <ListItemIcon>*/}
            {/*      <BeenhereIcon />*/}
            {/*    </ListItemIcon>*/}
            {/*    <ListItemText primary={"Claim Free Account"} secondary={"Save Your History"} />*/}
            {/*  </ListItemButton>*/}
            {/*)}*/}
          </List>
        </Box>
        <BottomNav activeTab={3} managerGid={this.props.profile.managerGid} />
      </>
    );
  }
}

AccountSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  email: PropTypes.string,
  balance: PropTypes.number,
  uid: PropTypes.string,
  profile: PropTypes.object,
  history: PropTypes.array,
  userGroupPastBets: PropTypes.object,
  userGroupPendingBets: PropTypes.object,
  showSignup: PropTypes.bool,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: "inline-block",
    marginTop: "20px",
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  grid: {
    textAlign: "center",
    marginBottom: "20px",
  },
});

const mapStateToProps = (state) => {
  const getPastBets = (b) => {
    const collection = collect(b);
    const pastBets = collection.filter((val) => val.result !== "pending");
    return pastBets.all();
  };
  const getPendingBets = (b) => {
    const collection = collect(b);
    const pendingBets = collection.filter((val) => val.result === "pending");
    return pendingBets.all();
  };

  return {
    uid: state.firebase.auth.uid,
    email: state.firebase.auth.email,
    profile: state.firebase.profile,
    userGroupPastBets: getPastBets(state.firestore.data.userGroupBets) || null,
    userGroupPendingBets: getPendingBets(state.firestore.data.userGroupBets) || null,
    balance: state.firestore.data.cashier_userBalance || null,
    showSignup: state.sportsbookState.showSignup || false,
  };
};

export default compose(
  connect(mapStateToProps),
  withRouter,
  firestoreConnect((props) => {
    console.log("---------------firestore connecting");
    console.log("---------------USER ACCOUNT SUMMARY connect props", props);

    return [
      {
        collection: `groups/${props.profile.gid}/bets/`,
        where: [["uid", "==", props.uid ? props.uid : false]],
        storeAs: "userGroupBets",
      },
      {
        collection: `groups/${props.profile.gid}/balances/`,
        doc: props.uid,
        storeAs: "cashier_userBalance",
      },
    ];
  })
)(withStyles(styles)(AccountSettings));
