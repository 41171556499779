import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { forgotPassword, loginWithGoogle, signInWithEmail, signInWithGoogle } from "./functions/signIn";
import { withRouter } from "react-router-dom";
import { showSignInDialog, showSignUpDialog } from "../../reducers/new-user-reducer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import GoogleIcon from "@mui/icons-material/Google";
import Link from "@mui/material/Link";
import {getFirebase} from "react-redux-firebase";

class SignInDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  _handTextChange = (e) => {
    switch (e.target.id) {
      case "email":
        this.setState({ email: e.target.value });
        break;
      case "password":
        this.setState({ password: e.target.value });
        break;
      default:
        console.log(
          "Uncaught onChange target->",
          e.target.id,
          "value ->",
          e.target.value,
          "checked ->",
          e.target.checked
        );
    }
  };

  _handleClickAway = () => {
    console.log("click");
    this.props.dispatch(showSignInDialog(false));
  };

  render() {
    console.log("Login dialog props open if true true ", this.props.user.showSignInDialog, !this.props.user.showSignUpDialog);
    console.log("Login dialog props  ", this.props);
    const { classes } = this.props;

    return (
      <Dialog fullWidth open={this.props.user.showSignInDialog && !this.props.user.showSignUpDialog && !this.props.user.claimingFreeAccount} style={{ textAlign: "center" }}>
        {/*<ClickAwayListener onClickAway={this._handleClickAway}>*/}

        <Box sx={{ flexGrow: 1 }}>
          <Box elevation={0} sx={{ borderBottom: 2 }}>
            <DialogTitle style={{ fontFamily: "NeuveticaHeavyItalic", borderBottom: 3 }}>LOG IN</DialogTitle>
          </Box>

          <DialogContent sx={{ margin: 0 }}>
            {/*<Grid container spacing={2} fulWidth >*/}
            {/*  <Grid item xs={12} sm={12}>*/}
            {/*    <Stack spacing={2}>*/}
            {/*      <Paper fullWidth variant={"outlined"}*/}
            {/*             style={{ backgroundColor: alpha("#ffffff",.4), padding: 10, justifyContent: "center" }}>*/}
            {/*        <List>*/}
            {/*          <ListItemText className={classes.values} fullWidth>{"the app works better once you sign up"}</ListItemText>*/}
            {/*          /!*<ListItemText className={classes.values} fullWidth>{"Bet on all devices"}</ListItemText>*!/*/}
            {/*          /!*<ListItemText className={classes.values} fullWidth>{"Save all account history"}</ListItemText>*!/*/}
            {/*        </List>*/}
            {/*      </Paper>*/}
            {/*    </Stack>*/}
            {/*  </Grid>*/}
            {/*</Grid>*/}
            <Box style={{ marginTop: 0 }} display="flex" justifyContent="center" alignItems="center">
              <form className={classes.form} noValidate>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      onChange={this._handTextChange}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      label="Password"
                      type={"password"}
                      id="password"
                      onChange={this._handTextChange}
                    />
                  </Grid>
                </Grid>
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  className={classes.submit}
                  onClick={() => {
                    if (this.state.email && this.state.password) {
                      signInWithEmail(this.state.email, this.state.password).then(() =>
                          console.log("signing in with email")
                        // this.props.dispatch(showSignInDialog(false))
                      );
                      // this.props.dispatch(showSignInDialog(false));
                    } else {
                      alert("no blank fields allowed");
                    }
                  }}
                >
                  Log In
                </Button>
                <List>
                  <Divider> or </Divider>
                </List>
                <Button
                  style={{ margin: 0, color: "#ffffff" }}
                  fullWidth
                  variant="contained"
                  color="secondary"
                  className={classes.submit}
                  onClick={() => {
                    loginWithGoogle();
                  }}
                >
                  <GoogleIcon style={{ marginRight: 5, color: "white" }} />
                  Log In With Google
                </Button>

                {!this.props.user.initialPage.includes("join") && (
                    <Link
                        sx={{ color: "#bf0070", float: "right", marginTop: 4 }}
                        onClick={() => {
                          getFirebase().updateProfile({
                            conversionPopups: {
                              nextSignupReminder: Date.now() + 1000 * 60 * 2
                            },
                          });
                          this.props.dispatch(showSignInDialog(false));
                          this.props.history.push('/')
                        }}
                        variant="body2"
                    >
                      No Account?  Create one.
                    </Link>
                )}

                {this.props.user?.signInError?.message && (
                  <p style={{ color: "red" }}>Error: {this.props.user?.signInError?.message}</p>
                )}

                {
                  this.props.user?.signInError?.code === "auth/wrong-password" && (<>
                    <Link
                      sx={{ color: "#bf0070", marginTop: 4 }}
                      onClick={() => {
                        forgotPassword(this.state.email)
                      }}
                      variant="body2"
                    >
                      click to reset password
                    </Link>

                  </>)
                }

                {
                  !this.props.user?.signInError?.code === "auth/wrong-password" && (<>
                    <Link
                      sx={{ color: "#bf0070", float: "right", marginTop: 4 }}
                      onClick={() => {
                        this.props.dispatch(showSignUpDialog(false));
                        this.props.dispatch(showSignInDialog(false));
                        this.props.history.push("/");
                      }}
                      variant="body2"
                    >
                      need to create an account?
                    </Link>
                  </>)
                }


              </form>
            </Box>
          </DialogContent>
        </Box>
        {/*</ClickAwayListener>*/}
      </Dialog>
    );
  }
}

SignInDialog.defaultProps = {
  noFooter: false,
};

SignInDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  location: PropTypes.object,
  history: PropTypes.object,
  // user: PropTypes.shape({
  //   signUpError: PropTypes.any,
  //   referringBook: PropTypes.string
  // }),
  noFooter: PropTypes.bool,
  match: PropTypes.object,
  user: PropTypes.object,
  router: PropTypes.object,
  firstVisitURL: PropTypes.string,
  profile: PropTypes.object,
  open: PropTypes.bool,
};

const styles = (theme) => ({
  dialogTitle: {
    color: theme.palette.primary.dark,
    paddingBottom: 0,
  },
  dialogSubtitle: {
    paddingTop: 10,
    color: theme.palette.primary.light,
  },
  paper: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  values: {
    borderColor: theme.palette.primary.light,
    color: theme.palette.primary.dark,
    borderWidth: 2,
    marginBottom: 15,
    alignItems: "left",
    padding: 0,
  },
});

const mapStateToProps = (state) => {
  return {
    user: state.newUserState,
  };
};

export default withRouter(connect(mapStateToProps)(withStyles(styles)(SignInDialog)));
