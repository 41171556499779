import { Container } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import TextField from "@mui/material/TextField";
import { firestoreConnect, getFirebase } from "react-redux-firebase";
import { compose } from "redux";
import CasinoIcon from "@mui/icons-material/Casino";
import { Header } from "../../../components";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import { v4 } from "uuid";
import { withRouter } from "react-router-dom";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

class PropBets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      displaySuccessMessage: false,
      outcomes: 2,
    };
    this.handleSuccessfulAdd = this.handleSuccessfulAdd.bind(this);
  }

  pushToSports = () => {
    console.log("Hit redirect");
    this.props.history.push("/sports");
    //<Redirect to={"/sportsbook"} />
  };

  handleSuccessfulAdd() {
    this.setState({ displaySuccessMessage: true });
    setTimeout(this.pushToSports, 1000);
  }

  submitNewProp() {
    const db = getFirebase().firestore();
    var groupRef = db.collection("lines").doc("cup_2021");

    let markets = [
      { name: this.state.Outcome1Name, price: this.state.Outcome1Odds },
      { name: this.state.Outcome2Name, price: this.state.Outcome2Odds },
    ];

    if (this.state.Outcome3Name) markets.push({ name: this.state.Outcome3Name, price: this.state.Outcome3Odds });
    if (this.state.Outcome4Name) markets.push({ name: this.state.Outcome4Name, price: this.state.Outcome4Odds });
    if (this.state.Outcome5Name) markets.push({ name: this.state.Outcome5Name, price: this.state.Outcome5Odds });

    let lineToAdd = {
      AwayTeam: null,
      HomeTeam: null,
      LineID: v4(),
      Title: this.state.eventName,
      StartTime: Date.now(),
      markets: [{ outcomes: markets }],
    };

    console.log("line to add: ", lineToAdd);

    groupRef
      .update({
        futureLines: getFirebase().firestore.FieldValue.arrayUnion(lineToAdd),
      })
      .then(this.handleSuccessfulAdd);
  }

  renderNWayUI() {
    return (
      <Container maxWidth="sm" component="main">
        <Grid container alignItems="center" justifyContent="center" spacing={2}>
          <Grid item sm={12}>
            <Typography component="h6" variant="h6" align="center" color="textPrimary" style={{ marginTop: "20px" }}>
              Create a Prop Bet
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Card align={"center"}>
              <Typography style={{ marginTop: 15 }} variant="h7" component="div">
                Event
              </Typography>
              <CardContent>
                <Grid container alignItems="center" justifyContent="center" spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      onChange={(e) => this.setState({ eventName: String(e.target.value) })}
                      type="search"
                      label="Event Name"
                      helperText={"e.g. 'Number of infractions in airbnb review'"}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>

              <Typography style={{ marginTop: 15 }} variant="h7" component="div">
                Outcome 1
              </Typography>
              <CardContent>
                <Grid container alignItems="center" justifyContent="center" spacing={2}>
                  <Grid item xs={8}>
                    <TextField
                      onChange={(e) => this.setState({ Outcome1Name: String(e.target.value) })}
                      type="search"
                      id="standard-number"
                      label="Bet Name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      onChange={(e) => this.setState({ Outcome1Odds: Number(e.target.value) })}
                      type="number"
                      id="standard-number"
                      label="Bet Odds"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>

              <Typography style={{ marginTop: 15 }} variant="h7" component="div">
                Outcome 2
              </Typography>
              <CardContent>
                <Grid container alignItems="center" justifyContent="center" spacing={2}>
                  <Grid item xs={8}>
                    <TextField
                      onChange={(e) => this.setState({ Outcome2Name: String(e.target.value) })}
                      type="search"
                      id="standard-number"
                      label="Bet Name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      onChange={(e) => this.setState({ Outcome2Odds: Number(e.target.value) })}
                      type="number"
                      id="standard-number"
                      label="Bet Odds"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>

              {this.state.outcomes >= 3 && (
                <>
                  <Typography style={{ marginTop: 15 }} variant="h7" component="div">
                    Outcome 3
                  </Typography>
                  <CardContent>
                    <Grid container alignItems="center" justifyContent="center" spacing={2}>
                      <Grid item xs={8}>
                        <TextField
                          onChange={(e) => this.setState({ Outcome3Name: String(e.target.value) })}
                          type="search"
                          id="standard-number"
                          label="Bet Name"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          onChange={(e) => this.setState({ Outcome3Odds: Number(e.target.value) })}
                          type="number"
                          id="standard-number"
                          label="Bet Odds"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </>
              )}

              {this.state.outcomes >= 4 && (
                <>
                  <Typography style={{ marginTop: 15 }} variant="h7" component="div">
                    Outcome 4
                  </Typography>
                  <CardContent>
                    <Grid container alignItems="center" justifyContent="center" spacing={2}>
                      <Grid item xs={8}>
                        <TextField
                          onChange={(e) => this.setState({ Outcome4Name: String(e.target.value) })}
                          type="search"
                          id="standard-number"
                          label="Bet Name"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          onChange={(e) => this.setState({ Outcome4Odds: Number(e.target.value) })}
                          type="number"
                          id="standard-number"
                          label="Bet Odds"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </>
              )}

              {this.state.outcomes >= 5 && (
                <>
                  <Typography style={{ marginTop: 15 }} variant="h7" component="div">
                    Outcome 5
                  </Typography>
                  <CardContent>
                    <Grid container alignItems="center" justifyContent="center" spacing={2}>
                      <Grid item xs={8}>
                        <TextField
                          onChange={(e) => this.setState({ Outcome5Name: String(e.target.value) })}
                          type="search"
                          id="standard-number"
                          label="Bet Name"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          onChange={(e) => this.setState({ Outcome5Odds: Number(e.target.value) })}
                          type="number"
                          id="standard-number"
                          label="Bet Odds"
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>
                </>
              )}

              <AddCircleOutlineIcon
                onClick={() => {
                  if (this.state.outcomes < 5) this.setState({ outcomes: this.state.outcomes + 1 });
                  else alert("sorry, degen, that's too many");
                }}
                fontSize={"large"}
              />
              <Typography>Add more</Typography>

              <CardActions>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    console.log("this.state: ", this.state);
                    this.submitNewProp(this.state, "nWay");
                  }}
                >
                  {" "}
                  SUBMIT PROP
                </Button>
              </CardActions>
              {this.state.displaySuccessMessage && <Typography style={{ margin: 10 }}>Success!</Typography>}
            </Card>
          </Grid>
        </Grid>
      </Container>
    );
  }

  render() {
    console.log("add prop bets this.props: ", this.props);
    console.log("add prop bets this.state: ", this.state);
    return (
      <>
        <Header title="PropBets" icon={CasinoIcon} />
        {this.renderNWayUI()}
      </>
    );
  }
}

PropBets.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  email: PropTypes.string,
  balance: PropTypes.number,
  uid: PropTypes.string,
  profile: PropTypes.object,
  cashierTransactions: PropTypes.object,
  history: PropTypes.array,
  userBalance: PropTypes.number,
  cashierPlayerGroup: PropTypes.object,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: "inline-block",
    marginTop: "20px",
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  grid: {
    textAlign: "center",
    marginBottom: "20px",
  },
});

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    email: state.firebase.auth.email,
    profile: state.firebase.profile,
    cashierTransactions: state.firestore.data.cashierTransactions,
    userBalance: state.firestore.data.userBalance?.balance,
    cashierPlayerGroup: state.firestore.data.cashierPlayerGroup,
    history: PropTypes.object,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    console.log("---------------firestore connecting");
    console.log("---------------COMPOSE connect props", props);

    return [
      {
        collection: `users/${props.uid}/transactions`,
        where: [["gid", "==", props.profile.gid ? props.profile.gid : false]],
        storeAs: "cashierTransactions",
      },
      {
        collection: `users/${props.uid}/balances/`,
        doc: `${props.profile.gid}`,
        storeAs: "userBalance",
      },
      {
        // collection: "groups",
        // doc: props.profile.gid,
        // storeAs: "cashierPlayerGroup",
      },
    ];
  })
)(withStyles(styles)(withRouter(PropBets)));

{
  /*
    renderTwoWayUI() {
    const { classes } = this.props;
    return (
      <Container maxWidth="sm" component="main">
        <Grid container alignItems="center" justifyContent="center" spacing={2}>
          <Grid item sm={12}>
            <Typography component="h6" variant="h6" align="center" color="textPrimary" style={{ marginTop: "20px" }}>
              Two Way Prop Bets
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Card align={"center"}>
              <Typography style={{ marginTop: 15 }} variant="h7" component="div">
                Side 1
              </Typography>
              <CardContent>
                <Grid container alignItems="center" justifyContent="center" spacing={2}>
                  <Grid item>
                    <TextField
                      onChange={(e) => this.setState({ Bet1Name: String(e.target.value) })}
                      type="search"
                      id="standard-number"
                      label="Bet Name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      onChange={(e) => this.setState({ Bet1Odds: Number(e.target.value) })}
                      type="number"
                      id="standard-number"
                      label="Bet Odds"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>

              <Typography style={{ marginTop: 15 }} variant="h7" component="div">
                Side 2
              </Typography>
              <CardContent>
                <Grid container alignItems="center" justifyContent="center" spacing={2}>
                  <Grid item>
                    <TextField
                      onChange={(e) => this.setState({ Bet2Name: String(e.target.value) })}
                      type="search"
                      id="standard-number"
                      label="Bet Name"
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                  <Grid item>
                    <TextField
                      //onChange={(e) => this.setState({ Bet2Odds: Number(e.target.value) })}
                      type="number"
                      id="standard-number"
                      label="Bet Odds"
                      value={-1 * this.state.Bet1Odds}
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  </Grid>
                </Grid>
              </CardContent>

              <CardActions>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    console.log("this.state: ", this.state);
                    this.submitNewProp(this.state, "twoWay");
                  }}
                >
                  {" "}
                  SUBMIT PROP
                </Button>
              </CardActions>
              {this.state.displaySuccessMessage && <Typography style={{ margin: 10 }}>Success!</Typography>}
            </Card>
          </Grid>
        </Grid>
      </Container>
    );
  }

   */
}
