import {
  SportsBaseball,
  SportsBasketball,
  SportsFootball,
  SportsHockey,
  SportsKabaddi,
  SportsSoccer,
} from "@mui/icons-material";
import * as React from "react";

function getTopNavText(sport) {
  if (sport === "baseball_mlb") return "MLB";
  else if (sport === "NCAA-FB") return "NCAAF";
  else return sport;
}

function getTopNavIcon(sport) {
  if (sport === "Baseball") return <SportsBaseball />;
  else if (sport === "American Football") return <SportsFootball />;
  else if (sport === "Mixed Martial Arts") return <SportsKabaddi />;
  else if (sport === "Ice Hockey") return <SportsHockey />;
  else if (sport === "Basketball") return <SportsBasketball />;
  else if (sport === "Soccer") return <SportsSoccer />;
  else return null;
}

export { getTopNavIcon, getTopNavText };
