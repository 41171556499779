import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {Dialog, DialogActions, DialogContent, DialogTitle, Typography} from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { withRouter } from "react-router-dom";
import { showSignInDialog, showSignUpDialog } from "../../reducers/new-user-reducer";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import GoogleIcon from "@mui/icons-material/Google";
import Link from "@mui/material/Link";
import {v4} from "uuid";
import {awardBonus} from "../../reducers/cashier-reducer";
import {firestoreConnect, getFirebase} from "react-redux-firebase";
import {compose} from "redux";

class BookieReferralWelcomeHelp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  giveSelfCredits = (amount) => {
    console.log("trying to bonus amt: ", amount);
    const bonus = {
      uid: this.props.profile.uid,
      email: "My Demo Account",
      gid: this.props.profile.uid,
      type: "bonus",
      amount: amount,
      timestamp: new Date().getTime(),
      trans_id: v4(),
      state: "credited",
    };
    console.log("bonus obj: ", bonus);
    this.props.dispatch(awardBonus(bonus));
    getFirebase()
        .updateProfile({
          conversionPopups: {
            needsSelfBonus: false,
          },
        })
        .then((res) => console.log("update profile res: ", res));
  };

  renderSelfBonusDialog = (bookName) => {
    if (this.props.profile?.conversionPopups?.needsSelfBonus) {
      return (
          <Dialog open={this.props.profile?.conversionPopups?.needsSelfBonus} >
            <Box sx={{ flexGrow: 1 }}>
              <Box elevation={0} sx={{ border: 2, padding: 1 }}>
                <Box elevation={0} sx={{ borderBottom: 2 }}>
                  <DialogTitle style={{ paddingLeft: 1, paddingRight: 1, textAlign: "center", fontSize: "1.5rem" }}>
                    {bookName}
                    <Typography>credits</Typography>
                  </DialogTitle>
                </Box>
                <DialogContent sx={{ maxWidth: "450px" }}>
                  <Typography>
                    {`Credits are play money. You need them to place bets. As this book's owner, you can give out as many as you want. Give yourself some to bet with.`}
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button
                      fullWidth
                      variant="contained"
                      disabled={this.state.creditBtnDisabled}
                      color={"secondary"}
                      onClick={() => {
                        this.setState({ creditBtnDisabled: true });
                        this.giveSelfCredits(100);
                      }}
                  >
                    100 Credit Bonus
                  </Button>
                  {/*<Button fullWidth variant="contained" color={"secondary"} onClick={() => this.giveSelfCredits(500)}>*/}
                  {/*  500 Credits*/}
                  {/*</Button>*/}
                </DialogActions>
              </Box>
            </Box>
          </Dialog>
      )
    } else
      return null
  };


  render() {
    console.log("CONVERSION DIALOG props  ", this.props);
    const { classes } = this.props;

    return (<>
      <Dialog open={!this.props.profile?.conversionPopups?.knowsHowDepositsWork} >
        <Box sx={{ flexGrow: 1 }}>
          <Box elevation={0} sx={{ border: 2, padding: 1 }}>
            <Box elevation={0} sx={{ borderBottom: 2 }}>
              <DialogTitle style={{ paddingLeft: 1, paddingRight: 1, textAlign: "center", fontSize: "1.5rem" }}>
                You Need Credits
              </DialogTitle>
            </Box>
            <DialogContent sx={{ maxWidth: "450px" }}>
              <Typography>
                {`In order to place a bet, you need credits.  Click 'Deposit' to send a credit request to your book's owner.`}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button
                  fullWidth
                  variant="contained"
                  disabled={this.state.creditBtnDisabled}
                  color={"secondary"}
                  onClick={() => {
                    this.setState({ creditBtnDisabled: true });
                    getFirebase().updateProfile({
                      conversionPopups: {
                        knowsHowDepositsWork: true
                      }
                    })
                  }}
              >
                Ok!
              </Button>
              {/*<Button fullWidth variant="contained" color={"secondary"} onClick={() => this.giveSelfCredits(500)}>*/}
              {/*  500 Credits*/}
              {/*</Button>*/}
            </DialogActions>
          </Box>
        </Box>
      </Dialog>    </>);
  }
}

BookieReferralWelcomeHelp.defaultProps = {
  noFooter: false,
};

BookieReferralWelcomeHelp.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  location: PropTypes.object,
  history: PropTypes.object,
  // user: PropTypes.shape({
  //   signUpError: PropTypes.any,
  //   referringBook: PropTypes.string
  // }),
  noFooter: PropTypes.bool,
  match: PropTypes.object,
  user: PropTypes.object,
  router: PropTypes.object,
  firstVisitURL: PropTypes.string,
  profile: PropTypes.object,
  open: PropTypes.bool,
  groupConfigs: PropTypes.object
};

const styles = (theme) => ({
  dialogTitle: {
    color: theme.palette.primary.dark,
    paddingBottom: 0,
  },
  dialogSubtitle: {
    paddingTop: 10,
    color: theme.palette.primary.light,
  },
  paper: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  values: {
    borderColor: theme.palette.primary.light,
    color: theme.palette.primary.dark,
    borderWidth: 2,
    marginBottom: 15,
    alignItems: "left",
    padding: 0,
  },
});

const mapStateToProps = (state) => {
  return {
    user: state.newUserState,
    profile: state.firebase.profile,
    groupConfigs: state.firestore.data.groupConfigs,
  };
};

export default compose(
    connect(mapStateToProps),
    withRouter,
    // firestoreConnect((props) => {
    //
    //   if (props.profile.managerGid)
    //     return [
    //       {
    //         collection: "groups",
    //         doc: props.profile.managerGid,
    //         // where: [["groupName", "==", props.bookName || "Just For Fun"]],
    //         // limit: 1,
    //         storeAs: "groupConfigs",
    //       },
    //     ]
    //   else
    //     return []
    //   })
)(withStyles(styles)(BookieReferralWelcomeHelp));
