import { createAction, handleActions } from "redux-actions";
import { getFirebase } from "react-redux-firebase";
// import { auth } from "../firebase";
import { adj, nouns } from "./RandomWords";
import { claimFreeAccount, showSignInDialog, showSignUpDialog, newUserSignUpError } from "./new-user-reducer";
import store from "../store";
// import store from "../store";

const logoutError = createAction("LOGOUT_ERROR", (message, error) => {
  return {
    message: message,
    error: error,
  };
});

const addFirstVisitURLToProfile = (state) => {
  console.log("STATE!: ", state);
};

const logoutSuccess = createAction("LOGOUT_SUCCESS");

export const joinBookViaUrl = createAction("JOIN_BOOK_VIA_URL");
export const showConversionDialog = createAction("SHOW_CONVERSION_DIALOG", (show) => show);
export const landingPageClick = createAction("LANDING_PAGE_CLICK");

const signUpSuccess = createAction("SIGNUP_SUCCESS", (message) => {
  return {
    message: message,
  };
});

const signUpError = createAction("SIGNUP_ERROR", (message, error) => {
  // store.dispatch(claimFreeAccount(false))
  // store.dispatch(showSignUpDialog(true))
  // store.dispatch(signUpError(error))
  return {
    message: message,
    error: error,
  };
});

const signInSuccess = createAction("SIGNIN_SUCCESS", (message) => {
  return {
    message: message,
  };
});

const signInError = createAction("SIGNIN_ERROR", (message, error) => {
  return {
    message: message,
    error: error,
  };
});


const capFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const defaultCreateBookName = "Sportsbook" + String(Math.floor(Math.random() * 1000));

const defaultUsername =
  String(capFirstLetter(adj[Math.floor(Math.random() * adj.length)])) +
  "-" +
  String(capFirstLetter(nouns[Math.floor(Math.random() * nouns.length)]));

//create configs for the book the user will own
const getDefaultBook = (user, email) => {
  return {
    managerUid: user.uid,
    activePromo: "",
    maxBet: 1000,
    groupName: defaultCreateBookName,
    groupJuice: 4.55,
    groupCoinflipJuice: 0.98,
    managerEmail: email || "anon",
    initialBalance: 0,
    gradeYourOwnBet: false,
  };
};

const defaultGroupDoc = (user) => {
  return {
    managerUid: user.uid,
    activePromo: "",
    maxBet: 1000,
    groupName: "Sportsbook" + String(Math.floor(Math.random() * 1000)),
    groupJuice: 4.55,
    groupCoinflipJuice: 0.98,
    managerEmail: user.email || "anon",
    initialBalance: 0,
    gradeYourOwnBet: false,
  };
};

const defaultUserDoc = (user, firstVisitURL) => {
  return {
    email: user.email || "anon",
    activeBookie: true,
    managerGid: user.uid,
    gid: user.uid,
    username: defaultUsername,
    conversionState: "showBookieWelcomeMessage",
    firstVisitURL: firstVisitURL,
  }
}

export const createAccountWithGoogle = (firstVisitURL) => async (dispatch) => {
  store.dispatch(claimFreeAccount(true));
  const db = getFirebase().firestore();
  const adjustBalance = getFirebase().functions().httpsCallable("user-adjustBalanceGetRidOfMeLater");
  const firebase = getFirebase()
  var provider = new firebase.auth.GoogleAuthProvider();
  getFirebase().auth()
    .signInWithPopup(provider)
    .then((res) => {
      console.log("signIn res: ",res)
      dispatch(showSignUpDialog(false));
      let user = res.user;
      try {
        return db.collection("users").doc(user.uid).set(defaultUserDoc(user,firstVisitURL))
          .then((res2) => {
            console.log("just set user doc res: ",res2)
            console.log("ready to set group doc")
            return db.collection("groups").doc(user.uid).set(defaultGroupDoc(user))
          })
          .then((res3) => {
            // const ownerSelfBonus = 100;
            console.log("just set group doc res: ",res3)
            console.log("ready to self bonus")
            return adjustBalance({ uid: user.uid, gid: user.uid, adjust: 0 });
            // return store.dispatch(awardBonus(bankerSelfBonus(user, ownerSelfBonus)))
          }).then((res4) => {
            console.log("just tried to bonus: ",res4)
            console.log("end of new bookie")
            store.dispatch(claimFreeAccount(false));
          })
      } catch (error) {
        console.log("error! ", error)
      }

    })
    .then(() => {
        store.dispatch(showSignUpDialog(false))
        store.dispatch(claimFreeAccount(false));
      })
    .catch((error) => {
        console.log("Error upgrading anonymous account", error);
        store.dispatch(newUserSignUpError(error));
        store.dispatch(claimFreeAccount(false));
        store.dispatch(showSignUpDialog(true))
    })
}

export const convertAnonAccount = (email, password, uid, gid, managerGid) => async (dispatch) => {

  store.dispatch(claimFreeAccount(true));

  console.log("convert!: ", email, password, uid);
  const credential = getFirebase().auth.EmailAuthProvider.credential(email, password);
  console.log("credential ", credential);
  getFirebase()
    .auth()
    .currentUser.linkWithCredential(credential)
    .then(
      function(user) {
        console.log("Anonymous account successfully upgraded", user);
        //update users email in their profile
        getFirebase().updateProfile({
            email: email,
            conversionState: "bookieWizardWithEmail"
          })
          //update users email in their group
          .then((res) => {
            console.log("updating user email in group collection")
            getFirebase().firestore().collection("groups").doc(gid).collection("balances").doc(uid).update({
              email: email,
            });
          })
          //update the manager email in the book the user owns
          .then((res) => {
            console.log("updating user email in group configs")
            getFirebase().firestore().collection("groups").doc(managerGid).update({
              managerEmail: email,
            });
          })
          .then(() => {
            store.dispatch(showSignUpDialog(false))
            store.dispatch(claimFreeAccount(false));
          })
      },
      function(error) {
        console.log("Error upgrading anonymous account", error);
        store.dispatch(newUserSignUpError(error));
        store.dispatch(claimFreeAccount(false));
        store.dispatch(showSignUpDialog(true))
      },
    );
};


export const createEmailAndPasswordLogin = (email, password, firstVisitURL, gid) => async (dispatch) => {
  const db = getFirebase().firestore();
  const adjustBalance = getFirebase().functions().httpsCallable("user-adjustBalanceGetRidOfMeLater");

  let joinBookGID = "uU5VEX6AHwMqhdMDqOho";

  if (gid) {
    joinBookGID = gid
  }

  if (!firstVisitURL) {
    firstVisitURL = "UNKNOWN";
  }

  // TODO fix this later
  // if (this.props.refLink)
  //   defaultJoinBookGID = await this.getGidFromName(this.props.refLink)

  return getFirebase()
    .auth()
    .createUserWithEmailAndPassword(email, password)
    .then((res) => {
      dispatch(showSignUpDialog(false));
      let user = res.user;
      try {
        return db
          .collection("users")
          .doc(user.uid)
          .get()
          .then((doc) => {
            if (!doc.exists) {
              return db
                .collection("groups")
                .add(getDefaultBook(user))
                .then((groupDoc) => {
                  return db
                    .collection("users")
                    .doc(user.uid)
                    .set(
                      {
                        email: user.email || "anon",
                        welcomePromoEligible: true,
                        managerGid: groupDoc.id,
                        activeBookie: false,
                        gid: joinBookGID,
                        username: defaultUsername,
                        conversionState: firstVisitURL === "/claim-free-credits" ? "claimedFreeCredits" : "noClicksYet",
                        firstVisitURL: firstVisitURL,
                      },
                      { merge: true },
                    )
                    .then(() => {
                      getFirebase().analytics().logEvent('sign_up', {
                        type: 'email',
                        fromAnonAccount: false,
                        bankerReferral: gid ? true : false,
                        gid: joinBookGID,
                        firstVisitURL: firstVisitURL
                      });
                      adjustBalance({ uid: user.uid, gid: joinBookGID, adjust: 1000 });
                      // getFirebase().analytics().logEvent("ANON_SIGNUP_SUCCESS");
                      return { user, managerGID: groupDoc.id };
                    })
                    .then(() =>  dispatch(claimFreeAccount(false)))
                    .catch((err) => {
                      dispatch(signUpError("Something went wrong, we couldn't create your account. Please try again.", err));
                    })
                });
            }
          });
      } catch (error) {
        dispatch(signUpError("2 Something went wrong, we couldn't create your account. Please try again.", error));
        // dispatch(claimFreeAccount(false));
        // dispatch(showSignUpDialog(false));
        return false;
      }
    })
    .catch((err) => {
      dispatch(signUpError("Error.", err));
    });
};

export const updateConversionState = (uid) => {
  const db = getFirebase().firestore();
  db.collection("users").doc(uid).update({
    conversionState: "wantsToBeBanker",
  });
};

export const forgotPassword = (Email) => {
  // getFirebase().analytics().logEvent("FORGOT_PASSWORD");
  getFirebase().auth()
    .sendPasswordResetEmail(Email)
    .then(() => {
      alert("Please check your email...");
    })
    .catch((e) => {
      console.log(e);
    });
};

export const signOut = (callback) => async (dispatch) => {
  console.log("signing out!");
  // getFirebase().analytics().logEvent("SIGN_OUT");
  try {
    getFirebase().auth()
      .signOut()
      .then((user1) => {
        console.log("user1: ",user1)
        getFirebase().auth().onAuthStateChanged((user) => {
          console.log("auth state changed user: ",user)
          dispatch(logoutSuccess());
        });
        callback();
      })
      .catch((error) => {
        dispatch(logoutError("2 Something went wrong, we couldn't log you out.", error));
      });
  } catch (error) {
    dispatch(logoutError("3 Something went wrong, we couldn't log you out.", error));
  }
};

export const signIn = (email, password, callback) => async (dispatch) => {
  // getFirebase().analytics().logEvent("SIGN_IN");
  console.log("manual sign in!")
  getFirebase().analytics().logEvent('sign_in_manual', {
    email: email,
  });
  try {
    getFirebase().auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        getFirebase().auth().onAuthStateChanged((user) => {
          // dispatch(claimFreeAccount(false));
          // dispatch(showSignInDialog(false));
        });
        dispatch(signInSuccess());
        console.log("getting ready to hit callback");
        callback();
      })
      .then(() => {
        dispatch(claimFreeAccount(false));
        dispatch(showSignInDialog(false));
      })
      .catch((err) => {
        dispatch(signInError("2 Something went wrong, we couldn't create your account. Please try again.", err));
      });
  } catch (err) {
    dispatch(signInError("2 Something went wrong, we couldn't create your account. Please try again.", err));
  }
};

const initialState = {};

export default handleActions(
  {
    SIGNIN_ERROR: (state, { payload }) => {
      return {
        ...state,
        signInError: payload.error.message,
      };
    },
    SIGNUP_SUCCESS: (state) => {
      addFirstVisitURLToProfile(state);
      return {
        ...state,
      };
    },
    SIGNUP_ERROR: (state, { payload }) => {
      return {
        ...state,
        error: payload.error.message,
      };
    },
    JOIN_BOOK_VIA_URL: (state, { payload }) => {
      console.log("YAY JOIN BOOK! ", payload);
      return {
        ...state,
        referringBookGID: payload.bookGID,
        bonusAmt: payload.bonusAmt ? parseFloat(payload.bonusAmt) : 0,
      };
    },
    LANDING_PAGE_CLICK: (state, { payload }) => {
      console.log("Landing LandingPage Click! ", payload);
      return {
        ...state,
      };
    },
    SHOW_CONVERSION_DIALOG: (state, { payload }) => {
      return {
        ...state,
        showDialog: payload,
      };
    },
  },
  initialState,
);
