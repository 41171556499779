import React, { Component } from "react";
import PropTypes from "prop-types";
import { Box, Button, Divider, Grid, Typography } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { getTimestamp, gradeWager2, markParlayLegAsPush } from "../../reducers/sportsbook-reducer";
import {
  Adjust,
  AssignmentTurnedIn,
  AutorenewSharp,
  CancelPresentationOutlined,
  RadioButtonUnchecked,
} from "@mui/icons-material";
import { connect } from "react-redux";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";

class BetStandardized extends Component {
  constructor(props) {
    super(props);
    this.state = {
      buttonsDisabled: false,
    };
  }

  _pushLeg = (lineID) => {
    // console.log("Mark Leg as Push", {
    //   betID: this.props.bet.betID,
    //   LineID: lineID,
    //   gid: this.props.bet.gid,
    //   uid: this.props.bet.uid
    // });
    this.props.dispatch(
      markParlayLegAsPush({
        betID: this.props.bet.betID,
        LineID: lineID,
        gid: this.props.bet.gid,
        uid: this.props.bet.uid,
      })
    );
  };
  _getResultIcon = (result) => {
    if (result === "win") {
      return <AssignmentTurnedIn style={{ color: "lightgreen" }} />;
    } else if (result === "push") {
      return <RadioButtonUnchecked style={{ color: "gray" }} />;
    } else if (result === "pending") {
      return <Adjust style={{ color: "gray" }} />;
    } else {
      return <CancelPresentationOutlined style={{ color: "lightsalmon" }} />;
    }
  };
  _getReturn = (result, risk, win) => {
    if (result === "win") {
      return <Typography style={{ color: "lightgreen" }}>{"+" + win.toFixed(2)}</Typography>;
    } else if (result === "push") {
      return <Typography style={{ color: "gray" }}>Push</Typography>;
    } else if (result === "pending") {
      return null;
    } else {
      return <Typography style={{ color: "lightsalmon" }}>{"-" + risk.toFixed(2)}</Typography>;
    }
  };
  _getPickColor = (result, location) => {
    if (result === "win" && location === "pays") {
      return "green";
    } else if (result === "loss" && location === "costs") {
      return "red";
    }
  };
  _getParlayLegColor = (result) => {
    if (result === "win") {
      return "green";
    } else if (result === "loss") {
      return "red";
    } else if (result === "push") {
      return "gray"
    }
  };
  _getParlayTextColor = (result) => {
    if (result === "win") return "green";
    else if (result === "loss") return "red";
    else return null;
  };

  _betFooter = () => {
    return (
      <Grid
        item
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        xs={12}
        sx={{ paddingTop: 2 }}
      >
        <Grid item xs={3}>
          <Typography textAlign={"left"} className={this.props.classes.footerBetDetails}>
            Bet Placed
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography className={this.props.classes.footerBetDetails} textAlign={"right"}>
            {getTimestamp(this.props.bet.betPlaced)}
          </Typography>
        </Grid>

        <Grid item xs={3}>
          <Typography textAlign={"left"} className={this.props.classes.footerBetDetails}>
            Bet ID
          </Typography>
        </Grid>

        <Grid item xs={9}>
          <Typography className={this.props.classes.footerBetDetails} textAlign={"right"}>
            {this.props.bet.bet_id}
          </Typography>
        </Grid>


        {/*<Grid item xs={6}>*/}
        {/*  <Typography*/}
        {/*    textAlign={"left"}*/}
        {/*    className={this.props.classes.footerBetDetails}*/}
        {/*  >*/}
        {/*    Bet ID*/}
        {/*  </Typography>*/}
        {/*</Grid>*/}

        {/*<Grid item xs={6} >*/}
        {/*  <Typography*/}
        {/*    className={this.props.classes.footerBetDetails}*/}
        {/*    textAlign={"right"}*/}
        {/*  >*/}
        {/*    {this.props.bet.user_bet_id}*/}
        {/*  </Typography>*/}
        {/*</Grid>*/}
      </Grid>
    );
  };

  _renderGameInfo = (game) => {
    console.log("game: ", game);
    return (
      <Grid
        container
        direction="row"
        justifyContent="left"
        alignItems="center"
        sx={{ color: "text.secondary", typography: "body2" }}
      >
        <Grid item>{game.away}</Grid>
        <Grid item>&nbsp;at {game.home}</Grid>
        <Grid item xs={12}>
          {getTimestamp(game.starts_ms)}
        </Grid>
      </Grid>
    );
  };
  _renderWagerInfo = () => {
    let B = this.props.bet;
    return (
      <Grid
        container
        direction="row"
        justifyContent="left"
        alignItems="center"
        sx={{ color: this._getPickColor(B.result), typography: "body2" }}
      >
        <Grid item>risk {B.risk}</Grid>
        <Grid item>&nbsp;to win</Grid>
        <Grid item>&nbsp;{B.win}</Grid>
        <Grid item>&nbsp;- {B.result}</Grid>
      </Grid>
    );
  };
  _gradeBet = (result) => {
    this.setState({ buttonsDisabled: true });

    if (!["win", "push", "loss"].includes(result)) {
      return false;
    }

    this.props.dispatch(
      gradeWager2({
        uid: this.props.bet.uid,
        betID: this.props.bet.bet_id,
        result: result,
      })
    );
  };
  _renderGradingButtons = () => {
    return (
      <Grid item>
        <Grid container direction="row" justifyContent="center" alignItems="center" spacing={2} sx={{ paddingTop: 1 }}>
          <Grid item>
            <Button variant="outlined" disabled={this.state.buttonsDisabled} onClick={() => this._gradeBet("win")}>
              Win
            </Button>
          </Grid>
          <Grid item>
            <Button variant="outlined" disabled={this.state.buttonsDisabled} onClick={() => this._gradeBet("loss")}>
              Loss
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="outlined"
              disabled={this.state.buttonsDisabled}
              onClick={() => {
                this._gradeBet("push");
              }}
            >
              Push
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderParlayLegs = () => {
    const { classes } = this.props;
    const PC = this.props.bet.parlayCard;
    return (
      <>
        <Grid item xs={12}>
          <Typography className={classes.header} textAlign={"center"} sx={{ paddingBottom: 1 }}>
            {this.props.fromAdmin ? this.props.email : "Parlay"}
            {this.props.fromSuperAdmin && <> - {this.props.bet.groupName}</> }
          </Typography>
          <Grid item xs={12} sx={{ paddingLeft: 1, paddingRight: 1, maxWidth: "400px" }}>
            <Divider sx={{ marginBottom: 1.5 }} />
          </Grid>
        </Grid>

        {Object.keys(PC)
          .sort((x, y) => (PC[x].line.starts_ms > PC[y].line.starts_ms ? 1 : -1))
          .map((i) => {
            return (
              <>
                <Grid item xs={9} sx={{ color: this._getParlayLegColor(PC[i].result) }}>
                  <Typography className={classes.bet} textAlign={"left"}>
                    {PC[i].betPrimaryText}
                  </Typography>
                </Grid>
                <Grid item xs={3} sx={{ color: this._getParlayLegColor(PC[i].result) }}>
                  <Typography className={classes.bet} textAlign={"right"}>
                    {PC[i].price > 0 ? String("+" + PC[i].price) : String(PC[i].price)}
                  </Typography>
                </Grid>

                <Grid item xs={8} sx={{ marginBottom: 1 }}>
                  <Typography className={classes.betDetails} textAlign={"left"}>
                    {PC[i].line.away} at {PC[i].line.home}
                  </Typography>
                </Grid>
                <Grid item xs={4} sx={{ marginBottom: 1 }}>
                  <Typography className={classes.betDetails} textAlign={"right"}>
                    {getTimestamp(PC[i].line.starts_ms)}
                  </Typography>
                </Grid>
              </>
            );
          })}
      </>
    );
  };

  render() {
    const { classes } = this.props;

    // console.log("Standardized Bet Props: ", this.props);

    let minutesSinceStart = (Math.floor((Date.now() / 1000) * 1000) - this.props.bet.maxGameStartTime) / 1000 / 60;
    // console.log("minutes since start: ",minutesSinceStart)

    let B = this.props.bet;
    let PC;
    if (B.parlayCard) PC = B.parlayCard;

    if (!this.props.isParlay) {
      return (
        <Grid container>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" justifyContent="center" sx={{ paddingBottom: 1 }}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ marginBottom: 1, paddingLeft: 1, paddingRight: 1, maxWidth: "400px" }}
              >
                <Grid item xs={12}>
                  <Typography className={classes.header} textAlign={"center"} sx={{ paddingBottom: 1 }}>
                    {this.props.fromAdmin ? this.props.email : "Straight Bet"}
                    {this.props.bet.bet_data.is_half &&
                        <small >
                           &nbsp;@&nbsp;<font style={{ fontSize: ".65rem", color: "#00721e", fontWeight: 700 }}>HALFTIME</font>
                       </small>
                    }
                    {this.props.bet.isCoinflip && <AutorenewSharp fontSize="small" />}
                    {this.props.fromSuperAdmin && <> - {this.props.bet.groupName}</> }
                  </Typography>

                  <Grid item xs={12} sx={{ paddingLeft: 1, paddingRight: 1, maxWidth: "400px" }}>
                    <Divider sx={{ marginBottom: 1.5 }} />
                    {/*<Divider textAlign="left">*/}
                    {/*  /!*<Chip label="Game" size="small" />*!/*/}
                    {/*</Divider>*/}
                  </Grid>
                </Grid>

                <Grid item xs={9}>
                  <Typography
                    sx={{ color: this._getParlayLegColor(this.props.bet.result) }}
                    className={classes.bet}
                    textAlign={"left"}
                  >
                    {this.props.bet.emailData.bet}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    sx={{ color: this._getParlayLegColor(this.props.bet.result) }}
                    className={classes.bet}
                    textAlign={"right"}
                  >
                    {this.props.bet.emailData.price}
                  </Typography>
                </Grid>

                <Grid item xs={8}>
                  <Typography className={classes.betDetails} textAlign={"left"}>
                    {this.props.bet.emailData.matchName}
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography className={classes.betDetails} textAlign={"right"}>
                    {this.props.bet.emailData.gameTime}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" justifyContent="center" sx={{ paddingBottom: 4 }}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ paddingLeft: 1, paddingRight: 1, maxWidth: "400px" }}
              >
                {/*<Grid item xs={12} sx={{ paddingLeft: 1, paddingRight: 1, maxWidth: "400px" }}>*/}
                {/*  <Divider textAlign="right">*/}
                {/*    <Chip label="Bet" size="small" />*/}
                {/*  </Divider>*/}
                {/*</Grid>*/}
                <Grid item xs={9}>
                  <Typography
                    sx={{ color: this._getPickColor(this.props.bet?.result, "costs") }}
                    className={classes.bet}
                    textAlign={"left"}
                    // sx={{ color: this._getPickColor(this.props.bet?.result, "costs"), textDecoration: this.props.bet?.result === "push" ? "line-through" : null }}
                  >
                    { this.props.bet?.result === "push" ? "Push - Ticket Cost Refunded" : "Ticket Cost" }
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    sx={{ color: this._getPickColor(this.props.bet?.result, "costs") }}
                    className={classes.bet}
                    textAlign={"right"}
                  >
                    {this.props.bet.risk.toFixed(2)}
                  </Typography>
                </Grid>

                <Grid item xs={8}>
                  <Typography
                    className={classes.bet}
                    textAlign={"left"}
                    sx={{ textDecoration: this.props.bet?.result === "push" ? "line-through" : null }}
                  >
                    To Win
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography
                    className={classes.bet}
                    textAlign={"right"}
                    sx={{ textDecoration: this.props.bet?.result === "push" ? "line-through" : null }}
                  >
                    {this.props.bet.win.toFixed(2)}
                  </Typography>
                </Grid>

                <Grid item xs={8}>
                  <Typography
                    sx={{
                      textDecoration: this.props.bet?.result === "push" ? "line-through" : null,
                      color: this._getPickColor(this.props.bet?.result, "pays"),
                    }}
                    className={classes.bet}
                    textAlign={"left"}
                  >
                    Ticket Pays
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography
                    sx={{
                      textDecoration: this.props.bet?.result === "push" ? "line-through" : null,
                      color: this._getPickColor(this.props.bet?.result, "pays"),
                    }}
                    className={classes.bet}
                    textAlign={"right"}
                  >
                    {Number(this.props.bet.win + this.props.bet.risk).toFixed(2)}
                  </Typography>
                </Grid>
                {this._betFooter()}
              </Grid>
            </Box>
          </Grid>

          {this.props.allowGrading && this.props.bet.result === "pending" && (
            <Grid container justifyContent={"center"} sx={{ marginBottom: 7 }}>
              <Grid item>{this._renderGradingButtons()}</Grid>
            </Grid>
          )}
        </Grid>
      );
    } else {
      console.log("lb error: ",this.props.bet.risk)
      return (
        <Grid container>
          <Grid item xs={12}>
            <Box display="flex" alignItems="center" justifyContent="center" sx={{ paddingBottom: 1 }}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ marginBottom: 1, paddingLeft: 1, paddingRight: 1, maxWidth: "400px" }}
              >
                {this.renderParlayLegs()}
              </Grid>
            </Box>
          </Grid>

          <Grid item xs={12}>
            <Box display="flex" alignItems="center" justifyContent="center" sx={{ paddingBottom: 4 }}>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ paddingLeft: 1, paddingRight: 1, maxWidth: "400px" }}
              >
                {/*<Grid item xs={12} sx={{ paddingLeft: 1, paddingRight: 1, maxWidth: "400px" }}>*/}
                {/*  <Divider sx={{ marginBottom: 1}}/>*/}
                {/*</Grid>*/}

                <Grid item xs={9}>
                  <Typography
                    sx={{ color: this._getPickColor(this.props.bet?.result, "costs") }}
                    className={classes.bet}
                    textAlign={"left"}
                  >
                    Ticket Cost
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    sx={{ color: this._getPickColor(this.props.bet?.result, "costs") }}
                    className={classes.bet}
                    textAlign={"right"}
                  >
                    {this.props.bet.risk.toFixed(2)}
                  </Typography>
                </Grid>

                <Grid item xs={8}>
                  <Typography className={classes.bet} textAlign={"left"}>
                    To Win
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography className={classes.bet} textAlign={"right"}>
                    {Number(this.props.bet.win).toFixed(2)}
                  </Typography>
                </Grid>

                <Grid item xs={8}>
                  <Typography
                    sx={{ color: this._getPickColor(this.props.bet?.result, "pays") }}
                    className={classes.bet}
                    textAlign={"left"}
                  >
                    Ticket Pays
                  </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography
                    sx={{ color: this._getPickColor(this.props.bet?.result, "pays") }}
                    className={classes.bet}
                    textAlign={"right"}
                  >
                    {Number(this.props.bet.win + this.props.bet.risk).toFixed(2)}
                  </Typography>
                </Grid>
                {this._betFooter()}
              </Grid>
            </Box>
          </Grid>

          {this.props.allowGrading && this.props.bet.result === "pending" && (
            <Grid container justifyContent={"center"} sx={{ marginBottom: 7 }}>
              <Grid item>{this._renderGradingButtons()}</Grid>
            </Grid>
          )}
        </Grid>
      );
    }
  }
}

BetStandardized.defaultProps = {
  isHistory: false,
  allowGrading: false,
};

BetStandardized.propTypes = {
  bet: PropTypes.object,
  email: PropTypes.string,
  classes: PropTypes.object,
  allowGrading: PropTypes.bool,
  dispatch: PropTypes.func,
  isHistory: PropTypes.bool,
  isParlay: PropTypes.bool,
  fromAdmin: PropTypes.bool,
  fromSuperAdmin: PropTypes.bool,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  pastBetsList: {
    width: "100%",
  },
  bet: {
    fontSize: ".9rem",
  },
  betDetails: {
    fontSize: ".7rem",
  },
  footerBetDetails: {
    fontSize: ".7rem",
    color: "grey",
  },
});

const mapStateToProps = (state, props) => {
  return {
    email: state.firestore.data["user-" + props.bet.uid]?.email,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: "users",
        doc: props.bet.uid,
        storeAs: `user-${props.bet.uid}`,
      },
    ];
  })
)(withStyles(styles)(BetStandardized));
