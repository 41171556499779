import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { firestoreConnect, getFirebase } from "react-redux-firebase";
import { compose } from "redux";
import Box from "@mui/material/Box";
import ReceiptIcon from "@mui/icons-material/Receipt";
import LocalAtmIcon from "@mui/icons-material/LocalAtm";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import GroupIcon from "@mui/icons-material/Group";
import SettingsApplicationsIcon from '@mui/icons-material/SettingsApplications';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import ToggleOffIcon from '@mui/icons-material/ToggleOff';
import {
  Alert,
  Badge,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import List from "@mui/material/List";
import {AutorenewSharp, ChevronRight, Help, OpenInNew, Settings} from "@mui/icons-material";
import { withRouter } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import { Header } from "../../../components";
import CountUp from "react-countup";
import { toggleDepositHelperOff } from "../../../reducers/cashier-reducer";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import JuiceMap from "../../OLD/Admin/screens/JuiceMap";

class BookieAdvancedSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bonusOpen: false,
      changeBookNameOpen: false,
      newBookName: "",
      copySnackBarOpen: false,
      depositHelperClosed: false,
      juices: [0, 0.49, 0.98, 1.46, 1.92, 2.38, 2.83, 3.27, 3.7, 4.13, 4.55, 4.95, 5.36, 5.75, 6.14, 6.75],

    };
  }

  _changeBookName = async () => {
    let bookID = this.props.uid

    if (this.props.profile.managerGid && this.props.uid !== this.props.profile.managerGid)
      bookID = this.props.profile.managerGid

    const bookConfigs = getFirebase().firestore().collection("groups").doc(bookID);

    bookConfigs
      .update({ groupName: this.state.newBookName })
      .then(() => {
        getFirebase().updateProfile({ conversionState: "newBookieChangedName" });
        getFirebase().analytics().logEvent("book_created", { uid: this.props.uid });
      })
      .then(() => {
        this.setState({ changeBookNameOpen: false });
      });
  };

  renderBookPNL = () => {
    let B = this.props.balances;
    return Object.keys(B)
      .reduce((a, b) => {
        return a - (B[b].totalWin || 0) + (B[b].totalLoss || 0);
      }, 0)
      .toFixed(0);
  };

  renderTotalBalances = () => {
    let B = this.props.balances;
    return Object.keys(B)
      .reduce((a, b) => {
        return a + (B[b].balance || 0);
      }, 0)
      .toFixed(0);
  };

  renderPendingSummary = () => {
    let B = this.props.balances;
    let totalPastBets = Object.keys(B).reduce((a, b) => {
      return a + (B[b].totalBets || 0);
    }, 0);
    let totalPendingBetCount = Object.keys(B).reduce((a, b) => {
      return a + (B[b].pendingBetCount || 0);
    }, 0);
    let totalPendingRisk = Object.keys(B).reduce((a, b) => {
      return a + (B[b].totalPendingRisk || 0);
    }, 0);
    let totalPendingWin = Object.keys(B).reduce((a, b) => {
      return a + (B[b].totalPendingWin || 0);
    }, 0);
    let totalPendingDeposits = Object.keys(B).reduce((a, b) => {
      return a + (B[b].pendingDepositCount || 0);
    }, 0);
    let totalPendingWithdrawals = Object.keys(B).reduce((a, b) => {
      return a + (B[b].pendingWithdrawalCount || 0);
    }, 0);

    let primaryText;
    let secondaryText;
    if (totalPendingWithdrawals + totalPendingDeposits === 0) {
      secondaryText = "no pending deposits/withdrawals";
      primaryText = "Transactions";
    } else {
      primaryText = "Pending Transactions";
      secondaryText = `${totalPendingDeposits} pending deposits, ${totalPendingWithdrawals} pending withdrawals`;
    }

    return (
      <>
        <ListItemButton divider onClick={() => this.props.history.push("/bookie/transactions")}>
          <ListItemIcon>
            <ReceiptIcon color="secondary" />
          </ListItemIcon>
          <ListItemText primary={primaryText} secondary={secondaryText} />
          <ListItemSecondaryAction>
            {totalPendingDeposits + totalPendingWithdrawals > 0 && (
              <Badge badgeContent={totalPendingDeposits + totalPendingWithdrawals} color="secondary">
                <ChevronRight />
              </Badge>
            )}
            {totalPendingDeposits + totalPendingWithdrawals == 0 && <ChevronRight />}
          </ListItemSecondaryAction>
        </ListItemButton>

        <ListItemButton divider onClick={() => this.props.history.push("/bookie/bookwide-pending-bets")}>
          <ListItemIcon>
            <PendingActionsIcon color="secondary" />
          </ListItemIcon>
          <ListItemText
            primary={`${totalPendingBetCount} Pending Bets`}
            secondary={`Risking ${Math.round(totalPendingRisk)} to Win ${Math.round(totalPendingWin)}`}
          />
          <ListItemSecondaryAction>
            <ChevronRight />
          </ListItemSecondaryAction>
        </ListItemButton>

        <ListItemButton divider onClick={() => this.props.history.push("/bookie/bookwide-past-bets")}>
          <ListItemIcon>
            <AssignmentTurnedInIcon color="secondary" />
          </ListItemIcon>
          <ListItemText
            primary={`Past Bets`}
            secondary={`${totalPastBets} bets from ${Object.keys(B).length} players`}
          />

          <ListItemSecondaryAction>
            <ChevronRight />
          </ListItemSecondaryAction>
        </ListItemButton>
      </>
    );
  };
  getAmericanfromJuicePcnt = (num) => {
    return Object.keys(JuiceMap).find((key) => JuiceMap[key] === num);
  };

  renderChangeBookName = () => {
    return (
      <Dialog open={this.state.changeBookNameOpen}>
        <DialogTitle style={{ fontFamily: "Libre Franklin", borderBottom: 3 }}>
          <Grid container direction="row" justifyContent="space-around" alignItems="center">
            <Grid item xs={11}>
              Change Book Name
            </Grid>
            <Grid
              item
              xs={1}
              onClick={() => {
                this.setState({ changeBookNameOpen: false });
              }}
            >
              <CloseIcon />
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Stack spacing={2}>
            <ListItemText
              primary={`currently: ${this.props.bookConfigs?.groupName}`}
              // secondary={"current balance: "}
            />
            <TextField label={"new name"} onChange={(e) => this.setState({ newBookName: String(e.target.value) })} />

            <Button
              variant={"contained"}
              onClick={() => {
                this._changeBookName();
                // this.props.dispatch(awardBonus(bonus))
                // this.setState(this.initialState)
              }}
              // sx={{ borderRadius: 6, border: 2, borderColor: "#bf0070" }}
            >
              SUBMIT
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>
    );
  };
  renderChangeCoinflipJuice = () => {
    return (
      <Dialog open={this.state.changeCoinflipJuiceOpen}>
        <DialogTitle style={{ fontFamily: "Libre Franklin", borderBottom: 3 }}>
          <Grid container direction="row" justifyContent="space-around" alignItems="center">
            <Grid item xs={11}>
              Change Coinflip Juice
            </Grid>
            <Grid
              item
              xs={1}
              onClick={() => {
                this.setState({ changeCoinflipJuiceOpen: false });
              }}
            >
              <CloseIcon />
            </Grid>
          </Grid>
        </DialogTitle>

        <DialogContent>

          <ListItemButton sx={{ borderRadius: 6, margin: 1, border: 2, borderColor: "#bf0070" }}>
            <Grid container direction="row" justifyContent="space-around" alignItems="center">
              <Grid item>
                {this.props.bookConfigs?.groupCoinflipJuice !== 0 && <RemoveCircleIcon onClick={this._changeJuiceDown} />}
              </Grid>

              <Grid item>
                <Grid container direction="column" justifyContent="center" alignItems="center">
                  <Grid item xs={12}>
                    Juice
                  </Grid>
                  <Grid item xs={12}>
                    {this.props.bookConfigs.groupCoinflipJuice >= 0
                        ? this.getAmericanfromJuicePcnt(this.props.bookConfigs?.groupCoinflipJuice)
                        : this.getAmericanfromJuicePcnt(this.props.bookConfigs?.groupCoinflipJuice)}
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                {this.props.bookConfigs?.groupCoinflipJuice !== 4.55 && <AddCircleIcon onClick={this._changeJuiceUp} />}
              </Grid>
            </Grid>
          </ListItemButton>

        </DialogContent>
      </Dialog>
    );
  };

  americanJuiceFromPcnt = (newJuice) => {
    let impliedA = 0.5;
    impliedA += (impliedA * newJuice) / 100;
    return Math.round((impliedA / (1 - impliedA)) * -100);
  };

  _changeJuiceUp = (e) => {
    let bookID = this.props.uid

    if (this.props.profile.managerGid && this.props.uid !== this.props.profile.managerGid)
      bookID = this.props.profile.managerGid

    let currentJuice = this.props.bookConfigs?.groupCoinflipJuice;
    const groupJuice = getFirebase().firestore().collection("groups").doc(bookID)
    groupJuice.update({ groupCoinflipJuice: this.state.juices[this.state.juices.indexOf(currentJuice) + 1] });
  };

  _changeJuiceDown = (e) => {
    let bookID = this.props.uid

    if (this.props.profile.managerGid && this.props.uid !== this.props.profile.managerGid)
      bookID = this.props.profile.managerGid

    let currentJuice = this.props.bookConfigs?.groupCoinflipJuice;
    const groupJuice = getFirebase().firestore().collection("groups").doc(bookID)
    groupJuice.update({ groupCoinflipJuice: this.state.juices[this.state.juices.indexOf(currentJuice) - 1] });
  };

  render() {
    console.log("Book advanced settings this.props: ", this.props);

    return (
      <>
        <Header showBackButton={true} />


        {this.renderChangeBookName()}
        {this.renderChangeCoinflipJuice()}

        <List>

          <ListItemButton onClick={() => this.setState({ changeBookNameOpen: true})}>
            <ListItemIcon>
              <BorderColorIcon color="secondary" />
            </ListItemIcon>
            <ListItemText primary="Book Name" secondary="Changes display name, not bet history" />
            <ListItemSecondaryAction>
              <ChevronRight />
            </ListItemSecondaryAction>
          </ListItemButton>


          <Divider />
          <ListItemButton onClick={() => this.setState({ changeCoinflipJuiceOpen: true})}>
            <ListItemIcon>
              <AutorenewSharp color="secondary" />
            </ListItemIcon>
            <ListItemText primary="Coinflip Juice" secondary={`Coinflips are currently ${this.americanJuiceFromPcnt(this.props.bookConfigs.groupCoinflipJuice)}`} />
            <ListItemSecondaryAction>
              <ChevronRight />
            </ListItemSecondaryAction>
          </ListItemButton>


          {/*<Divider />*/}
          {/*<ListItemButton onClick={() => this.toggleAchievements()}>*/}
          {/*  <ListItemIcon>*/}
          {/*    <EmojiEventsIcon color="secondary" />*/}
          {/*  </ListItemIcon>*/}
          {/*  <ListItemText primary="Hide Bookie Achievements" secondary="you can see achievements" />*/}
          {/*  <ListItemSecondaryAction>*/}
          {/*    <ToggleOffIcon />*/}
          {/*  </ListItemSecondaryAction>*/}
          {/*</ListItemButton>*/}


          <Divider />
          <ListItemButton onClick={() => window.location = 'mailto:admin@superfunbet.com' }>
            <ListItemIcon>
              <Help color="secondary" />
            </ListItemIcon>
            <ListItemText primary="Request a Feature" secondary="We'd love to hear from you" />
            <ListItemSecondaryAction>
              <OpenInNew />
            </ListItemSecondaryAction>
          </ListItemButton>


        </List>
      </>
    );
  }
}

BookieAdvancedSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  managerGid: PropTypes.string,
  history: PropTypes.array,
  uid: PropTypes.string,
  balances: PropTypes.object,
  bookConfigs: PropTypes.object,
  profile: PropTypes.object,
  newUserState: PropTypes.object,
  showSignup: PropTypes.bool,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: "inline-block",
    marginTop: "20px",
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  grid: {
    textAlign: "center",
    marginBottom: "20px",
  },
  dialogTitle: {
    color: "green",
    paddingBottom: 0,
  },
  dialogSubtitle: {
    paddingTop: 10,
    color: theme.palette.primary.light,
  },
});

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    email: state.firebase.auth.email,
    profile: state.firebase.profile,
    managerGid: state.firebase.profile.managerGid,
    sportsbookState: state.sportsbookState,
    showSignup: state.newUserState.showSignUpDialog || false,
    balances: state.firestore.data?.bookie_balances,
    bookConfigs: state.firestore.data?.bookie_bookConfigs,
    newUserState: state.newUserState,
  };
};

export default compose(
  connect(mapStateToProps),
  withRouter,
  firestoreConnect((props) => {
    return [
      {
        collection: `groups/${props.profile.managerGid}/balances`,
        storeAs: "bookie_balances",
      },
      {
        collection: `groups`,
        doc: `${props.profile.managerGid}`,
        storeAs: "bookie_bookConfigs",
      },
    ];
  })
)(withStyles(styles)(BookieAdvancedSettings));
