import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import SuperAdminHeader from "./components/SuperAdminHeader";
import { Box, CircularProgress, Stack, Switch, Typography } from "@mui/material";
import BetList2 from "../../components/BetDisplay/BetList2";

class SuperAdminNewBets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortByBetPlaced: false,
    };
  }

  render() {
    return (
      <>
        <SuperAdminHeader />
        {!this.props.newBets && <CircularProgress />}
        {this.props.newBets && (
          <>
            <Box display="flex" justifyContent="center" alignItems="center">
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography variant="caption">Sort By Game Start</Typography>
                <Switch
                  color="secondary"
                  checked={this.state.sortByBetPlaced}
                  onClick={() => this.setState({ sortByBetPlaced: !this.state.sortByBetPlaced })}
                />
                <Typography variant="caption">Sort By Bet Placed</Typography>
              </Stack>
            </Box>
            <BetList2 fromSuperAdmin fromAdmin bets={this.props.newBets} oldestAtTop={true && this.state.sortByBetPlaced === false} doNotSort={this.state.sortByBetPlaced} />
          </>
        )}
      </>
    );
  }
}

SuperAdminNewBets.propTypes = {
  newBets: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    newBets: state.firestore.data.superAdminNewBets,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    const interval = 1000 * 10;
    const roundedNow = Math.round(Date.now() / interval) * interval;
    const sevenDaysAgo = roundedNow - 1000 * 3600 * 24 * 7;
    // const sevenDaysAgo = roundedNow - 1000 * 3600 * 24 * 7;

    return [
      {
        type: "child_added",
        collection: "bets2",
        where: [["result", "==", "pending"]],
        orderBy: ["betPlaced", "asc"],
        storeAs: "superAdminNewBets",
      },
    ];
  })
)(SuperAdminNewBets);
