import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import LPHeader from "./components/LPHeader"
import ValueProp from "./components/ValueProp";
import LPFooter from "./components/LPFooter";
import CTA from "./components/CTA";
import BottomCTA from "./components/BottomCTA";
import {Grid} from "@mui/material";

class Page extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  sloganTop = "Bet With Friends"
  sloganBottom = "Not Casinos"
  description = "Super Fun Bet lets you be the bookie for your friends.  Create your own private sportsbook.  No fees, no risk," +
      " no download.  Just name your book!";


  valuePropTitle1 = "YOU OWN THE BOOK"; valuePropDesc1 = "You issue credits to your friends, they place bets. You set the max bet and the juice, and our software does the rest. It's like you own Fanduel.";
  valuePropTitle2 = "PURE SPORTSBETTING"; valuePropDesc2 = "Fast, user-friendly software offering sharp lines, live betting, and parlays. We'll never ask you to upload your ID, provide personal information, or give us money.";
  valuePropTitle3 = "LEGAL EVERYWHERE"; valuePropDesc3 = "Super Fun Bet uses credits instead of cash, so it's legal everywhere. Stop giving money to casinos, governments, and offshore books.";
  valuePropTitle4 = "FREE AND SECURE"; valuePropDesc4 = "We don't charge you money or sell your information. The app works better if you save your account with an email address, but you don't need to provide one.";
  // valuePropTitle4 = "ABOUT US"; valuePropDesc4 =
  //     <>
  //       {"We're a small group of sportsbettors who made this app to bet among ourselves.  " +
  //           "We don't think sportsbetting needs to be shady or dangerous or taxed or promoted endlessly in annoying commercials.  " }
  //       <br/>
  //       <br/>
  //       {  "SuperFunBet is free, with no plans to ever charge anything.  We don't require ANY personal information, " +
  //         "though the app works much better with an email address to use as your login id."}
  //       <br/>
  //       <br/>
  //       {
  //         "We hope you'll enjoy the app as much as we do.  It's pretty sophisticated for being free.  "
  //       }
  //     </>


  render() {
    console.log("Landing Page this.props: ", this.props);

    if (this.props.url.includes("take")){
      this.sloganTop = "Free Bookie Software"
      this.sloganBottom = "Better for players, better for bookies"
      this.description =  "Stop dealing with shady offshore software companies with their pay-per-head fees and Soviet era software. SuperFunBet is 100% free, easy to use, and legal. No credit card needed-- ever.  Just name your book."


      this.valuePropTitle1 = "EASY TO USE"; this.valuePropDesc1 = "Our modern software is clean, fast, and easy to use for bookies and players.  No complicated onboarding process.";
      this.valuePropTitle2 = "SAFE AND LEGAL"; this.valuePropDesc2 = "SuperFunBet uses credits instead of cash, so it's legal everywhere. Handle finances directly with your users, not via untrustworthy offshore companies and payment processors.";
      this.valuePropTitle3 = "FEATURE RICH"; this.valuePropDesc3 = "The sharpest lines in the business.  Set the juice and max bet for each player.  Parlays, live betting, autograded bets, and more.";
      this.valuePropTitle4 = "TRY IT OUT"; this.valuePropDesc4 = "Have your own book up and running in minutes.  No credit card or email required.  All you need is a name for your book.";


    }


    if (this.props.uid) {
      return <Redirect to={"/sports"} />;
    } else {
      return (<>
          <LPHeader />

      <Grid container>
          <Grid item xs={12}>
            <CTA url={this.props.url}
                 sloganTop={this.sloganTop}
                 sloganBottom={this.sloganBottom}
                 description={this.description}/>
          </Grid>
          <Grid item sx={{ padding: 0}}>
            <ValueProp url={this.props.url}
               vpTitle1 = {this.valuePropTitle1}
               vpTitle2 = {this.valuePropTitle2}
               vpTitle3 = {this.valuePropTitle3}
               vpTitle4 = {this.valuePropTitle4}
               vpDesc1 = {this.valuePropDesc1}
               vpDesc2 = {this.valuePropDesc2}
               vpDesc3 = {this.valuePropDesc3}
               vpDesc4 = {this.valuePropDesc4}
            />
          </Grid>
          <Grid item xs={12} sx={{paddingTop: 8}}>

            <BottomCTA
                url={this.props.url}
                sloganTop={this.sloganTop}
                sloganBottom={this.sloganBottom}
                description={this.description} />

          </Grid>
        </Grid>
      <LPFooter />

      </>);
    }
  }
}

Page.propTypes = {
  classes: PropTypes.object.isRequired,
  firstVisitURL: PropTypes.string,
  anonAccountState: PropTypes.string,
  history: PropTypes.array,
  uid: PropTypes.object.isRequired,
  url: PropTypes.string
};

const styles = (theme) => ({
});

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    firstVisitURL: state.newUserState.initialPage,
    anonAccountState: state.newUserState.anonAccountState,
  };
};

export default withRouter(connect(mapStateToProps)(withStyles(styles)(Page)));
