import React, { useEffect } from "react";
import firebase from "../../../firebase";
import Button from "@mui/material/Button";
import { DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";

const CheckForHandle = (props) => {
  const db = firebase.firestore();
  const auth = firebase.auth();
  const [modalOpen, setModalOpen] = React.useState(false);
  const [handle, setHandle] = React.useState("");

  useEffect(() => {
    if (!auth?.currentUser?.uid) return;
    const fetchHandle = async (uid) => {
      if (!uid) return;
      const doc = await db.collection("pickem").doc("user").collection(uid).doc("public").get();
      if (!doc.data()?.handle) {
        setModalOpen(true);
      } else {
        setHandle(doc.data().handle);
      }
    };

    fetchHandle(firebase.auth().currentUser.uid);
  }, [auth?.currentUser?.uid]);

  console.log("Handle: ", handle);

  const saveHandle = async () => {
    if (!auth?.currentUser?.uid) return;
    console.log("Save handle: ", handle);
    const doc = await db.collection("pickem").doc("user").collection(auth.currentUser.uid).doc("public");
    await doc.set({ handle }, { merge: true });
    setModalOpen(false);
  };

  const handleClose = () => {
    setModalOpen(false);
  };

  return (
    <Dialog open={modalOpen} onClose={handleClose}>
      <DialogTitle>Pickem Display Name</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You don&apos;t have a display name set. This is how other users will see you in the leaderboard. Please enter
          a display name.
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Handle"
          type="text"
          fullWidth
          variant="standard"
          onChange={(e) => setHandle(e.target.value)}
          value={handle}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="contained" onClick={saveHandle}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

CheckForHandle.propTypes = {};

export default CheckForHandle;
