import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import * as React from "react";
import PropTypes from "prop-types";
import withStyles from "@mui/styles/withStyles";
import clsx from "clsx";
import Button from "@mui/material/Button";
import { signOut } from "../../reducers/user-reducer";
import { activateBookie } from "../../reducers/admin-reducer";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { firestoreConnect } from "react-redux-firebase";
import { compose } from "redux";
// import { quickEvent } from "../../utilities/sftTrack";
import HomeIcon from "@mui/icons-material/Home";
// import ClickBalanceMenu from "./ClickBalanceMenu";
import { alpha, Grid } from "@mui/material";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { ChevronLeft } from "@mui/icons-material";
import CountUp from "react-countup";

const drawerWidth = 240;

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerOpen: false,
      listOfBookNames: [],
      oldBalance: false,
      newBalance: false,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      (prevProps.headerUserBalance || prevProps.headerUserBalance === 0) &&
      (this.props.headerUserBalance || this.props.headerUserBalance === 0) &&
      prevProps.headerUserBalance !== this.props.headerUserBalance
    ) {
      this.setState({
        oldBalance: this.state.newBalance,
        newBalance: this.props.headerUserBalance,
      });
    }
    if (this.state.newBalance === false && (this.props.headerUserBalance || this.props.headerUserBalance === 0)) {
      this.setState({ newBalance: this.props.headerUserBalance });
    }
  }

  handleDrawerOpen = () => {
    this.setState({
      drawerOpen: true,
    });
  };

  handleDrawerClose = () => {
    this.setState({
      drawerOpen: false,
    });
  };

  logout = () => {
    this.setState({
      drawerOpen: false,
    });
    this.props.dispatch(signOut(() => this.props.history.push("/")));
    // this.props.history.push("/signup");
  };

  signup = () => {
    this.props.history.push("/");
  };

  switchSportsbook = () => {
    this.props.history.push("/cashier/switch");
  };

  signupFromAnon = () => {
    // getFirebase().analytics().logEvent("ANON_CLICK_HEADER_SIGNUP");
    this.props.history.push("/");
  };

  activateBookie = () => {
    console.log("become a bookie!");
    console.log("become a this.props.profile.uid!", this.props.uid);
    this.props.dispatch(activateBookie({ uid: this.props.uid }));
    this.props.history.push("/admin");
  };

  superAdmin = () => {
    this.props.history.push("/super-admin");
  };

  cashier = () => {
    console.log("clicked casheir button ");
    // getFirebase().analytics().logEvent("USE_CASHIER", {
    //   //id: this.props.uid,
    //   //sport: this.props.sport
    // });

    // const client = new KeenTracking({
    //   projectId: '61ba6227ab0d2a7bed2589ca',
    //   writeKey: '2624e79dceb52ecfed7b3b12f2c1c54ce0b75638ffa9780b84cee8e9e87680268e2c8dc7a5e2429dd88e4b3a15196e30ae54afdee7c8a41fce0bfa6ca8de889fccff77234bb84bc65b15d5906c1c2768013dd84bab5ca087fa10b1461ab15ebe'
    // });
    //
    // client.recordEvent('user_click', {
    //   uid: this.props.uid
    // });

    // quickEvent("user_click");

    this.props.history.push("/cashier");
  };

  sportsbook = () => {
    // getFirebase().analytics().logEvent("USE_SPORTSBOOK", {});
    this.props.history.push("/sports");
  };

  signin = () => {
    this.props.history.push("/signin");
  };

  renderBookName = () => {
    if (this.props.playerGroup?.groupName)
      return this.props.playerGroup?.groupName
    else
      return "SuperFunBet"
    // if (this.props.history.location.pathname === "/admin")
    //   return this.props.managerGroup ? String(this.props.managerGroup?.groupName) : "Your Book Here";
    // else return this.props.playerGroup?.groupName ? this.props.playerGroup?.groupName : "Super Fun Bet";
  };

  _displayBalance = () => {
    console.log("header oldBalance:", this.state.oldBalance);
    console.log("header newBalance:", this.state.newBalance);
    console.log("header show CountUp:", this.state.newBalance !== false && this.state.oldBalance !== false);
    console.log("header duration:", this.state.oldBalance && this.state.oldBalance !== this.state.newBalance ? 1 : 0);
    if (this.state.newBalance !== false && this.state.oldBalance !== false) {
      return (
        <CountUp
          duration={
            (this.state.oldBalance || this.state.oldBalance === 0) && this.state.oldBalance !== this.state.newBalance
              ? 1
              : 0
          }
          start={this.state.oldBalance}
          end={this.state.newBalance}
          decimals={2}
          delay={
            (this.state.oldBalance || this.state.oldBalance === 0) && this.state.oldBalance !== this.state.newBalance
              ? 1.5
              : 0
          }
          onEnd={() => this.setState({ oldBalance: this.state.newBalance })}
        />
      );
    } else {
      if (this.props.headerUserBalance) {
        return this.props.headerUserBalance?.toFixed(2);
      } else {
        return "0.00";
      }
    }
  };

  render() {
    const { classes } = this.props;

    console.log("header this.props: ", this.props);
    console.log("header this.state: ", this.state);

    return (
      <div className={classes.root}>
        {/*<CssBaseline/>*/}
        <AppBar
          position="relative"
          color={"tertiary"}
          elevation={0}
          sx={{ borderBottom: 2 }}
          className={clsx(classes.appBar, {
            [classes.appBarShift]: this.state.drawerOpen,
          })}
        >
          <Toolbar>
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" padding={0}>
              {/*Book Name on the left*/}
              {/*<Grid item xs={8} sm={4}>*/}
              {/*  <Grid container direction="row" justifyContent="left" alignItems={"center"} spacing={1} padding={0}>*/}
              {/*    <Grid item>*/}
              {/*      <HomeIcon />*/}
              {/*    </Grid>*/}
              {/*    <Grid item>*/}
              {/*      <Typography sx={{ textTransform: "uppercase" }} onClick={() => this.props.history.push("/sports")}>*/}
              {/*        {this.renderBookName()}*/}
              {/*      </Typography>*/}
              {/*    </Grid>*/}
              {/*  </Grid>*/}
              {/*</Grid>*/}

              <Grid item xs={8} sm={4}>
                <Grid container direction="row" justifyContent="flex-start" alignItems="center">
                  <Grid item>
                    {!this.props.showBackButton && (
                      <Button
                        ref={(ref) => (this.anchorRef = ref)}
                        id="composition-button"
                        style={{ borderColor: alpha("#bf0070", 0.0) }}
                        variant={"outlined"}
                        onClick={() => this.props.history.push("/sports")}
                        sx={{ paddingLeft: 0 }}
                      >
                        {<HomeIcon sx={{ marginRight: 1 }} />}
                        <Grid sx={{ display: { xs: " block", sm: "none" } }}>{this.renderBookName()}</Grid>
                      </Button>
                    )}
                    {this.props.showBackButton && (
                      <Button
                        ref={(ref) => (this.anchorRef = ref)}
                        id="composition-button"
                        style={{ borderColor: alpha("#bf0070", 0.0) }}
                        variant={"outlined"}
                        onClick={() => this.props.history.goBack()}
                        sx={{ paddingLeft: 0 }}
                      >
                        {this.props.location.pathname !== "/sports" && <ChevronLeft sx={{ marginRight: 1 }} />}
                        {this.renderBookName()}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>

              {/*Brand logo in middle*/}
              <Grid item xs={0} sm={4}>
                <Grid container direction="row" justifyContent="center" alignItems="center">
                  <Grid item>
                    <Button
                      className={classes.headerLogo}
                      sx={{ color: "black", display: { xs: "none", sm: "block" } }}
                      onClick={() => this.props.history.push("/sports")}
                      // style={{fontStyle: 'italic', color: "black", fontWeight: 700}}
                      // variant="h6"
                      // color="inherit" noWrap className={classes.title}
                    >
                      {this.renderBookName()}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              {/*Cashier on the right*/}
              <Grid item xs={4} sm={4}>
                <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                  <Grid item>
                    {this.props.auth.isLoaded && !this.props.auth.isEmpty && (
                      <>
                        <Button
                          ref={(ref) => (this.anchorRef = ref)}
                          id="composition-button"
                          style={{ borderColor: alpha("#bf0070", 0.0) }}
                          variant={"outlined"}
                          onClick={() => this.props.history.push("/profile")}
                          sx={{ paddingRight: 0 }}
                        >
                          {this.props.headerUserBalance?.toFixed(2)}
                          {/*{this._displayBalance()}*/}
                          {/*{" - "}*/}
                          {/*{this.props.headerUserBalance?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}*/}
                          <ManageAccountsIcon sx={{ marginLeft: 1 }} />
                        </Button>
                        {/*<Button*/}
                        {/*  ref={(ref) => this.anchorRef = ref}*/}
                        {/*  id="composition-button"*/}
                        {/*  style={{ borderColor: alpha("#bf0070", 0.00) }}*/}
                        {/*  variant={"outlined"}*/}
                        {/*  onClick={() => this.props.history.push("/bookie")}*/}
                        {/*  sx={{ paddingRight: 0 }}*/}
                        {/*>*/}
                        {/*  <AccountBalance style={{ marginLeft: 1 }} />*/}
                        {/*</Button>*/}
                      </>
                      // <CashierButton
                      //   dispatch={this.props.dispatch}
                      //   auth={this.props.auth}
                      //   history={this.props.history}
                      //   balance={this.props.headerUserBalance?.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                      //   profile={this.props.profile}
                      // />
                    )}
                    {this.props.auth.isLoaded && this.props.auth.isEmpty && (
                      <Button color="inherit" aria-label="open drawer" edge="end" onClick={this.signup}>
                        LOGIN/SIGNUP
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

Header.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  firebase: PropTypes.object,
  history: PropTypes.object,
  location: PropTypes.object,
  uid: PropTypes.string,
  profile: PropTypes.object,
  pastBets: PropTypes.object,
  pendingBets: PropTypes.object,
  headerUserBalance: PropTypes.number,
  playerGroup: PropTypes.object,
  // managerGroup: PropTypes.object,
  email: PropTypes.string,
  auth: PropTypes.object.isRequired,
  showBackButton: PropTypes.bool,
};

const styles = (theme) => ({
  root: {
    display: "flex",
  },
  headerLogo: {
    fontFamily: "NeuveticaHeavyItalic",
    // fontStyle: 'italic',
    // color: "black",
    // fontWeight: 700,
    fontSize: 25,
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  drawerDirection: {
    direction: theme.direction,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  title: {
    flexGrow: 1,
  },
  drawerHeader: {
    display: "flex",
    alignItems: "center",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: "flex-start",
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginRight: -drawerWidth,
  },
  contentShift: {
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  hide: {
    display: "none",
  },
  dividerInset: {
    margin: `5px 0 0 ${theme.spacing(9)}`,
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  },
});

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    email: state.firebase.auth.email,
    auth: state.firebase.auth,
    profile: state.firebase.profile,
    headerUserBalance: state.firestore.data.headerUserBalance?.balance,
    // managerGroup: state.firebase.profile.managerGid ? state.firestore.data.header_managerGroup : null,
    playerGroup: state.firebase.profile.gid ? state.firestore.data.header_playerGroup : null,
  };
};

//export default withRouter(connect(mapStateToProps)((withyStyles(styles)(Header))));

export default compose(
  withRouter,
  connect(mapStateToProps),
  firestoreConnect((props) => {
    console.log("firestore connect props: ", props);
    return [
      {
        collection: `groups/${props.profile.gid}/balances`,
        doc: `${props.uid}`,
        storeAs: "headerUserBalance",
      },
      // {
      //   collection: "groups",
      //   doc: props.profile.managerGid,
      //   storeAs: "header_managerGroup",
      // },
      {
        collection: "groups",
        doc: props.profile.gid,
        storeAs: "header_playerGroup",
      },
    ];
  })
)(withStyles(styles)(Header));
