import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import TextField from "@mui/material/TextField";
import { connect } from "react-redux";
import { joinGroup } from "../../../../reducers/register-reducer";

class JoinPrivateBook extends Component {
  constructor(props) {
    super(props);
    this.state = {
      amount: 0,
    };
  }

  _joinGroup = () => {
    const gid = this.getGidFromName(this.state.bookName);
    // console.log("state: ",this.state)
    // console.log("gid: ",gid)
    if (gid) {
      this.props.dispatch(joinGroup({ uid: this.props.uid, gid: gid }));
      this.props.closeCallback();
    } else {
      alert("This is not a valid group");
    }
  };

  getGidFromName(name) {
    let gid;
    Object.entries(this.props.allGroups).forEach((obj) => {
      if (obj[1].groupName === name) {
        gid = obj[0];
      }
    });
    return gid;
  }

  render() {
    return (
      <Dialog open={this.props.open}>
        <DialogTitle>Join a New Sportsbook</DialogTitle>
        <DialogContent sx={{ margin: 2, padding: 0 }}>
          <TextField
            onChange={(e) => this.setState({ bookName: e.target.value })}
            id="standard-number"
            label="Book Name"
            type="text"
            helperText={"book names are case sensitive"}
            value={this.state.bookName}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{ margin: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={this.props.closeCallback}>
            Cancel
          </Button>
          <Button variant="contained" onClick={this._joinGroup}>
            Join
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

JoinPrivateBook.propTypes = {
  open: PropTypes.bool,
  closeCallback: PropTypes.func,
  uid: PropTypes.string.isRequired,
  gid: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  allGroups: PropTypes.object.isRequired,
  groupDisplayName: PropTypes.string.isRequired,
  dispatch: PropTypes.func,
};

export default connect()(JoinPrivateBook);
