import React, { Component } from "react";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import { withRouter } from "react-router-dom";
import clsx from "clsx";
import { Badge, Grid } from "@mui/material";
import AnimateOnChange from "react-animate-on-change/index";
import { changeJuice } from "../../utilities";
import { clickGame, setBetslipOpen } from "../../reducers/sportsbook-reducer";

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(1),
    margin: 2,
    fontSize: ".85rem",
    textAlign: "center",
    cursor: "pointer",
    textOverflow: "ellipsis",
    // overflow: "hidden",
    whiteSpace: "nowrap",
    height: "100%",
  },
  one: {
    background: theme.palette.primary.mainLight,
    ...theme.palette.custom.betItemText,
  },
  two: {
    background: theme.palette.primary.main,
    ...theme.palette.custom.betItemText,
  },
  draw: {
    background: theme.palette.primary.mainDraw,
    ...theme.palette.custom.betItemText,
  },
  liveone: {
    background: theme.palette.primary.liveLight,
    ...theme.palette.custom.betItemText,
  },
  livetwo: {
    background: theme.palette.primary.live,
    ...theme.palette.custom.betItemText,
  },
});

class BetItem2 extends Component {
  constructor(props) {
    super(props);
    this.state = { animate: false, oldNumber: props.number };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (!this.props.pageVisible) {
      return;
    }
    if (this.props.bucket !== this.props.activeSport) {
      return;
    }
    if (prevProps.betPeriod !== this.props.betPeriod) {
      return;
    }
    if (prevProps.number < this.props.number) {
      this.setState({ animate: "green", oldNumber: prevProps.number });
      return;
    }
    if (prevProps.number > this.props.number) {
      this.setState({ animate: "red", oldNumber: prevProps.number });
      return;
    }
    if (prevProps.price < this.props.price) {
      this.setState({ animate: "green", oldNumber: prevProps.number });
      return;
    }
    if (prevProps.price > this.props.price) {
      this.setState({ animate: "red", oldNumber: prevProps.number });
      return;
    }

    // if(prevProps.price !== this.props.price || prevProps.number !== this.props.number){
    if (prevProps.number !== this.props.number) {
      this.setState({ animate: "red", oldNumber: prevProps.number });
      // setTimeout(() => this.setState({ animate: false }), 1000);
    }
  }

  _clickBet = () => {
    console.log("click bet this.props: ", this.props);
    let oddsjamData = null;
    if (this.props.betType === "props" && (this.props.bucket === "NFL-OJ" || this.props.bucket === "MLB-OJ")) {
      oddsjamData = {
        bet_name: this.props.betName,
        market_name: this.props.market,
        betslipDisplay: this.props.label,
        price: this.props.price,
      };
      console.log("oj data: ", oddsjamData);
    }
    if (this._checkBadge() === false) {
      this.props.dispatch(setBetslipOpen(true));
    } else {
      this.props.dispatch(
        clickGame({
          betOrigin: "standard",
          is_half: this.props.isHalf || false,
          bucket: this.props.bucket,
          collection: this.props.collection,
          doc_id: this.props.docId,
          bet_type: this.props.betType,
          bet_side: this.props.betSide,
          gid: this.props.profile.gid,
          uid: this.props.uid,
          bet_period: this.props.betPeriod,
          lineSource: this.props.lineSource,
          oddsjamData: oddsjamData,
        })
      );
    }
  };

  _checkBadge = () => {
    // console.log("pending bet: ",this.props.pendingBets)
    if (this.props.pendingBets) {
      return !Object.values(this.props.pendingBets)
        .map(
          (pendingBet) =>
            (pendingBet &&
              pendingBet.bet_type !== "props" &&
              pendingBet.doc_id === this.props.docId &&
              pendingBet.bet_side === this.props.betSide &&
              pendingBet.bet_type === this.props.betType &&
              pendingBet.bet_period === this.props.betPeriod) ||
            (pendingBet &&
              pendingBet.bet_type === "props" &&
              pendingBet.oddsjamData.betslipDisplay === this.props.label)
          // && (pendingBet.bet_type !== "props" || pendingBet.oddsjamData?.bet_name === this.props.oddsjamData?.bet_name)
        )
        .reduce((a, b) => a || b, false);
    }
    return true;
  };

  render() {
    // console.log("betitem2 ",this.props)
    // console.log("window: ",window.innerWidth)
    const { classes } = this.props;
    const newPrice = changeJuice(this.props.price, this.props.isLive ? 6.75 : this.props.juice);
    return (
      <Paper
        elevation={0}
        className={clsx(classes.paper, classes[this.props.isLive ? "live" + this.props.rowColor : this.props.rowColor])}
        onClick={this._clickBet}
      >
        <Badge badgeContent="✔" color="secondary" invisible={this._checkBadge()} sx={{ height: "100%", width: "100%" }}>
          <Grid item container direction="column" justifyContent="center" alignItems="stretch">
            <Grid container direction="row" justifyContent="center" alignItems="center" height="100%">
              {/*<Grid*/}
              {/*  container*/}
              {/*  item*/}
              {/*  direction="column"*/}
              {/*  justifyContent="center"*/}
              {/*  alignItems="center"*/}
              {/*  height="100%"*/}
              {/*  xs={1}*/}
              {/*>*/}
              {/*</Grid>*/}
              <Grid item height="100%" xs={12}>
                <AnimateOnChange
                  baseClassName="betItemChildren"
                  animationClassName={
                    this.state.animate === "green" ? "betItemChildrenAnimationGreen" : "betItemChildrenAnimationRed"
                  }
                  animate={this.state.animate}
                  sx={{ height: "100%" }}
                >
                  <Grid container direction="column" justifyContent="center" alignItems="center" height="100%">
                    <Grid item sx={{ color: this.props.price ? null : "#666666" }}>
                      {window.innerWidth < 450 && this.props.betPeriod === "1h" && this.props.label.length > 18
                        ? this.props.label.substring(0, 15).concat("...")
                        : this.props.label}
                      {this.props.betPeriod === "1h" && this.props.betType === "ml" ? " (1H)" : ""}
                    </Grid>
                    <Grid item sx={{ color: this.props.price ? null : "#666666" }}>
                      {newPrice > 0 && "+"}
                      {newPrice}
                      {/*<CountUp end={newPrice} preserveValue={true} duration={this.state.animate ? 1 : 0}/>*/}
                    </Grid>
                  </Grid>
                </AnimateOnChange>
              </Grid>
              {/*<Grid container item direction="column" justifyContent="center" alignItems="center" height="100%" xs={1}>*/}
              {/*  {this.props.score}*/}
              {/*</Grid>*/}
            </Grid>
          </Grid>
        </Badge>
      </Paper>
    );
  }
}

BetItem2.defaultProps = {
  offOverride: "Off",
  isLive: false,
};

BetItem2.propTypes = {
  rowColor: PropTypes.oneOf(["one", "two"]),
  juice: PropTypes.number,
  coinflipJuice: PropTypes.number,
  game: PropTypes.object,
  oddsjamData: PropTypes.object,
  classes: PropTypes.object,
  dispatch: PropTypes.func,
  history: PropTypes.object,
  offOverride: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  price: PropTypes.number,
  number: PropTypes.number,
  isLive: PropTypes.bool,
  betPeriod: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  bucket: PropTypes.string.isRequired,
  collection: PropTypes.string.isRequired,
  docId: PropTypes.string.isRequired,
  betType: PropTypes.oneOf(["ml", "spread", "ou"]),
  betSide: PropTypes.oneOf(["home", "away", "over", "under"]),
  profile: PropTypes.object,
  uid: PropTypes.string,
  pendingBets: PropTypes.array,
  pageVisible: PropTypes.bool,
  activeSport: PropTypes.string,
  market: PropTypes.string,
  betName: PropTypes.string,
  lineSource: PropTypes.string.isRequired,
  isHalf: PropTypes.bool.isRequired,
  score: PropTypes.string,
};

const mapStateToProps = (state) => {
  return {
    profile: state.firebase.profile,
    uid: state.firebase.auth.uid,
    pendingBets: state.firestore.data.pendingBets2,
    pageVisible: state.appState.pageVisible,
    activeSport: state.sportsbookState.activeSport,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(withRouter(BetItem2)));
