import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/functions";
import "firebase/compat/analytics";

const fbConfigDev = {
  apiKey: "AIzaSyBDhrgQl2il9eG-eZsImgS_dH-VoHxVSDM",
  authDomain: "sft-dev-a1e2f.firebaseapp.com",
  projectId: "sft-dev-a1e2f",
  storageBucket: "sft-dev-a1e2f.appspot.com",
  messagingSenderId: "966703840966",
  appId: "1:966703840966:web:3dd1f9002be0f3b80c61c7",
  measurementId: "G-T1LZTZ6SNF",
};

firebase.initializeApp(fbConfigDev);

const db = firebase.firestore();
const auth = firebase.auth;
const functions = firebase.functions;

// eslint-disable-next-line no-restricted-globals
// eslint-disable-no-constant-condition
// eslint-disable-next-line no-restricted-globals
if (location.hostname === "localhost_off") {
  db.useEmulator("localhost", 8080);
  auth().useEmulator("http://localhost:9099/", { disableWarnings: true });
  functions().useEmulator("localhost", 5001);
}

export default firebase;
export { db, auth, functions };
