import React from "react";
import PropTypes from "prop-types";
import ListItem from "@mui/material/ListItem";
import { ListItemText } from "@mui/material";
import Grid from "@mui/material/Grid";
import { EntryPointsBar } from "./EntryPointsBar";
import { useHistory } from "react-router-dom";
import Typography from "@mui/material/Typography";
import EntryPick from "./EntryPick";

export function EntryListItem(props) {
  const history = useHistory();

  const checkForNavigate = () => {
    if (props.navigate_on_click) {
      history.push("/pickem/tournament/" + props.entry.tournamentID);
    }
  };

  return (
    <ListItem key={props.entry.id} alignItems="flex-start" divider onClick={checkForNavigate}>
      {/*<ListItemIcon sx={{ color: "#bf0070", display: { xs: "none", sm: "block" } }}>*/}
      {/*  <ReceiptIcon />*/}
      {/*</ListItemIcon>*/}

      <ListItemText
        primary={
          <Grid container>
            <Grid item xs={12}>
              <Typography>{props.entry.tournamentName}</Typography>
            </Grid>
            <Grid item xs={12} sx={{ marginTop: 1, marginBottom: 1 }}>
              <EntryPointsBar
                entry={props.entry}
                hasLeaderData={props.hasLeaderData}
                leaderPendingPoints={props.leaderPendingPoints}
                leaderEarnedPoints={props.leaderEarnedPoints}
                leaderPossiblePoints={props.leaderPossiblePoints}
              />
            </Grid>
          </Grid>
        }
        secondary={props.entry.picks.map((i, j) => (
          <EntryPick key={j} pick={i} riskAmount={props.entry.riskAmount} />
        ))}
      />
    </ListItem>
  );
}

EntryListItem.defaultProps = {
  hasLeaderData: false,
  leaderPendingPoints: 0,
  leaderEarnedPoints: 0,
  leaderPossiblePoints: 0,
  navigate_on_click: false,
};

EntryListItem.propTypes = {
  entry: PropTypes.object.isRequired,
  hasLeaderData: PropTypes.bool,
  leaderPendingPoints: PropTypes.number,
  leaderEarnedPoints: PropTypes.number,
  leaderPossiblePoints: PropTypes.number,
  navigate_on_click: PropTypes.bool,
};
