import { applyMiddleware, createStore } from "redux";
import reducers from "./reducers";
import { logger } from "redux-logger";
import { createPromise } from "redux-promise-middleware";
import thunk from "redux-thunk";
import { getFirebase } from "react-redux-firebase";
import LogRocket from "logrocket";

LogRocket.init("ijurqj/sfb");

function configureStore() {
  return createStore(
    reducers,
    // composeWithDevTools(
    applyMiddleware(
      thunk.withExtraArgument(getFirebase),
      createPromise({
        promiseTypeSuffixes: ["LOADING", "SUCCESS", "ERROR"],
      }),
      logger,
      LogRocket.reduxMiddleware({
        actionSanitizer: function(action) {
          // if (process.env.NODE_ENV === "development" || !action.type.includes("ERROR")) {
          //   return null;
          // }
          if (process.env.NODE_ENV === "development") {
            return null;
          }
          return action;
        },
      }),
    ),
    // )
  );
}

export default configureStore();
