import React from "react";
import PropTypes from "prop-types";
import ListItem from "@mui/material/ListItem";
import { Box, ListItemText } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useHistory } from "react-router-dom";
import Typography from "@mui/material/Typography";
import EntryPick from "./EntryPick";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { LeaderboardEntryPointsBar } from "./LeaderboardEntryPointsBar";
import firebase from "../../../firebase";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

export function LeaderboardEntryListItem(props) {
  const history = useHistory();
  const [picksExpanded, setPicksExpanded] = React.useState(false);
  const auth = firebase.auth();

  const checkForNavigate = () => {
    if (props.navigate_on_click) {
      history.push("/pickem/tournament/" + props.entry.tournamentID);
    }
  };

  const getBorderColor = () => {
    if (auth?.currentUser?.uid === props.entry.user_id) return "#bf0070";
    else return "white";
  };

  return (
    <Box sx={{ padding: 1 }}>
      <ListItem
        sx={{ border: 1, borderColor: getBorderColor(), borderRadius: 2, marginBottom: 1 }}
        key={props.entry.id}
        alignItems="flex-start"
        divider
        onClick={checkForNavigate}
      >
        {/*<ListItemIcon sx={{ color: "#bf0070", display: { xs: "none", sm: "block" } }}>*/}
        {/*  <ReceiptIcon />*/}
        {/*</ListItemIcon>*/}

        <ListItemText
          primary={
            <div>
              <LeaderboardEntryPointsBar
                entry={props.entry}
                hasLeaderData={props.hasLeaderData}
                leaderPendingPoints={props.leaderPendingPoints}
                leaderEarnedPoints={props.leaderEarnedPoints}
                leaderPossiblePoints={props.leaderPossiblePoints}
              />
            </div>
          }
          secondary={
            <>
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ marginTop: 3, marginBottom: 3 }}
                onClick={() => setPicksExpanded(!picksExpanded)}
              >
                <Grid item xs={6} textAlign={"left"}>
                  <Typography>
                    {auth?.currentUser?.uid === props.entry.user_id && "Your Picks"}
                    {auth?.currentUser?.uid !== props.entry.user_id && "Picks"}
                  </Typography>
                </Grid>
                <Grid item xs={6} textAlign={"right"}>
                  {!picksExpanded && <ExpandMoreIcon />}
                  {picksExpanded && <ExpandLessIcon />}
                </Grid>
              </Grid>
              {picksExpanded && (
                <Grid container direction="row" rowSpacing={1} alignItems="stretch" justifyContent="space-between">
                  {props.entry.picks.map((i, j) => (
                    <EntryPick key={j} pick={i} riskAmount={props.entry.riskAmount} />
                  ))}
                </Grid>
              )}
              {/*<Typography variant="caption" color="text.note">*/}
              {/*  Entry ID: {props.entry.id}*/}
              {/*</Typography>*/}
            </>
          }
        />
      </ListItem>
    </Box>
  );
}

LeaderboardEntryListItem.defaultProps = {
  hasLeaderData: false,
  leaderPendingPoints: 0,
  leaderEarnedPoints: 0,
  leaderPossiblePoints: 0,
  navigate_on_click: false,
};

LeaderboardEntryListItem.propTypes = {
  entry: PropTypes.object.isRequired,
  hasLeaderData: PropTypes.bool,
  leaderPendingPoints: PropTypes.number,
  leaderEarnedPoints: PropTypes.number,
  leaderPossiblePoints: PropTypes.number,
  navigate_on_click: PropTypes.bool,
};
