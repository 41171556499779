import { createAction, handleActions } from "redux-actions";
import { getFirebase } from "react-redux-firebase";
import { createAsyncAction } from "redux-promise-middleware-actions";

export const joinGroup = createAsyncAction("JOIN_GROUP", (data) => {
  const joinGroup = getFirebase().functions().httpsCallable("user-joinGroup");
  console.log("joining group data: ",data)
  return joinGroup({ gid: data.gid });
});

export const setRegGoal = createAction("SET_REG_GOAL");

const initialState = {
  regGoal: "none",
};

export default handleActions(
  {
    JOIN_GROUP_LOADING(state) {
      // console.log("join group: ", payload);
      // getFirebase().analytics().logEvent("JOIN_GROUP");
      // const adjustBalance = getFirebase().functions().httpsCallable("user-adjustBalanceGetRidOfMeLater");
      // const db = getFirebase().firestore();
      // db.collection("users")
      //   .doc(payload.uid)
      //   .update({
      //     gid: payload.gid,
      //   })
      //   .then(() => {
      //     console.log("success, about to adjust balance");
      //     adjustBalance({ uid: payload.uid, gid: payload.gid, adjust: 0 });
      //   });
      return {
        ...state,
      };
    },
    JOIN_GROUP_SUCCESS(state, { payload }) {
      console.log("join group result: ", payload);
      // getFirebase().analytics().logEvent("JOIN_GROUP");
      // const adjustBalance = getFirebase().functions().httpsCallable("user-adjustBalanceGetRidOfMeLater");
      // const db = getFirebase().firestore();
      // db.collection("users")
      //   .doc(payload.uid)
      //   .update({
      //     gid: payload.gid,
      //   })
      //   .then(() => {
      //     console.log("success, about to adjust balance");
      //     adjustBalance({ uid: payload.uid, gid: payload.gid, adjust: 0 });
      //   });
      return {
        ...state,
      };
    },
    SET_REG_GOAL(state, { payload }) {
      console.log("set reg goal: ", payload);
      let regGoal = payload.regGoal;
      state.regGoal = payload.regGoal;
      return {
        regGoal,
        ...state,
      };
    },
  },
  initialState
);
