import React, { Component } from "react";
import PropTypes from "prop-types";
import { CircularProgress, Grid } from "@mui/material";

import withStyles from "@mui/styles/withStyles";

class Loading extends Component {
  render() {
    console.log("LOADING SCREEN")
    return (
      <Grid container direction="column" alignItems="center" justifyContent="center" style={{ minHeight: "100vh" }}>
        <Grid item xs="auto">
          <CircularProgress />
        </Grid>
        <Grid item xs="auto">
          {this.props.loadingMessage}
        </Grid>
      </Grid>
    );
  }
}

Loading.propTypes = {
  loadingMessage: PropTypes.string.isRequired,
  classes: PropTypes.object.isRequired,
};

const styles = () => ({
  fullContainer: {
    width: "100%",
    height: "100%",
  },
});

export default withStyles(styles)(Loading);
