import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { alpha, Box, Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import Logo from "../../../images/logo.png";

import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Redirect, withRouter } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";

function Texfield() {
  return null;
}

class LPHeader extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      friendDialogOpen: false,
    };
  }

  logIn = () => {
    console.log("log in!");
    this.props.history.push("/login");
  };

  renderHeader = () => {
    const { classes } = this.props;
    return (
      <AppBar position="relative" color={"tertiary"} elevation={0} sx={{ borderBottom: 0 }}>
        <Toolbar>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            {/* formatting for SUPERFUNBET when the screen is xs, disappears at sm and up*/}
            <Grid
              item
              xs={6}
              sm={2}
              sx={{
                backgroundImage: `url(${Logo})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                paddingTop: 1,
                paddingBottom: 1,
              }}
            >
              <Typography variant="h6">&nbsp;</Typography>
            </Grid>

            {/* this allows us to center the SUPERFUNBET by creating a grid to balance the login btn*/}
            {/*<Grid item sm={2} sx={{ display: { xs: "none", sm: "block" } }} />*/}

            <Grid item sm={6} sx={{ display: { xs: "none", lg: "block" } }}>
              <Grid container direction="row" alignItems="center" justifyContent="center" padding={0}>
                <Grid item>
                  <Typography className={classes.headerLogo}>SUPERFUNBET</Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6} sm={2}>
              <Grid container direction="row" alignItems="center" justifyContent="right" padding={0}>
                <Grid item>
                  <Button
                    onClick={this.logIn}
                    sx={{
                      padding: 0,
                      margin: 0,
                      border: 1,
                      color: "#bf0070",
                    }}
                  >
                    LOG IN
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  };

  render() {
    console.log("LPHeader this.props: ", this.props);
    return this.renderHeader()
  }
}

LPHeader.propTypes = {
  classes: PropTypes.object.isRequired,
  firstVisitURL: PropTypes.string,
  anonAccountState: PropTypes.string,
  history: PropTypes.array,
  uid: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  dialogTitle: {
    color: theme.palette.primary.dark,
    paddingBottom: 0,
  },
  headerLogo: {
    fontFamily: "NeuveticaHeavyItalic",
    // fontStyle: 'italic',
    // color: "black",
    fontWeight: 700,
    fontSize: "2rem",
  },
  footerLogo: {
    fontFamily: "NeuveticaHeavyItalic",
    // fontStyle: 'italic',
    // color: "black",
    fontWeight: 700,
    fontSize: "1rem",
  },
  dialogSubtitle: {
    paddingTop: 10,
    color: theme.palette.primary.light,
  },
  paper: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  values: {
    borderColor: theme.palette.primary.light,
    color: theme.palette.primary.dark,
    borderWidth: 2,
    marginBottom: 15,
    alignItems: "left",
    padding: 0,
  },
});

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    firstVisitURL: state.newUserState.initialPage,
    anonAccountState: state.newUserState.anonAccountState,
  };
};

export default withRouter(connect(mapStateToProps)(withStyles(styles)(LPHeader)));
