import React, { useEffect } from "react";
import PickemAppBar from "../PickemAppBar";
import firebase from "../../../firebase";
import { EntryListItem } from "./EntryListItem";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import Grid from "@mui/material/Grid";

export function EntriesHome() {
  const auth = firebase.auth();
  const db = firebase.firestore();
  const [entries, setEntries] = React.useState([]);

  const fetchEntries = async () => {
    if (!auth?.currentUser?.uid) return;
    await db
      .collection("pickem")
      .doc("tournies")
      .collection("entries")
      .where("user_id", "==", firebase.auth().currentUser.uid)
      .onSnapshot((querySnapshot) => {
        let entries = [];
        querySnapshot.forEach((doc) => {
          const entry = doc.data();
          const originalPoints = entry.picks.map((pick) => parseFloat(pick.points)).reduce((a, b) => a + b, 0.0);
          const pointsPending = entry.picks
            .filter((pick) => pick.result === "pending")
            .map((pick) => parseFloat(pick.points))
            .reduce((a, b) => a + b, 0.0);
          const pointsEarned = entry.picks
            .filter((pick) => pick.result === "win" || pick.result === "push")
            .map((pick) => (pick.result === "win" ? parseFloat(pick.points) : entry.riskAmount))
            .reduce((a, b) => a + b, 0.0);
          entries.push({
            ...entry,
            id: doc.id,
            originalPoints,
            pointsPending,
            pointsEarned,
            pointsPossible: pointsPending + pointsEarned,
          });
        });
        setEntries(entries);
      });
  };

  useEffect(() => {
    fetchEntries();
  }, [auth?.currentUser?.uid]);

  return (
    <div>
      <PickemAppBar title="Pickem" isHome={false} />
      <Box>
        <Grid xs={12} container direction="row" justifyContent="center" alignItems="center">
          <Typography
            sx={{
              textAlign: "center",
              padding: 1,
              borderBottom: 1,
              minWidth: "250px",
              borderColor: "#bf0070",
              color: "#bf0070",
            }}
          >
            My Entries
          </Typography>
        </Grid>
        <List sx={{ width: "100%" }}>
          {entries.map((entry) => {
            return <EntryListItem entry={entry} key={entry.id} navigate_on_click={true} />;
          })}
        </List>
        {/*<Grid xs={12} sx={{ marginTop: 3, marginBottom: 3}}  container direction="row" justifyContent="center" alignItems="center">*/}
        {/*    <Typography*/}
        {/*        sx={{ textAlign: "center", padding: 1, borderBottom: 1, minWidth: "250px", borderColor: "#bf0070", color: "#bf0070" }}>*/}
        {/*        Opposing Entries*/}
        {/*    </Typography>*/}
        {/*</Grid>*/}
        {/*<List sx={{ width: "100%" }}>*/}
        {/*    {allEntries*/}
        {/*        .filter((entry) => entry.user_id !== firebase.auth().currentUser.uid)*/}
        {/*        .map((entry) => {*/}
        {/*        return <EntryListItem entry={entry} key={entry.id} />;*/}
        {/*    })}*/}
        {/*</List>*/}
      </Box>
    </div>
  );
}
