import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import { firestoreConnect } from "react-redux-firebase";
import GenericBackHeader from "../../../components/GenericBackHeader";
import ListItem from "@mui/material/ListItem";
import { fulfillTransaction } from "../../../reducers/cashier-reducer";
import Button from "@mui/material/Button";
import { getTimestamp } from "../../../reducers/sportsbook-reducer";
import BottomNav from "../../../components/BottomNav";
import ReferralLink from "../../../components/ReferralLink";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import { Container, IconButton, ListItemSecondaryAction, ListItemText, ListSubheader } from "@mui/material";
import { CheckCircle, ThumbDownOffAlt } from "@mui/icons-material";
import collect from "collect.js";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

class BookieTransactions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialogOpen: false,
    };
  }

  renderPendingTransactions() {
    let t = this.props.pendingTrans;
    if (t.length === 0) {
      return (
        <ListItem>
          <Box textAlign="center" width="100%">
            No Pending Transactions
          </Box>
        </ListItem>
      );
    }
    return t.map((v) => {
      return (
        <ListItem key={v} divider>
          <ListItemText secondary={`${v.amount} credit ${v.type}`} primary={`${v.email}`} />
          <ListItemSecondaryAction>
            {/*<FlakyIcon color={"secondary"} />*/}
            <IconButton
              color="secondary"
              sx={{ paddingLeft: 2 }}
              onClick={() => {
                this.setState({ dialogOpen: true, unsettledTrans: v, action: "decline" });
              }}
            >
              <ThumbDownOffAlt />
            </IconButton>
            <IconButton
              color="secondary"
              onClick={() => {
                this.setState({ dialogOpen: true, unsettledTrans: v, action: "approve" });
              }}
            >
              <CheckCircle />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      );
    });
  }

  formatTimestamp(trans) {
    // on feb 28 we moved to calling all timestamps just "timestamp"
    // this handles old ways of formatting the data
    let TS;
    if (trans.awardedTimestamp) {
      TS = trans.awardedTimestamp;
    } else if (trans.pendingTimestamp) {
      TS = trans.pendingTimestamp;
    } else if (trans.timestamp) TS = trans.timestamp;
    return getTimestamp(TS);
  }

  renderOldTransactions() {
    let t = this.props.oldTrans;

    if (t.length === 0) {
      return (
        <ListItem>
          <Box textAlign="center" width="100%">
            No Transaction History
          </Box>
        </ListItem>
      );
    }
    return t
      .sort((x, y) => x.timestamp < y.timestamp ? 1 : -1)
      .map((v) => {
        console.log("v : ", v);
        return (
          <ListItem key={v} divider>
            <List>
              <ListItemText primary={v.email} />
              <ListItemText secondary={`${v.amount} credit ${v.type} ${v.state}`} />
              <ListItemText secondary={`${this.formatTimestamp(v)}`} />
            </List>
          </ListItem>
        );
      });
  }

  reset = () => {
    this.setState({ dialogOpen: false });
  };

  DIALOG = () => {
    let d = this.state.dialogOpen;

    if (this.state.unsettledTrans) {
      let UT = this.state.unsettledTrans;
      return (
        <div>
          <Dialog open={d}>
            <DialogTitle>Transaction Request</DialogTitle>
            <DialogContent>
              <List>
                <ListItemText primary={`${UT.email}`} />
                <ListItemText primary={`Type: ${UT.type}`} />
                <ListItemText primary={`Amount: ${UT.amount}`} />
              </List>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={this.reset}>
                Cancel
              </Button>
              {this.state.action === "decline" && (
                <Button
                  variant="contained"
                  color="error"
                  onClick={() => {
                    this.props.dispatch(
                      fulfillTransaction({
                        state: "denied",
                        uid: UT.uid,
                        trans_id: UT.trans_id,
                        amount: UT.amount,
                        type: UT.type,
                        gid: UT.gid,
                      })
                    );
                    this.reset();
                  }}
                >
                  Deny
                </Button>
              )}
              {this.state.action === "approve" && (
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => {
                    this.props.dispatch(
                      fulfillTransaction({
                        state: "approved",
                        uid: UT.uid,
                        trans_id: UT.trans_id,
                        amount: UT.amount,
                        type: UT.type,
                        gid: UT.gid,
                      })
                    );
                    this.reset();
                  }}
                >
                  Approve
                </Button>
              )}
            </DialogActions>
          </Dialog>
        </div>
      );
    }
  };

  render() {
    console.log("BookieTransactions this.props: ", this.props);
    console.log("BookieTransactions this.state: ", this.state);

    return (
      <>
        <Box>
          <GenericBackHeader title="Transactions" showBackButton />

          {this.DIALOG()}

          <List>
            <ListSubheader>Pending Transactions</ListSubheader>
            {this.props.pendingTrans && this.renderPendingTransactions()}
            <ListSubheader>Completed Transactions</ListSubheader>
            {this.props.oldTrans && this.renderOldTransactions()}

            {!this.props.oldTrans?.length && !this.props.pendingTrans?.length && (
              <Container>
                <div style={{ paddingTop: 5 }}>no transactions</div>
                <ReferralLink />
              </Container>
            )}
          </List>
        </Box>
        <BottomNav isBookieMode activeTab={3} managerGid={this.props.profile.managerGid} />
      </>
    );
  }
}

BookieTransactions.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  pendingTrans: PropTypes.object,
  oldTrans: PropTypes.object,
  profile: PropTypes.object,
  history: PropTypes.array,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  pastBetsList: {
    width: "100%",
  },
  table: {
    minWidth: 650,
  },
});

const mapStateToProps = (state) => {
  const getPending = () => {
    let t = state.firestore.data?.trans_allTrans;
    let T = collect(t)
      .filter((i) => i.state === "pending")
      .toArray();
    return T;
  };
  const getOld = () => {
    let t = state.firestore.data?.trans_allTrans;
    let T = collect(t)
      .filter((i) => i.state !== "pending")
      .toArray();
    return T;
  };

  return {
    uid: state.firebase.auth.uid,
    email: state.firebase.auth.email,
    profile: state.firebase.profile,
    oldTrans: getOld(),
    pendingTrans: getPending(),
  };
};

export default compose(
  connect(mapStateToProps),
  withRouter,
  firestoreConnect((props) => {
    return [
      {
        collection: `groups/${props.profile.managerGid}/transactions`,
        storeAs: "trans_allTrans",
      },
    ];
  })
)(withStyles(styles)(BookieTransactions));
//
// renderUserPnL(user){
//   console.log("rendering pnl for user: ",user)
//   let b = this.props.bets
//   let wagerCount = 0; let winAmt = 0; let lossAmt = 0;
//   Object.keys(b).forEach((i) => {
//     if (b[i].email === user && b[i].result !== "pending"){
//       wagerCount ++;
//       if (b[i].result === "win") winAmt += b[i].win;
//       if (b[i].result === "loss") lossAmt += b[i].risk;
//     }
//   })
//   return <>Net Win/Loss: {(winAmt - lossAmt).toFixed(2)}.  <br/> Total wagers: {wagerCount}.  Total win: {winAmt.toFixed(2)}.  Total loss: {lossAmt.toFixed(2)}.</>
// }
//
// renderUserPendingBetSummary(user){
//   let b = this.props.bets
//   let pendingWagerCount = 0; let pendingWagerRisk = 0; let pendingWagerWin = 0;
//   Object.keys(b).forEach((i) => {
//     if (b[i].email === user && b[i].result === "pending"){
//       pendingWagerCount ++;
//       pendingWagerRisk += b[i].risk;
//       pendingWagerWin += b[i].win
//     }
//   })
//   return <>{pendingWagerCount} pending wagers risking {pendingWagerRisk.toFixed(2)} to win {pendingWagerWin.toFixed(2)}</>
// }
//
// renderUserPendingTransSummary(user){
//   let t = this.props.trans
//   let pendingDepositCount = 0; let pendingWithdrawalCount = 0;
//   let totalWithdrawals = 0; let totalDeposits = 0;
//   Object.keys(t).forEach((i) => {
//     if (t[i].email === user && t[i].type === "deposit" && t[i].state === "pending") pendingDepositCount ++;
//     if (t[i].email === user && t[i].type === "withdrawal" && t[i].state === "pending") pendingWithdrawalCount ++;
//     if (t[i].email === user && t[i].type === "deposit" && t[i].state === "approved") totalDeposits += t[i].amount;
//     if (t[i].email === user && t[i].type === "withdrawal" && t[i].state === "approved") totalWithdrawals += t[i].amount;
//   })
//   return <>
//     Total Deposits: {totalDeposits}.  Total Withdrawals: {totalWithdrawals} <br/>
//     {pendingDepositCount} pending deposits and {pendingWithdrawalCount} pending withdrawals</>
// }
