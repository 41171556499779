import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import withStyles from "@mui/styles/withStyles";
import GenericBackHeader from "../../../components/GenericBackHeader";
import { BottomNav } from "../../../components";
import Box from "@mui/material/Box";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import BetList2 from "../../../components/BetDisplay/BetList2";

class Leaderboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      changeUsernameDialogOpen: false,
    };
  }

  calcScore(win, loss) {
    if (win > 0) {
      return (win / loss).toFixed(0) * 100;
    } else {
      return 0;
    }
  }

  renderScores() {
    console.log(this.allBets)
    const classes = this.props;
    let B = this.props.balances;
    return (
      Object.keys(B)
        .filter((i) => B[i])
        .filter((i) => B[i].email)
        .filter((i) => B[i].totalBets || B[i].balance || B[i].pendingBetCount)
        // .sort((x,y) => this.calcScore(B[x].totalWin,B[x].totalLoss) < this.calcScore(B[y].totalWin,B[y].totalLoss) ? 1 : -1 )
        .sort((x, y) =>
          B[x].balance + (B[x].totalPendingRisk || 0) === B[y].balance + (B[y].totalPendingRisk || 0)
            ? (B[x].totalPendingWin || 0) < (B[y].totalPendingWin || 0)
              ? 1
              : -1
            : B[x].balance + (B[x].totalPendingRisk || 0) < B[y].balance + (B[y].totalPendingRisk || 0)
            ? 1
            : -1
        )
        .map((v) => {
          return (
            <TableRow key={"bet"} className={B[v].email === this.props.email ? this.props.classes.usersRow : ""}>
              <TableCell align={"left"} component="th" scope="row" sx={{ fontSize: { xs: "10px", sm: "12px" } }}>
                {B[v].email.split("@")[0]}
                <br />
                <small>{B[v].pendingBetCount || 0} pending, {B[v].totalBets || 0} past </small>
              </TableCell>
              <TableCell align="right" component="th" scope="row" sx={{ fontSize: { xs: "10px", sm: "12px" } }}>
                {(B[v].totalPendingRisk || 0).toFixed(0)}
              </TableCell>
              <TableCell align="right" component="th" scope="row" sx={{ fontSize: { xs: "10px", sm: "12px" } }}>
                {(B[v].totalPendingWin || 0).toFixed(0)}
              </TableCell>
              {/*<TableCell align="right" component="th" scope="row" sx={{ fontSize: { xs: "10px", sm: "12px" } }}>*/}
              {/*  {B[v].balance.toFixed(0)}*/}
              {/*</TableCell>*/}
              <TableCell align="right" component="th" scope="row" sx={{ fontSize: { xs: "10px", sm: "12px" } }}>
                {(B[v].balance + (B[v].totalPendingRisk || 0)).toFixed(0)}
              </TableCell>
            </TableRow>
          );
        })
    );
  }

  renderTable() {
    return (
      <TableContainer key={"bv"}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align="left" sx={{ fontSize: { xs: "10px", sm: "12px" }, fontWeight: "bold" }}>
                Bettor
              </TableCell>
              <TableCell align="right" sx={{ fontSize: { xs: "10px", sm: "12px" }, fontWeight: "bold" }}>
                Pending Risk
              </TableCell>
              <TableCell align="right" sx={{ fontSize: { xs: "10px", sm: "12px" }, fontWeight: "bold" }}>
                Pending Win
              </TableCell>
              {/*<TableCell align="right" sx={{ fontSize: { xs: "10px", sm: "12px" }, fontWeight: "bold" }}>*/}
              {/*  <b>Balance</b>*/}
              {/*</TableCell>*/}
              <TableCell align="right" sx={{ fontSize: { xs: "10px", sm: "12px" }, fontWeight: "bold" }}>
                Balance + Pending
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{this.renderScores()}</TableBody>
        </Table>
      </TableContainer>
    );
  }

  _closeChangeName = () => {
    this.setState({ changeUsernameDialogOpen: false });
  };

  render() {
    console.log("Social > leaderboard > this.props: ", this.props);
    return (
      <>
        <Box>
          <GenericBackHeader title="Leaderboard" showBackButton />
          {this.props.balances && this.renderTable()}

          <Box sx={{ paddingTop: 5}}>
            {this.props.allBets && (
                <>
                  <BetList2 fromAdmin={true} bets={this.props.allBets}/>
                </>
            )}
          </Box>


          {/*<ChangeUsername*/}
          {/*  open={this.state.changeUsernameDialogOpen}*/}
          {/*  closeCallback={this._closeChangeName}*/}
          {/*  uid={this.props.uid}*/}
          {/*  gid={this.props.profile.gid}*/}
          {/*  email={this.props.profile.email}*/}
          {/*  currentName={this.props.userBalanceDoc?.username}*/}
          {/*/>*/}

          {/*<List>*/}
          {/*  <ListItemButton*/}
          {/*    divider*/}
          {/*    onClick={() => {*/}
          {/*      this.setState({ changeUsernameDialogOpen: true });*/}
          {/*    }}*/}
          {/*  >*/}
          {/*    <ListItemText*/}
          {/*      primary={`Change Username (${this.props.userBalanceDoc?.username})`}*/}
          {/*      secondary="It worked for Don Draper..."*/}
          {/*    />*/}
          {/*    <ListItemSecondaryAction>*/}
          {/*      <ChevronRight />*/}
          {/*    </ListItemSecondaryAction>*/}
          {/*  </ListItemButton>*/}
          {/*</List>*/}
        </Box>
        <BottomNav activeTab={1} managerGid={this.props.profile.managerGid} />
      </>
    );
  }
}

Leaderboard.propTypes = {
  classes: PropTypes.object.isRequired,
  profile: PropTypes.object,
  pastBets: PropTypes.object,
  balances: PropTypes.object,
  allBets: PropTypes.object,
  userBalanceDoc: PropTypes.object,
  email: PropTypes.string,
  uid: PropTypes.string,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: "inline-block",
    marginTop: "20px",
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  grid: {
    textAlign: "center",
    marginBottom: "20px",
  },
  usersRow: {
    backgroundColor: "#daf5c8",
  },
  tableCell: {
    "$hover:hover &": {
      color: "pink",
    },
  },
  hover: {},
});

const mapStateToProps = (state) => {
  const getUserBalanceDoc = (obj) => {
    if (state.firebase.auth.uid && state.firestore.data.social_Balances) return obj[state.firebase.auth.uid];
  };

  return {
    profile: state.firebase.profile,
    balances: state.firestore.data?.social_Balances,
    userBalanceDoc: getUserBalanceDoc(state.firestore.data?.social_Balances),
    uid: state.firebase.auth.uid,
    email: state.firebase.auth.email,
    allBets: state.firestore.data?.social_allBets,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    var oneHour = 1000*60;
    var roundedNow = (Math.ceil(Date.now() / oneHour) * oneHour) - 1000 * 60 * 5
    // console.log("now: ",roundedNow)
    // console.log("Dat", (Date.now()-roundedNow)/1000/60)


    return [
      {
        collection: `groups/${props.profile.gid}/balances`,
        storeAs: "social_Balances",
      },
      {
        collection: `groups/${props.profile.gid}/bets2`,
        where: [["maxGameStartTime", "<", roundedNow]],
        storeAs: "social_allBets",
      },
    ];
  })
)(withStyles(styles)(Leaderboard));

// const filterPublicBets = (allBets) => {
//   if (allBets && state.firestore.data.socialGroupConfigs.publicBettors) {
//     let publicUsers = state.firestore.data.socialGroupConfigs.publicBettors;
//     let filtered;
//     filtered = Object.keys(allBets)
//         .filter((key) => publicUsers.includes(allBets[key].uid))
//         .reduce((obj, key) => {
//           return {
//             ...obj,
//             [key]: allBets[key],
//           };
//         }, {});
//     return filtered;
//   } else return {};
// };
// const filterSocialUIDs = (balances) => {
//   if (balances && state.firestore.data.socialGroupConfigs.publicBettors) {
//     let publicUsers = state.firestore.data.socialGroupConfigs?.publicBettors;
//     let filtered;
//     filtered = Object.keys(balances)
//         .filter((key) =>
//             //console.log("key is: ",key)
//             publicUsers.includes(key)
//         )
//         .reduce((obj, key) => {
//           return {
//             ...obj,
//             [key]: balances[key],
//           };
//         }, {});
//     return filtered;
//   }
// };
