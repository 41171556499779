import React, { Component } from "react";
import PropTypes from "prop-types";
import SuperAdminHeader from "./components/SuperAdminHeader";
import { List, ListItemButton, ListItemIcon, ListItemSecondaryAction, ListItemText } from "@mui/material";
import {
  AddBusiness,
  AirlineSeatFlat,
  AirlineSeatFlatAngled,
  AutoAwesomeMotion,
  ChevronRight,
  InsertEmoticon,
} from "@mui/icons-material";
import { withRouter } from "react-router-dom";

class SuperAdminMenu extends Component {
  render() {
    return (
      <>
        <SuperAdminHeader />
        <List>
          <ListItemButton divider onClick={() => this.props.history.push("/super-admin-new-players")}>
            <ListItemIcon>
              <InsertEmoticon />
            </ListItemIcon>
            <ListItemText primary="New Players" secondary="List of the newest players" />
            <ListItemSecondaryAction>
              <ChevronRight />
            </ListItemSecondaryAction>
          </ListItemButton>
          <ListItemButton divider onClick={() => this.props.history.push("/super-admin-new-bets")}>
            <ListItemIcon>
              <AutoAwesomeMotion />
            </ListItemIcon>
            <ListItemText primary="Upcoming Bets" secondary="Pending bets, starting soonest at the top" />
            <ListItemSecondaryAction>
              <ChevronRight />
            </ListItemSecondaryAction>
          </ListItemButton>
          <ListItemButton divider onClick={() => this.props.history.push("/super-admin-old-bets")}>
            <ListItemIcon>
              <AutoAwesomeMotion />
            </ListItemIcon>
            <ListItemText primary="Old Bets" secondary="Last 7 days of bets" />
            <ListItemSecondaryAction>
              <ChevronRight />
            </ListItemSecondaryAction>
          </ListItemButton>
          {/*<ListItemButton divider>*/}
          {/*  <ListItemIcon>*/}
          {/*    <AddBusiness />*/}
          {/*  </ListItemIcon>*/}
          {/*  <ListItemText primary="List Books" secondary="coming soon..." />*/}
          {/*  <ListItemSecondaryAction>*/}
          {/*    <ChevronRight />*/}
          {/*  </ListItemSecondaryAction>*/}
          {/*</ListItemButton>*/}
          <ListItemButton divider onClick={() => this.props.history.push("/super-admin/bet-grading")}>
            <ListItemIcon>
              <AirlineSeatFlatAngled />
            </ListItemIcon>
            <ListItemText primary="Bet Grading" secondary="All pending bets whose games have started" />
            <ListItemSecondaryAction>
              <ChevronRight />
            </ListItemSecondaryAction>
          </ListItemButton>
          <ListItemButton divider onClick={() => this.props.history.push("/super-admin/book-summaries")}>
            <ListItemIcon>
              <AirlineSeatFlat />
            </ListItemIcon>
            <ListItemText primary="Book Summaries" secondary="and use SFB as that bookie" />
            <ListItemSecondaryAction>
              <ChevronRight />
            </ListItemSecondaryAction>
          </ListItemButton>
          <ListItemButton divider onClick={() => this.props.history.push("/super-admin/book-summaries-light")}>
            <ListItemIcon>
              <AirlineSeatFlat />
            </ListItemIcon>
            <ListItemText primary="Book Summaries Light" secondary="only books active last 7 days" />
            <ListItemSecondaryAction>
              <ChevronRight />
            </ListItemSecondaryAction>
          </ListItemButton>
        </List>
      </>
    );
  }
}

SuperAdminMenu.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(SuperAdminMenu);
