import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { alpha, Box, Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";

import IMAGE_qb from "../../../images/qb.webp";
import IMAGE_iphone from "../../../images/iphone.png";
import IMAGE_ticket from "../../../images/ticket2.webp";
import IMAGE_stadium from "../../../images/stadium.webp";
import IMAGE_bball from "../../../images/bball.webp";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Redirect, withRouter } from "react-router-dom";

function Texfield() {
  return null;
}

class ValueProp extends React.Component {
  prop1 = {
    headline: this.props.vpTitle1,
    desc: this.props.vpDesc1,
    img: IMAGE_iphone,
  };
  prop2 = {
    headline: this.props.vpTitle2,
    desc: this.props.vpDesc2,
    img: IMAGE_ticket,
  };
  prop3 = {
    headline: this.props.vpTitle3,
    desc: this.props.vpDesc3,
    img: IMAGE_stadium,
  };
  prop4 = {
    headline: this.props.vpTitle4,
    desc: this.props.vpDesc4,
    img: IMAGE_bball,
  };

  constructor(props) {
    super(props);
    this.state = {
      friendDialogOpen: false,
    };
  }

  renderValueProp = (picSide, headline, description, img) => {
    const { classes } = this.props;
    headline = headline.toUpperCase();

    return (
      <Box>
        <Grid container>
          {/* For xs screens, pic on top, then text*/}

          <Grid item padding={2} xs={12} md={6} sx={{ display: { xs: "block", md: "none" } }}>
            <Box
              sx={{
                // border: 2,
                // borderRadius: 2,
                height: "40vh",
                flexGrow: 1,
                backgroundSize: "contain",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${img})`,
              }}
            />
            <Grid item xs={12} md={6} sx={{ display: { xs: "block", md: "none" } }}>
              <Grid
                container
                direction="column"
                justifyContent="space-evenly"
                alignItems="center"
                style={{ height: "100%", border: 2 }}
              >
                <Grid item>
                  <Grid item sx={{ borderBottom: 1, padding: 3, paddingLeft: 0, paddingRight: 0 }}>
                    <Typography className={classes.headerLogo} align={"center"} style={{ fontSize: "1.9rem" }}>
                      {headline}
                    </Typography>
                  </Grid>
                  <Grid item sx={{ padding: 3, paddingLeft: 0, paddingRight: 0 }}>
                    <Typography
                      sx={{ textAlign: "justify", fontFamily: "Libre Franklin", fontSize: "1rem", maxWidth: "450px" }}
                    >
                      {description}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* For large screens alternate */}

          {picSide === "left" && (
            <>
              <Grid container sx={{ display: { xs: "none", md: "flex" } }}>
                <Grid item xs={1} />

                <Grid item xs={5}>
                  <Box
                    sx={{
                      // border: 2,
                      // borderRadius: 2,
                      height: "40vh",
                      flexGrow: 1,
                      backgroundSize: "contain",
                      backgroundPosition: "center center",
                      backgroundRepeat: "no-repeat",
                      backgroundImage: `url(${img})`,
                    }}
                  />
                </Grid>

                <Grid item xs={5}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-evenly"
                    alignItems="center"
                    style={{ height: "100%" }}
                  >
                    <Grid item>
                      <Grid item sx={{ borderBottom: 1, borderColor: "secondary", padding: 2 }}>
                        <Typography className={classes.headerLogo} align={"center"} style={{ fontSize: "2rem" }}>
                          {headline}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          sx={{
                            textAlign: "justify",
                            padding: 2,
                            fontFamily: "Libre Franklin",
                            fontSize: "1rem",
                            maxWidth: "450px",
                          }}
                        >
                          {description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={1} />
              </Grid>
            </>
          )}

          {picSide === "right" && (
            <>
              <Grid container sx={{ display: { xs: "none", md: "flex" } }}>
                <Grid item xs={1} />

                <Grid item xs={5}>
                  <Grid
                    container
                    direction="column"
                    justifyContent="space-evenly"
                    alignItems="center"
                    style={{ height: "100%" }}
                  >
                    <Grid item>
                      <Grid item sx={{ borderBottom: 1, borderColor: "secondary", padding: 2 }}>
                        <Typography className={classes.headerLogo} align={"center"} style={{ fontSize: "2rem" }}>
                          {headline}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          sx={{
                            textAlign: "justify",
                            padding: 2,
                            fontFamily: "Libre Franklin",
                            fontSize: "1rem",
                            maxWidth: "450px",
                          }}
                        >
                          {description}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>

                {/*<Grid item xs={1} />*/}

                <Grid item xs={5}>
                  <Box
                    sx={{
                      // border: 2,
                      // borderRadius: 2,
                      height: "40vh",
                      flexGrow: 1,
                      backgroundSize: "contain",
                      backgroundPosition: "center center",
                      backgroundRepeat: "no-repeat",
                      backgroundImage: `url(${img})`,
                    }}
                  />
                </Grid>

                <Grid item xs={1} />
              </Grid>
            </>
          )}
        </Grid>
      </Box>
    );
  };

  render() {
    console.log("VALUE PROPS this.props: ", this.props);
     return (
          <Grid container>
            <Grid item xs={12} sx={{ paddingTop: "8vh" }}>
              {this.renderValueProp("left", this.prop1.headline, this.prop1.desc, this.prop1.img)}
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: "10vh" }}>
              {this.renderValueProp("right", this.prop2.headline, this.prop2.desc, this.prop2.img)}
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: "10vh" }}>
              {this.renderValueProp("left", this.prop3.headline, this.prop3.desc, this.prop3.img)}
            </Grid>
            <Grid item xs={12} sx={{ paddingTop: "10vh" }}>
              {this.renderValueProp("right", this.prop4.headline, this.prop4.desc, this.prop4.img)}
            </Grid>
          </Grid>
      );
    }
  }

ValueProp.propTypes = {
  classes: PropTypes.object.isRequired,
  vpTitle1: PropTypes.string,
  vpTitle2: PropTypes.string,
  vpTitle3: PropTypes.string,
  vpTitle4: PropTypes.string,
  vpDesc1: PropTypes.string,
  vpDesc2: PropTypes.string,
  vpDesc3: PropTypes.string,
  vpDesc4: PropTypes.string,
  firstVisitURL: PropTypes.string,
  anonAccountState: PropTypes.string,
  history: PropTypes.array,
  uid: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  dialogTitle: {
    color: theme.palette.primary.dark,
    paddingBottom: 0,
  },
  headerLogo: {
    fontFamily: "NeuveticaHeavyItalic",
    // fontStyle: 'italic',
    // color: "black",
    fontWeight: 700,
    fontSize: "2rem",
  },
  footerLogo: {
    fontFamily: "NeuveticaHeavyItalic",
    // fontStyle: 'italic',
    // color: "black",
    fontWeight: 700,
    fontSize: "1rem",
  },
  dialogSubtitle: {
    paddingTop: 10,
    color: theme.palette.primary.light,
  },
  paper: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  values: {
    borderColor: theme.palette.primary.light,
    color: theme.palette.primary.dark,
    borderWidth: 2,
    marginBottom: 15,
    alignItems: "left",
    padding: 0,
  },
});

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    firstVisitURL: state.newUserState.initialPage,
    anonAccountState: state.newUserState.anonAccountState,
  };
};

export default withRouter(connect(mapStateToProps)(withStyles(styles)(ValueProp)));
