import * as React from "react";
import { useEffect } from "react";
import List from "@mui/material/List";
import firebase from "../../../../firebase";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { formatSpread, renderGametime } from "../../../../utilities/pickemUtils";
import PickemBetItem from "./PickemBetItem";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { getTopNavIcon, getTopNavText } from "./TopNav";

export default function PickMatch(props) {
  const [selectedSport, setSelectedSport] = React.useState("NBA");
  const [matches, setMatches] = React.useState([]);
  // const availableSports = ["NFL", "baseball_mlb", "NCAA-FB", "UFC"];
  const [maxGameStartTime, setMaxGameStartTime] = React.useState([]);
  const [availableSports, setAvailableSports] = React.useState([]);

  const db = firebase.firestore();

  const disableSpreads = selectedSport === "UFC";
  const disableTotals = selectedSport === "UFC";
  const onlyMoneyline = selectedSport === "UEFA Champ" || selectedSport === "Champ League";

  const fetchSports = async () => {
    const doc = await db.collection("available_lines_2").where("active", "==", true).where("weight", ">", 0);
    await doc.onSnapshot((querySnapshot) => {
      const newData = querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
      setAvailableSports(newData);
    });
  };

  useEffect(() => {
    fetchSports();
  }, []);

  const fetchMatches = async () => {
    const doc = await db
      .collection("lines_standardized")
      .doc(selectedSport)
      .collection("futures")
      .where("starts_ms", "<", props.maxGameStartTime);
    await doc.onSnapshot((querySnapshot) => {
      const newData = querySnapshot.docs
        // .filter((doc) => doc.data().starts_ms < props.maxGameStartTime)
        .map((doc) => ({ ...doc.data(), id: doc.id }))
        .sort((a, b) => a.starts_ms - b.starts_ms);
      setMatches(newData);
    });
  };

  useEffect(() => {
    fetchMatches();
  }, [selectedSport, props.maxGameStartTime]);

  const renderGame = (game) => {
    console.log(selectedSport);
    if (onlyMoneyline) {
      return renderNoMoneyline(game);
    } else {
      return renderGameWithMoneyline(game);
    }
  };

  const renderGameWithMoneyline = (game) => {
    return (
      <Grid item xs={12} key={game.id} sx={{ paddingBottom: 4 }}>
        <Grid container item xs={12} direction="row" alignItems="center" spacing={0}>
          <Grid item xs={disableSpreads && disableTotals ? 12 : 6}>
            <PickemBetItem
              label={game.away}
              price={game.standard?.ft?.moneyline?.away}
              game={game}
              rowColor={"one"}
              bucket={selectedSport}
              collection="futures"
              docId={game.id}
              betType="moneyline"
              betSide="away"
              pickNumber={props.pickNumber}
              tournamentID={props.tournamentID}
              handleClose={props.handleClose}
              riskAmount={props.riskAmount}
            />
          </Grid>
          {!disableSpreads && (
            <Grid item sm={3} xs={3}>
              <PickemBetItem
                label={formatSpread(-1 * game.standard.ft?.spread?.hdp)}
                price={game.standard?.ft?.spread?.away}
                game={game}
                rowColor="one"
                bucket={selectedSport}
                collection="futures"
                docId={game.id}
                betType="spread"
                betSide="away"
                pickNumber={props.pickNumber}
                tournamentID={props.tournamentID}
                handleClose={props.handleClose}
                riskAmount={props.riskAmount}
              />
            </Grid>
          )}
          {!disableTotals && (
            <Grid item sm={disableSpreads ? 6 : 3} xs={disableSpreads ? 6 : 3}>
              <PickemBetItem
                label={"o" + game.standard?.ft?.total?.points}
                price={game.standard?.ft?.total?.over}
                game={game}
                rowColor="one"
                bucket={selectedSport}
                collection="futures"
                docId={game.id}
                betType="total"
                betSide="over"
                pickNumber={props.pickNumber}
                tournamentID={props.tournamentID}
                handleClose={props.handleClose}
                riskAmount={props.riskAmount}
              />
            </Grid>
          )}
        </Grid>

        <Grid container item xs={12} direction="row" alignItems="center">
          <Grid item xs={disableSpreads && disableTotals ? 12 : 6}>
            <PickemBetItem
              label={game.home}
              price={game.standard?.ft?.moneyline?.home}
              game={game}
              rowColor={"two"}
              bucket={selectedSport}
              collection="futures"
              docId={game.id}
              betType="moneyline"
              betSide="home"
              pickNumber={props.pickNumber}
              tournamentID={props.tournamentID}
              handleClose={props.handleClose}
              riskAmount={props.riskAmount}
            />
          </Grid>

          {!disableSpreads && (
            <Grid item sm={3} xs={3}>
              <PickemBetItem
                label={formatSpread(game.standard?.ft?.spread?.hdp)}
                price={game.standard?.ft?.spread?.home}
                game={game}
                rowColor="two"
                bucket={selectedSport}
                collection="futures"
                docId={game.id}
                betType="spread"
                betSide="home"
                pickNumber={props.pickNumber}
                tournamentID={props.tournamentID}
                handleClose={props.handleClose}
                riskAmount={props.riskAmount}
              />
            </Grid>
          )}
          {!disableTotals && (
            <Grid item sm={disableSpreads ? 6 : 3} xs={disableSpreads ? 6 : 3}>
              <PickemBetItem
                label={"u" + game.standard?.ft?.total?.points}
                price={game.standard?.ft?.total?.under}
                game={game}
                rowColor="two"
                bucket={selectedSport}
                collection="futures"
                docId={game.id}
                betType="total"
                betSide="under"
                pickNumber={props.pickNumber}
                tournamentID={props.tournamentID}
                handleClose={props.handleClose}
                riskAmount={props.riskAmount}
              />
            </Grid>
          )}
        </Grid>
        {renderGametime(game.starts_ms)}
      </Grid>
    );
  };

  const renderNoMoneyline = (game) => {
    return (
      <Grid item xs={12} key={game.id} sx={{ paddingBottom: 4 }}>
        <Grid container item xs={12} direction="row" alignItems="center" spacing={0}>
          <Grid item xs={12}>
            <PickemBetItem
              label={game.away}
              price={game.standard?.ft?.moneyline?.away}
              game={game}
              rowColor={"one"}
              bucket={selectedSport}
              collection="futures"
              docId={game.id}
              betType="moneyline"
              betSide="away"
              pickNumber={props.pickNumber}
              tournamentID={props.tournamentID}
              handleClose={props.handleClose}
              riskAmount={props.riskAmount}
            />
          </Grid>
        </Grid>

        <Grid container item xs={12} direction="row" alignItems="center">
          <Grid item xs={12}>
            <PickemBetItem
              label={game.home}
              price={game.standard?.ft?.moneyline?.home}
              game={game}
              rowColor={"two"}
              bucket={selectedSport}
              collection="futures"
              docId={game.id}
              betType="moneyline"
              betSide="home"
              pickNumber={props.pickNumber}
              tournamentID={props.tournamentID}
              handleClose={props.handleClose}
              riskAmount={props.riskAmount}
            />
          </Grid>
        </Grid>
        {renderGametime(game.starts_ms)}
      </Grid>
    );
  };

  return (
    <div>
      <Tabs
        value={selectedSport}
        variant="scrollable"
        // scrollButtons
        allowScrollButtonsMobile
        onChange={(e, newValue) => {
          setSelectedSport(newValue);
        }}
        TabIndicatorProps={{ style: { opacity: 0 } }}
        textColor={"secondary"}
        sx={{ paddingTop: 1, paddingBottom: 1, background: "#ffffff" }}
      >
        {availableSports.map((sport) => (
          <Tab
            value={sport.bucket}
            icon={getTopNavIcon(sport.group)}
            key={sport.id}
            label={getTopNavText(sport.title)}
          />
        ))}
      </Tabs>
      <List sx={{ background: "white", paddingTop: 0 }}>
        {matches
          .filter(
            (game) =>
              game.standard?.ft?.moneyline?.away &&
              !game.is_live &&
              !game.away.startsWith("Away") &&
              !game.home.startsWith("Home")
          )
          .map((game) => renderGame(game))}
      </List>
    </div>
  );
}

PickMatch.propTypes = {
  // sport: PropTypes.string.isRequired,
  // selectSport: PropTypes.func.isRequired,
  pickNumber: PropTypes.number.isRequired,
  tournamentID: PropTypes.string.isRequired,
  handleClose: PropTypes.func.isRequired,
  riskAmount: PropTypes.number.isRequired,
  maxGameStartTime: PropTypes.number,
};
