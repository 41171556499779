import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import { Redirect, withRouter } from "react-router-dom";
function Texfield() {
  return null;
}

class LPFooter extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      friendDialogOpen: false,
    };
  }

  renderFooter = () => {
    const { classes } = this.props;
    return (
      <AppBar position="relative" color={"tertiary"} elevation={0} sx={{ marginTop: 10, borderTop: 1 }}>
        <Toolbar>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Grid item xs={6} sm={4}>
              <Typography sx={{ fontSize: ".8rem" }}>copyright 2022</Typography>
            </Grid>

            <Grid item xs={0} sm={4} sx={{ display: { xs: "none", sm: "flex" } }}>
              <Grid container direction="row" alignItems="center" justifyContent="center" padding={0}>
                <Grid item>
                  <Typography align="center" className={classes.footerLogo}>
                    SUPERFUNBET
                  </Typography>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={6} sm={4}>
              <Grid container direction="row" alignItems="center" justifyContent="right" padding={0}>
                <Grid item>
                  <Button
                    onClick={() => (window.location = "mailto:admin@superfunbet.com")}
                    sx={{
                      padding: 0,
                      margin: 0,
                      // border: 1,
                      fontSize: ".8rem",
                      color: "#bf0070",
                    }}
                  >
                    Contact Us
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    );
  };

  render() {
    console.log("LPFooter this.props: ", this.props);


    return this.renderFooter()

  }
}

LPFooter.propTypes = {
  classes: PropTypes.object.isRequired,
  firstVisitURL: PropTypes.string,
  anonAccountState: PropTypes.string,
  history: PropTypes.array,
  uid: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  dialogTitle: {
    color: theme.palette.primary.dark,
    paddingBottom: 0,
  },
  headerLogo: {
    fontFamily: "NeuveticaHeavyItalic",
    // fontStyle: 'italic',
    // color: "black",
    fontWeight: 700,
    fontSize: "2rem",
  },
  footerLogo: {
    fontFamily: "NeuveticaHeavyItalic",
    // fontStyle: 'italic',
    // color: "black",
    fontWeight: 700,
    fontSize: "1rem",
  },
  dialogSubtitle: {
    paddingTop: 10,
    color: theme.palette.primary.light,
  },
  paper: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  values: {
    borderColor: theme.palette.primary.light,
    color: theme.palette.primary.dark,
    borderWidth: 2,
    marginBottom: 15,
    alignItems: "left",
    padding: 0,
  },
});

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    firstVisitURL: state.newUserState.initialPage,
    anonAccountState: state.newUserState.anonAccountState,
  };
};

export default withRouter(connect(mapStateToProps)(withStyles(styles)(LPFooter)));
