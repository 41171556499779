import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import GenericBackHeader from "../../components/GenericBackHeader";
import Register from "../OLD/Register/Register";

class SwitchSportsbook extends Component {
  render() {
    return (
      <>
        <GenericBackHeader title="Switch Sportsbook" showBackButton />
        <Register />
      </>
    );
  }
}

SwitchSportsbook.propTypes = {
  profile: PropTypes.object,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: "inline-block",
    marginTop: "20px",
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  grid: {
    textAlign: "center",
    marginBottom: "20px",
  },
});

const mapStateToProps = (state) => {
  return {
    profile: state.firebase.profile,
  };
};

export default compose(connect(mapStateToProps))(withStyles(styles)(SwitchSportsbook));
