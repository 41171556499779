import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import TextField from "@mui/material/TextField";
import JuiceMap from "./JuiceMap.js";
import { firestoreConnect, getFirebase } from "react-redux-firebase";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import GenericBackHeader from "../../../../components/GenericBackHeader";
import { ListItemButton, ListItemSecondaryAction, ListItemText } from "@mui/material";
import { ChevronRight } from "@mui/icons-material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import BottomNav from "../../../../components/BottomNav";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CustomPlayerJuice from "./CustomPlayerJuice";

const initialState = {
  activeDialog: "",
  title: "",
  text: "",
  newValue: "",
  customPlayerJuiceOpen: false,
};

class GroupSettings extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }

  componentDidMount() {}

  // renderJuiceOptions = (newJuice) => {
  //   let returnArray = [];
  //   for (var i = 0; i < 16; i++) {
  //     var juice = -100;
  //     returnArray.push(<MenuItem value={juice - i}>{juice - i}</MenuItem>);
  //   }
  //   return returnArray;
  // };

  reset = () => {
    this.setState(initialState);
  };

  juicePcntFromAmerican = (juice) => {
    let hardCodedJuice = Number(JuiceMap[String(juice)]).toFixed(2);
    return Number(hardCodedJuice).toFixed(2);
  };

  americanJuiceFromPcnt = (newJuice) => {
    let impliedA = 0.5;
    impliedA += (impliedA * newJuice) / 100;
    return Math.round((impliedA / (1 - impliedA)) * -100);
  };

  checkValidEntry = (field) => {
    let s = this.state.newValue;
    switch (field) {
      case "groupName":
        if (s.length > 5) return true;
        else alert("Error: Too Short");
        break;
      case "Max Bet":
        if (isNaN(s) || s < 0) {
          console.log("s: ", s);
          alert("Error: bad value");
        } else return true;
        break;
    }
  };

  updateFirestore = (field) => {
    const GROUP_CONFIGS = getFirebase().firestore().collection("groups").doc(this.props.profile.managerGid);
    // const sftTrack = getFirebase().functions().httpsCallable("sftTrack");

    switch (field) {
      case "groupName":
        if (this.checkValidEntry(this.state.activeDialog) === true) {
          getFirebase().analytics().logEvent("book_created", {
            event: "change_group_name",
            value: this.state.newValue,
          });
          // sftTrack({
          //   bucket: "BOOK_CREATED",
          //   data: {
          //     event: "change_group_name",
          //     value: this.state.newValue,
          //     uid: this.props.uid,
          //     profile: this.props.profile,
          //   },
          // })
          //   .then((result) => console.log("Cloud function return", result))
          //   .catch((error) => console.log("Cloud function error", error));
          return GROUP_CONFIGS.update({ groupName: this.state.newValue });
        } else alert(this.checkValidEntry(this.state.activeDialog));
        break;
      case "maxBet":
        GROUP_CONFIGS.update({ maxBet: Number(this.state.newValue) });
        break;
      case "groupJuice":
        GROUP_CONFIGS.update({ groupJuice: Number(this.juicePcntFromAmerican(this.state.newValue)) });
        break;
      case "groupCoinflipJuice":
        GROUP_CONFIGS.update({ groupCoinflipJuice: Number(this.juicePcntFromAmerican(this.state.newValue)) });
        break;
      case "gradeYourOwnBet":
        GROUP_CONFIGS.update({ gradeYourOwnBet: Boolean(this.state.newValue) });
        break;
    }
  };

  getLabel = () => {
    if (this.state.activeDialog === "groupJuice" || this.state.activeDialog === "groupCoinflipJuice")
      return `${this.americanJuiceFromPcnt(this.props.groupConfigs.groupCoinflipJuice)}`;
    else return `${this.props.groupConfigs[this.state.activeDialog]}`;
  };

  renderToggles = () => {
    const handleChange = (e) => {
      this.setState({ newValue: e.target.checked });
    };
    return (
      <FormGroup>
        <FormControlLabel
          onChange={handleChange}
          control={<Switch defaultChecked={this.props.groupConfigs?.gradeYourOwnBet} />}
          label="Allow"
        />
      </FormGroup>
    );
  };

  renderJuiceOptions = () => {
    let returnArray = [];
    for (let juice = -100; juice > -116; juice--) {
      returnArray.push(<MenuItem value={juice}>{juice}</MenuItem>);
    }
    return returnArray;
  };

  renderDialogText = () => {
    if (this.state.activeDialog === "gradeYourOwnBet") {
      return (
        <>
          {this.renderToggles()}
          <DialogContentText>
            {`The app automatically grades all bets within a few minutes.  However, you can always grade bets manually, and can allow your players to do so as well.`}
          </DialogContentText>
        </>
      );
    } else {
      return (
        <>
          <DialogContentText>{this.state.text}</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={this.getLabel()}
            // type="email"
            fullWidth
            variant="standard"
            onChange={(e) => this.setState({ newValue: e.target.value })}
          />
        </>
      );
    }
  };

  DIALOG = () => {
    let d = this.state.activeDialog;

    if (d.includes("Juice")) {
      return (
        <div>
          <Dialog open={d !== ""}>
            <DialogTitle>{this.state.title}</DialogTitle>
            <DialogContent>
              {d.includes("flip")
                ? `this is usually set lower than regular juice, since the player cannot have an edge`
                : `-110 means risk 110 to win 100`}
              <FormControl fullWidth style={{ paddingTop: 20 }}>
                <Select
                  labelId="demo-simple-select-helper-label"
                  id="demo-simple-select-helper"
                  // defaultValue={this.americanJuiceFromPcnt(this.props.managerGroupConfig?.groupJuice) || -110}
                  value={d.includes("flip") ? this.state.groupCoinflipJuice : this.state.groupJuice}
                  onChange={(e) => this.setState({ newValue: e.target.value })}
                >
                  {this.renderJuiceOptions()}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={this.reset}>
                Cancel
              </Button>
              <Button
                variant={"contained"}
                onClick={() => {
                  this.updateFirestore(d);
                  this.reset();
                }}
              >
                Submit
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      );
    }
    return (
      <div>
        <Dialog open={d !== ""}>
          <DialogTitle>{this.state.title}</DialogTitle>
          <DialogContent>{this.renderDialogText()}</DialogContent>
          <DialogActions>
            <Button variant="outlined" onClick={this.reset}>
              Cancel
            </Button>
            <Button
              variant={"contained"}
              onClick={() => {
                this.updateFirestore(d);
                this.reset();
              }}
            >
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  };

  render() {
    console.log("Group Settings this.props: ", this.props);
    console.log("Group Settings this.state: ", this.state);

    if (this.props.groupConfigs)
      return (
        <>
          <GenericBackHeader title="Book Settings" showBackButton />

          {this.DIALOG()}

          <CustomPlayerJuice
            open={this.state.customPlayerJuiceOpen}
            closeCallback={() => this.setState({ customPlayerJuiceOpen: false })}
            gid={this.props.profile.gid}
            email={this.props.profile.email}
          />

          <ListItemButton
            divider
            onClick={() => {
              this.setState({
                activeDialog: "groupName",
                title: "Change Group Name",
                text: "This is purely display.  It will not affect balances.",
              });
            }}
          >
            <ListItemText primary="Group Name" secondary={`currently: ${this.props.groupConfigs.groupName}`} />
            <ListItemSecondaryAction>
              <ChevronRight />
            </ListItemSecondaryAction>
          </ListItemButton>

          <ListItemButton
            divider
            onClick={() => {
              this.setState({
                activeDialog: "maxBet",
                title: "Change Max Bet",
                text: "Max win on a straight bet or parlay.",
              });
            }}
          >
            <ListItemText primary="Max Bet" secondary={`currently: ${this.props.groupConfigs.maxBet}`} />
            <ListItemSecondaryAction>
              <ChevronRight />
            </ListItemSecondaryAction>
          </ListItemButton>

          <ListItemButton
            divider
            onClick={() => {
              this.setState({
                activeDialog: "groupJuice",
                title: "Change Juice",
                text: "-110 means risk 110 to win 100",
              });
            }}
          >
            <ListItemText
              primary="Juice"
              secondary={`currently: ${this.americanJuiceFromPcnt(this.props.groupConfigs.groupJuice)}`}
            />
            <ListItemSecondaryAction>
              <ChevronRight />
            </ListItemSecondaryAction>
          </ListItemButton>

          <ListItemButton
            divider
            onClick={() => {
              this.setState({
                activeDialog: "groupCoinflipJuice",
                title: "Change Coinflip Juice",
                text: "-102 means risk 102 to win 100",
              });
            }}
          >
            <ListItemText
              primary="Coinflip Juice"
              secondary={`currently: ${this.americanJuiceFromPcnt(this.props.groupConfigs.groupCoinflipJuice)}`}
            />
            <ListItemSecondaryAction>
              <ChevronRight />
            </ListItemSecondaryAction>
          </ListItemButton>

          <ListItemButton
            divider
            onClick={() => {
              this.setState({
                customPlayerJuiceOpen: true,
              });
            }}
          >
            <ListItemText primary="Custom Juice by Player" secondary={`different juice for different players`} />
            <ListItemSecondaryAction>
              <ChevronRight />
            </ListItemSecondaryAction>
          </ListItemButton>

          <ListItemButton
            divider
            onClick={() => {
              this.setState({
                activeDialog: "gradeYourOwnBet",
                title: "Let Users Grade Their Bets?",
                text: `${
                  this.props.groupConfigs.gradeYourOwnBet
                    ? "currently: users CAN grade their own bets"
                    : "currently: users CANNOT grade their own bets"
                }`,
              });
            }}
          >
            <ListItemText
              primary="Bet Grading"
              secondary={
                this.props.groupConfigs.gradeYourOwnBet
                  ? "currently: users CAN grade their own bets"
                  : "currently: users CANNOT grade their own bets"
              }
            />
            <ListItemSecondaryAction>
              <ChevronRight />
            </ListItemSecondaryAction>
          </ListItemButton>

          <BottomNav isBookieMode activeTab={3} managerGid={this.props.profile.managerGid} />
        </>
      );
    else return <>loading...</>;
  }
}

GroupSettings.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  groupConfigs: PropTypes.object,
  adminProfile: PropTypes.object,
  history: PropTypes.array,
  profile: PropTypes.object,
  uid: PropTypes.string,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  customCard: {
    borderColor: theme.palette.primary.light,
    borderWidth: 2,
    marginBottom: 5,
    alignItems: "center",
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  pastBetsList: {
    width: "100%",
  },
});

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    email: state.firebase.auth.email,
    profile: state.firebase.profile,
    groupConfigs: state.firestore.data?.groupConfigs,
  };
};

export default compose(
  connect(mapStateToProps),
  withRouter,
  firestoreConnect((props) => {
    console.log("---------------firestore connecting");
    console.log("---------------ADMIN - COMPOSE connect props", props);

    return [
      {
        collection: "groups",
        doc: props.profile.managerGid,
        storeAs: "groupConfigs",
      },
    ];
  })
)(withStyles(styles)(GroupSettings));
