import React, { useEffect } from "react";
import { Avatar, Chip, ListItemAvatar, ListItemText } from "@mui/material";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import PropTypes from "prop-types";
import PickDialog from "./PickDialog";
import firebase from "../../../../firebase";
import { getPickFullName, pickemWinAmount } from "../../../../utilities/pickemUtils";
import { useDispatch } from "react-redux";
import { pickemSetGame, pickemSetPickGood, pickemUpdatePickValue } from "../../../../reducers/pickem/tournamentEntry";
import { DeleteForever } from "@mui/icons-material";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";

function PickItem(props) {
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [line, setLine] = React.useState({});
  const [pickGood, setPickGood] = React.useState(false);
  const [pickDuplicate, setPickDuplicate] = React.useState(false);

  const db = firebase.firestore();
  const dispatch = useDispatch();

  const fetchLines = async () => {
    if (!props.pickDetails) {
      dispatch(pickemUpdatePickValue(props.pickNumber, 0));
      return;
    }
    const doc = await db
      .collection("lines_standardized")
      .doc(props.pickDetails.bucket)
      .collection(props.pickDetails.collection)
      .doc(props.pickDetails.docId);
    await doc.onSnapshot((docSnapshot) => {
      let data = docSnapshot.data();
      setLine(data);
      dispatch(pickemSetGame(props.pickNumber, data));
      let points = getPoints(data);
      dispatch(pickemUpdatePickValue(props.pickNumber, points));
      let pickGood = isPickGood(data, points);
      dispatch(pickemSetPickGood(props.pickNumber, pickGood));
      setPickGood(pickGood);
    });
  };

  const deletePick = async () => {
    if (!props.pickDetails) return;
    const doc = await db
      .collection("pickem")
      .doc("user")
      .collection(firebase.auth().currentUser.uid)
      .doc("pending")
      .collection(props.tournamentID)
      .doc("picks");
    await doc.set(
      {
        ["pick_" + props.pickNumber]: firebase.firestore.FieldValue.delete(),
      },
      { merge: true }
    );
    setPickDuplicate(false);
  };

  useEffect(() => {
    if (line) {
      let points = getPoints(line);
      dispatch(pickemUpdatePickValue(props.pickNumber, points));
      let pickGood = isPickGood(line, points);
      dispatch(pickemSetPickGood(props.pickNumber, pickGood));
      setPickGood(pickGood);
    }
    fetchLines();
  }, [props?.pickDetails?.betSide, props?.pickDetails?.betType, props?.pickDetails?.bucket, props?.pickDetails?.docId]);

  useEffect(() => {
    if (line) {
      let pickGood = isPickGood(line, getPoints(line));
      dispatch(pickemSetPickGood(props.pickNumber, pickGood));
      setPickGood(pickGood);
    }
  }, [props.allPicks]);

  const isPickGood = (line, points) => {
    if (line === undefined) {
      deletePick();
      return false;
    }
    if (line?.is_live) {
      deletePick();
      return false;
    }

    if (!(parseFloat(points) > 0)) {
      return false;
    }
    const checkDups = [...Array(props.pickTotal)]
      .map((e, i) => {
        if (i !== props.pickNumber) {
          if (props.allPicks["pick_" + i]?.docId === props.pickDetails?.docId) {
            setPickDuplicate(true);
            return false;
          }
        }
        return true;
      })
      .reduce((a, b) => a && b, true);
    if (!checkDups) {
      return false;
    }
    if (pickDuplicate) {
      setPickDuplicate(false);
    }
    return true;
  };

  const secondaryText = () => {
    if (!props.pickDetails) return "No pick made yet";
    if (!line) return "No line available yet";
    return getPickFullName(props.pickDetails, line);
  };

  const primaryText = () => {
    if (!props.pickDetails) return <Typography>Make Selection</Typography>;
    return (
      <Typography>
        <Chip color="secondary" label={getPoints(line)} variant="outlined" />
        {pickDuplicate && <Typography color={"red"}>Duplicate</Typography>}
      </Typography>
    );
  };

  const getPoints = (line) => {
    if (!props.pickDetails) return "";
    if (!line) return "";
    let winAmount = false;
    if (props.pickDetails.betType === "moneyline") {
      winAmount = pickemWinAmount(
        props.riskAmount,
        line.standard?.ft?.[props.pickDetails.betType]?.[props.pickDetails.betSide]
      );
    }
    if (props.pickDetails.betType === "spread") {
      winAmount = pickemWinAmount(
        props.riskAmount,
        line.standard?.ft?.[props.pickDetails.betType]?.[props.pickDetails.betSide]
      );
    }
    if (props.pickDetails.betType === "total") {
      winAmount = pickemWinAmount(
        props.riskAmount,
        line.standard?.ft?.[props.pickDetails.betType]?.[props.pickDetails.betSide]
      );
    }
    // dispatch(pickemUpdatePickValue(props.pickNumber, winAmount));
    return winAmount;
  };

  console.log(line);

  return (
    <div>
      <ListItem
        key={props.tournamentID}
        alignItems="flex-end"
        divider
        secondaryAction={
          <Grid container direction="row" justifyContent="space-between" alignItems="center">
            <IconButton
              onClick={(e) => {
                e.stopPropagation();
                deletePick();
              }}
            >
              <DeleteForever />
            </IconButton>
          </Grid>
        }
        // secondaryAction={
        //   <Grid container justifyContent={'flex-end'}>
        //     <Grid item xs={12} justifyContent={'flex-end'}>
        //       <Typography sx={{ paddingRight: 1 }}>{getPoints(line)}</Typography>
        //     </Grid>
        //     <Grid item xs={12}>
        //       {props.pickDetails && (

        //       )}
        //     </Grid>
        //   </Grid>
        // }
        onClick={() => setDialogOpen(true)}
      >
        <ListItemAvatar>
          <Avatar sx={{ backgroundColor: props.pickDetails ? "#bf0070" : null }}>{props.pickNumber + 1}</Avatar>
        </ListItemAvatar>
        <ListItemText primary={primaryText()} secondary={secondaryText()} />
      </ListItem>

      <PickDialog
        open={dialogOpen}
        handleClose={() => setDialogOpen(false)}
        pickNumber={props.pickNumber}
        tournamentID={props.tournamentID}
        riskAmount={props.riskAmount}
        maxGameStartTime={props.maxGameStartTime}
      />
    </div>
  );
}

PickItem.propTypes = {
  tournamentID: PropTypes.string.isRequired,
  pickNumber: PropTypes.number.isRequired,
  pickDetails: PropTypes.object.isRequired,
  allPicks: PropTypes.object.isRequired,
  pickTotal: PropTypes.number.isRequired,
  riskAmount: PropTypes.number.isRequired,
  maxGameStartTime: PropTypes.number,
};

export { PickItem };
