import React, { Component } from "react";
import PropTypes from "prop-types";
import BetList from "../../Sportsbook/BetList";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import withStyles from "@mui/styles/withStyles";
import GenericBackHeader from "../../../components/GenericBackHeader";
import Container from "@mui/material/Container";
import { BottomNav } from "../../../components";
import Box from "@mui/material/Box";

class SocialPendingBets extends Component {
  render() {
    console.log("social pending bet props: ", this.props);
    return (
      <>
        <Box>
          <GenericBackHeader title="Group Pending Bets" showBackButton />
          {this.props.socialPendingBets && this.props.socialBalances && this.props.socialGroupConfigs && (
            <BetList
              noAccordion
              activePromo={this.props.socialGroupConfigs.activePromo}
              bets={this.props.socialPendingBets}
              isSocial
              balances={this.props.socialBalances}
            />
          )}
          {!this.props.socialPendingBets && <Container>No Pending Bets</Container>}
        </Box>
        <BottomNav activeTab={1} managerGid={this.props.profile.managerGid} />
      </>
    );
  }
}

SocialPendingBets.propTypes = {
  profile: PropTypes.object,
  socialPendingBets: PropTypes.object,
  socialBalances: PropTypes.object,
  socialGroupConfigs: PropTypes.object,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: "inline-block",
    marginTop: "20px",
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  grid: {
    textAlign: "center",
    marginBottom: "20px",
  },
});

const mapStateToProps = (state) => {
  return {
    profile: state.firebase.profile,
    socialPastBets: state.firestore.data?.socialPastBets,
    socialPendingBets: state.firestore.data?.socialPendingBets,
    socialGroupConfigs: state.firestore.data?.socialGroupConfigs,
    socialBalances: state.firestore.data?.socialBalances,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    console.log("---------------firestore connecting");
    console.log("---------------ADMIN - COMPOSE connect props", props);

    return [
      {
        collection: `groups/${props.profile.gid}/balances`,
        storeAs: "socialBalances",
      },
      {
        collection: `groups/${props.profile.gid}/bets`,
        where: [["result", "!=", "pending"]],
        storeAs: "socialPastBets",
      },
      {
        collection: `groups/${props.profile.gid}/bets`,
        where: [["result", "==", "pending"]],
        storeAs: "socialPendingBets",
      },
      {
        collection: "groups",
        doc: props.profile.gid,
        storeAs: "socialGroupConfigs",
      },
    ];
  })
)(withStyles(styles)(SocialPendingBets));

// const filterPublicBets = (allBets) => {
//   if (allBets && state.firestore.data.socialGroupConfigs.publicBettors) {
//     let publicUsers = state.firestore.data.socialGroupConfigs.publicBettors;
//     let filtered;
//     filtered = Object.keys(allBets)
//         .filter((key) => publicUsers.includes(allBets[key].uid))
//         .reduce((obj, key) => {
//           return {
//             ...obj,
//             [key]: allBets[key],
//           };
//         }, {});
//     return filtered;
//   } else return {};
// };
// const filterSocialUIDs = (balances) => {
//   if (balances && state.firestore.data.socialGroupConfigs.publicBettors) {
//     let publicUsers = state.firestore.data.socialGroupConfigs?.publicBettors;
//     let filtered;
//     filtered = Object.keys(balances)
//         .filter((key) =>
//             //console.log("key is: ",key)
//             publicUsers.includes(key)
//         )
//         .reduce((obj, key) => {
//           return {
//             ...obj,
//             [key]: balances[key],
//           };
//         }, {});
//     return filtered;
//   }
// };
