import Typography from "@mui/material/Typography";
import * as React from "react";
import withStyles from "@mui/styles/withStyles";
import PropTypes from "prop-types";
import Container from "@mui/material/Container";
import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import RestoreIcon from "@mui/icons-material/Restore";
import LocationOnIcon from "@mui/icons-material/LocationOn";

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      navValue: true,
    };
  }

  oldFooter() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <footer className={classes.footer}>
          <Container maxWidth="sm">
            <Typography variant="h6" align="center" gutterBottom>
              copyright 2022
            </Typography>
          </Container>
        </footer>
      </div>
    );
  }

  newFooter() {
    const { classes } = this.props;

    return (
      <>
        <BottomNavigation
          value={this.state.navValue}
          onChange={(event, newValue) => {
            console.log("new value: ", newValue);
          }}
          showLabels
          className={classes.root}
        >
          <BottomNavigationAction label="FAQ" icon={<RestoreIcon />} />
          <BottomNavigationAction label="Nearby" icon={<LocationOnIcon />} />
        </BottomNavigation>
      </>
    );
  }

  render() {
    // const { classes } = this.props;

    return this.oldFooter();
  }
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  footer: {
    padding: theme.spacing(3, 2),
    marginTop: "10px",
    backgroundColor: theme.palette.mode === "light" ? theme.palette.grey[200] : theme.palette.grey[800],
  },
  root: {
    display: "flex",
    flexDirection: "column",
    // minHeight: "100vh",
  },
});

export default withStyles(styles)(Footer);
