import { createAsyncAction } from "redux-promise-middleware-actions";
import { getFirebase } from "react-redux-firebase";
import { handleActions } from "redux-actions";

export const lookupUserDetails = createAsyncAction(
  "LOOKUP_USER_DETAILS",
  (uid) => {
    const lookupUserInfo = getFirebase().functions().httpsCallable("user-lookupUserInfo");
    return lookupUserInfo({ uid });
  },
  (uid) => {
    return { uid };
  }
);

const initialState = {
  userDetails: {},
};

const userLoading = {
  loading: true,
  email: "",
};

export default handleActions(
  {
    LOOKUP_USER_DETAILS_LOADING: (state, { meta }) => {
      if (state.userDetails.includes(meta.uid)) {
        return { ...state };
      }
      return {
        ...state,
        userDetails: { ...state.userDetails, [meta.uid]: userLoading },
      };
    },
    LOOKUP_USER_DETAILS_SUCCESS: (state, { payload, meta }) => {
      return {
        ...state,
        userDetails: { ...state.userDetails, [meta.uid]: { ...payload, loading: false } },
      };
    },
  },
  initialState
);
