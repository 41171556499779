import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { IconButton } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { Footer } from "../../../components";
import { convertAnonAccount, createEmailAndPasswordLogin, joinBookViaUrl } from "../../../reducers/user-reducer";
import { Redirect, withRouter } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import { sportsbookShowSignup } from "../../../reducers/sportsbook-reducer";
import { getFirebase } from "react-redux-firebase";

class SignUp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      promos: false,
      gotReferral: false,
      needToCloseModal: true,
    };
  }

  _handTextChange = (e) => {
    switch (e.target.id) {
      case "email":
        this.setState({ email: e.target.value });
        break;
      case "password":
        this.setState({ password: e.target.value });
        break;
      default:
        console.log(
          "Uncaught onChange target->",
          e.target.id,
          "value ->",
          e.target.value,
          "checked ->",
          e.target.checked
        );
    }
  };

  signin = () => {
    this.props.history.push("/signin");
  };

  getGidFromName = async (name, bonusAmt) => {
    console.log("getting gid for: ", name);
    const db = getFirebase().firestore();
    return await db
      .collection("groups")
      .where("groupName", "==", name)
      .get()
      .then((res) => {
        this.props.dispatch(joinBookViaUrl({ bookName: name, bookGID: res.docs[0].id, bonusAmt: Number(bonusAmt) }));
      });
  };

  render() {
    // getFirebase().analytics().logEvent("HIT_SIGNUP");
    const { classes } = this.props;
    console.log("sign up props: ", this.props);
    console.log("sign up state: ", this.state);

    //redirect users who have just converted anon accounts
    if (this.props.firebase.auth.uid && this.props.firebase.profile.email !== "anon") {
      console.log("SIGNUP- user just converted anon account");
      return <Redirect to={"/sports"} />;
    }

    //user arrived via a referral link
    if (this.props.match && !this.state.gotReferral && this.props.match.params.bookName) {
      this.getGidFromName(this.props.match.params.bookName, this.props.match.params.bonusAmt);
      this.setState({ gotReferral: true });
      return <Redirect to={"/sports"} />;
    }

    //user arrived via manager-signup link
    if (this.props.match && this.props.location.pathname === "/manager-signup") {
      console.log("MANAGER! ");
      // this.getGidFromName(this.props.match.params.bookName,this.props.match.params.bonusAmt);
      // this.setState({ gotReferral: true });
      // return <Redirect to={"/admin"} />;
    }

    //dont remember what this is
    if (
      this.props.match &&
      !this.state.gotReferral &&
      this.props.match.params.bookName &&
      this.props.firebase.auth.email
    ) {
      this.getGidFromName(this.props.match.params.bookName, this.props.match.params.bonusAmt);
      this.setState({ gotReferral: true });
      return <Redirect to={"/sports"} />;
    }

    if (this.props.firebase.auth.isLoaded && !this.props.firebase.auth.isEmpty && !this.props.firebase.auth.uid) {
      console.log("any params?  ", this.props);
      if (this.state.needToCloseModal) {
        this.props.dispatch(sportsbookShowSignup(false));
        this.setState({ needToCloseModal: false });
      }
      return <Redirect to={"/sports"} />;
    }

    return (
      <Container component="main" maxWidth="xs">
        {/*<CssBaseline />*/}
        <div className={classes.paper}>
          {this.props.noFooter && (
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
              <IconButton
                aria-label="close"
                onClick={() => this.props.dispatch(sportsbookShowSignup(false))}
                size="large"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          )}
          <Typography component="h1" variant="h5">
            Super Fun Bet
          </Typography>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Create An Account
          </Typography>
          <form className={classes.form} noValidate>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  InputLabelProps={{ shrink: true }}
                  onChange={this._handTextChange}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  InputLabelProps={{ shrink: true }}
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  onChange={this._handTextChange}
                />
              </Grid>
            </Grid>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              onClick={() => {
                if (this.state.email && this.state.password) {
                  if (!this.props.firebase.auth.uid) {
                    this.props.dispatch(
                      createEmailAndPasswordLogin(this.state.email, this.state.password, this.props.firstVisitURL)
                    );
                  } else if (this.props.firebase.auth.uid) {
                    this.props.dispatch(
                      convertAnonAccount(this.state.email, this.state.password, this.props.firebase.auth.uid)
                    );
                  }
                } else {
                  alert("check the fields are properly formatted");
                }
              }}
            >
              Sign Up
            </Button>
            {this.props.user.signUpError && <p style={{ color: "red" }}>Error: {this.props.user.signUpError}</p>}
            <Button fullWidth variant="contained" color="green" className={classes.submit} onClick={this.signin}>
              Already have an account?
            </Button>
          </form>
          {/*<Typography onClick={() => this.props.history.push("/sports")}>View Sports</Typography>*/}
        </div>
        {(!this.props.noFooter && (
          <Box mt={8}>
            <Footer />
          </Box>
        )) || <Box style={{ marginBottom: 64 }} />}
      </Container>
    );
  }
}

SignUp.defaultProps = {
  noFooter: false,
};

SignUp.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  firebase: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  // user: PropTypes.shape({
  //   signUpError: PropTypes.any,
  //   referringBook: PropTypes.string
  // }),
  firstVisitURL: PropTypes.string,
  noFooter: PropTypes.bool,
  match: PropTypes.object,
  user: PropTypes.object,
};

const styles = (theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
});

const mapStateToProps = (state) => {
  return {
    user: state.userState,
    firstVisitURL: state.newUserState.initialPage,
  };
};

export default withRouter(connect(mapStateToProps)(withStyles(styles)(SignUp)));
