import ScrollToTop from "./navigation";

const changeJuice = (line, juice) => {
  if (line === null || line === undefined) {
    return "Off";
  }
  let impliedLine = 0;

  if (Math.abs(line) === 100) {
    impliedLine = 0.5;
  } else if (line < 0) {
    impliedLine = Math.abs(line) / (100 - line);
  } else if (line > 100) {
    impliedLine = 100 / (line + 100);
  }

  impliedLine *= 1.0 + juice * 0.01;
  if (impliedLine >= 0.99) {
    impliedLine = 0.99;
  }

  if (impliedLine >= 0.5) {
    return Math.floor((impliedLine / (1 - impliedLine)) * -100);
  } else {
    return Math.ceil(((1 - impliedLine) / impliedLine) * 100);
  }
};

export { ScrollToTop, changeJuice };
