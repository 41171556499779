import React, { Component } from "react";
import PropTypes from "prop-types";
import { compose } from "redux";
import { connect } from "react-redux";
import { firestoreConnect } from "react-redux-firebase";
import withStyles from "@mui/styles/withStyles";
import GenericBackHeader from "../../../components/GenericBackHeader";
import { BottomNav } from "../../../components";
import Box from "@mui/material/Box";
import collect from "collect.js";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import List from "@mui/material/List";
import ChangeUsername from "../components/ChangeUsername";
import Button from "@mui/material/Button";
import GavelIcon from "@mui/icons-material/Gavel";
import PromoLeaderboardTable from "./PromoLeaderboardTable";
import moment from "moment";

class PromoLeaderboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rulesOpen: false,
    };
  }

  calcScore(wins, losses) {
    let W = collect(wins);
    let L = collect(losses);
    return Number(W.unique().count() - L.unique().count());
  }

  renderScores() {
    const classes = this.props;
    let P = this.props.promoResults;
    let B = this.props.balances;
    return (
      Object.keys(B)
        .filter((i) => B[i])
        // .sort((x,y) => this.calcScore(P[x].winningTickets,P[x].losingTickets) < this.calcScore(P[y].winningTickets,P[y].losingTickets) ? 1 : -1 )
        .map((v) => {
          console.log("v: ", v);
          return (
            <TableRow key={v} className={v === this.props.uid ? this.props.classes.usersRow : ""}>
              <TableCell
                onClick={() => {
                  if (v === this.props.uid) this.setState({ changeUsernameDialogOpen: true });
                }}
                component="th"
                scope="row"
              >
                {P[v]?.username} {v === this.props.uid ? "(Click to change name)" : null}
              </TableCell>
              <TableCell align="right" component="th" scope="row">
                {B[v]}
                {/*{this.calcScore(P[v].winningTickets, P[v].losingTickets)}*/}
              </TableCell>
            </TableRow>
          );
        })
    );
  }

  renderScores2() {
    const classes = this.props;
    let P = this.props.promoResults;
    let B = this.props.balances;
    return (
      Object.keys(B)
        .filter((v) => B[v])
        // .sort((x,y) => this.calcScore(P[x].winningTickets,P[x].losingTickets) < this.calcScore(P[y].winningTickets,P[y].losingTickets) ? 1 : -1 )
        .map((v) => {
          console.log("v: ", v);
          return (
            <TableRow key={v}>
              <TableCell align="right" component="th" scope="row">
                {B[v]}
                {/*{this.calcScore(P[v].winningTickets, P[v].losingTickets)}*/}
              </TableCell>
            </TableRow>
          );
        })
    );
  }

  renderTable() {
    let B = this.props.balances;
    return (
      <TableContainer key={"bv"} style={{ paddingBottom: 30 }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell align={"center"} colSpan={2}>
                Leaderboard
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell />
              <TableCell align={"right"}>Score</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {Object.keys(B).map((v) => {
              console.log("V: ", v);
              return (
                <TableRow key={v}>
                  <TableCell align="right" component="th" scope="row">
                    {/*{v}*/}
                    {/*{this.calcScore(P[v].winningTickets, P[v].losingTickets)}*/}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  _closeChangeName = () => {
    this.setState({ changeUsernameDialogOpen: false });
  };

  renderLeaderboard() {
    console.log("Social > promo lb > this.props: ", this.props);
    return (
      <>
        <Box style={{ paddingBottom: 60 }}>
          <GenericBackHeader title={this.props.groupConfigs?.activePromo} showBackButton />

          {this.props.balances && this.props.promoResults && this.props.promoRules && this.renderTable()}

          <Dialog onBackdropClick={() => this.setState({ rulesOpen: false })} open={this.state.rulesOpen}>
            <DialogTitle>{this.props.groupConfigs.activePromo}</DialogTitle>
            <DialogContent dividers>
              <Typography gutterBottom>
                <u>Duration</u>
              </Typography>
              <Typography gutterBottom>
                {moment(this.props.promoRules[this.props.groupConfigs.activePromo].promoStart).format("MMM DD YYYY")} to{" "}
                {moment(this.props.promoRules[this.props.groupConfigs.activePromo].promoEnd).format("MMM DD YYYY")}
              </Typography>
              <Typography gutterBottom>
                <u>Scoring</u>
              </Typography>
              <Typography gutterBottom>+1 point for a winning bet</Typography>
              <Typography gutterBottom>-1 point for a losing bet</Typography>
              <Typography gutterBottom>
                <u>Rules</u>
              </Typography>
              <Typography gutterBottom>
                {this.props.promoRules[this.props.groupConfigs.activePromo].sportTitle
                  ? `* ${this.props.promoRules[this.props.groupConfigs.activePromo].sportTitle} bets only`
                  : "* Bet any sport"}
              </Typography>
              <Typography gutterBottom>* Spreads and totals only. You can bet both on the same game.</Typography>
              <Typography gutterBottom>* Moneyline, coinflip, and parlay bets do not count.</Typography>
              <Typography gutterBottom>* Bet amounts do not matter.</Typography>
              <Typography gutterBottom>
                * Only unique wins and losses count. Two wins (or losses) on the same bet only count once.
              </Typography>
              <Typography gutterBottom>* You can effectively cancel a bet by betting a penny the other way.</Typography>
              <Typography gutterBottom>* Coffee is for closers.</Typography>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={() => this.setState({ rulesOpen: false })}>
                Close
              </Button>
            </DialogActions>
          </Dialog>

          <ChangeUsername
            open={this.state.changeUsernameDialogOpen}
            closeCallback={this._closeChangeName}
            uid={this.props.uid}
            gid={this.props.profile.gid}
            email={this.props.profile.email}
            currentName={this.props.userBalanceDoc?.username}
          />

          {this.props.balances && this.props.profile && this.props.promoRules && this.props.groupConfigs && (
            <PromoLeaderboardTable
              balances={this.props.balances}
              profile={this.props.profile}
              promoRules={this.props.promoRules}
              groupConfigs={this.props.groupConfigs}
              currentName={this.props.userBalanceDoc?.username}
            />
          )}

          <List>
            {/*<ListItemButton*/}
            {/*                onClick={()=> {*/}
            {/*                  this.setState({changeUsernameDialogOpen: true})*/}
            {/*                }}*/}
            {/*>*/}
            {/*  <ListItemText primary={`Change Username (${this.props.userBalanceDoc?.username})`} secondary="It worked for Don Draper..." />*/}
            {/*  <ListItemSecondaryAction>*/}
            {/*    <ChevronRight />*/}
            {/*  </ListItemSecondaryAction>*/}
            {/*</ListItemButton>*/}

            <ListItemButton
              onClick={() => {
                this.setState({ rulesOpen: true });
              }}
            >
              <div style={{ padding: 10 }}>
                <GavelIcon />
              </div>
              <ListItemText primary={` Rules`} />
              <ListItemSecondaryAction></ListItemSecondaryAction>
            </ListItemButton>
          </List>
        </Box>
        <BottomNav activeTab={1} managerGid={this.props.profile.managerGid} />
      </>
    );
  }

  render() {
    if (this.props.balances && this.props.profile && this.props.promoRules && this.props.groupConfigs)
      return this.renderLeaderboard();
    else
      return (
        <>
          <Box>
            <GenericBackHeader title={"Back to Social"} showBackButton />

            <container>
              <h5>Promo Has Ended</h5>
            </container>
          </Box>
          <BottomNav activeTab={1} managerGid={this.props.profile.managerGid} />
        </>
      );
  }
}

PromoLeaderboard.propTypes = {
  classes: PropTypes.object.isRequired,
  profile: PropTypes.object,
  pastBets: PropTypes.object,
  balances: PropTypes.object,
  groupConfigs: PropTypes.object.isRequired,
  userBalanceDoc: PropTypes.object,
  promoRules: PropTypes.object,
  promoResults: PropTypes.object,
  email: PropTypes.string,
  uid: PropTypes.string,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    display: "inline-block",
    marginTop: "20px",
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  grid: {
    textAlign: "center",
    marginBottom: "20px",
  },
  usersRow: {
    backgroundColor: "#daf5c8",
  },
  tableCell: {
    "$hover:hover &": {
      color: "pink",
    },
  },
  hover: {},
});

const mapStateToProps = (state) => {
  const getUserBalanceDoc = (obj) => {
    if (state.firebase.auth.uid && state.firestore.data.social_promos_balances) return obj[state.firebase.auth.uid];
  };

  const getPromoResults = () => {
    if (state.firestore.data?.social_promosRules && state.firestore.data?.social_groupConfigs)
      return state.firestore.data?.social_promos[state.firestore.data?.social_groupConfigs.activePromo]["results"];
  };

  return {
    profile: state.firebase.profile,
    uid: state.firebase.auth.uid,
    email: state.firebase.auth.email,
    groupConfigs: state.firestore.data?.social_groupConfigs,
    userBalanceDoc: getUserBalanceDoc(state.firestore.data?.social_promosBalances),
    promoRules: state.firestore.data?.social_promosRules,
    promoResults: state.firestore.data?.social_promoResults,
    balances: state.firestore.data?.social_userBalances,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: `groups`,
        doc: props.profile.gid,
        storeAs: "social_groupConfigs",
      },
      {
        collection: `groups/${props.profile.gid}/promos/`,
        storeAs: "social_promosRules",
      },
      {
        collection: `groups/${props.profile.gid}/balances`,
        storeAs: "social_userBalances",
      },
    ];
  })
)(withStyles(styles)(PromoLeaderboard));

// const filterPublicBets = (allBets) => {
//   if (allBets && state.firestore.data.socialGroupConfigs.publicBettors) {
//     let publicUsers = state.firestore.data.socialGroupConfigs.publicBettors;
//     let filtered;
//     filtered = Object.keys(allBets)
//         .filter((key) => publicUsers.includes(allBets[key].uid))
//         .reduce((obj, key) => {
//           return {
//             ...obj,
//             [key]: allBets[key],
//           };
//         }, {});
//     return filtered;
//   } else return {};
// };
// const filterSocialUIDs = (balances) => {
//   if (balances && state.firestore.data.socialGroupConfigs.publicBettors) {
//     let publicUsers = state.firestore.data.socialGroupConfigs?.publicBettors;
//     let filtered;
//     filtered = Object.keys(balances)
//         .filter((key) =>
//             //console.log("key is: ",key)
//             publicUsers.includes(key)
//         )
//         .reduce((obj, key) => {
//           return {
//             ...obj,
//             [key]: balances[key],
//           };
//         }, {});
//     return filtered;
//   }
// };
