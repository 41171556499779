import React from "react";
import { Grid, ListItemIcon, ListItemText } from "@mui/material";
import Typography from "@mui/material/Typography";
import ListItem from "@mui/material/ListItem";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import moment from "moment";
import { ordinal_suffix_of } from "../../../utilities/pickemUtils";

function TournamentListItem(props) {
  let history = useHistory();

  return (
    <ListItem
      key={props.tournament.id}
      sx={{
        border: 1,
        borderColor: "#bf0070",
        borderRadius: 2,
        marginBottom: 2,
      }}
      alignItems="flex-start"
      onClick={() => history.push("/pickem/tournament/" + props.tournament.id)}
    >
      <ListItemIcon sx={{ color: "#bf0070" }}>
        <EmojiEventsIcon />
      </ListItemIcon>
      <ListItemText
        primary={
          <>
            <Typography component="span" variant="body1" color="text.primary" sx={{ fontWeight: 800 }}>
              {props.tournament.tournamentName}
            </Typography>
            <br />
            <Typography component="span" variant="body2" color="text.primary">
              {props.tournament.numEntries}/{props.tournament.maxEntries} Entries
            </Typography>
            <br />
            {!props.tournament.hasEtarted && (
              <Typography component="span" variant="body2" color="text.primary">
                Start: {moment(props.tournament.startTime).calendar()}
              </Typography>
            )}
            {props.tournament.hasStarted && (
              <Typography component="span" variant="body2" color="text.primary">
                Tournament has started
              </Typography>
            )}
          </>
        }
        secondary={
          <>
            {props.tournament.prizes && (
              <Grid container margin={2} padding={1} spacing={1}>
                {props.tournament.prizes.map((prize) => (
                  <Grid key={prize.id} item xs={12}>
                    <Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
                      <Grid item xs={6} sm={3} md={2}>
                        <Typography component="span" variant="body1" color="text.primary">
                          {ordinal_suffix_of(prize.id)} Place:
                        </Typography>
                      </Grid>
                      <Grid item xs={6} sm={3} md={2}>
                        <Typography component="span" variant="body1" color="text.primary">
                          {prize.prize}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            )}
            <Typography
              align="right"
              component="span"
              variant="caption"
              color="text.note"
              sx={{ fontSize: 8, paddingTop: 1, margin: 0 }}
            >
              {props.tournament.id}
            </Typography>
          </>
        }
      />
    </ListItem>
  );
}

TournamentListItem.propTypes = {
  tournament: PropTypes.object.isRequired,
};

export { TournamentListItem };
