import { ListItemButton, ListItemSecondaryAction, ListItemText } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { firestoreConnect, getFirebase } from "react-redux-firebase";
import { compose } from "redux";
import { withRouter } from "react-router-dom";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import { ChevronRight } from "@mui/icons-material";
import JoinPublicBook from "./components/JoinPublicBook";
import JoinPrivateBook from "./components/JoinPrivateBook";
import GenericBackHeader from "../../../components/GenericBackHeader";

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      regGoal: "",
      newBookName: "",
      groupToJoin: "",
      groupToJoinPin: "",
      joinPublicBookOpen: false,
      joinPrivateBookOpen: false,
    };
  }

  checkName(name) {
    const db = getFirebase().firestore();
    let okToCreate = true;
    db.collection("groups")
      .where("groupName", "==", name)
      .get()
      .then((res) => {
        res.forEach((doc) => {
          if (doc.id) {
            okToCreate = false;
            alert("sorry this name is already taken!");
          }
        });
      });
    return okToCreate;
  }

  confirmGroupExists(name) {
    let gid;
    Object.entries(this.props.allGroups).forEach((obj) => {
      if (obj[1].groupName === name) {
        gid = obj[0];
      }
    });
    return gid;
  }

  _closeJoinPublicBook = () => {
    this.setState({ joinPublicBookOpen: false });
  };
  _closeJoinPrivateBook = () => {
    this.setState({ joinPrivateBookOpen: false });
  };

  render() {
    console.log("switch group registration props: ", this.props);
    console.log("switch group registration state: ", this.state);
    return (
      <>
        <GenericBackHeader title="Switch Sportsbook" showBackButton />
        <Box>
          <List>
            <ListItemButton divider>
              <ListItemText
                primary={"Current Sportsbook: " + this.props.currentGroup?.groupName}
                secondary={"Your balance, bet history, etc will not change if you leave and come back"}
              />
            </ListItemButton>

            <ListItemButton
                divider
                onClick={() => {
                  this.setState({ joinPublicBookOpen: true });
                }}
            >
              <ListItemText primary="Switch Books" secondary={"somewhere you've already bet"} />
              <ListItemSecondaryAction>
                <ChevronRight />
              </ListItemSecondaryAction>
            </ListItemButton>


            <ListItemButton
              divider
              onClick={() => {
                this.setState({ joinPrivateBookOpen: true });
              }}
            >
              <ListItemText primary="Join A New Sportsbook" secondary={"you only need the book's name"} />
              <ListItemSecondaryAction>
                <ChevronRight />
              </ListItemSecondaryAction>
            </ListItemButton>



          </List>

          <JoinPublicBook
            open={this.state.joinPublicBookOpen}
            closeCallback={this._closeJoinPublicBook}
            uid={this.props.uid}
            currentGroup={this.props.currentGroup}
            profile={this.props.profile}
            allGroups={this.props.allGroups}
            email={this.props.email}
          />

          <JoinPrivateBook
            open={this.state.joinPrivateBookOpen}
            closeCallback={this._closeJoinPrivateBook}
            uid={this.props.uid}
            currentGroup={this.props.currentGroup}
            allGroups={this.props.allGroups}
            email={this.props.email}
          />
        </Box>
        {/*<BottomNav activeTab={2} managerGid={this.props.profile.managerGid} />*/}
      </>
    );
  }
}

Register.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  email: PropTypes.string,
  uid: PropTypes.string,
  currentGroup: PropTypes.object,
  allGroups: PropTypes.object,
  profile: PropTypes.object,
  history: PropTypes.array,
};

const styles = (theme) => ({
  paper: {
    padding: theme.spacing(1),
    margin: 2,
    fontSize: ".85rem",
    textAlign: "center",
    cursor: "pointer",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  one: {
    background: theme.palette.primary.light,
    ...theme.palette.custom.betItemText,
  },
  two: {
    background: theme.palette.primary.dark,
    ...theme.palette.custom.betItemText,
  },
});

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    email: state.firebase.auth.email,
    profile: state.firebase.profile,
    currentGroup: state.firestore.data.switchBooksCurrentGroup,
    allGroups: state.firestore.data.switchBooksAllGroups,
  };
};

export default compose(
  withRouter,
  connect(mapStateToProps),
  firestoreConnect((props) => {
    return [
      {
        collection: "groups",
        doc: props.profile.gid,
        storeAs: "switchBooksCurrentGroup",
      },
      {
        collection: "groups",
        storeAs: "switchBooksAllGroups",
      },
    ];
  })
)(withStyles(styles)(Register));
