import React, { Component } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import BetItem2 from "../../BetItem2";
import CoinFlipBetItem2 from "../../CoinFlipBetItem2";
import { renderGametime } from "../../../../utilities/lineUtilities";
import { connect } from "react-redux";
import withStyles from "@mui/styles/withStyles";
import Button from "@mui/material/Button";
import PropLines from "../../PropLines";
// import PropLines from "../../PropLines";
// import Button from "@mui/material/Button";

class LineStandard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPropBets: false,
    };
  }

  _getLastWord = (str) => {
    const n = str.split(" ");
    return n[n.length - 1];
  };

  _formatMoneyLine(moneyline) {
    if (!moneyline || moneyline === Infinity) return "Off";
    if (moneyline > 0) {
      return "+" + moneyline.toString();
    } else {
      return moneyline.toString();
    }
  }

  _formatHandicap = (handicap, side) => {
    if (side === "home") {
      return this._formatMoneyLine(handicap);
    }
    if (side === "away") {
      return this._formatMoneyLine(-1 * handicap);
    }
    return null;
  };

  _renderMoneyLineCoinflip = () => {
    const size = this.props.options.onlyMoneylineNoDraw ? 12 : 6;
    return (
      <Grid item container xs={size} direction="column" alignItems="center" width="100%">
        <Grid xs={12} item width="100%">
          <CoinFlipBetItem2
            flipOptions={[
              {
                displayName: this.props.lineData.away,
                betSide: "away",
                wheelName: this._getLastWord(this.props.lineData.away),
              },
              {
                displayName: this.props.lineData.home,
                betSide: "home",
                wheelName: this._getLastWord(this.props.lineData.home),
              },
            ]}
            coinflipJuice={this.props.coinflipJuice}
            betPeriod={this.props.betPeriod}
            bucket={this.props.sportsBucket}
            collection="futures"
            docId={this.props.lineID}
            betType="ml"
            game={this.props.lineData}
            lineSource={this.props.lineSource}
          />
        </Grid>
      </Grid>
    );
  };

  _renderMoneyLine = (renderAsCoinflip) => {
    if (
      (this.props.lineData.standard && this.props.lineData.standard[this.props.betPeriod]?.moneyline) ||
      this.props.coinflip
    ) {
      if (this.props.coinflip || renderAsCoinflip) {
        return this._renderMoneyLineCoinflip();
      } else {
        if (this.props.options.onlyMoneylineNoDraw) {
          return (
            <Grid item container xs={12} direction="row" alignItems="stretch">
              <Grid item xs={12} sm={12}>
                <Grid item container xs={12} direction="column" alignItems="center">
                  <Grid xs={12} item width="100%">
                    <BetItem2
                      isLive={this.props.lineData.is_live}
                      isHalf={this.props.lineData.is_half}
                      price={this.props.lineData.standard[this.props.betPeriod]?.moneyline?.away}
                      rowColor={"one"}
                      betPeriod={this.props.betPeriod}
                      juice={this.props.juice}
                      coinflipJuice={this.props.coinflipJuice}
                      label={this.props.lineData.away}
                      bucket={this.props.sportsBucket}
                      collection="futures"
                      docId={this.props.lineID}
                      betType="ml"
                      betSide="away"
                      lineSource={this.props.lineSource}
                    />
                  </Grid>
                  <Grid xs={12} item width="100%">
                    <BetItem2
                      isLive={this.props.lineData.is_live}
                      isHalf={this.props.lineData.is_half}
                      price={this.props.lineData.standard[this.props.betPeriod]?.moneyline?.home}
                      rowColor={"two"}
                      betPeriod={this.props.betPeriod}
                      juice={this.props.juice}
                      coinflipJuice={this.props.coinflipJuice}
                      label={this.props.lineData.home}
                      bucket={this.props.sportsBucket}
                      collection="futures"
                      docId={this.props.lineID}
                      betType="ml"
                      betSide="home"
                      lineSource={this.props.lineSource}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          );
        }

        if (this.props.options.onlyMoneyline) {
          return (
            <Grid item container xs={12} direction="row" alignItems="stretch">
              <Grid item xs={8} sm={10}>
                <Grid item container xs={12} direction="column" alignItems="center">
                  <Grid xs={12} item width="100%">
                    <BetItem2
                      isLive={this.props.lineData.is_live}
                      isHalf={this.props.lineData.is_half}
                      price={this.props.lineData.standard[this.props.betPeriod]?.moneyline?.away}
                      rowColor={"one"}
                      betPeriod={this.props.betPeriod}
                      juice={this.props.juice}
                      coinflipJuice={this.props.coinflipJuice}
                      label={this.props.lineData.away}
                      bucket={this.props.sportsBucket}
                      collection="futures"
                      docId={this.props.lineID}
                      betType="ml"
                      betSide="away"
                      lineSource={this.props.lineSource}
                    />
                  </Grid>
                  <Grid xs={12} item width="100%">
                    <BetItem2
                      isLive={this.props.lineData.is_live}
                      isHalf={this.props.lineData.is_half}
                      price={this.props.lineData.standard[this.props.betPeriod]?.moneyline?.home}
                      rowColor={"two"}
                      betPeriod={this.props.betPeriod}
                      juice={this.props.juice}
                      coinflipJuice={this.props.coinflipJuice}
                      label={this.props.lineData.home}
                      bucket={this.props.sportsBucket}
                      collection="futures"
                      docId={this.props.lineID}
                      betType="ml"
                      betSide="home"
                      lineSource={this.props.lineSource}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4} sm={2} sx={{ paddingBottom: 0.5 }}>
                <BetItem2
                  isLive={this.props.lineData.is_live}
                  isHalf={this.props.lineData.is_half}
                  price={this.props.lineData.standard[this.props.betPeriod]?.moneyline?.draw}
                  rowColor={"draw"}
                  betPeriod={this.props.betPeriod}
                  juice={this.props.juice}
                  coinflipJuice={this.props.coinflipJuice}
                  label="Draw"
                  bucket={this.props.sportsBucket}
                  collection="futures"
                  docId={this.props.lineID}
                  betType="ml"
                  betSide="draw"
                  lineSource={this.props.lineSource}
                />
              </Grid>
            </Grid>
          );
        }
        return (
          <Grid item container xs={6} direction="column" alignItems="center">
            <Grid xs={12} item width="100%">
              <BetItem2
                isLive={this.props.lineData.is_live}
                isHalf={this.props.lineData.is_half}
                price={this.props.lineData.standard[this.props.betPeriod]?.moneyline?.away}
                rowColor={"one"}
                betPeriod={this.props.betPeriod}
                juice={this.props.juice}
                coinflipJuice={this.props.coinflipJuice}
                label={this.props.lineData.away}
                score={this.props.lineData.score?.away}
                bucket={this.props.sportsBucket}
                collection="futures"
                docId={this.props.lineID}
                betType="ml"
                betSide="away"
                lineSource={this.props.lineSource}
              />
            </Grid>
            <Grid xs={12} item width="100%">
              <BetItem2
                isLive={this.props.lineData.is_live}
                isHalf={this.props.lineData.is_half}
                price={this.props.lineData.standard[this.props.betPeriod]?.moneyline?.home}
                rowColor={"two"}
                betPeriod={this.props.betPeriod}
                juice={this.props.juice}
                coinflipJuice={this.props.coinflipJuice}
                label={this.props.lineData.home}
                score={this.props.lineData.score?.home}
                bucket={this.props.sportsBucket}
                collection="futures"
                docId={this.props.lineID}
                betType="ml"
                betSide="home"
                lineSource={this.props.lineSource}
              />
            </Grid>
          </Grid>
        );
      }
    }
    if (this.props.coinflip || renderAsCoinflip) {
      return this._renderMoneyLineCoinflip();
    }
    return (
      <Grid item container xs={6} direction="column" alignItems="center">
        <Grid xs={12} item width="100%">
          <BetItem2
            isLive={this.props.lineData.event_type === "live" || this.props.lineData.is_live}
            price={null}
            rowColor={"one"}
            betPeriod={this.props.betPeriod}
            juice={this.props.juice}
            coinflipJuice={this.props.coinflipJuice}
            label={this.props.lineData.away}
            bucket={this.props.sportsBucket}
            collection="futures"
            docId={this.props.lineID}
            betType="ml"
            betSide="away"
            lineSource={this.props.lineSource}
          />
        </Grid>
        <Grid xs={12} item width="100%">
          <BetItem2
            isLive={this.props.lineData.event_type === "live" || this.props.lineData.is_live}
            price={null}
            rowColor={"two"}
            betPeriod={this.props.betPeriod}
            juice={this.props.juice}
            coinflipJuice={this.props.coinflipJuice}
            label={this.props.lineData.home}
            bucket={this.props.sportsBucket}
            collection="futures"
            docId={this.props.lineID}
            betType="ml"
            betSide="home"
            lineSource={this.props.lineSource}
          />
        </Grid>
      </Grid>
    );
  };

  _renderSpreads = (renderAsCoinflip) => {
    const spreadID = this.props.lineData.standard[this.props.betPeriod]?.spread?.points;
    if (spreadID) {
      if (this.props.coinflip || renderAsCoinflip) {
        return (
          <Grid item container xs={3} direction="column" alignItems="center" width="100%">
            <Grid xs={12} item width="100%">
              <CoinFlipBetItem2
                flipOptions={[
                  {
                    displayName: this._formatHandicap(
                      this.props.lineData.standard[this.props.betPeriod]?.spread.points,
                      "away"
                    ),
                    wheelName:
                      this._getLastWord(this.props.lineData.away) +
                      " " +
                      this._formatHandicap(this.props.lineData.standard[this.props.betPeriod]?.spread.points, "away"),
                    betSide: "away",
                  },
                  {
                    displayName: this._formatHandicap(
                      this.props.lineData.standard[this.props.betPeriod]?.spread.points,
                      "home"
                    ),
                    wheelName:
                      this._getLastWord(this.props.lineData.home) +
                      " " +
                      this._formatHandicap(this.props.lineData.standard[this.props.betPeriod]?.spread.points, "home"),
                    betSide: "home",
                  },
                ]}
                coinflipJuice={this.props.coinflipJuice}
                betPeriod={this.props.betPeriod}
                bucket={this.props.sportsBucket}
                collection="futures"
                docId={this.props.lineID}
                betType="spread"
                game={this.props.lineData}
                lineSource={this.props.lineSource}
              />
            </Grid>
          </Grid>
        );
      }
      return (
        <Grid item container xs={3} direction="column" alignItems="center">
          <Grid xs={12} item width="100%">
            <BetItem2
              isLive={this.props.lineData.is_live}
              isHalf={this.props.lineData.is_half}
              price={this.props.lineData.standard[this.props.betPeriod]?.spread?.away}
              number={-1 * this.props.lineData.standard[this.props.betPeriod]?.spread.points}
              betPeriod={this.props.betPeriod}
              rowColor={"one"}
              juice={this.props.juice}
              coinflipJuice={this.props.coinflipJuice}
              label={this._formatHandicap(this.props.lineData.standard[this.props.betPeriod]?.spread.points, "away")}
              bucket={this.props.sportsBucket}
              collection="futures"
              docId={this.props.lineID}
              betType="spread"
              betSide="away"
              lineSource={this.props.lineSource}
            />
          </Grid>
          <Grid xs={12} item width="100%">
            <BetItem2
              isLive={this.props.lineData.is_live}
              isHalf={this.props.lineData.is_half}
              price={this.props.lineData.standard[this.props.betPeriod]?.spread?.home}
              number={this.props.lineData.standard[this.props.betPeriod]?.spread.points}
              betPeriod={this.props.betPeriod}
              rowColor={"two"}
              juice={this.props.juice}
              coinflipJuice={this.props.coinflipJuice}
              label={this._formatHandicap(this.props.lineData.standard[this.props.betPeriod]?.spread.points, "home")}
              bucket={this.props.sportsBucket}
              collection="futures"
              docId={this.props.lineID}
              betType="spread"
              betSide="home"
              lineSource={this.props.lineSource}
            />
          </Grid>
        </Grid>
      );
    }

    if (this.props.coinflip || renderAsCoinflip) {
      return (
        <Grid item container xs={3} direction="column" alignItems="center">
          <Grid item container direction="column" alignItems="center" width="100%">
            <Grid xs={12} item width="100%">
              <CoinFlipBetItem2
                flipOptions={[
                  {
                    displayName: "Off",
                  },
                  {
                    displayName: "Off",
                  },
                ]}
                coinflipJuice={this.props.coinflipJuice}
                disable={true}
                lineSource={this.props.lineSource}
                docId={false}
              />
            </Grid>
          </Grid>
        </Grid>
      );
    }

    return (
      <Grid item container xs={3} direction="column" alignItems="center">
        <Grid xs={12} item width="100%">
          <BetItem2
            isLive={this.props.lineData.is_live}
            isHalf={this.props.lineData.is_half}
            price={null}
            rowColor={"one"}
            betPeriod={this.props.betPeriod}
            bucket={this.props.sportsBucket}
            collection="futures"
            docId={this.props.lineID}
            betType="spread"
            betSide="home"
            label="Off"
            lineSource={this.props.lineSource}
          />
        </Grid>
        <Grid xs={12} item width="100%">
          <BetItem2
            isLive={this.props.lineData.is_live}
            isHalf={this.props.lineData.is_half}
            price={null}
            rowColor={"two"}
            betPeriod={this.props.betPeriod}
            bucket={this.props.sportsBucket}
            collection="futures"
            docId={this.props.lineID}
            betType="spread"
            betSide="away"
            label="Off"
            lineSource={this.props.lineSource}
          />
        </Grid>
      </Grid>
    );
  };

  _renderOverUnder = (renderAsCoinflip) => {
    const overUnderID = this.props.lineData.standard[this.props.betPeriod]?.total?.points;
    if (overUnderID) {
      if (this.props.coinflip || renderAsCoinflip) {
        return (
          <Grid
            item
            container
            xs={this.props.options.disableSpreads ? 6 : 3}
            direction="column"
            alignItems="center"
            width="100%"
          >
            <Grid xs={12} item width="100%">
              <CoinFlipBetItem2
                flipOptions={[
                  {
                    displayName: "o" + this.props.lineData.standard[this.props.betPeriod]?.total?.points,
                    betSide: "over",
                  },
                  {
                    displayName: "u" + this.props.lineData.standard[this.props.betPeriod]?.total?.points,
                    betSide: "under",
                  },
                ]}
                coinflipJuice={this.props.coinflipJuice}
                betPeriod={this.props.betPeriod}
                bucket={this.props.sportsBucket}
                collection="futures"
                docId={this.props.lineID}
                betType="ou"
                game={this.props.lineData}
                lineSource={this.props.lineSource}
              />
            </Grid>
          </Grid>
        );
      }
      return (
        <Grid item container xs={this.props.options.disableSpreads ? 6 : 3} direction="column" alignItems="center">
          <Grid xs={12} item width="100%">
            <BetItem2
              isLive={this.props.lineData.is_live}
              isHalf={this.props.lineData.is_half}
              price={this.props.lineData.standard[this.props.betPeriod]?.total?.over}
              number={this.props.lineData.standard[this.props.betPeriod]?.total?.points}
              betPeriod={this.props.betPeriod}
              rowColor={"one"}
              juice={this.props.juice}
              coinflipJuice={this.props.coinflipJuice}
              label={"o" + this.props.lineData.standard[this.props.betPeriod]?.total?.points}
              bucket={this.props.sportsBucket}
              collection="futures"
              docId={this.props.lineID}
              betType="ou"
              betSide="over"
              lineSource={this.props.lineSource}
            />
          </Grid>
          <Grid xs={12} item width="100%">
            <BetItem2
              isLive={this.props.lineData.is_live}
              isHalf={this.props.lineData.is_half}
              price={this.props.lineData.standard[this.props.betPeriod]?.total?.under}
              number={-1 * this.props.lineData.standard[this.props.betPeriod]?.total?.points}
              betPeriod={this.props.betPeriod}
              rowColor={"two"}
              juice={this.props.juice}
              coinflipJuice={this.props.coinflipJuice}
              label={"u" + this.props.lineData.standard[this.props.betPeriod]?.total?.points}
              bucket={this.props.sportsBucket}
              collection="futures"
              docId={this.props.lineID}
              betType="ou"
              betSide="under"
              lineSource={this.props.lineSource}
            />
          </Grid>
        </Grid>
      );
    }
    if (this.props.coinflip || renderAsCoinflip) {
      return (
        <Grid
          item
          container
          xs={this.props.options.disableSpreads ? 6 : 3}
          direction="column"
          alignItems="center"
          width="100%"
        >
          <Grid xs={12} item width="100%">
            <CoinFlipBetItem2
              flipOptions={[
                {
                  displayName: "Off",
                },
                {
                  displayName: "Off",
                },
              ]}
              coinflipJuice={this.props.coinflipJuice}
              disable={true}
              lineSource={this.props.lineSource}
              docId={false}
            />
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid item container xs={this.props.options.disableSpreads ? 6 : 3} direction="column" alignItems="center">
        <Grid xs={12} item width="100%">
          <BetItem2
            isLive={this.props.lineData.is_live}
            isHalf={this.props.lineData.is_half}
            price={null}
            rowColor={"one"}
            betPeriod={this.props.betPeriod}
            label="Off"
            bucket={this.props.sportsBucket}
            collection="futures"
            docId={this.props.lineID}
            betType="ou"
            betSide="over"
            lineSource={this.props.lineSource}
          />
        </Grid>
        <Grid xs={12} item width="100%">
          <BetItem2
            isLive={this.props.lineData.is_live}
            isHalf={this.props.lineData.is_half}
            price={null}
            rowColor={"two"}
            betPeriod={this.props.betPeriod}
            label="Off"
            bucket={this.props.sportsBucket}
            collection="futures"
            docId={this.props.lineID}
            betType="ou"
            betSide="under"
            lineSource={this.props.lineSource}
          />
        </Grid>
      </Grid>
    );
  };

  _renderPeriod = (renderAsCoinflip) => {
    return (
      <Grid container item xs={12} direction="row" alignItems="center" justifyContent="space-evenly" spacing={0}>
        {this._renderMoneyLine(renderAsCoinflip)}
        {!this.props.options.disableSpreads &&
          !this.props.options.onlyMoneyline &&
          this._renderSpreads(renderAsCoinflip)}
        {!this.props.options.onlyMoneyline && this._renderOverUnder(renderAsCoinflip)}
      </Grid>
    );
  };

  render() {
    console.log("!!! LineStandard props", this.props);
    // console.log("!!! LineStandard state", this.state,  this.props.lineData?.home);
    // const minutesSinceStart = (Date.now() - this.props.lineData?.startsMS) / 1000 / 60;
    // const whitelist = this.props.liveWhitelist?.teams;
    const blacklist = this.props.liveBlacklist?.teams;
    // if (this.props.lineData?.home.includes(this.props.liveWhitelist?.teams[0])) {
    //     console.log("nova game minutes since start: ",minutesSinceStart)
    // }
    const hoursUntilGameStarts = (this.props.lineData.starts_ms - Date.now())/3600000
      console.log("hoursUntilGameStarts: ", hoursUntilGameStarts);

    // nfl has been showing up long after game ended
    if (hoursUntilGameStarts < -4)
        return null;

      if (
      this.props.lineData?.home.includes(" Runs ") ||
      this.props.lineData?.away.includes(" Runs ") ||
      this.props.lineData?.home.includes(" Goals ") ||
      this.props.lineData?.away.includes(" Goals ")
    ) {
      console.log("!!! Eph null on 1");
      return null;
    }
    if (!this.props.lineData || this.props.lineData?.category === "Futures") {
      console.log("!!! Eph null on 2");
      return null;
    }
    // if (this.props.lineData?.event_type === "live") {
    //   return null;
    // }
    if (
      this.props.searchTerm.length > 1 &&
      !this.props.lineData?.home.toLowerCase().includes(this.props.searchTerm) &&
      !this.props.lineData?.away.toLowerCase().includes(this.props.searchTerm)
    ) {
      console.log("!!! Eph null on 3");
      return null;
    }
    if (this.props.lineData?.home.includes("(To Qualify)") || this.props.lineData?.away.includes("(To Qualify)")) {
      console.log("!!! Eph null on 4");
      return null;
    }
    if (this.props.betPeriod === "1h" && this.props.lineData.is_live) {
      return null;
    }

    for (const i in blacklist) {
      const BB = blacklist[i];
      if (this.props.lineData?.home.includes(BB) || this.props.lineData?.away.includes(BB)) {
        console.log("!!! Eph null on 5");
        return null;
      }
    }

    let renderAsCoinflip = false;
    if (this.props.lineData?.event_type === "live" && !this.props.coinflip) {
      // console.log("Allow? ", this.props.lineData)
      // let allow = false;
      // if (whitelist) {
      //   whitelist.forEach((i) => {
      //     if (this.props.lineData.home.includes(i)) {
      //       allow = true;
      //     }
      //   });
      // }
      // if (allow) console.log("ALLOW DUE TO WHITELIST");
      // else {
      renderAsCoinflip = true;
      // }
    }

    if (this.props.lineData?.origin === "oddsjam" && this.props.lineData?.is_live && !this.props.lineData?.is_half) {
      renderAsCoinflip = true;
    }

    // const oldLineSelector =
    //   (((this.props.betPeriod === "num_0" || this.props.betPeriod === "num_1") &&
    //       this.props.lineData.periods[this.props.betPeriod]?.cutoffMs > Date.now()) ||
    //     this.props.betPeriod === "num_2") &&
    //   (this.props.lineData?.periods[this.props.betPeriod]?.money_line ||
    //     this.props.lineData?.periods[this.props.betPeriod]?.spreads ||
    //     this.props.lineData?.periods[this.props.betPeriod]?.totals);

    const newLineSelector =
      this.props.lineData.standard &&
      this.props.lineData.standard[this.props.betPeriod] &&
      this.props.lineData.standard[this.props.betPeriod]?.cutoffMs > Date.now();

    if (newLineSelector) {
      // if (this.props.sportsBucket === "NCAA-FB-OJ" || this.props.sportsBucket === "NFL-OJ") {
      //   console.log("!!! Rendering LineStandard", this.props.lineData);
      // }
      return (
        <>
          <Grid
            item
            xs={12}
            key={this.props.lineData?.event_id}
            width="100%"
            sx={{ paddingBottom: 0.8, background: "#ffffff" }}
          >
            {this._renderPeriod(renderAsCoinflip)}
            <Grid container justifyContent="space-between">
              <Grid sx={{ padding: 0, paddingLeft: 1, paddingTop: 0.5 }}>
                {renderGametime(
                  this.props.lineData.starts_ms,
                  this.props.lineData.event_type || (this.props.lineData.is_live ? "live" : "prematch")
                )}
                {this.props.lineData.is_half && (
                  <small style={{ fontSize: ".65rem", textAlign: "left", float: "left", color: "#888888" }}>
                    &nbsp;@&nbsp;<font style={{ color: "#00721e", fontWeight: 700 }}>HALFTIME</font>
                  </small>
                )}
              </Grid>
              {!this.props.isLive && this.props.betPeriod === "ft" && !this.props.coinflip && this.props.lineData.standard?.props?.length > 0 && (
                <Grid>
                  <small
                    style={{
                      fontSize: ".65rem",
                      textAlign: "left",
                      float: "left",
                      color: "#888888",
                      marginRight: "4px",
                      padding: 0,
                      paddingTop: "4px",
                    }}
                  >
                    <Button
                      size={"small"}
                      sx={{ padding: 0, marginRight: 1, fontSize: ".7rem" }}
                      onClick={() => this.setState({ showPropBets: !this.state.showPropBets })}
                      // href={"/sports/props/"+this.props.lineData.event_id}
                      color={"secondary"}
                    >
                      {this.state.showPropBets ? "Hide" : "Show"} Player Props
                    </Button>
                  </small>
                </Grid>
              )}
            </Grid>
            {/*</Paper>*/}
          </Grid>
          <PropLines
            event_id={this.props.lineData.event_id}
            bucket={this.props.sportsBucket}
            expanded={this.state.showPropBets && this.props.betPeriod === "ft" && !this.props.isLive && !this.props.coinflip}
            lineData={this.props.lineData.standard.props}
            home={this.props.lineData.home}
            away={this.props.lineData.away}
          />
            { this.state.showPropBets &&
                <Grid>
                    <small
                        style={{
                            fontSize: ".65rem",
                            textAlign: "left",
                            float: "left",
                            color: "#888888",
                            marginRight: "4px",
                            padding: 0,
                            paddingTop: "4px",
                        }}
                    >
                        <Button
                            size={"small"}
                            sx={{ margin: 2, padding: 0, fontSize: ".7rem" }}
                            onClick={() => this.setState({ showPropBets: !this.state.showPropBets })}
                            // href={"/sports/props/"+this.props.lineData.event_id}
                            color={"secondary"}
                        >
                            Hide Player Props
                        </Button>
                    </small>
                </Grid>
            }

        </>
      );
    }

    return null;
  }
}

LineStandard.defaultProps = {
  isLive: false,
  options: {},
};

LineStandard.propTypes = {
  isLive: PropTypes.bool.isRequired,
  lineID: PropTypes.string.isRequired,
  lineData: PropTypes.object.isRequired,
  // liveWhitelist: PropTypes.object,
  liveBlacklist: PropTypes.object,
  betPeriod: PropTypes.string.isRequired,
  sportsBucket: PropTypes.string.isRequired,
  juice: PropTypes.number.isRequired,
  coinflipJuice: PropTypes.number.isRequired,
  searchTerm: PropTypes.string,
  email: PropTypes.string,
  coinflip: PropTypes.bool.isRequired,
  options: PropTypes.object.isRequired,
  lineSource: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    email: state.firebase.auth.email,
  };
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
});

export default connect(mapStateToProps)(withStyles(styles)(LineStandard));
