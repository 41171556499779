import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { alpha, Box, Dialog, DialogContent, DialogTitle, TextField } from "@mui/material";
import IMAGE_hero from "../../../images/Football_transparent.png";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { createAnonAccount } from "../../Auth/functions/createAccount";
import { Redirect, withRouter } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import Link from "@mui/material/Link";


function Texfield() {
  return null;
}

class CTA extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      friendDialogOpen: false,
    };
  }



  openFriendDialog = () => {
    this.setState({ friendDialogOpen: true });
  };
  closeFriendDialog = () => {
    this.setState({ friendDialogOpen: false });
  };
  goToAboutUs = () => {
    console.log("GO TO ABOU TUS")
    document.getElementById('aboutus').scrollIntoView()
  }
  renderCTA = () => {
    const { classes } = this.props;
    const description =
        "Super Fun Bet lets you be the bookie for your friends.  Create your own private sportsbook.  No fees, no risk," +
        " no download.  Just name your book!";
    return (
        <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="stretch"
            sx={{
              height: "80vh",
              // flexGrow: 1,
              // backgroundColor: "rgba(255,232,103,0.5)",
              background: "linear-gradient(#ffffff, #FFE86780)",
            }}
        >
          <Grid item xs={.5} />

          <Grid item xs={11} md={5.5}>
            <Grid
                container
                direction="column"
                justifyContent="space-evenly"
                alignItems="center"
                style={{ height: "100%" }}
            >
              <Grid item>
                <Grid item sx={{ borderBottom: 1, borderColor: "secondary", padding: 3, paddingRight: 0, paddingLeft: 0 }}>
                  <Typography className={classes.headerLogo} align={"left"} style={{ fontSize: "2rem" }}>
                    {this.props.sloganTop}
                  </Typography>
                  <Typography sx={{ fontFamily: "Libre Franklin", fontSize: "1.75rem" }}>
                    {this.props.sloganBottom}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                      sx={{
                        borderBottom: 1,
                        textAlign: "justify",
                        padding: 2,
                        paddingLeft: 0,
                        paddingRight: 0,
                        fontFamily: "Libre Franklin",
                        fontSize: "1rem",
                        maxWidth: "450px",
                      }}
                  >
                    {this.props.description}
                  </Typography>
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: 4, paddingBottom: 4 }}>
                  {this.renderCTAButtons()}
                </Grid>
                <Grid item xs={12} sx={{ paddingTop: 3, paddingRight: 0 }}>
                  <Grid container direction="row" justifyContent="right" alignItems="center">
                    <Grid item>
                      {
                          !this.props.url.includes("take") && (
                              <Link
                                  onClick={this.goToAboutUs}
                                  sx={{
                                    padding: 0,
                                    margin: 0,
                                    color: "#bf0070",
                                  }}
                              >
                                About Us
                              </Link>
                          )
                      }

                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>


          <Grid
              item
              display={{ xs: "none", md: "block" }}
              md={5.5}
              sx={{
                // border: 2,
                // borderRadius: 2,
                height: "100%",
                // flexGrow: 1,
                backgroundSize: "contain",
                backgroundPosition: "center center",
                backgroundRepeat: "no-repeat",
                backgroundImage: `url(${IMAGE_hero})`,
              }}
          >
          </Grid>

          <Grid item xs={.5} />


        </Grid>
    );
  };
  renderCTAButtons = () => {
    return (
        <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Grid item xs={12} sm={6}>
            <TextField
                sx={{
                  width: "100%",
                  boxShadow: 4,
                  borderRadius: 4,
                }}
                onChange={(e) => this.setState({ bookName: e.target.value })}
                label={`book name`}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  style: {
                    textAlign: "center",
                    backgroundColor: "#ffffff",
                    height: "100%",
                  },
                }}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
                onClick={this._handleClick}
                sx={{ boxShadow: 4, fontSize: "1.2rem", height: "100%", width: "100%", minHeight: "52px" }}
                align="center"
                variant={"contained"}
                color="secondary"
            >
              CREATE
            </Button>
          </Grid>
        </Grid>
    );
  };
  _handleClick = () => {
    if (this.state.bookName?.length >= 4 && this.state.bookName?.length <= 18)
      createAnonAccount(this.props.firstVisitURL, this.state.bookName);
    else alert("Your book name must be between 4 and 18 characters");
  };
  renderFriendDialog = () => {
    return (
        <Grid container justifyContent={"left"} padding={0}>
          <Dialog open={this.state.friendDialogOpen} sx={{ margin: 10, padding: 0, minWidth: "350px" }}>
            <DialogTitle style={{ fontFamily: "Libre Franklin", borderBottom: 3 }}>
              <Grid container direction="row" justifyContent="space-around" alignItems="center">
                <Grid item xs={11}>
                  Invited By A Friend?
                </Grid>
                <Grid
                    item
                    xs={1}
                    onClick={() => {
                      this.setState({ friendDialogOpen: false });
                    }}
                >
                  <CloseIcon />
                </Grid>
              </Grid>
            </DialogTitle>

            <DialogContent sx={{ padding: 1, marginTop: 2, marginBottom: 2 }}>
              <Box sx={{ padding: 0, border: 1, borderRadius: 2, borderColor: "#ffe867" }}>
                <Grid padding={2}>
                  <Typography
                      sx={{
                        textAlign: "justify",
                        paddingTop: 0,
                        fontFamily: "Libre Franklin",
                        fontSize: "1rem",
                        maxWidth: "450px",
                      }}
                  >
                    {`Ask your friend for their unique signup link.`}
                  </Typography>
                  <Typography
                      sx={{
                        textAlign: "justify",
                        paddingTop: 2,
                        fontFamily: "Libre Franklin",
                        fontSize: "1rem",
                        maxWidth: "450px",
                      }}
                  >
                    {`Their link will look like this:`}
                  </Typography>
                  <Typography
                      sx={{
                        textAlign: "justify",
                        paddingTop: 2,
                        fontFamily: "Libre Franklin",
                        fontSize: "1rem",
                        maxWidth: "450px",
                      }}
                  >
                    {`superfunbet.com/join/gid9ekeje`}
                  </Typography>
                </Grid>
              </Box>
            </DialogContent>
          </Dialog>
        </Grid>
    );
  };

  render() {
    console.log("CTA this.props: ", this.props);
    return (<>

      {this.renderCTA()}
      {this.renderFriendDialog()}

    </>)
  }
}

CTA.propTypes = {
  classes: PropTypes.object.isRequired,
  firstVisitURL: PropTypes.string,
  anonAccountState: PropTypes.string,
  sloganTop: PropTypes.string,
  sloganBottom: PropTypes.string,
  description: PropTypes.string,
  url: PropTypes.string,
  history: PropTypes.array,
  uid: PropTypes.object.isRequired,
};

const styles = (theme) => ({
  dialogTitle: {
    color: theme.palette.primary.dark,
    paddingBottom: 0,
  },
  headerLogo: {
    fontFamily: "NeuveticaHeavyItalic",
    // fontStyle: 'italic',
    // color: "black",
    fontWeight: 700,
    fontSize: "2rem",
  },
  footerLogo: {
    fontFamily: "NeuveticaHeavyItalic",
    // fontStyle: 'italic',
    // color: "black",
    fontWeight: 700,
    fontSize: "1rem",
  },
  dialogSubtitle: {
    paddingTop: 10,
    color: theme.palette.primary.light,
  },
  paper: {
    marginTop: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  values: {
    borderColor: theme.palette.primary.light,
    color: theme.palette.primary.dark,
    borderWidth: 2,
    marginBottom: 15,
    alignItems: "left",
    padding: 0,
  },
});

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    firstVisitURL: state.newUserState.initialPage,
    anonAccountState: state.newUserState.anonAccountState,
  };
};

export default withRouter(connect(mapStateToProps)(withStyles(styles)(CTA)));
