import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import GenericBackHeader from "../../components/GenericBackHeader";
import { BottomNav } from "../../components";
import Box from "@mui/material/Box";

class pnlOverTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPnl: false,
    };
  }

  //
  // sx={{ '&:last-child td, &:last-child th': { border: 0 } }}

  getPnl(period) {
    // let b = this.props.bets;
    let b2 = this.props.bets2;
    let now = new Date().getTime();

    // let totalWin = Object.keys(b)
    //   .filter((i) => b[i].result && b[i].win && b[i].minGameStartTime)
    //   .filter((i) => b[i].result === "win")
    //   .filter((i) => period >= (now - b[i].minGameStartTime) / (60 * 60 * 24) / 1000)
    //   .reduce((i, j) => {
    //     return i + b[j].win;
    //   }, 0);

    let totalWin2 = Object.keys(b2)
      .filter((i) => b2[i].result && b2[i].win && b2[i].minGameStartTime)
      .filter((i) => b2[i].result === "win")
      .filter((i) => period >= (now - b2[i].minGameStartTime) / (60 * 60 * 24) / 1000)
      .reduce((i, j) => {
        return i + b2[j].win;
      }, 0);

    // let totalLoss = Object.keys(b)
    //   .filter((i) => b[i].result && b[i].win && b[i].minGameStartTime)
    //   .filter((i) => b[i].result === "loss")
    //   .filter((i) => period >= (now - b[i].minGameStartTime) / (60 * 60 * 24) / 1000)
    //   .reduce((i, j) => {
    //     return i + b[j].risk;
    //   }, 0);

    let totalLoss2 = Object.keys(b2)
      .filter((i) => b2[i].result && b2[i].win && b2[i].minGameStartTime)
      .filter((i) => b2[i].result === "loss")
      .filter((i) => period >= (now - b2[i].minGameStartTime) / (60 * 60 * 24) / 1000)
      .reduce((i, j) => {
        return i + b2[j].risk;
      }, 0);

    return (totalWin2 - totalLoss2).toFixed(0);
    // return (totalWin + totalWin2 - totalLoss - totalLoss2).toFixed(0);
  }

  renderPnl() {
    return (
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="right">Win/Loss</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={"bet"}>
              <TableCell component="th" scope="row">
                24 Hours
              </TableCell>
              <TableCell align="right" component="th" scope="row">
                {this.getPnl(1)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                7 Days
              </TableCell>
              <TableCell align="right" component="th" scope="row">
                {this.getPnl(7)}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                28 Days
              </TableCell>
              <TableCell align="right" component="th" scope="row">
                {this.getPnl(28)}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  renderPnlEmpty() {
    return (
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell align="right">Win/Loss</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow key={"bet"}>
              <TableCell component="th" scope="row">
                24 Hours
              </TableCell>
              <TableCell align="right" component="th" scope="row">
                0
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                7 Days
              </TableCell>
              <TableCell align="right" component="th" scope="row">
                0
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell component="th" scope="row">
                28 Days
              </TableCell>
              <TableCell align="right" component="th" scope="row">
                0
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  render() {
    console.log("user PNL this.props: ", this.props);
    console.log("user PNL this.state: ", this.state);

    return (
      <>
        <Box>
          <GenericBackHeader title="Daily Figures" showBackButton />
          {this.props.bets2 && this.renderPnl()}
          {!this.props.bets2 && this.renderPnlEmpty()}
        </Box>
        <BottomNav activeTab={2} managerGid={this.props.profile.managerGid} />
      </>
    );
  }
}

pnlOverTime.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func,
  trans: PropTypes.object,
  // bets: PropTypes.object,
  bets2: PropTypes.object,
  summary: PropTypes.object,
  profile: PropTypes.object,
};

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  pastBetsList: {
    width: "100%",
  },
  table: {
    minWidth: 650,
  },
});

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    email: state.firebase.auth.email,
    profile: state.firebase.profile,
    bets: state.firestore.data?.bets,
    bets2: state.firestore.data?.bets2,
    summary: state.firestore.data?.summary,
  };
};

export default compose(
  connect(mapStateToProps),
  firestoreConnect((props) => {
    console.log("---------------firestore connecting");
    console.log("---------------USer Account Summary - COMPOSE connect props", props);

    return [
      // {
      //   collection: `groups/${props.profile.gid}/bets/`,
      //   where: [["uid", "==", props.uid ? props.uid : false]],
      //   storeAs: "bets",
      // },
      {
        collection: `groups/${props.profile.gid}/bets2/`,
        where: [["uid", "==", props.uid ? props.uid : false]],
        storeAs: "bets2",
      },
      {
        collection: `groups/${props.profile.gid}/balances/`,
        doc: `${props.uid}`,
        storeAs: "summary",
      },
    ];
  })
)(withStyles(styles)(pnlOverTime));
