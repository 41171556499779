import { createAction, handleActions } from "redux-actions";

export const setInitialPage = createAction("SET_INITIAL_PAGE", (page) => ({ page }));

export const claimFreeAccount = createAction("CLAIM_FREE_ACCOUNT", (claiming) => ({ claiming }));

export const showSignInDialog = createAction("SHOW_SIGN_IN_DIALOG", (showDialog) => ({ showDialog }));

export const showSignUpDialog = createAction("SHOW_SIGN_UP_DIALOG", (showDialog) => ({ showDialog }));

export const newUserSignUpError = createAction("NEW_USER_SIGNUP_ERROR", (error) => ({ error }));

export const signInError = createAction("SIGN_IN_ERROR", (error) => ({ error }));

export const updateAnonAccountState = createAction("ANON_ACCOUNT_STATE", (d) => ({ d }));

const initialState = {
  initialPage: false,
  claimingFreeAccount: false,
  showSignInDialog: false,
  showSignUpDialog: false,
};

export default handleActions(
  {
    LOGOUT_SUCCESS: () => {
      return initialState;
    },
    SET_INITIAL_PAGE: (state, { payload }) => {
      if (state.initialPage === false) {
        return {
          ...state,
          initialPage: payload.page,
        };
      } else {
        return state;
      }
    },
    CLAIM_FREE_ACCOUNT: (state, { payload }) => {
      return {
        ...state,
        claimingFreeAccount: payload.claiming,
      };
    },
    SHOW_SIGN_IN_DIALOG: (state, { payload }) => {
      return {
        ...state,
        showSignInDialog: payload.showDialog,
      };
    },
    NEW_USER_SIGNUP_ERROR: (state, { payload }) => {
      console.log("new signup payload error: ",payload)
      return {
        ...state,
        signUpError: payload,
      };
    },
    SIGN_IN_ERROR: (state, { payload }) => {
      console.log("sign in payload error: ",payload)
      return {
        ...state,
        signInError: payload,
      };
    },
    SHOW_SIGN_UP_DIALOG: (state, { payload }) => {
      return {
        ...state,
        showSignUpDialog: payload.showDialog,
      };
    },
    ANON_ACCOUNT_STATE: (state, { payload }) => {
      return {
        ...state,
        anonAccountState: payload.d.status,
      };
    },
  },
  initialState,
);
