import React, {Component} from "react";

import PropTypes from "prop-types";
import {withRouter} from "react-router-dom";
import {Alert, ListItemButton, ListItemSecondaryAction, ListItemText, Snackbar,} from "@mui/material";
import {ContentCopy} from "@mui/icons-material";
import Button from "@mui/material/Button";
import {compose} from "redux";
import {connect} from "react-redux";
import {firestoreConnect} from "react-redux-firebase";
import withStyles from "@mui/styles/withStyles";

class ReferralLink extends Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  generateURL() {

    if (this.props.profile?.gid === "Y7bqQzvue9Mk3AamrIDKNaFpukN2")
      return "superfunbet.com/kelvin"
    else if (this.props.uid === "NLUUYJXONHee2Woo7ktVMFIquQC3")
      return "superfunbet.com/join/4cduVmV0vNoII1QucHpa"
    else if (this.props.uid === "J4fUKbP3CuhkpKHA6df8acZ88W72")
      return "superfunbet.com/join/iDlxBCFgmTPVYuYVTZuO"
    else if (this.props.uid === "fxKT8I0KIvTCbhI2k8u5nFCs0M93")
      return "superfunbet.com/join/3PfmaqsXa5SYbYkgtvOH"
    else if (this.props.uid === "VYMnLWO3yAe4Q5TpJWQhWOjfqfO2")
      return "superfunbet.com/join/WKgL0pPUMdx62VhPMHDj"
    else
      return "superfunbet.com/join/" + this.props.profile?.gid;
  }

  render() {
    if (this.props.groupConfigs) {
      return (
        <>
          {/*<Card sx={{ minWidth: 275, margin: 1 }}>*/}
          {/*  <CardContent>*/}
          {/*    {*/}
          {/*      this.props.message && (*/}
          {/*        <Grid item xs={12}>*/}
          {/*          <Typography>*/}
          {/*            {this.props.message}*/}
          {/*          </Typography>*/}
          {/*        </Grid>*/}
          {/*      )*/}
          {/*    }*/}
              <Button
                sx={{ width: "100%" }}
                style={{textTransform: 'none', marginTop: 10,}}
                variant="outlined"
                onClick={() => {
                  navigator.clipboard.writeText(this.generateURL()).then(() => this.setState({ copySnackBarOpen: true }));
                }}
              >
                <ListItemButton>
                  <ListItemText  style={{paddingTop: 15, marginTop: 0}} primary="Invite Friends" secondary={this.generateURL().slice(8)} />
                  <ListItemSecondaryAction >
                    <ContentCopy />
                  </ListItemSecondaryAction>
                </ListItemButton>
              </Button>
          {/*  </CardContent>*/}
          {/*</Card>*/}
          <Snackbar
            open={this.state.copySnackBarOpen}
            onClose={() => this.setState({ copySnackBarOpen: false })}
            autoHideDuration={6000}
            message={String("Copied: ")}
            severity="info"
            sx={{ bottom: { xs: 16, sm: 16 } }}
          >
            <Alert onClose={() => this.setState({ copySnackBarOpen: false })} severity="success" sx={{ width: "100%" }}>
              {String("Copied: " + this.generateURL())}
            </Alert>
          </Snackbar>
        </>
      );
    } else return null;
  }
}

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  linesArea: {
    width: "90%",
    margin: theme.spacing(2),
  },
  resultGrid: {
    height: 40,
  },
  pastBetsList: {
    width: "100%",
  },
  table: {
    minWidth: 650,
  },
});


ReferralLink.propTypes = {
  title: PropTypes.string.isRequired,
  showBackButton: PropTypes.bool.isRequired,
  history: PropTypes.object,
  message: PropTypes.string,
  groupConfigs: PropTypes.object,
  profile: PropTypes.object,
  uid: PropTypes.string
};

const mapStateToProps = (state) => {
  return {
    uid: state.firebase.auth.uid,
    email: state.firebase.auth.email,
    profile: state.firebase.profile,
    groupConfigs: state.firestore.data?.refLink_groupConfigs,
  };
};


export default compose(
  connect(mapStateToProps),
  withRouter,
  firestoreConnect((props) => {
    return [
      {
        collection: `groups`,
        doc: props.profile.managerGid,
        storeAs: "refLink_groupConfigs",
      },
    ];
  }),
)(withStyles(styles)(ReferralLink));
