import React, { useEffect } from "react";
import PropTypes from "prop-types";
import firebase from "../../../firebase";
import Typography from "@mui/material/Typography";

export function EntryPointsBar(props) {
  const auth = firebase.auth();
  const [user_id, setUser_id] = React.useState(false);
  const [playerName, setPlayerName] = React.useState("Loading...");

  useEffect(() => {
    if (!auth?.currentUser?.uid) return;
    setUser_id(auth.currentUser.uid);
    fetchPlayerName(auth.currentUser.uid);
  }, [auth?.currentUser?.uid]);

  const fetchPlayerName = async (uid) => {
    if (!uid) return;
    const db = firebase.firestore();
    const doc = await db.collection("users").doc(uid).get();
    setPlayerName(doc.data().username);
  };

  const calculatePercentage = (value, max) => {
    return (value / max) * 100;
  };

  const stringPercentage = (value, max) => {
    let x = calculatePercentage(value, max);
    return String(x) + "%";
  };

  const getName = () => {
    if (!props.hasLeaderData) {
      return <Typography>Max points:&nbsp;</Typography>;
    }
    if (user_id === props.entry.user_id) {
      return (
        <Typography>
          <i>{"Your"}</i>&nbsp;max points:&nbsp;
        </Typography>
      );
    } else {
      return <Typography>{playerName} max points:&nbsp;</Typography>;
    }
  };

  return (
    <>
      {/*<div style={{ marginBottom: 10, width: "100%", height: "20px", display: "flex" }}>*/}
      {/*    <Grid container>*/}
      {/*        <Grid item xs={6} textAlign={"left"}>*/}
      {/*            /!*{getName()}*!/*/}
      {/*        </Grid>*/}
      {/*        <Grid item xs={6} textAlign={"right"}>*/}
      {/*            /!*{props.entry.pointsPossible.toFixed(1)}*!/*/}
      {/*        </Grid>*/}
      {/*    </Grid>*/}
      {/*</div>*/}
      <div
        style={{
          width: stringPercentage(props.entry.pointsPossible, props.leaderPossiblePoints),
          height: "20px",
          display: "flex",
        }}
      >
        <div
          style={{
            flex: calculatePercentage(props.entry.pointsEarned, props.entry.pointsPossible),
            backgroundColor: "green",
            borderRadius: props.entry.pointsPending === 0 ? "10px 10px 10px 10px" : "10px 0px 0px 10px",
          }}
        ></div>
        <div
          style={{
            flex: calculatePercentage(props.entry.pointsPending, props.entry.pointsPossible),
            backgroundColor: "grey",
            borderRadius: props.entry.pointsEarned === 0 ? "10px 10px 10px 10px" : "0px 10px 10px 0px",
          }}
        ></div>
      </div>
      {props.hasLeaderData && (
        <div>
          <div style={{ marginBottom: 10, marginTop: 10, width: "100%", height: "20px", display: "flex" }}>
            Leader max points: {props.leaderPossiblePoints}
          </div>
          <div style={{ width: "100%", height: "20px", display: "flex" }}>
            <div
              style={{
                flex: calculatePercentage(props.leaderEarnedPoints, props.leaderPossiblePoints),
                backgroundColor: "green",
                borderRadius: props.leaderPendingPoints === 0 ? "10px 10px 10px 10px" : "10px 0px 0px 10px",
              }}
            ></div>
            <div
              style={{
                flex: calculatePercentage(props.leaderPendingPoints, props.leaderPossiblePoints),
                backgroundColor: "grey",
                borderRadius: props.leaderEarnedPoints === 0 ? "10px 10px 10px 10px" : "0px 10px 10px 0px",
              }}
            ></div>
          </div>
        </div>
      )}
    </>
  );
}

EntryPointsBar.propTypes = {
  entry: PropTypes.object.isRequired,
  hasLeaderData: PropTypes.bool.isRequired,
  leaderPendingPoints: PropTypes.number,
  leaderEarnedPoints: PropTypes.number,
  leaderPossiblePoints: PropTypes.number,
};
