import { alpha, FormControl, FormControlLabel, InputAdornment, styled, Switch, TextField } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import * as React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { connect } from "react-redux";
import SportsHockeyIcon from "@mui/icons-material/SportsHockey";
import SportsRugbyIcon from "@mui/icons-material/SportsRugby";
import SportsFootballIcon from "@mui/icons-material/SportsFootball";
import SportsBasketballIcon from "@mui/icons-material/SportsBasketball";
import SportsBaseballIcon from "@mui/icons-material/SportsBaseball";
import SportsKabaddiIcon from "@mui/icons-material/SportsKabaddi";
import { AccessibleForward, AutorenewSharp } from "@mui/icons-material";
import Line from "./components/Line";
import { compose } from "redux";
import { firestoreConnect } from "react-redux-firebase";
import LineStandard from "./components/Standard/LineStandard";
import SearchIcon from "@mui/icons-material/Search";

const CoinflipSwitch = styled(Switch)(({ theme }) => ({
    "& .MuiSwitch-switchBase.Mui-checked": {
        color: theme.palette.secondary.main,
        "&:hover": {
            backgroundColor: alpha(theme.palette.secondary.main, theme.palette.action.hoverOpacity),
        },
    },
    "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
        backgroundColor: theme.palette.secondary.main,
    },
    "& .MuiSwitch-switchBase": {
        color: theme.palette.secondary.main,
        "&:hover": {
            backgroundColor: alpha(theme.palette.primary.dark, theme.palette.action.hoverOpacity),
        },
    },
}));

class Lines2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isOpen: false,
            betPeriod: this.props.linesSource === "lines_standardized" ? "ft" : "num_0",
            userWantsCoinflips: false,
            searchText: "",
            searchTextLower: "",
            backgroundOn: false,
            coinflipHintOpen: false,
        };
    }

    americanJuiceFromPcnt = (newJuice) => {
        let impliedA = 0.5;
        impliedA += (impliedA * newJuice) / 100;
        return Math.round((impliedA / (1 - impliedA)) * -100);
    };

    getSportsIcon = (sport) => {
        const { classes } = this.props;
        if (sport === "NHL") {
            return <SportsHockeyIcon className={classes.sportsIcon} />;
        }
        if (sport === "NCAAMBB") {
            return <SportsBasketballIcon className={classes.sportsIcon} />;
        }
        if (sport === "NBA") {
            return <SportsBasketballIcon className={classes.sportsIcon} />;
        }
        if (sport === "MMA") {
            return <SportsKabaddiIcon className={classes.sportsIcon} />;
        }
        if (sport === "MLB") {
            return <SportsBaseballIcon className={classes.sportsIcon} />;
        }
        if (sport === "NFL" || sport === "NCAAF") {
            return <SportsFootballIcon className={classes.sportsIcon} />;
        }
        if (sport === "RUGBY" || sport === "NCAAF") {
            return <SportsRugbyIcon className={classes.sportsIcon} />;
        }
        if (sport === "Special") {
            return <AccessibleForward className={classes.sportsIcon} />;
        }
    };

    _changeSearch = (event) => {
        if (event.target.value === "") {
            // getFirebase().analytics().logEvent("SEARCH_BAR_CLEARED");
        } else {
            // getFirebase().analytics().logEvent("SEARCH_BAR_CHANGED");
        }
        this.setState({
            searchText: event.target.value,
            searchTextLower: event.target.value.toLowerCase(),
        });
    };

    renderToggles() {
        const { classes } = this.props;
        return (
            <Grid container direction="row" justifyContent="space-evenly" alignItems="center" style={{ paddingTop: 0 }}>
                <Grid item xs={6} sx={{ padding: 2 }}>
                    <TextField
                        padding={20}
                        id="standard-search"
                        variant="outlined"
                        label="Search Teams"
                        type="search"
                        onChange={this._changeSearch}
                        fullWidth
                        onFocus={(event) => {
                            event.target.select();
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        size="small"
                        color={"secondary"}
                        sx={{ background: "#FFFFFFAA" }}
                    />
                </Grid>

                <Grid item xs={6} sx={{ padding: 2 }}>
                    <Grid container direction="row" justifyContent="center" alignItems="center">
                        <Grid item>
                            <FormControl component="fieldset" variant="standard">
                                <FormControlLabel
                                    sx={{ margin: 0 }}
                                    control={
                                        <CoinflipSwitch
                                            checked={this.state.userWantsCoinflips}
                                            onChange={() => {
                                                if (!this.props.profile?.conversionPopups?.knowsWhatCoinflipsAre) {
                                                    console.log("DOESNT KNOW SHIT!");
                                                    this.setState({ coinflipHintOpen: true });
                                                }
                                                this.setState({ userWantsCoinflips: !this.state.userWantsCoinflips });
                                            }}
                                        />
                                    }
                                    label={<AutorenewSharp sx={{ color: "secondary" }} />}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Grid>


            </Grid>
        );
    }
    renderTogglesOJ() {
        const { classes } = this.props;
        return (
            <Grid container direction="row" justifyContent="flex-start" alignItems="center" style={{ paddingTop: 0 }}>
                <Grid item xs={6} sx={{ padding: 2 }}>
                    <TextField
                        padding={20}
                        id="standard-search"
                        variant="outlined"
                        label="Search Teams"
                        type="search"
                        onChange={this._changeSearch}
                        fullWidth
                        onFocus={(event) => {
                            event.target.select();
                        }}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        size="small"
                        color={"secondary"}
                        sx={{ background: "#FFFFFFAA" }}
                    />
                </Grid>
                {/*{(this.props.sport === "NFL" || this.props.sport === "NCAAF" || this.props.sport === "MLB-DEV") && (*/}
                {/*{(this.props.sport === "NFL") && (*/}
                {/*    <Grid item xs={3} sx={{ padding: 2 }}>*/}
                {/*        <Grid container direction="row" justifyContent="center" alignItems="center">*/}
                {/*            <Grid item>*/}
                {/*                <FormControl component="fieldset" variant="standard">*/}
                {/*                    <FormControlLabel*/}
                {/*                        sx={{ margin: 0 }}*/}
                {/*                        control={*/}
                {/*                            <CoinflipSwitch*/}
                {/*                                checked={this.state.betPeriod === "1h"}*/}
                {/*                                onChange={() => {*/}
                {/*                    Ï                if (this.state.betPeriod === "1h") this.setState({ betPeriod: "ft" });*/}
                {/*                                    else this.setState({ betPeriod: "1h" });*/}
                {/*                                }}*/}
                {/*                            />*/}
                {/*                        }*/}
                {/*                        label={"1H"}*/}
                {/*                    />*/}
                {/*                </FormControl>*/}
                {/*            </Grid>*/}
                {/*        </Grid>*/}
                {/*    </Grid>*/}
                {/*)}*/}

                <Grid
                    item
                    xs={6}
                    // xs={this.props.sport === "NFL" || this.props.sport === "NCAAF" || this.props.sport === "MLB-DEV" ? 3 : 6}
                    sx={{ padding: 2 }}
                >
                    {
                        // this.props.sport !== "World Cup" && (
                         (
                            <Grid container direction="row" justifyContent="center" alignItems="center">
                                <Grid item>
                                    <FormControl component="fieldset" variant="standard">
                                        <FormControlLabel
                                            sx={{ margin: 0 }}
                                            control={
                                                <CoinflipSwitch
                                                    checked={this.state.userWantsCoinflips}
                                                    onChange={() => {
                                                        if (!this.props.profile?.conversionPopups?.knowsWhatCoinflipsAre) {
                                                            console.log("DOESNT KNOW SHIT!");
                                                            this.setState({ coinflipHintOpen: true });
                                                        }
                                                        this.setState({ userWantsCoinflips: !this.state.userWantsCoinflips });
                                                    }}
                                                />
                                            }
                                            label={<AutorenewSharp sx={{ color: "secondary" }} />}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        )
                    }

                </Grid>
            </Grid>
        );
    }

    _renderLine = (input) => {
        const [lineID, ld] = input;
        if (this.props.linesSource === "lines_standardized") {
            return (
                <LineStandard
                    betPeriod={this.props.isLive ? this.props.betPeriod : this.state.betPeriod}
                    lineID={lineID}
                    sportsBucket={this.props.sportBucket}
                    juice={this.props.juice}
                    lineData={ld}
                    coinflipJuice={this.props.coinflipJuice}
                    isLive={this.props.isLive}
                    searchTerm={this.state.searchTextLower}
                    coinflip={this.state.userWantsCoinflips}
                    options={this.props.options}
                    key={lineID}
                    // liveWhitelist={this.props.liveWhitelist ? this.props.liveWhitelist : null}
                    liveBlacklist={this.props.liveBlacklist ? this.props.liveBlacklist : null}
                    lineSource={this.props.linesSource}
                />
            );
        }
        return (
            <Line
                betPeriod={this.props.isLive ? this.props.betPeriod : this.state.betPeriod}
                lineID={lineID}
                sportsBucket={this.props.sportBucket}
                juice={this.props.juice}
                lineData={ld}
                coinflipJuice={this.props.coinflipJuice}
                isLive={this.props.isLive}
                searchTerm={this.state.searchTextLower}
                coinflip={this.state.userWantsCoinflips}
                options={this.props.options}
                key={lineID}
                // liveWhitelist={this.props.liveWhitelist ? this.props.liveWhitelist : null}
                lineSource={this.props.linesSource}
            />
        );
    };

    _renderLineHeader = () => {
        const disableSpreads = this.props.options.disableSpreads || false;
        const onlyMoneylineNoDraw = this.props.options.onlyMoneylineNoDraw || false;
        const onlyMoneyline = this.props.options.onlyMoneyline || false;
        if (onlyMoneylineNoDraw) {
            return (
                <Grid
                    container
                    item
                    xs={12}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-evenly"
                    spacing={0}
                    width="100%"
                    sx={{
                        top: -1,
                        left: 0,
                        zIndex: 2,
                        position: "sticky",
                        backgroundColor: "rgba(255,255,255,0.80)",
                        // backgroundImage: `url(${sportsBackgroundImage})`,
                        // paddingRight: 1,
                        // paddingLeft: 1,
                        // borderBottom: 1,
                        borderBottomColor: "#ccccccee",
                        borderTop: 1,
                        borderTopColor: "#ffffffee",
                        // opacity: 0.9,
                    }}
                >
                    <Grid
                        item
                        sm={12}
                        width="100%"
                        sx={{
                            textAlign: "center",
                            borderRight: 1,
                            borderRightColor: "#ccccccee",
                            color: "#666666",
                            fontSize: ".65rem",
                        }}
                    >
                        Moneyline {this.state.userWantsCoinflips ? this.americanJuiceFromPcnt(this.props.coinflipJuice) : ""}
                    </Grid>
                </Grid>
            );
        }

        if (onlyMoneyline) {
            return (
                <Grid
                    container
                    item
                    xs={12}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-evenly"
                    spacing={0}
                    width="100%"
                    sx={{
                        top: -1,
                        left: 0,
                        zIndex: 2,
                        position: "sticky",
                        backgroundColor: "rgba(255,255,255,0.80)",
                        // backgroundImage: `url(${sportsBackgroundImage})`,
                        // paddingRight: 1,
                        // paddingLeft: 1,
                        // borderBottom: 1,
                        borderBottomColor: "#ccccccee",
                        borderTop: 1,
                        borderTopColor: "#ffffffee",
                        // opacity: 0.9,
                    }}
                >
                    <Grid
                        item
                        sm={12}
                        width="100%"
                        sx={{
                            textAlign: "center",
                            borderRight: 1,
                            borderRightColor: "#ccccccee",
                            color: "#666666",
                            fontSize: ".65rem",
                        }}
                    >
                        Moneyline {this.state.userWantsCoinflips ? this.americanJuiceFromPcnt(this.props.coinflipJuice) : ""}
                    </Grid>
                </Grid>
            );
        }

        return (
            // <Box sx={{ flexGrow: 1, background: "#ffffff" }}>
            <Grid
                container
                item
                xs={12}
                direction="row"
                alignItems="center"
                justifyContent="space-evenly"
                spacing={0}
                width="100%"
                sx={{
                    top: -1,
                    left: 0,
                    zIndex: 2,
                    position: "sticky",
                    backgroundColor: "rgba(255,255,255,0.80)",
                    // backgroundImage: `url(${sportsBackgroundImage})`,
                    // paddingRight: 1,
                    // paddingLeft: 1,
                    // borderBottom: 1,
                    borderBottomColor: "#ccccccee",
                    borderTop: 1,
                    borderTopColor: "#ffffffee",
                    // opacity: 0.9,
                }}
            >
                <Grid
                    item
                    sm={6}
                    xs={6}
                    width="100%"
                    sx={{
                        textAlign: "center",
                        borderRight: 1,
                        borderRightColor: "#ccccccee",
                        color: "#666666",
                        fontSize: ".65rem",
                    }}
                >
                    {this.state.betPeriod === "1h" ? "1st Half " : ""}
                    Moneyline {this.state.userWantsCoinflips ? this.americanJuiceFromPcnt(this.props.coinflipJuice) : ""}
                </Grid>

                {!disableSpreads && (
                    <Grid
                        item
                        sm={3}
                        xs={3}
                        width="100%"
                        sx={{
                            textAlign: "center",
                            borderRight: 1,
                            borderRightColor: "#ccccccee",
                            color: "#666666",
                            fontSize: ".65rem",
                        }}
                    >
                        {this.state.betPeriod === "1h" && !this.state.userWantsCoinflips ? "1st Half " : ""}
                        {this.state.betPeriod === "1h" && this.state.userWantsCoinflips ? "1H " : ""}
                        Spread {this.state.userWantsCoinflips ? this.americanJuiceFromPcnt(this.props.coinflipJuice) : ""}
                    </Grid>
                )}

                <Grid
                    item
                    sm={disableSpreads ? 6 : 3}
                    xs={disableSpreads ? 6 : 3}
                    width="100%"
                    sx={{ textAlign: "center", color: "#666666", fontSize: ".65rem" }}
                >
                    {this.state.betPeriod === "1h" && !this.state.userWantsCoinflips ? "1st Half " : ""}
                    {this.state.betPeriod === "1h" && this.state.userWantsCoinflips ? "1H " : ""}
                    O/U {this.state.userWantsCoinflips ? this.americanJuiceFromPcnt(this.props.coinflipJuice) : ""}
                </Grid>
            </Grid>
            // </Box>
        );
    };

    renderLines() {
        let lineItems = [];
        if (this.props.linesSource === "lines_standardized") {
            lineItems = Object.entries(this.props.lines)
                .filter(([line1Key, line1Data]) => line1Data && line1Data.starts_ms)
                .sort(([, lineData1], [, lineData2]) => lineData1.starts_ms - lineData2.starts_ms);
        } else {
            lineItems = Object.entries(this.props.lines).sort(([, lineData1], [, lineData2]) =>
                lineData1?.startsMS && lineData2?.startsMS ? lineData1.startsMS - lineData2.startsMS : -10000000
            );
        }

        return (
            // <Box sx={{ minHeight: "100vh", flexGrow: 1, backgroundImage: `url(${sportsBackgroundImage})` }}>
            // <Box sx={{ flexGrow: 1, background: "#ffffff" }}>
            <>
                {this.renderTogglesOJ()}
                {this._renderLineHeader()}
                <Grid container direction="column" justifyContent="center" alignItems="center">
                    {this.props.lines && lineItems.map(this._renderLine)}
                </Grid>
                {this.props.lines && this.props.lines.length === 0 && "No Lines"}
                {!this.props.lines && "Loading Lines"}
            </>
            // </Box>
        );
    }

    render() {
        console.log("LINES2 this.props", this.props.sport, this.props);
        console.log("LINES2 this.state", this.props.sport, this.state);
        return this.renderLines();
    }
}

Lines2.defaultProps = {
    isLive: false,
};

Lines2.propTypes = {
    classes: PropTypes.object.isRequired,
    sportsbookState: PropTypes.object,
    dispatch: PropTypes.func,
    uid: PropTypes.string,
    email: PropTypes.string,
    sport: PropTypes.string,
    lines: PropTypes.object,
    juice: PropTypes.number,
    coinflipJuice: PropTypes.number,
    parlayInflight: PropTypes.bool,
    description: PropTypes.string,
    options: PropTypes.object,
    our_last_update: PropTypes.number,
    period: PropTypes.string,
    sportsGroup: PropTypes.string,
    isLive: PropTypes.bool,
    sportBucket: PropTypes.string.isRequired,
    betPeriod: PropTypes.string,
    // liveWhitelist: PropTypes.object,
    liveBlacklist: PropTypes.object,
    profile: PropTypes.object,
    linesSource: PropTypes.string,
};

const styles = (theme) => ({
    root: {
        flexGrow: 1,
    },
    secondary: {
        color: theme.palette.secondary.main,
    },
    linesArea: {
        width: "100%",
        margin: 0, //theme.spacing(2),
    },
    resultGrid: {
        height: 40,
    },
    paper: {
        padding: theme.spacing(0),
        margin: 2,
        fontSize: ".85rem",
        textAlign: "center",
        height: "100%",
    },
    sportsIcon: {
        color: theme.palette.primary.light,
        marginRight: theme.spacing(2),
    },
    buttonGroup: {
        marginLeft: 8,
        outlineColor: theme.palette.secondary.main,
    },
    clickable: {
        cursor: "pointer",
    },
    shadow: {
        textShadow: "2px 2px 2px rgba(0,0,0,0.60);",
    },
});

const mapStateToProps = (state) => {
    return {
        parlayInflight: state.sportsbookState.parlayInflight,
        // liveWhitelist: state.firestore.data.liveWhitelist,
        liveBlacklist: state.firestore.data.liveBlacklist,
        profile: state.firebase.profile,
    };
};

export default compose(
    connect(mapStateToProps),
    firestoreConnect((props) => {
        return [
            // {
            //   collection: "globalOptions",
            //   doc: "liveWhitelist",
            //   storeAs: "liveWhitelist",
            // },
            {
                collection: "globalOptions",
                doc: "liveBlacklist",
                storeAs: "liveBlacklist",
            },
        ];
    })
)(withStyles(styles)(Lines2));